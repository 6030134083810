import { InputAdornment } from '@material-ui/core';
import { useStore } from 'effector-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { BaseModalTheme } from 'components/BaseModal';
import Checkbox, { CheckboxBase } from 'components/Checkbox';
import { ConfirmModal, getConfirmation } from 'components/ConfirmModal';
import Button from 'components/form/Button';
import TextField from 'components/form/TextField';
import Icon from 'components/Icon';
import LoadMoreButton from 'components/LoadMoreButton';
import Preloader from 'components/Preloader';
import ResetFilters from 'components/ResetFilters';
import { enqueueSnackbar } from 'components/Snackbar';
import EnhancedTableHead, { IHeadCell } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import GridTable from 'components/table/GridTable';
import { Typo } from 'components/typography/Typo';
import { AssetRecipient } from 'models/assetDetails/dto';
import { assetRecipientsModel } from 'models/assetDetails/model';
import { AssetDetailJson, AssetJson } from 'models/assets/dto';
import { createAssetFx, getAssetByIdFx, updateAssetFx } from 'models/assets/effects';
import { toLocaleString } from 'services/numbers';
import { pluralize } from 'services/strings';
import AddToAssetModal from './AddToAssetModal';
import CreateAssetModal from './CreateAssetModal';
import HighlightedText from './HighlightedText';
import styles from './index.module.css';

const EMPTY_ASSET: AssetDetailJson = {
	created_at: 0,
	description: '',
	groups: [],
	cluster_id: 0,
	id: 0,
	is_deleted: false,
	is_external: true,
	k8s_types: [],
	labels: [],
	name: '',
	namespace: '',
	owner: '',
	rules: [],
	type: 'custom',
	updated_at: 0,
};

const tableConfig: IHeadCell<AssetRecipient>[] = [
	{
		id: 'action',
		label: '',
	},
	{
		id: 'ip',
		label: 'IP',
	},
	{
		id: 'user_agent',
		label: 'User agent',
	},
];

function noop() {
	return undefined;
}

type Props = {
	asset: AssetJson;
};

function AssetRecipients(props: Props) {
	const { asset } = props;

	const recipientStore = useStore(assetRecipientsModel.store);

	const [isSelectMode, setSelectMode] = useState(false);
	const [selected, setSelected] = useState<
		Map<AssetRecipient['user_agent'], Set<AssetRecipient['ip']>>
	>(new Map());

	const assetIdRef = useRef(0);
	const newAssetRef = useRef({ name: '', description: '' });

	useEffect(() => {
		assetRecipientsModel.fetchFx({
			asset: asset.id,
			search: '',
			invert: false,
		});
	}, [asset.id]);

	const highlightRegex = useMemo(() => {
		// Approach from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
		const searchEscaped = recipientStore.params.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
		return new RegExp(searchEscaped, 'i');
	}, [recipientStore.params.search]);

	function onRecipientSearchChange(search: string) {
		assetRecipientsModel.fetchFx({ search });
	}

	function onRecipientInvertChange(invert: boolean) {
		assetRecipientsModel.fetchFx({ invert });
	}

	function onResetFilters() {
		const newParams = {
			search: '',
			invert: false,
		};

		assetRecipientsModel.fetchFx(newParams);
	}

	function toggleSelected(recipient: { ip: string; user_agent: string }) {
		const { ip, user_agent } = recipient;
		const result = new Map(selected);

		if (!result.has(user_agent)) result.set(user_agent, new Set());

		const byUserAgent = result.get(user_agent)!;

		if (byUserAgent.has(ip)) {
			byUserAgent.delete(ip);
		} else {
			byUserAgent.add(ip);
		}

		setSelected(result);
	}

	const totalSelected = useMemo(() => {
		let result = 0;
		for (const ips of selected.values()) {
			result += ips.size;
		}

		return result;
	}, [selected]);

	async function onResetSelection() {
		if (totalSelected === 0) {
			// Nothing to reset! Just return.
			return;
		}

		const modal = (
			<ConfirmModal
				title="Reset selection"
				confirmProps={{
					children: 'Reset',
				}}
			>
				Do you want to reset selection?
				<br />
				This action cannot be undone.
			</ConfirmModal>
		);

		const agree = await getConfirmation(modal);

		if (!agree) return;

		setSelected(new Map());
	}

	async function onExitSelection() {
		if (totalSelected === 0) {
			// Nothing to reset! Just exit select mode and return.
			setSelectMode(false);
			return;
		}

		const modal = (
			<ConfirmModal
				title="Exit select mode"
				confirmProps={{
					children: 'Exit',
				}}
			>
				Do you want to exit select mode?
				<br />
				This action cannot be undone.
			</ConfirmModal>
		);

		const agree = await getConfirmation(modal);

		if (!agree) return;

		setSelected(new Map());
		setSelectMode(false);
	}

	async function onCreateAsset() {
		if (totalSelected === 0) {
			// Nothing to create services from! Just return.
			return;
		}

		const modal = (
			<ConfirmModal
				title="Save selection as new custom connection"
				theme={BaseModalTheme.normal}
				confirmProps={{
					children: 'Save',
				}}
			>
				<CreateAssetModal newAssetRef={newAssetRef} />
			</ConfirmModal>
		);

		const agree = await getConfirmation(modal);
		const newAssetFields = newAssetRef.current;

		if (!agree || !newAssetFields) return;

		const rules: AssetDetailJson['rules'] = [];

		for (const [userAgent, ips] of selected) {
			if (ips.size > 0)
				rules.push([
					{ type: 'header', key: 'User-Agent', values: [userAgent] },
					{ type: 'ip', key: '', values: [...ips] },
				]);
		}

		try {
			await createAssetFx({ ...EMPTY_ASSET, ...newAssetFields, rules });
			enqueueSnackbar(
				`${totalSelected} ${pluralize('recipient', totalSelected)} successfully saved as ${
					newAssetFields.name
				}`
			);
			assetRecipientsModel.fetchFx({});
			setSelected(new Map());
		} catch (e) {
			enqueueSnackbar('Error while saving recipients to custom connection');
		}
	}

	async function onAddToAsset() {
		if (totalSelected === 0) {
			// Nothing to add to asset! Just return.
			return;
		}

		const modal = (
			<ConfirmModal
				title="Add selection to custom connection"
				theme={BaseModalTheme.normal}
				confirmProps={{
					children: 'Add',
				}}
			>
				<AddToAssetModal assetIdRef={assetIdRef} />
			</ConfirmModal>
		);

		const agree = await getConfirmation(modal);
		const assetId = assetIdRef.current;

		if (!agree || !assetId) return;

		const rules: AssetDetailJson['rules'] = [];

		for (const [userAgent, ips] of selected) {
			if (ips.size > 0)
				rules.push([
					{ type: 'header', key: 'User-Agent', values: [userAgent] },
					{ type: 'ip', key: '', values: [...ips] },
				]);
		}

		try {
			const assetToAddTo = await getAssetByIdFx(assetId);
			await updateAssetFx({ ...assetToAddTo, rules: [...assetToAddTo.rules, ...rules] });
			enqueueSnackbar(
				`${totalSelected} ${pluralize('recipient', totalSelected)} successfully added to ${
					assetToAddTo.name
				}`
			);
			assetRecipientsModel.fetchFx({});
			setSelected(new Map());
		} catch (e) {
			enqueueSnackbar('Error while adding recipients to custom connection');
		}
	}

	const totalFiltered = toLocaleString(recipientStore.total_filtered || recipientStore.total);
	const title = isSelectMode ? (
		<>
			{totalSelected} of {totalFiltered} recipients selected
		</>
	) : (
		<>{totalFiltered} recipients found</>
	);

	const hasFilter = !!recipientStore.params.search || recipientStore.params.invert;

	return (
		<div className={styles.container}>
			<div className={styles.controlsContainer}>
				<div className={styles.infoAndButtonsContainer}>
					<Typo variant="D/Medium/Body-S" className={styles.info}>
						{title}
					</Typo>

					<div className={styles.buttonsContainer}>
						{isSelectMode ? (
							<>
								<Button
									size="extraSmall"
									color="tertiary"
									onClick={onCreateAsset}
									data-test="recipients-save-button"
								>
									Save as new custom connection
								</Button>
								<Button
									size="extraSmall"
									color="tertiary"
									onClick={onAddToAsset}
									data-test="recipients-add-button"
								>
									Add to custom connection
								</Button>
								<Button
									size="extraSmall"
									color="tertiary"
									theme="danger"
									onClick={onResetSelection}
									data-test="recipients-reset-button"
								>
									Reset
								</Button>
								<Button
									size="extraSmall"
									color="ghost"
									onClick={onExitSelection}
									data-test="recipients-cancel-button"
								>
									Cancel
								</Button>
							</>
						) : (
							<Button
								size="extraSmall"
								color="tertiary"
								onClick={() => setSelectMode(true)}
								key="select"
								data-test="recipients-select-button"
							>
								Select
							</Button>
						)}
					</div>
				</div>

				<div className={styles.searchContainer}>
					<TextField
						value={recipientStore.params.search}
						onChange={(e) => {
							onRecipientSearchChange(e.target.value);
						}}
						placeholder="Search"
						size="small"
						helperText={null}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon name="search" size={20} />
								</InputAdornment>
							),
						}}
						data-test="recipient-search-input"
					/>

					<Checkbox
						size="M"
						dataTest="recipient-invert-checkbox"
						checked={recipientStore.params.invert}
						onChange={({ target }) => {
							onRecipientInvertChange(target.checked);
						}}
						label="Invert search results"
					/>
				</div>
			</div>

			<div className={styles.tableContainer}>
				<GridTable dataTest="recipients-table" className={styles.table}>
					<EnhancedTableHead
						config={tableConfig}
						onRequestSort={noop}
						onRequestFilter={noop}
						rowClassname={styles.rowContainer}
					/>

					<Preloader
						isLoading={recipientStore.status === 'initial' || recipientStore.status === 'loading'}
					>
						<GridBody data-test="recipients-list">
							<>
								{recipientStore.data.length ? (
									recipientStore.data.map((recipient, i) => {
										const isSelected =
											selected.get(recipient.user_agent)?.has(recipient.ip) || false;

										return (
											<GridRow
												key={i}
												className={styles.rowContainer}
												onClick={isSelectMode ? () => toggleSelected(recipient) : undefined}
												border
												hover={isSelectMode}
											>
												<GridCell className={styles.checkbox}>
													{isSelectMode && (
														<CheckboxBase checked={isSelected} className={styles.tableCheckbox} />
													)}
												</GridCell>

												<GridCell dataTest="recipients-item-ip">
													<HighlightedText
														highlightRegex={highlightRegex}
														highlightLength={recipientStore.params.search.length}
													>
														{recipient.ip}
													</HighlightedText>
												</GridCell>

												<GridCell dataTest="recipients-item-useragent">
													<HighlightedText
														highlightRegex={highlightRegex}
														highlightLength={recipientStore.params.search.length}
													>
														{recipient.user_agent}
													</HighlightedText>
												</GridCell>
											</GridRow>
										);
									})
								) : hasFilter ? (
									<ResetFilters
										onReset={onResetFilters}
										description="No results matching these filters."
									/>
								) : (
									<Typo>No results</Typo>
								)}

								<LoadMoreButton
									show={recipientStore.hasMoreData}
									loading={recipientStore.status === 'loadingMore'}
									request={assetRecipientsModel.fetchMoreFx}
								/>
							</>
						</GridBody>
					</Preloader>
				</GridTable>
			</div>
		</div>
	);
}

export default AssetRecipients;
