import { get } from 'api/Api';
import { dataTypesStore } from 'models/dataTypes/store';
import checkResponse from 'services/api/checkResponse';
import {
	DataDistributionByRegions,
	DataStoresOverview,
	DiscoveredDataTypeGroups,
	PolicyEventsByCategory,
	PolicyEventsByName,
} from './dto';
import {
	dataDistributionByRegionsExample,
	dataStoresOverviewExample,
	discoveredDataTypeGroupsExample,
	policyEventsByCategoryExample,
	policyEventsByNameExample,
} from './examples';

async function getDataDistributionByRegions() {
	return get<DataDistributionByRegions>('v2/data-distribution-regions')
		.catch(() => dataDistributionByRegionsExample) // TODO remove mocks
		.then(checkResponse(dataDistributionByRegionsExample));
}

async function getDataStoresOverview() {
	return get<DataStoresOverview>('v2/data-stores-statistic')
		.catch(() => dataStoresOverviewExample) // TODO remove mocks
		.then(checkResponse(dataStoresOverviewExample));
}

async function getDiscoveredDataTypeGroups() {
	return get<DiscoveredDataTypeGroups>('v2/discovered-data-types/groups')
		.catch(() => discoveredDataTypeGroupsExample) // TODO remove mocks
		.then((res) => {
			const dTs = dataTypesStore.getState();

			const map: { [key: number]: string } = {
				52: 'PII',
				53: 'PCI',
				233: 'DEVELOPER_SECRETS',
				214: 'CUSTOM',
			};

			const processed = {
				...res,
				data_types: res.data_types.map((dt) => ({
					...dt,
					data_type: dTs.find((dtt) => dtt.alias === (map[dt.data_type] || 52))?.id || dTs[0].id,
				})),
			};

			return processed;
		})
		.then(checkResponse(discoveredDataTypeGroupsExample));
}

async function getPolicyEventsByCategory() {
	return get<PolicyEventsByCategory>('v2/policy-log-events/categories')
		.catch(() => policyEventsByCategoryExample) // TODO remove mocks
		.then(checkResponse(policyEventsByCategoryExample));
}

async function getPolicyEventsByName() {
	return get<PolicyEventsByName>('v2/policy-log-events/counters?limit=4')
		.catch(() => policyEventsByNameExample) // TODO remove mocks
		.then(checkResponse(policyEventsByNameExample));
}

export {
	getDataDistributionByRegions,
	getDataStoresOverview,
	getDiscoveredDataTypeGroups,
	getPolicyEventsByCategory,
	getPolicyEventsByName,
};
