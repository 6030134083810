import { useCallback } from 'react';
import { DraftFunction } from 'use-immer';
import TextField from 'components/form/TextField';
import { PolicyItem } from 'models/policiesV2/dto';
import styles from '../index.module.css';

type Props = {
	value: string;
	setFormData: (arg: DraftFunction<PolicyItem>) => void;
};

function PolicyDescription(props: Props) {
	const { value, setFormData } = props;

	const onChange = useCallback((description) => {
		setFormData((draft) => {
			draft.description = description;
		});
	}, []);

	return (
		<div className={styles.name}>
			<TextField
				dataTest="policy-description-input"
				helperText={null}
				variant="outlined"
				placeholder="Policy description"
				multiline={true}
				value={value}
				onChange={(e) => onChange(e.target.value)}
			/>
		</div>
	);
}

export { PolicyDescription };
