import { useStore } from 'effector-react';
import { MutableRefObject, useEffect, useMemo, useState } from 'react';
import Radio from 'components/Radio';
import { Typo } from 'components/typography/Typo';
import { getAssetsFx } from 'models/assets/effects';
import { assetsList } from 'models/assets/store';
import styles from './index.module.css';

type Props = {
	assetIdRef: MutableRefObject<number>;
};

function AddToAssetModal(props: Props) {
	const { assetIdRef } = props;

	const assets = useStore(assetsList);
	const customAssets = useMemo(() => assets.filter((asset) => asset.type === 'custom'), [assets]);

	const [selectedAsset, setSelectedAsset] = useState(0);

	useEffect(() => {
		getAssetsFx();
	}, []);

	assetIdRef.current = selectedAsset;

	return (
		<>
			<Typo variant="D/Regular/Body-S">Choose the connection to add the recipients to it.</Typo>

			<div className={styles.assetRadioGroup}>
				{customAssets.length === 0 ? (
					<Typo color="secondary">No custom connection created yet.</Typo>
				) : (
					customAssets.map(({ id, name }) => (
						<Radio
							name="assetId"
							onChange={({ target }) => {
								setSelectedAsset(Number(target.value));
							}}
							value={String(id)}
							label={name}
							className={styles.assetRadio}
							key={id}
							dataTest="asset-name-button"
						/>
					))
				)}
			</div>
		</>
	);
}

export default AddToAssetModal;
