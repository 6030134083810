import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import Button from 'components/form/Button';
import TextField from 'components/form/TextField';
import { enqueueSnackbar } from 'components/Snackbar';
import rowStyles from 'components/table/GridRow/index.module.pcss';
import { Typo } from 'components/typography/Typo';
import { ClientItem } from 'models/clients/dto';
import { updateClientFx } from 'models/clients/effects';
import { APIError } from 'services/api/httpRequest';
import { TStatus } from '../ClientItem';
import styles from './index.module.pcss';

interface Props {
	client: ClientItem;
	isBlocked: boolean;
	isCurrent: boolean;
	itemStatus: TStatus;
	setItemStatus: (status: TStatus) => void;
	fullName: string;
}

function ClientName({ client, isBlocked, itemStatus, setItemStatus, fullName, isCurrent }: Props) {
	const [editMode, setEditMode] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [changedName, setChangedName] = useState<string>(fullName);

	useEffect(() => {
		if (itemStatus === 'delete' || itemStatus === 'stable') {
			cancelEditing();
		}
	}, [itemStatus]);

	useEffect(() => {
		setChangedName(fullName);
	}, [client]);

	function cancelEditing() {
		setChangedName(fullName);
		endEditMode();
	}

	function startEditMode() {
		setItemStatus('edit');
		setEditMode(true);
	}

	function endEditMode() {
		setEditMode(false);
		setItemStatus('stable');
	}

	const disableSaveButton = useMemo(
		() => changedName === fullName || !changedName,
		[changedName, fullName]
	);

	const saveClientName = async () => {
		if (changedName === fullName || !changedName) {
			return; // Don't try to send new full_name if it makes no sense.
		}

		setIsLoading(true);

		try {
			await updateClientFx({ id: client.client_id, full_name: changedName });

			endEditMode();
		} catch (err) {
			if (err instanceof APIError) {
				const { message } = await err.response.json();

				enqueueSnackbar(message);
			} else {
				throw err;
			}
		}
		setIsLoading(false);
	};

	return (
		<>
			<div className={styles.nameContainer}>
				{!editMode && (
					<>
						<Typo
							variant="D/Medium/Body-S"
							color={isBlocked ? 'secondary' : 'primary'}
							className={styles.nameText}
						>
							{changedName}
						</Typo>

						{isCurrent && (
							<Typo variant="D/Regular/Body-S" color="secondary">
								(you)
							</Typo>
						)}

						{!isBlocked && (
							<ButtonIcon
								size="XS"
								icon="pencil"
								onClick={startEditMode}
								className={cn(styles.editButton, rowStyles.showWhenFocus)}
								dataTest="client-name-edit-button"
							/>
						)}
					</>
				)}

				{editMode && (
					<>
						<TextField
							required
							autoFocus={true}
							className={styles.nameInput}
							optional={false}
							value={changedName}
							onChange={(e) => setChangedName(e.target.value)}
							helperText={null}
							size="extraSmall"
							disabled={isLoading}
							dataTest="client-name-input"
						/>

						<Button
							size="extraSmall"
							color="tertiary"
							onClick={saveClientName}
							disabled={disableSaveButton}
							className={styles.button}
							loading={isLoading}
							data-test="client-name-save-button"
						>
							Save
						</Button>

						{!isLoading && (
							<Button
								size="extraSmall"
								color="transparent"
								onClick={cancelEditing}
								className={cn(styles.button, styles.cancelButton)}
								data-test="client-name-cancel-button"
							>
								Cancel
							</Button>
						)}
					</>
				)}
			</div>

			<Typo variant="D/Regular/Meta" color="secondary">
				{client.email}
			</Typo>
		</>
	);
}

export default ClientName;
