import cn from 'classnames';
import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import Preloader from 'components/Preloader';
import SensitivityChip from 'components/SensitivityChip';
import { enqueueSnackbar } from 'components/Snackbar';
import { Tab, TabPanel, Tabs } from 'components/Tabs';
import { Tooltip } from 'components/Tooltip';
import { Typo } from 'components/typography/Typo';
import { DatabaseDetails as DatabaseDetailsType } from 'models/databases/dto';
import { getNoSQLDatabaseDetails } from 'models/noSQLDatabases/api';
import {
	NoSQLDatabaseDetails as NoSQLDatabaseDetailsType,
	NoSQLDatabaseInstanceBase,
} from 'models/noSQLDatabases/dto';
import { noSQLDatabaseInstancesById } from 'models/noSQLDatabases/store';
import { lastSeen } from 'services/lastSeen';
import { toFileSize, toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { DASH_SIGN } from 'services/strings';
import { unselectInteractsWith } from '../../model/store';
import CloseButton from '../CloseButton';
import commonStyles from '../index.module.css';
import Label from '../Label';
import { Collections } from './Collections';
import styles from './index.module.pcss';
type Props = {
	databaseId: DatabaseDetailsType['id'];
};

export function NoSQLDatabaseDetails({ databaseId }: Readonly<Props>) {
	const [database, setDatabase] = useState<NoSQLDatabaseDetailsType | null>(null);
	const [instance, setInstance] = useState<NoSQLDatabaseInstanceBase | null>(null);
	const [loading, setLoading] = useState(false);
	const databaseInstances = useStore(noSQLDatabaseInstancesById);

	useEffect(() => {
		setLoading(true);

		getNoSQLDatabaseDetails(databaseId)
			.then((db) => {
				setDatabase(db);
				setInstance(databaseInstances[db.instance_id]);
			})
			.catch(async (e) => {
				if (e.response.status === 422 || e.response.status === 400) {
					const errorBody = await e.response.json();
					enqueueSnackbar(errorBody.message);
				} else {
					throw e;
				}
			})
			.finally(() => {
				setLoading(false);
			});

		return unselectInteractsWith;
	}, [databaseId]);

	return (
		<div
			className={cn(commonStyles.container, commonStyles.sidePaddings)}
			data-test="data-map-details-database"
		>
			<Preloader isLoading={loading}>
				{database && instance && <DatabaseDetailsContent database={database} instance={instance} />}
			</Preloader>
		</div>
	);
}

interface DatabaseDetailsContentProps {
	database: NoSQLDatabaseDetailsType;
	instance: NoSQLDatabaseInstanceBase;
}

function DatabaseDetailsContent({ database, instance }: Readonly<DatabaseDetailsContentProps>) {
	const [tab, setTab] = useState<'summary' | 'collections'>('summary');
	const lastCheckedValue = useMemo(
		() => lastSeen({ timestamp: database.last_checked || 0, short: true }),
		[database.last_checked]
	);

	return (
		<>
			<div className={commonStyles.header}>
				<Typo variant="D/Medium/H100-Header">{database.name}</Typo>

				<CloseButton />
			</div>

			<Typo variant="D/Regular/Body-S" color="secondary">
				{instance.name}
			</Typo>

			<Tabs value={tab} onChange={setTab}>
				<Tab value="summary" label="Summary" />
				<Tab value="collections" label="Collections" />
			</Tabs>

			<TabPanel value={tab} index="summary" className={commonStyles.tabPanel}>
				<div className={commonStyles.detailsGrid}>
					<Label title="Resource">Database</Label>

					<Label title="Resource type">
						<Typo variant="D/Regular/Body-S" className={styles.type}>
							{instance?.type}
						</Typo>
					</Label>

					<Label title="Description">{database.description || DASH_SIGN}</Label>

					<Label title="Owner">{database.owner || DASH_SIGN}</Label>

					<Label title="Collections">{toLocaleString(database.collections_count)}</Label>

					<Label title="Size">{toFileSize(database.size)}</Label>

					<Label title="sensitivity">
						<SensitivityChip sensitivity={database.sensitivity} />
					</Label>

					<Label title="Data types">
						<PiiTypeList noHighlight data={database.data_types} />
					</Label>

					<Label title="Instance">{instance?.name}</Label>

					<Label title="Last checked">
						<Tooltip title={lastCheckedValue.date} className={styles.detailsValue}>
							{lastCheckedValue.diff}
						</Tooltip>
					</Label>
				</div>

				<Button
					color="tertiary"
					size="small"
					endIcon={<Icon name="Open/Regular" size={20} />}
					fullWidth
					href={generatePath(PATHS.NOSQL_DATABASES_ITEM, { id: database.id })}
					target="_blank"
					className={commonStyles.detailedInfo}
				>
					View database page
				</Button>
			</TabPanel>

			<TabPanel
				value={tab}
				index="collections"
				className={cn(styles.tabPanel, commonStyles.tabPanel)}
			>
				<Collections databaseId={database.id} />
			</TabPanel>
		</>
	);
}
