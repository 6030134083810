import Icon from 'components/Icon';
import { RouterLink } from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import { methodOrProtocol } from 'models/endpointsV2/dto';
import {
	ClusteringEventJson,
	EventAsset,
	EventWithDIMSample,
	ExternalAssetConflictJson,
} from 'models/eventsV2/dto';
import { getSample } from 'models/samplesV2/api';
import { PATHS } from 'services/router';
import styles from '../index.module.pcss';
import { SampleExistsIcon } from './SampleExistsIcon';

export function Endpoint({
	event,
	sendingAsset,
}: {
	event: EventWithDIMSample | ClusteringEventJson | ExternalAssetConflictJson;
	sendingAsset: EventAsset;
}) {
	const pathToSample = `${PATHS.SAMPLE_V2}?id=${event.endpoint_id}&direction=${event.direction}`;

	return (
		<>
			<span className={styles.detailsLabel}>Endpoint</span>
			<RouterLink
				inherit
				to={pathToSample}
				data-test="event-details-endpoint"
				state={{
					sample: {
						method: event.method,
						protocol: event.protocol,
						host: event.hostname,
						url: event.url,
					},
					asset: sendingAsset,
				}}
			>
				{event.hostname}
				{event.url}
				<SampleExistsIcon getSampleFn={getSample} sampleId={event.endpoint_id} />

				<Typo variant="D/Regular/Meta" color="secondary" className={styles.method}>
					{methodOrProtocol(event)}
					{event.is_encrypted && <Icon name="tlsRegular" dataTest="tls-icon" />}
					{event.is_mesh_network && (
						<Icon name="MeshNetwork/Regular" dataTest="mesh-network-icon" />
					)}
				</Typo>
			</RouterLink>
		</>
	);
}
