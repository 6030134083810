import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Preloader from 'components/Preloader';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import {
	PolicyItem as PolicyItemType,
	DIMPolicyItem,
	DARPolicyItem,
	isDIMPolicy,
	isDARPolicy,
} from 'models/policiesV2/dto';
import { getPolicyItemFx } from 'models/policiesV2/effects';
import { PolicyItemFormDAR } from './PolicyItemForm/PolicyItemFormDAR';
import { PolicyItemFormDIM } from './PolicyItemForm/PolicyItemFormDIM';

const LS_POLICY_DUPLICATE_KEY = 'policy-duplicate';

const EMPTY_POLICY_DIM: DIMPolicyItem = {
	id: 0,
	type: 'dim',
	data_types: [],
	name: '',
	description: '',
	tags: [],
	is_active: false,
	rules: [[{ type: 'namespace', operator: 'is', key: '', values: [] }]],
	created_at: 0,
	updated_at: 0,
	allow_external_transfer: true,
	allow_encrypted_network_only: false,
	allowed_external_assets: [],
};

const EMPTY_POLICY_DAR: DARPolicyItem = {
	id: 0,
	type: 'dar',
	data_types: [],
	name: '',
	description: '',
	tags: [],
	is_active: false,
	rules: [[]],
	created_at: 0,
	updated_at: 0,
	allow_unencrypted_s3_buckets: true,
	allow_public_s3_buckets: true,
	allow_unencrypted_rds: true,
};

// "My Policy" -> "My Policy Copy" -> "My Policy Copy 2" -> "My Policy Copy 3"
function duplicatePolicyName(policyName: string) {
	const copyPattern = / Copy( \d+)?$/;

	const match = policyName.match(copyPattern);

	if (!match) {
		return `${policyName} Copy`;
	} else if (!match[1]) {
		return `${policyName} 2`;
	} else {
		const nextNumber = String(Number(match[1]) + 1);
		return policyName.replace(/\d+$/, nextNumber);
	}
}

function copyPolicyFromLocalstorage() {
	const data = localStorage.getItem(LS_POLICY_DUPLICATE_KEY);
	if (!data) return null;

	localStorage.removeItem(LS_POLICY_DUPLICATE_KEY);

	try {
		const parsedPolicy = JSON.parse(data);
		return {
			...parsedPolicy,
			id: 0,
			name: duplicatePolicyName(parsedPolicy.name),
			is_active: false,
		};
	} catch (e) {
		console.warn(`invalid data in ${LS_POLICY_DUPLICATE_KEY}`);
		return null;
	}
}

function PolicyItem() {
	const [isLoading, setIsLoading] = useState(true);
	const [policy, setPolicy] = useState<PolicyItemType | null>(null);

	const { id: policyId }: { id: string } = useParams();
	const location = useLocation();

	const dataTypeIds = useMemo(() => {
		const query = new URLSearchParams(location.search);
		const dataTypeId = Number(query.get('data_type'));
		const result = !Number.isNaN(dataTypeId) && dataTypeId !== 0 ? [dataTypeId] : [];

		return result;
	}, [location.search]);

	useEffect(() => {
		switch (true) {
			case policyId === 'new':
				setPolicy({ ...EMPTY_POLICY_DIM, data_types: dataTypeIds });
				setIsLoading(false);
				break;

			case policyId === 'new-dar':
				setPolicy({ ...EMPTY_POLICY_DAR, data_types: dataTypeIds });
				setIsLoading(false);
				break;

			case policyId === 'new-copy': {
				const duplicatedPolicy = copyPolicyFromLocalstorage();

				setPolicy(duplicatedPolicy);
				setIsLoading(false);
				break;
			}

			case isNaN(Number(policyId)):
				setPolicy(null);
				setIsLoading(false);
				break;

			default:
				getPolicyItemFx(Number(policyId))
					.then((resp) => {
						setPolicy(resp);
						setIsLoading(false);
					})
					.catch(() => {
						setPolicy(null);
						setIsLoading(false);
					});
		}
	}, [policyId]);

	const renderedPolicy = isDIMPolicy(policy) ? (
		<PolicyItemFormDIM data={policy} />
	) : isDARPolicy(policy) ? (
		<PolicyItemFormDAR data={policy} />
	) : (
		// @ts-ignore
		<div>Unknown policy type - {policy?.type}</div>
	);

	return (
		<>
			<Header isLoading={isLoading} />

			<Preloader isLoading={isLoading}>
				{policy ? renderedPolicy : <div>Policy does not exist</div>}
			</Preloader>
		</>
	);
}

export { PolicyItem, LS_POLICY_DUPLICATE_KEY };
