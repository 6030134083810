import { useCallback, useEffect, useState } from 'react';
import Alert, { AlertSeverity } from 'components/Alert';
import Icon from 'components/Icon';
import Link from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import { confirmDiscoveryStatus, getDiscoveryStatus } from 'models/discoveryStatus/api';
import { DiscoveryStatusJson } from 'models/discoveryStatus/dto';
import DataTest from './index.dataTest';
import styles from './index.module.pcss';

interface Props {
	classes?: { container: string };
}

function DiscoveryStatus({ classes }: Props) {
	const [discoveryStatus, setDiscoveryStatus] = useState<DiscoveryStatusJson['status']>('no_agent');

	useEffect(() => {
		getDiscoveryStatus().then(({ status }) => setDiscoveryStatus(status));
	}, []);

	const confirmHandle = useCallback(() => {
		confirmDiscoveryStatus().then(({ status }) => setDiscoveryStatus(status));
	}, []);

	switch (discoveryStatus) {
		case 'completed':
			return (
				<Alert
					severity={AlertSeverity['info']}
					startIcon={<Icon name="CheckmarkCircle/Filled" size={20} />}
					header="Initial discovery of your infrastructure is complete"
					className={classes?.container}
				>
					<Typo variant="D/Regular/Body-S">
						Discovery will continue via data-in-motion monitoring.
					</Typo>

					<Typo
						variant="D/Medium/Body-S"
						color="secondary"
						className={styles.confirmButton}
						onClick={confirmHandle}
						data-test={DataTest['discovery-status-confirm-btn']}
					>
						<Link to="#">OK</Link>
					</Typo>
				</Alert>
			);
		case 'in_progress':
			return (
				<Alert
					severity={AlertSeverity['info']}
					startIcon={<Icon name="Clock/Filled" size={20} />}
					header="We are collecting data and analyzing your infrastructure. This can take up to 7 days."
					className={classes?.container}
				/>
			);
		case 'no_agent':
		case 'confirmed':
		default:
			return null;
	}
}

export default DiscoveryStatus;
