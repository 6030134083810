import { useStore } from 'effector-react';
import { ReactNode, useContext, useMemo, useState } from 'react';
import { TLevel } from 'models/dataMapV2/dto';
import { DataTypeItem } from 'models/dataTypes/dto';
import { MapAsset, Cube, RagsAndCubesTree } from '../../index';
import { closest } from '../../utils/addParentPropertyToTree';
import { isPseudoRag } from '../../utils/calculateAbsoluteDimensions';
import { TILE_SIZE } from '../index';
import { mapControlsStore } from '../model/store';
import { ZoomContext } from '../ZoomWrapper';
import styles from './index.module.css';
import { MapPopover } from './MapPopover';
import { TLSIcon } from './TLSIcon';
import { getCurveCenter } from './TLSIcon/getCurveCenter';

const PIPE_COLOR_GRAY_OPACITY = '#232C408C'; // icon/secondary , 55% opacity
const PIPE_COLOR_GRAY_FILLED = '#868B96'; // icon/secondary without opacity
const PIPE_COLOR_BLUE = '#1656D9'; // icon/accent-primary

function getIsoPoint(x: number, y: number) {
	return [x * 0.707107 + y * -0.707107, x * 0.40558 + y * 0.40558];
}

type RagOrCube = RagsAndCubesTree | Cube;
const displacements = {
	300: 10,
	250: 15,
	200: 22,
	150: 22,
	100: 0,
	50: 0,
} as { [key: number]: number };
function getRagOrCubeCoordinatesForArrow(ragOrCube: RagOrCube, scaleStep: number) {
	if (isRag(ragOrCube)) {
		const x = (ragOrCube.dimensions.absoluteX + 1) * TILE_SIZE;
		const y = (ragOrCube.dimensions.absoluteY + ragOrCube.dimensions.height - 1) * TILE_SIZE;

		return [x, y];
	} else {
		const displacement = displacements[scaleStep] ?? 60000 / Math.pow(scaleStep, 1.5);
		const x = ragOrCube.dimensions.absoluteX * TILE_SIZE - displacement;
		const y = ragOrCube.dimensions.absoluteY * TILE_SIZE - displacement;

		return [x, y];
	}
}

function isRag(ragOrCube: RagOrCube): ragOrCube is RagsAndCubesTree {
	return 'level' in ragOrCube;
}

function findParent(ragsAndCubes: RagsAndCubesTree, cube: Cube, level: TLevel) {
	const result = closest(cube, level) || cube.parent; // sometimes we are OK with pseudo-rag instead of 'proper' parent. E.g. external assets

	if (result === null) throw new Error('Unexpected: cube parent not found');

	return result;
}

type Props = {
	mapAssets: MapAsset[];
	ragsAndCubes: RagsAndCubesTree;
};

function ArrowsLayer(props: Props) {
	const { mapAssets, ragsAndCubes } = props;
	const { scaleStep } = useContext(ZoomContext);

	const mapControls = useStore(mapControlsStore);
	const { selected, groupByNamespaceOrGroup, interactsWith, filter } = mapControls;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [popover, setPopover] = useState<{
		x: number;
		y: number;
		estimatedRequests: number;
	} | null>(null);

	const cubesByAssetId = useMemo(
		function () {
			const result: { [key: number]: Cube[] } = {};

			function collectCubes(rag: RagsAndCubesTree) {
				if (!isPseudoRag(rag)) {
					for (const child of rag.children) {
						collectCubes(child);
					}
				} else {
					for (const cube of rag.data) {
						result[cube.elementId] = result[cube.elementId] || [];
						result[cube.elementId].push(cube);
					}
				}
			}

			collectCubes(ragsAndCubes);

			return result;
		},
		[mapAssets, ragsAndCubes]
	);

	// Find out what asset(s) are selected. Multiple in case of ns/group/etc.
	const selectedAssets = useMemo(
		function () {
			if (selected === null) return [];

			let result: MapAsset[] = [];

			switch (selected.type) {
				case 'asset':
				case 's3_bucket':
					result = mapAssets.filter((asset) => asset.elementId === selected.id);
					break;

				case 'namespace':
					result = mapAssets.filter(
						(asset) => asset.namespace === selected.name && asset.cluster_id === selected.clusterId
					);
					break;

				case 'group':
					result = mapAssets.filter((asset) => asset.groups.includes(selected.id));
					break;

				case 'cluster':
					result = mapAssets.filter((asset) => asset.cluster_id === selected.id);
					break;
			}

			return result;
		},
		[selected, mapAssets]
	);

	// TODO move type to assignment, if TS can infer properly
	const selectedDataFlows = useMemo(
		function () {
			const result: {
				from: MapAsset['elementId'];
				to: MapAsset['elementId'];
				dataTypes: DataTypeItem['id'][];
				assetsIncluded: MapAsset['elementId'][];
				connectionTypes: ('connection' | 'dataflow')[];
				is_encrypted: boolean;
				is_mesh_network: boolean;
				estimated_requests: number;
			}[] = [];

			for (const asset of selectedAssets) {
				const dataFlows = asset.dataFlowsFrom.concat(asset.dataFlowsTo);

				for (const dataFlow of dataFlows) {
					result.push({
						from: asset.elementId,
						to: dataFlow.id,
						dataTypes: dataFlow.dataTypes,
						assetsIncluded: [dataFlow.id],
						connectionTypes: [dataFlow.connectionType],
						is_encrypted: dataFlow.is_encrypted,
						is_mesh_network: dataFlow.is_mesh_network,
						estimated_requests: dataFlow.estimated_requests,
					});
				}
			}

			return result;
		},
		[selectedAssets]
	);

	const selectedDataFlowsByCube = useMemo(
		function () {
			return selectedDataFlows
				.flatMap((dataFlow) =>
					cubesByAssetId[dataFlow.from].map((cube) => ({ ...dataFlow, from: cube }))
				)
				.flatMap((dataFlow) =>
					cubesByAssetId[dataFlow.to].map((cube) => ({ ...dataFlow, to: cube }))
				);
		},
		[selectedDataFlows, cubesByAssetId]
	);

	const aggregatedDataFlows = useMemo(
		function () {
			if (selected === null) return [];

			let dfByRagOrCube: {
				from: RagOrCube;
				to: RagOrCube;
				dataTypes: DataTypeItem['id'][];
				assetsIncluded: MapAsset['elementId'][];
				connectionTypes: ('connection' | 'dataflow')[];
				is_encrypted: boolean;
				is_mesh_network: boolean;
				estimated_requests: number;
			}[] = selectedDataFlowsByCube;

			if (selected.type === 'namespace' && groupByNamespaceOrGroup === 'namespace') {
				dfByRagOrCube = selectedDataFlowsByCube.map((dataFlow) => ({
					...dataFlow,
					from: findParent(ragsAndCubes, dataFlow.from, 'namespace'),
					to: findParent(ragsAndCubes, dataFlow.to, 'namespace'),
				}));
			} else if (selected.type === 'group' && groupByNamespaceOrGroup === 'customGroup') {
				dfByRagOrCube = selectedDataFlowsByCube.map((dataFlow) => ({
					...dataFlow,
					from: findParent(ragsAndCubes, dataFlow.from, 'customGroup'),
					to: findParent(ragsAndCubes, dataFlow.to, 'customGroup'),
				}));
			} else if (selected.type === 'cluster') {
				dfByRagOrCube = selectedDataFlowsByCube.map((dataFlow) => ({
					...dataFlow,
					from: findParent(ragsAndCubes, dataFlow.from, 'cluster'),
					to: findParent(ragsAndCubes, dataFlow.to, 'cluster'),
				}));
			}

			// Remove relations within self (relevant when e.g. namespace is selected)
			dfByRagOrCube = dfByRagOrCube.filter((dataFlow) => dataFlow.from !== dataFlow.to);

			// Now we have a lot of individual pipes with (sometimes) the same 'from' or
			// 'to' rag/cube. Aggregate together their relevant pipe data: datatypes, violations, etc.
			const dfMap = new Map<
				RagOrCube,
				Map<
					RagOrCube,
					{
						from: RagOrCube;
						to: RagOrCube;
						dataTypes: DataTypeItem['id'][];
						assetsIncluded: MapAsset['elementId'][];
						connectionTypes: ('connection' | 'dataflow')[];
						is_encrypted: boolean;
						is_mesh_network: boolean;
						estimated_requests: number;
					}
				>
			>();

			for (const dataFlow of dfByRagOrCube) {
				const from =
					dfMap.get(dataFlow.from) ||
					new Map<
						RagOrCube,
						{
							from: RagOrCube;
							to: RagOrCube;
							dataTypes: DataTypeItem['id'][];
							assetsIncluded: MapAsset['elementId'][];
							connectionTypes: ('connection' | 'dataflow')[];
							is_encrypted: boolean;
							is_mesh_network: boolean;
							estimated_requests: number;
						}
					>();
				const to = from.get(dataFlow.to) || {
					from: dataFlow.from,
					to: dataFlow.to,
					dataTypes: [],
					assetsIncluded: [],
					connectionTypes: [],
					is_encrypted: dataFlow.is_encrypted,
					is_mesh_network: dataFlow.is_mesh_network,
					estimated_requests: dataFlow.estimated_requests,
				};

				to.dataTypes = [...new Set(to.dataTypes.concat(dataFlow.dataTypes))];
				to.assetsIncluded = [...new Set(to.assetsIncluded.concat(dataFlow.assetsIncluded))];
				to.connectionTypes = [...new Set(to.connectionTypes.concat(dataFlow.connectionTypes))];
				to.is_encrypted = to.is_encrypted || dataFlow.is_encrypted;
				to.is_mesh_network = to.is_mesh_network || dataFlow.is_mesh_network;
				to.estimated_requests = to.estimated_requests + dataFlow.estimated_requests;

				from.set(dataFlow.to, to);
				dfMap.set(dataFlow.from, from);
			}

			return [...dfMap.values()].flatMap((v) => [...v.values()]);
		},
		[selected, groupByNamespaceOrGroup, selectedDataFlowsByCube, ragsAndCubes]
	);

	const pipes = useMemo(
		function () {
			return aggregatedDataFlows.map((dataFlow) => {
				const { from, to, dataTypes, assetsIncluded, connectionTypes } = dataFlow;

				let color = PIPE_COLOR_GRAY_OPACITY;
				if (dataTypes.some((dt: number) => filter.dataTypes.includes(dt))) color = PIPE_COLOR_BLUE;

				const selectedFlow = mapAssets.find((a) => a.entityId === interactsWith.selected?.id)!;
				const hoveredFlow = mapAssets.find((a) => a.entityId === interactsWith.hovered?.id)!;

				const isHighlighted =
					assetsIncluded.includes(selectedFlow?.elementId!) ||
					assetsIncluded.includes(hoveredFlow?.elementId!);

				const isShy = Boolean(!isHighlighted && (interactsWith.selected || interactsWith.hovered));

				const isDashed = connectionTypes.length === 1 && connectionTypes[0] === 'connection';

				return {
					from,
					to,
					color,
					isHighlighted,
					isShy,
					isDashed,
					is_encrypted: dataFlow.is_encrypted,
					is_mesh_network: dataFlow.is_mesh_network,
					estimated_requests: dataFlow.estimated_requests,
				};
			});
		},
		[aggregatedDataFlows, filter.dataTypes, interactsWith]
	);

	const [pipesRendered, centerPoints] = useMemo(
		function () {
			const pipesResult: ReactNode[] = [];
			const centerPointsResult: { x: number; y: number; color: string }[] = [];

			pipes.forEach((pipe, i) => {
				const { from, to, color, isHighlighted, isShy, isDashed } = pipe;

				const [x1, y1] = getRagOrCubeCoordinatesForArrow(from, scaleStep);
				const [x2, y2] = getRagOrCubeCoordinatesForArrow(to, scaleStep);

				const len = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
				const qx = (x1 + x2) / 2 - 88 * (len / 250);
				const qy = (y1 + y2) / 2 - 88 * (len / 250);

				const [xx1, yy1] = getIsoPoint(x1, y1);
				const [xx2, yy2] = getIsoPoint(x2, y2);
				const [qqx, qqy] = getIsoPoint(qx, qy);

				const center = getCurveCenter({
					start: { x: xx1, y: yy1 },
					end: { x: xx2, y: yy2 },
					quadratic: { x: qqx, y: qqy },
				});

				if (pipe.is_encrypted || pipe.is_mesh_network) {
					centerPointsResult.push({ x: center.x, y: center.y, color });
				}

				pipesResult.push(
					<path
						data-test="data-map-pipe"
						key={i}
						d={`M${xx1} ${yy1} Q${qqx} ${qqy} ${xx2} ${yy2}`}
						opacity={isShy ? '0.3' : '1'}
						stroke={color}
						strokeWidth={isHighlighted ? 10 : 5}
						strokeDasharray={isDashed ? '10 15' : undefined}
						strokeLinecap="round"
						fill="transparent"
						onMouseEnter={() =>
							setPopover({
								x: center.x,
								y: center.y,
								estimatedRequests: pipe.estimated_requests,
							})
						}
						onMouseLeave={() => setPopover(null)}
					/>
				);
			});

			return [pipesResult, centerPointsResult];
		},
		[pipes, scaleStep]
	);

	return (
		<>
			<svg className={styles.svgContainer}>{pipesRendered}</svg>

			{centerPoints.map((point, index) => (
				<TLSIcon
					key={index}
					{...point}
					background={
						point.color === PIPE_COLOR_GRAY_OPACITY ? PIPE_COLOR_GRAY_FILLED : point.color
					}
				/>
			))}

			{popover && <MapPopover {...popover} />}
		</>
	);
}

export default ArrowsLayer;
