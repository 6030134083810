import { Popover as UiPopover, PopoverProps } from '@material-ui/core';
import cn from 'classnames';
import styles from './index.module.pcss';

function Popover({ className, ...params }: PopoverProps) {
	return (
		<UiPopover
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			{...params}
			getContentAnchorEl={null}
			classes={{ paper: cn(styles.paper, className) }}
		/>
	);
}

export default Popover;
