import cn from 'classnames';
import { useEffect, useCallback, useState } from 'react';
import Loader from 'components/Loader';
import { Typo } from 'components/typography/Typo';
import { getPolicyEventsByName } from 'models/dashboard/api';
import { PolicyEventsByName } from 'models/dashboard/dto';
import { toAbbreviatedNumber } from 'services/numbers';
import styles from './index.module.css';

type Props = {
	data: PolicyEventsByName;
};

function MajorPolicyViolations(props: Props) {
	const { data } = props;

	const [hovered, setHovered] = useState(-1);

	const onMouseEnter = useCallback(function (e) {
		setHovered(+e.currentTarget.dataset.i);
	}, []);
	const onMouseLeave = useCallback(function (e) {
		const { i } = e.currentTarget.dataset;
		setHovered((h) => (h === +i ? -1 : h));
	}, []);

	const largestCount = data.policies.reduce((acc, i) => Math.max(acc, i.events_count), 0);

	return (
		<div className={styles.container}>
			<div className={styles.textColumn}>
				{data.policies.map((policy, i) => (
					<span key={i} data-i={i} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
						<span>{policy.name}</span>
					</span>
				))}
			</div>
			<div className={styles.numberColumn}>
				{data.policies.map((policy, i) => (
					<span key={i} data-i={i} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
						{toAbbreviatedNumber(policy.events_count, 1)}
					</span>
				))}
			</div>
			<div className={styles.numberColumn}>
				{data.policies.map((policy, i) => (
					<span
						className={cn(styles.row, hovered === i && styles.hoveredRow)}
						key={i}
						data-i={i}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
					>
						<span
							className={styles.bar}
							style={{ width: `${(policy.events_count * 100) / largestCount}%` }}
						/>
					</span>
				))}
			</div>
		</div>
	);
}

function Wrapper() {
	const [data, setData] = useState<PolicyEventsByName | null>(null);

	useEffect(() => {
		getPolicyEventsByName().then((res) => {
			res.policies = res.policies
				.filter((policy) => policy.events_count > 0)
				// @ts-ignore
				.toSorted((a, b) => b.events_count - a.events_count);
			setData(res);
		});
	}, []);

	return data === null ? (
		<Loader />
	) : data.policies.length === 0 ? (
		<Typo className={styles.noAnalytics} variant="D/Regular/Body-S" color="secondary">
			No available analytics
		</Typo>
	) : (
		<MajorPolicyViolations data={data} />
	);
}

export { Wrapper as MajorPolicyViolations };
