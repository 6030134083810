import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import Badge from 'components/Badge';
import Icon from 'components/Icon';
import Preloader from 'components/Preloader';
import { TabLink, TabPanel, Tabs } from 'components/Tabs';
import { Typo } from 'components/typography/Typo';
import { dataStoragesSteps, noSQLDatabasesSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getNoSQLCollectionDetails } from 'models/noSQLDatabases/api';
import { NoSQLDatabaseCollectionDetails } from 'models/noSQLDatabases/dto';
import { noSQLDatabaseInstancesById } from 'models/noSQLDatabases/store';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { AutomaticDiscoveryTooltip } from 'views/common/AutomaticDiscovery';
import styles from '../index.module.css';
import { SchemasTable } from './SchemasTable';
import { Summary } from './Summary';

function DatabaseError() {
	const params: {
		id: string;
	} = useParams();

	return (
		<>
			<Header>
				<Header.Breadcrumbs steps={dataStoragesSteps} finalStep="Not found" />

				<Header.Title>Error has occurred while loading database info</Header.Title>
			</Header>

			<Typo variant="D/Regular/Body-S" color="secondary">
				Database with id={params.id} not found or was deleted.
			</Typo>
		</>
	);
}

function NoSQLCollectionItemWrapper() {
	const [collection, setCollection] = useState<NoSQLDatabaseCollectionDetails | null>(null);
	const [loading, setLoading] = useState(true);

	const params: { id: string } = useParams();

	useEffect(() => {
		getNoSQLCollectionDetails(Number(params.id))
			.then(setCollection)
			.finally(() => setLoading(false));
	}, [params.id]);

	return (
		<Preloader isLoading={loading}>
			{collection ? <NoSQLCollectionItem collection={collection} /> : <DatabaseError />}
		</Preloader>
	);
}

const tabLabels = {
	summary: 'Summary',
	schemas: 'Message schemas',
};

interface NoSQLCollectionItemProps {
	collection: NoSQLDatabaseCollectionDetails;
}

function NoSQLCollectionItem({ collection }: Readonly<NoSQLCollectionItemProps>) {
	const history = useHistory();
	const params: {
		tab?: 'summary';
	} = useParams();
	const databaseInstances = useStore(noSQLDatabaseInstancesById);
	const instance = useMemo(
		() => databaseInstances[collection.instance_id],
		[collection.instance_id, databaseInstances]
	);

	const tabLinks = useMemo(
		() => ({
			summary: generatePath(PATHS.NOSQL_DATABASES_COLLECTION_ITEM, {
				id: collection.id,
				tab: 'summary',
			}),
			schemas: generatePath(PATHS.NOSQL_DATABASES_COLLECTION_ITEM, {
				id: collection.id,
				tab: 'schemas',
			}),
		}),
		[collection.id]
	);

	// Set default tab, if not specified.
	useEffect(function () {
		if (!params.tab) {
			const path = generatePath(PATHS.NOSQL_DATABASES_COLLECTION_ITEM, {
				...params,
				tab: 'summary',
			});
			history.replace(path + history.location.search);
		}
	}, []);

	const isScanned = !!collection.last_checked;

	const steps = [
		...noSQLDatabasesSteps,
		{
			path: generatePath(PATHS.NOSQL_DATABASES_ITEM, {
				id: collection.database_id,
				tab: 'summary',
			}),
			name: collection.database_name,
		},
		{
			path: generatePath(PATHS.NOSQL_DATABASES_COLLECTION_ITEM, {
				id: collection.id,
				tab: 'summary',
			}),
			name: collection.name,
		},
	];

	return (
		<>
			<Header>
				<Header.Breadcrumbs steps={steps} finalStep={tabLabels[params.tab ?? 'summary']} />

				<Header.Title>
					<Typo variant="D/Medium/H400-Page-Title" className={styles.title}>
						{!instance.is_crawler_attached && <Icon name="Radar/Filled" size={24} />}
						{collection.name}
					</Typo>
				</Header.Title>

				<Header.SensitiveSwitcher />
			</Header>

			<Tabs value={tabLinks[params.tab ?? 'summary']}>
				<TabLink value={tabLinks['summary']} label={tabLabels['summary']} />

				{instance.is_crawler_attached ? (
					<TabLink
						value={tabLinks['schemas']}
						label={
							<>
								{tabLabels['schemas']}
								<Badge
									badgeContent={isScanned ? toLocaleString(collection.schemas_count) : 'N/A'}
									data-test="database-tables-count"
								/>
							</>
						}
					/>
				) : (
					<AutomaticDiscoveryTooltip>
						<TabLink
							disabled
							value={tabLinks['schemas']}
							label={
								<>
									{tabLabels['schemas']}
									<Badge badgeContent="N/A" data-test="database-tables-count" />
								</>
							}
						/>
					</AutomaticDiscoveryTooltip>
				)}
			</Tabs>

			{/* TABS CONTENT */}
			<TabPanel value={params.tab} index="summary">
				<Summary collection={collection} instance={instance} />
			</TabPanel>

			<TabPanel value={params.tab} index="schemas">
				<SchemasTable collectionId={collection.id} />
			</TabPanel>
		</>
	);
}

export { NoSQLCollectionItemWrapper as NoSQLCollectionItem };
