import cn from 'classnames';
import { MouseEvent } from 'react';
import Icon from 'components/Icon';
import Loader from 'components/Loader';
import { RouterLink } from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import { EventJson } from 'models/eventsV2/dto';
import { format, DateFormat } from 'services/dayjs';
import { PATHS } from 'services/router';
import { getStringDividedByDot } from 'services/strings';
import { eventTitle } from 'views/EventsV3/EventRow';
import styles from './index.module.css';

type Props = {
	events: EventJson[];
	hovered: number;
	onMouseEnter: (e: MouseEvent<HTMLElement>) => void;
	onMouseLeave: (e: MouseEvent<HTMLElement>) => void;
};

function HighPriorityEvents(props: Props) {
	const { events, hovered, onMouseEnter, onMouseLeave } = props;

	return (
		<div className={styles.container}>
			{events.map((event, i) => {
				const { eventName, resourceName, region } = eventTitle({ event });

				return (
					<RouterLink to={`${PATHS.EVENTS}?id=${event.id}`} className={styles.link} key={i}>
						<div
							className={cn(styles.event, hovered === i && styles.hoveredEvent)}
							data-i={i}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
							data-test="high-priority-event-row"
						>
							<Icon name="warning" size={16} className={styles.redIcon} />

							<div>
								<div>{eventName}</div>
								<Typo variant="D/Regular/Meta-S" color="secondary">
									{getStringDividedByDot(
										format(event.created_at, DateFormat.cutDate),
										resourceName && `Resource: ${resourceName}`,
										region && `Region: ${region}`
									)}
								</Typo>
							</div>
						</div>
					</RouterLink>
				);
			})}
		</div>
	);
}

type WrapperProps = {
	events: null | EventJson[];
	hovered: number;
	onMouseEnter: (e: MouseEvent<HTMLElement>) => void;
	onMouseLeave: (e: MouseEvent<HTMLElement>) => void;
};

function Wrapper(props: WrapperProps) {
	const { events, ...rest } = props;

	return events === null ? <Loader /> : <HighPriorityEvents events={events} {...rest} />;
}

export { Wrapper as HighPriorityEvents };
