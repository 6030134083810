import ExternalLink from 'components/typography/ExternalLink';
import { Typo } from 'components/typography/Typo';
import signUpStyles from '../SignUp/index.module.pcss';

export function AWSNonExistentSubscription() {
	return (
		<div className={signUpStyles.container}>
			<div className={signUpStyles.wrapper}>
				<Typo variant="D/Medium/H500-Greeting-Title" className={signUpStyles.title}>
					Subscription update
				</Typo>

				<Typo variant="D/Medium/Body-S" color="secondary" className={signUpStyles.form}>
					Your subscription has expired or been canceled. <br />
					We appreciate your support and invite you to renew or explore our{' '}
					<ExternalLink href="https://aws.amazon.com/marketplace/pp/prodview-sekgnv6g3ekgi">
						subscription options
					</ExternalLink>
					.
				</Typo>
			</div>
		</div>
	);
}
