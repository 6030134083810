import { createEffect } from 'effector';
import { TOrder } from 'models/common/dto';
import { DataTypeRequest } from 'models/dataTypes/dto';
import { getDataTypesAsApiParam } from 'models/dataTypes/helpers';
import { createTableModel } from 'models/modelUtils/tableModelFactory';
import { ApiParams } from 'services/api';
import { getEvent, getEvents } from './api';
import { EventJson } from './dto';

type EventFromParam = '3' | '7' | '30' | '90';
// V3
type EventSeverityParam = 'critical' | 'high' | 'medium' | 'low' | 'info' | string;
type EventResourceTypeParam = 'service' | 's3' | 'database' | 'no_sql_database' | 'kafka' | string;
type EventTypeTypeParam =
	| 'infrastructure_updates'
	| 'misconfiguration'
	| 'misplaced_data'
	| 'data_at_risk'
	| 'other'
	| string;

type EventParams = {
	from: number;
	// to: number; // supported by backend, but not used in UI controls
	sort: {
		orderBy: keyof EventJson;
		order: TOrder;
	};
	'data-type-ids': DataTypeRequest;
	cluster_ids: number[];
	namespaces: string[];
	// v3
	regions: string[];
	severity: EventSeverityParam;
	resource_types: EventResourceTypeParam[];
	policy_tags: string[];
	type: EventTypeTypeParam;
};

function fetchDataApi(apiParamsRaw: ApiParams, signal?: AbortSignal) {
	const { 'data-type-ids': piiTypes, ...apiParams } = apiParamsRaw;

	const dataTypes = getDataTypesAsApiParam(piiTypes);

	// Backend doesn't work with empty and 'nonempty' values
	if (dataTypes && dataTypes !== 'nonempty') {
		apiParams['data-type-ids'] = dataTypes;
	}

	return getEvents(apiParams, signal).then((payload) => {
		const log_events = payload.log_events.map((e) => ({
			...e,
			data_types: 'data_types' in e ? e.data_types : [],
		}));

		log_events.sort((e1, e2) => e2.created_at - e1.created_at);

		return {
			data: log_events,
			total: payload.total,
			total_filtered: payload.total_filtered,
		};
	});
}

function createEventsModel(initialParams: EventParams, fetchFxOnInit: boolean = true) {
	return createTableModel<EventJson, EventParams>(initialParams, fetchDataApi, fetchFxOnInit);
}

//
// Instantiate model with initial params, but do not fetch data from server.
//

const initialParams: EventParams = {
	type: '',
	from: 0,
	sort: { orderBy: 'created_at', order: 'desc' },
	'data-type-ids': [],
	cluster_ids: [],
	namespaces: [],
	// V3
	regions: [],
	severity: '',
	resource_types: [],
	policy_tags: [],
};

const eventsModel = createEventsModel(initialParams, false);

//
// Separate effect to get single event - either from store, or by direct API call.
// Does not put result to store - no need (and will be hard to implement, given all smart logic inside store).
//

const getSingleEventFx = createEffect<number, EventJson>((id: number) => {
	const found = eventsModel.store.getState().data.find((event) => event.id === id);

	return found || getEvent(id);
});

export { eventsModel, getSingleEventFx };
export type {
	EventParams,
	EventFromParam,
	EventSeverityParam,
	EventResourceTypeParam,
	EventTypeTypeParam,
};
