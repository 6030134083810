import CopyToClipboard from 'components/CopyToClipboard';
import Button from 'components/form/Button';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import { Tooltip } from 'components/Tooltip';
import { RouterLink } from 'components/typography/Link';
import { getAssetNameByType as getAssetNameByTypeText } from 'models/assets/model';
import { dataTypesById } from 'models/dataTypes/store';
import { methodOrProtocol } from 'models/endpointsV2/dto';
import { ClusteringEventJson } from 'models/eventsV2/dto';
import { PATHS } from 'services/router';
import { eventTitleAsText } from '../EventRow';
import JiraButton from '../JiraButton';
import { formatWithToday } from '../utils';
import { EventDetailsAccordion } from './EventDetailsAccordion';
import Asset from './fields/Asset';
import { Endpoint } from './fields/Endpoint';
import styles from './index.module.pcss';

type Props = { event: ClusteringEventJson };

function ClusteringEvent(props: Props) {
	const { event } = props;

	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;
	const sendingAsset = event.direction === 'request' ? event.requestor : event.responder;
	const sendingAssetNameText = getAssetNameByTypeText(sendingAsset.type, sendingAsset.name);
	const receivingAsset = event.direction === 'request' ? event.responder : event.requestor;
	const receivingAssetNameText = getAssetNameByTypeText(receivingAsset.type, receivingAsset.name);
	const eventRegardingAsset = event.requestor; // It is always the requestor who puts sensitive data in requested url params.
	const interactsWithAsset = event.responder;

	const pathToDataMap = `${PATHS.DATA_MAP}?entityId=${eventRegardingAsset.id}&type=${eventRegardingAsset.type}&interacts-with=${interactsWithAsset.id}`;

	const formattedLocal = formatWithToday(event.created_at);
	const titleAsText = eventTitleAsText(event);
	const jiraSummary = `Soveren issue: ${titleAsText}`;
	const jiraDescription = `*${titleAsText}*

*Event*: #${event.id}
*Time*: ${formattedLocal}
*Sensitivity*: ${event.sensitivity}
*Category*: Other
*Data types*: ${event.data_types.map((dtId) => dataTypesById.getState()[dtId].name).join(', ')}
*Sending*: ${sendingAssetNameText}${sendingAsset.namespace ? ` • ${sendingAsset.namespace}` : ''}
*Receiving*: ${receivingAssetNameText}${
		receivingAsset.namespace ? ` • ${receivingAsset.namespace}` : ''
	}
*Endpoint*: [${methodOrProtocol(event)}] ${event.hostname}${event.url}

See on Soveren dashboard: ${linkHere}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<span className={styles.detailsLabel}>Event ID</span>
				<span>
					{event.id}{' '}
					<Tooltip
						title="Copy event link"
						placement="right"
						className={styles.copyToClipboardWrapper}
					>
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</span>

				<span className={styles.detailsLabel}>Data types</span>
				<PiiTypeList data={event.data_types} noHighlight dataTest="event-details-data-types" />

				<span className={styles.detailsLabel}>Severity</span>
				<SensitivityChip sensitivity={event.sensitivity} data-test="event-details-sensitivity" />

				<span className={styles.detailsLabel}>Category</span>
				<span>Other</span>

				<span className={styles.detailsLabel}>Sending</span>
				<Asset asset={sendingAsset} dataTest="event-details-sending" />

				<span className={styles.detailsLabel}>Receiving</span>
				<Asset asset={receivingAsset} dataTest="event-details-receiving" />

				<Endpoint event={event} sendingAsset={sendingAsset} />
			</div>

			<EventDetailsAccordion
				title="Sending"
				rows={{
					'Resource name': sendingAsset.name,
					'Resource type': event.object_type,
					Namespace: sendingAsset.namespace,
					Cluster: sendingAsset.cluster_name,
					Region: event.region,
				}}
			/>

			<EventDetailsAccordion
				title="Receiving"
				rows={{
					'Resource name': receivingAsset.name,
					'Resource type': event.object_type,
					Namespace: receivingAsset.namespace,
					Cluster: receivingAsset.cluster_name,
					Region: event.region,
				}}
			/>

			<div className={styles.links}>
				<JiraButton summary={jiraSummary} description={jiraDescription} />

				<RouterLink to={pathToDataMap} data-test="data-map-link">
					<Button size="small" color="tertiary">
						View on data map
					</Button>
				</RouterLink>
			</div>
		</>
	);
}

export default ClusteringEvent;
