import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ButtonIcon from 'components/ButtonIcon';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import Preloader from 'components/Preloader';
import SensitivityChip from 'components/SensitivityChip';
import { enqueueSnackbar } from 'components/Snackbar';
import { RouterLink } from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import { dataStoragesSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getKafkaInstanceItem, updateKafkaInstance } from 'models/kafkaInstances/api';
import { KafkaInstanceItem } from 'models/kafkaInstances/dto';
import { s3RegionsByKeyword } from 'models/s3Regions/store';
import { APIError } from 'services/api/httpRequest';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { DASH_SIGN } from 'services/strings';
import { AutomaticDiscoveryNonAvailableData } from 'views/common/AutomaticDiscovery';
import { EditableInput } from 'views/common/EditableInput';
import styles from './index.module.css';

function InstanceItem() {
	const params: {
		id: string;
	} = useParams();
	const s3Regions = useStore(s3RegionsByKeyword);
	const [isLoading, setLoading] = useState(true);
	const [instance, setInstance] = useState<KafkaInstanceItem | null>(null);

	useEffect(() => {
		getKafkaInstanceItem(Number(params.id)).then((res) => {
			setInstance(res);
			setLoading(false);
		});
	}, []);

	if (!instance) return null;
	const {
		data_types,
		description,
		has_tls,
		is_crawler_attached,
		messages_count,
		name,
		owner,
		sensitivity,
		topics_count,
		region,
	} = instance;

	const onSave = async (parameter: keyof KafkaInstanceItem, value: string) => {
		if (value.length > 200) {
			return 'The description is more than 200 symbols.';
		}

		try {
			const res = await updateKafkaInstance({ ...instance, [parameter]: value });
			setInstance(res);
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();
				return message;
			}

			const errorMessage = 'Something went wrong';
			enqueueSnackbar(errorMessage);
			return errorMessage;
		}

		return null;
	};

	const messagesCount = messages_count > 0 ? toLocaleString(messages_count) : DASH_SIGN;
	const topicsCount = topics_count > 0 ? toLocaleString(topics_count) : DASH_SIGN;

	const pathToTopic = `${PATHS.KAFKA_TOPICS}?instance-ids=${params.id}`;
	const titleBlock = is_crawler_attached ? (
		name
	) : (
		<Typo variant="D/Medium/H400-Page-Title" className={styles.title}>
			<Icon name="Radar/Filled" size={24} /> {name}
		</Typo>
	);

	return (
		<Preloader isLoading={isLoading}>
			<Header>
				<Header.Breadcrumbs
					steps={[...dataStoragesSteps, { path: PATHS.KAFKA_INSTANCES, name: 'Kafka clusters' }]}
					finalStep={name}
				/>

				<Header.Title>{titleBlock}</Header.Title>
			</Header>

			<div className={styles.detailsGrid} data-test="kafka-cluster-info-container">
				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Description
				</Typo>
				<EditableInput
					dataTest="kafka-cluster-info-description"
					value={description}
					onSave={(value: string) => onSave('description', value)}
					className={styles.editableInput}
					placeholder="Enter description"
					multiline
				/>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Owner
				</Typo>
				<EditableInput
					value={owner}
					onSave={(value: string) => onSave('owner', value)}
					className={styles.editableInput}
					placeholder="Enter owner’s name"
					dataTest="kafka-cluster-info-owner"
				/>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Region
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="kafka-cluster-info-region">
					{s3Regions[region]?.description || DASH_SIGN}
				</Typo>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Messages
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="kafka-cluster-info-messages">
					{messagesCount}
				</Typo>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Topics
				</Typo>
				<Typo
					variant="D/Regular/Body-S"
					className={styles.cellWithButton}
					data-test="kafka-cluster-info-topics"
				>
					{topicsCount}
					<RouterLink to={pathToTopic} setPageParams data-test="kafka-cluster-info-topics-link">
						<ButtonIcon icon="Open/Regular" size="S" />
					</RouterLink>
				</Typo>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					TLS
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="kafka-cluster-info-tls">
					{has_tls ? 'Yes' : 'No'}
				</Typo>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Sensitivity
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="kafka-cluster-info-sensitivity">
					<SensitivityChip sensitivity={sensitivity} />
				</Typo>

				<Typo variant="D/Regular/Meta-CAP" color="secondary">
					Data types
				</Typo>
				{is_crawler_attached ? (
					<PiiTypeList data={data_types} noHighlight dataTest="kafka-cluster-info-data-types" />
				) : (
					<AutomaticDiscoveryNonAvailableData dataTest="kafka-cluster-info-data-types" />
				)}
			</div>
		</Preloader>
	);
}

export { InstanceItem };
