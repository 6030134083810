import { useStore } from 'effector-react';
import { MouseEvent } from 'react';
import { Typo } from 'components/typography/Typo';
import { EventJson } from 'models/eventsV2/dto';
import { RecentActivityJson } from 'models/recentActivity/dto';
import { recentActivityStore } from 'models/recentActivity/store';
import { toAbbreviatedNumber } from 'services/numbers';
import { ApiCallsChart } from './ApiCallsChartOld';
import styles from './index.module.css';

type Props = {
	events: null | EventJson[];
	hovered: number;
	onMouseEnter: (e: MouseEvent<HTMLElement>) => void;
	onMouseLeave: (e: MouseEvent<HTMLElement>) => void;
};

function APICalls(props: Props) {
	const data: RecentActivityJson = useStore(recentActivityStore);
	const total = data.api_calls.daily.reduce((acc, d) => acc + d.value, 0);

	return (
		<>
			<div className={styles.total}>
				<Typo variant="D/Medium/Meta-S-CAP" color="secondary">
					Total
				</Typo>
				<Typo data-test="api-calls-total" variant="D/Medium/H400-Page-Title">
					{toAbbreviatedNumber(total, 1)}
				</Typo>
			</div>

			<ApiCallsChart {...props} />
		</>
	);
}

export { APICalls };
