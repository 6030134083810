import { Typo } from 'components/typography/Typo';
import { kafkaTopicsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import styles from './index.module.css';

interface WashedSchemaProps {
	state: { name: string } | null;
	id: string;
}

function WashedSchema({ state, id }: WashedSchemaProps) {
	const titleBlock = state ? state.name : `Message schema with ID ${id}`;

	return (
		<>
			<Header>
				<Header.Breadcrumbs steps={kafkaTopicsSteps} finalStep="Message schema" />

				<Header.Title>{titleBlock}</Header.Title>
			</Header>

			<Typo variant="D/Regular/Body-S" color="secondary" className={styles.noSample}>
				No current message schema available, as there has been
				<br /> no traffic in the last 7 days.
			</Typo>
		</>
	);
}

export type { WashedSchemaProps };
export { WashedSchema };
