import { useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import { Tooltip } from 'components/Tooltip';
import { KafkaTopicSampleItem } from 'models/kafkaTopics/dto';
import { DateFormat, dayjs } from 'services/dayjs';
import { lastSeen } from 'services/lastSeen';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import styles from './index.module.css';

type Props = {
	sample: KafkaTopicSampleItem;
};

function SampleRow({ sample }: Props) {
	const { id, offset, partition, message_timestamp, last_checked, data_types, sensitivity } =
		sample;
	const timestamp = dayjs(message_timestamp).format(DateFormat.timestamp);
	const lastSeenValue = useMemo(
		() => lastSeen({ timestamp: last_checked, short: true }),
		[last_checked]
	);

	const history = useHistory();
	function clickHandler() {
		history.push(generatePath(PATHS.KAFKA_TOPIC_FILE_SAMPLE, { sampleId: id }));
	}

	return (
		<GridRow
			className={styles.rowContainer}
			border
			hover
			onClick={clickHandler}
			data-test="kafka-samples-table-row"
		>
			<GridCell dataTest="kafka-samples-table-item-offset" align="right">
				{toLocaleString(offset)}
			</GridCell>
			<GridCell dataTest="kafka-samples-table-item-partition" align="right">
				{toLocaleString(partition)}
			</GridCell>
			<GridCell dataTest="kafka-samples-table-item-timestamp">{timestamp}</GridCell>
			<GridCell dataTest="kafka-message-schema-table-item-sensitivity">
				<SensitivityChip sensitivity={sensitivity} />
			</GridCell>
			<GridCell dataTest="kafka-topics-table-item-data_types">
				<PiiTypeList data={data_types} />
			</GridCell>
			<GridCell dataTest="kafka-samples-table-item-last-checked">
				<Tooltip className={styles.lastChecked} title={lastSeenValue.date}>
					{lastSeenValue.diff}
				</Tooltip>
			</GridCell>
		</GridRow>
	);
}

export { SampleRow };
