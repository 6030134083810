import { useEffect, useState } from 'react';
import { CurtainOverlay } from 'components/Curtain';
import NoContent from 'components/NoContent';
import EnhancedTableHead from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import { Typo } from 'components/typography/Typo';
import { getDatabaseRoles } from 'models/databases/api';
import { DatabaseRoleList, DatabaseRoleItem } from 'models/databases/dto';
import { AccessControlTableItem } from './AccessControlTableItem';
import tableConfig from './config';
import styles from './index.module.css';
import { PermissionsTable } from './PermissionsTable/PermissionsTable';

const getPriority = (role: DatabaseRoleItem) => {
	if (role.type === 'superuser') return -2;
	if (role.type === 'user') return -1;
	return 0;
};
const rolesSorting = (a: DatabaseRoleItem, b: DatabaseRoleItem) => getPriority(a) - getPriority(b);

export const AccessControlTable = ({ databaseId }: { databaseId: number }) => {
	let skeletonMode = false;
	const [databaseRoles, setDatabaseRoles] = useState<DatabaseRoleList>();
	const [activeRole, setActiveRole] = useState<DatabaseRoleItem>();
	const [isLoading, setLoading] = useState(true);
	const [curtainOpen, setCurtainOpen] = useState(false);

	useEffect(() => {
		getDatabaseRoles(databaseId).then((resp) => {
			setDatabaseRoles({
				...resp,
				roles: resp.roles.sort(rolesSorting),
			});
			setLoading(false);
		});
	}, [databaseId]);

	if (!isLoading && databaseRoles?.roles.length === 0) {
		skeletonMode = true;
	}

	return (
		<div>
			<GridTable dataTest="database-roles-table" className={styles.table}>
				<EnhancedTableHead config={tableConfig} rowClassname={styles.rowContainer} />

				{skeletonMode && (
					<NoContent type="databaseAccessControlTable" className={styles.rowContainer}>
						<div className={styles.emptyContainer}>
							<Typo variant="D/Medium/Body" color="primary" className={styles.emptyContainerText}>
								No data found
							</Typo>
							<Typo
								variant="D/Regular/Body-S"
								color="secondary"
								className={styles.emptyContainerText}
							>
								Failed to retrieve access data, possibly due to a scanning error. <br /> Ensure the
								database user has the necessary permissions for role queries and rerun the scan.
							</Typo>
						</div>
					</NoContent>
				)}

				{!skeletonMode && (
					<GridBody>
						{databaseRoles?.roles.map((databaseRole) => (
							<AccessControlTableItem
								databaseRole={databaseRole}
								key={databaseRole.id}
								onClick={() => {
									setActiveRole(databaseRole);
									setCurtainOpen(true);
								}}
							/>
						))}
					</GridBody>
				)}
			</GridTable>

			<CurtainOverlay
				open={curtainOpen}
				onClose={() => setCurtainOpen(false)}
				onOpen={() => activeRole && setCurtainOpen(true)}
				rightPart={<PermissionsTable role={activeRole} curtainOpen={curtainOpen} />}
				classes={{
					rightPart: styles.curtain,
					rightPartOpen: styles.curtainOpen,
				}}
			/>
		</div>
	);
};
