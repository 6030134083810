import { useStore } from 'effector-react';
import { useEffect, useMemo } from 'react';
import FilterLine from 'components/FilterLine';
import NewGatewayButton from 'components/NewGatewayBlock/NewGatewayButton';
import NoContent from 'components/NoContent';
import { clearPiiFilter, piiFilterWithGroupsStore } from 'components/PiiGlobalFilterV2/model';
import ResetFilters from 'components/ResetFilters';
import EnhancedTableHead, { TOrder } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import { Typo } from 'components/typography/Typo';
import { getComparator } from 'models/modelUtils/comparator';
import { CSVSampleColumn, CSVSampleTable, SamplePageBody } from 'models/sample/dto';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import { getColumns } from './csvParser';
import { dataBaseTableConfig } from './dataBaseTableConfig';
import styles from './index.module.css';
import { SampleColumnItem } from './SampleColumnItem';
import { tableConfig } from './tableConfig';

const NOOP = () => {};

const pageConfig = {
	sort: {
		type: 'sort',
		persistence: 'session',
	},
	'data-types': {
		type: 'numberArray',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	nonempty: {
		type: 'boolean',
		persistence: 'session', // TODO implement 'global' in actuality
	},
} satisfies PageParamsConfig;

type Props = Pick<SamplePageBody, 'sample' | 'data_fields'> & {
	database?: boolean;
};

function CSVViewer({ sample, data_fields, database }: Props) {
	const [pageParams, setPageParams] = usePageParams(pageConfig, 'TableViewSample');
	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);

	useEffect(() => {
		setPageParams({
			...pageParams,
			'data-types': piiFilterWithGroups.dataTypes,
			nonempty: piiFilterWithGroups.nonEmpty,
		});
	}, [piiFilterWithGroups]);

	const sortedAndFiltered = useMemo(() => {
		let result = sample ? [...getColumns(sample, data_fields)] : [];

		if (pageParams.nonempty) {
			result = result.filter((column) => column.data_fields.length > 0);
		}

		if (pageParams['data-types'].length > 0) {
			result = result.filter((column) =>
				column.data_fields.some(({ data_type }) => pageParams['data-types'].includes(data_type))
			);
		}
		const orderBy = pageParams.sort.value as keyof CSVSampleColumn;
		const order = pageParams.sort.operator;

		return result.sort(getComparator(orderBy, order));
	}, [pageParams, sample]);

	function onSortUpdate(property: keyof CSVSampleTable) {
		const { sort } = pageParams;

		const operator: TOrder = sort.value === property && sort.operator === 'asc' ? 'desc' : 'asc';
		const newParams = { sort: { operator, value: property } };

		setPageParams({ ...pageParams, ...newParams });
	}

	function onResetFilters() {
		clearPiiFilter();
	}

	const hasFilter = pageParams['data-types'].length > 0 || pageParams.nonempty;

	const rowClassname = useMemo(() => {
		return database ? styles.databaseRowContainer : styles.rowContainer;
	}, [database]);

	return (
		<div className={styles.wrapper}>
			<FilterLine
				config={['dataTypes']}
				values={{ dataTypes: pageParams['data-types'] }}
				onChange={NOOP}
			/>

			<GridTable dataTest="table-view-sample-table" className={styles.table}>
				<EnhancedTableHead
					config={database ? dataBaseTableConfig : tableConfig}
					order={pageParams.sort.operator}
					orderBy={pageParams.sort.value}
					onRequestSort={onSortUpdate}
					rowClassname={rowClassname}
				/>

				<GridBody data-test="table-view-sample-list">
					<>
						{sortedAndFiltered.length ? (
							sortedAndFiltered.map((column) => (
								<SampleColumnItem column={column} key={column.column_index} database={database} />
							))
						) : hasFilter ? (
							<ResetFilters onReset={onResetFilters} />
						) : (
							<NoContent type={database ? 'databaseSample' : 'csvSample'} className={rowClassname}>
								<div className={styles.emptyContainer}>
									<Typo variant="D/Regular/Body-S" color="secondary">
										No available analytics or no Data-at-rest Sensors set up yet.
									</Typo>

									<NewGatewayButton isDAR />
								</div>
							</NoContent>
						)}
					</>
				</GridBody>
			</GridTable>
		</div>
	);
}

export { CSVViewer };
