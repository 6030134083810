import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Alert, { AlertSeverity } from 'components/Alert';
import Button from 'components/form/Button';
import TextField from 'components/form/TextField';
import Link from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import { checkEmail } from 'models/session/api';
import { CheckEmailJson } from 'models/session/dto';
import { APIError } from 'services/api/httpRequest';
import { PATHS } from 'services/router';
import signUpStyles from '../SignUp/index.module.pcss';

type FormValues = {
	email: string;
};

type Props = {
	email: string;
	goToNextStep: (email: string, emailCheckResult: CheckEmailJson) => void;
};

function SignInEmail(props: Props) {
	const [isLoading, setLoading] = useState(false);
	const [serverError, setServerError] = useState<string | null>(null);

	const { control, handleSubmit } = useForm<FormValues>({
		defaultValues: {
			email: props.email,
		},
		mode: 'onBlur', // "onChange"
	});

	async function onSubmit(form: FormValues) {
		setLoading(true);

		try {
			const result = await checkEmail(form);
			props.goToNextStep(form.email, result);
		} catch (error) {
			if (error instanceof APIError && error.response.status === 401) {
				const { message } = await error.response.json();
				setServerError(message);
			} else {
				throw error;
			}

			setLoading(false);
		}
	}

	// Immediately check, given email in query string.
	useEffect(function () {
		if (props.email) {
			onSubmit({ email: props.email });
		}
	}, []);

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className={signUpStyles.form}>
				<TextField
					control={control}
					label="Email"
					name="email"
					type="email"
					required
					fullWidth
					size="large"
					data-test="sign-in-email"
				/>

				<Button
					type="submit"
					size="large"
					loading={isLoading}
					className={signUpStyles.formButton}
					data-test="sign-in-next-button"
				>
					Next
				</Button>

				{serverError && !isLoading && (
					<Alert className={signUpStyles.errorMessage} severity={AlertSeverity.error}>
						{serverError}
					</Alert>
				)}
			</form>

			<Typo variant="D/Medium/Body-S" className={signUpStyles.haveAccount}>
				Don’t have an account? <Link to={PATHS.SIGN_UP + window.location.search}>Sign up</Link>.
			</Typo>
		</>
	);
}

export default SignInEmail;
