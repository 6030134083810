import cn from 'classnames';
import { MouseEvent, useState } from 'react';
import Chip from 'components/Chip';
import Icon from 'components/Icon';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import { settingSensitivity } from 'components/SensitivityChip';
import { Tooltip } from 'components/Tooltip';
import { DataTypeItem } from 'models/dataTypes/dto';
import { useSensitivityData } from 'models/dataTypes/hooks';
import styles from './index.module.pcss';

interface Props {
	dataType: DataTypeItem['id'];
	label: string;
	onClick: () => void;
	isFP: boolean;
	isCorrected: boolean;
}

function SensitivityString({ dataType, label, onClick, isFP, isCorrected }: Props) {
	const [isActive, setActivity] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const { sensitivityLevel, piiTypeName, custom } = useSensitivityData(dataType, isFP);

	const handleClick = (event: MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (isCorrected) return;

		setActivity(true);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setActivity(false);
		setAnchorEl(null);
	};

	function handleMenuClick() {
		onClick();
		handleClose();
	}

	return (
		<span
			className={cn(styles[sensitivityLevel], styles.container)}
			data-test="sensitivity-string-container"
		>
			<span className={styles.string} data-test={dataType}>
				{label}
			</span>

			{isCorrected && (
				<Tooltip title="Custom, can’t be marked as false positive" placement="top">
					<span className={styles.сSign} data-test="corrected-data-type-с-sign">
						С
					</span>
				</Tooltip>
			)}

			<Chip
				onClick={handleClick}
				size="extraSmall"
				className={cn(styles.chip, { ['activeChip']: anchorEl })}
				label={
					<>
						{custom && <Icon name={'Tag/Filled'} size={12} className={styles.customIcon} />}
						{piiTypeName}{' '}
						{!isCorrected && (
							<Icon
								size={16}
								name="triangleDown"
								className={cn(styles.icon, { [styles.activeIcon]: isActive })}
							/>
						)}
					</>
				}
				theme={settingSensitivity[sensitivityLevel]}
				data-test={`small-menu-${sensitivityLevel}`}
			/>

			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				className={styles.menuContainer}
			>
				{isFP ? (
					<MenuItem size="small" onClick={handleMenuClick} data-test="reset-btn">
						Unmark as false positive
					</MenuItem>
				) : (
					<MenuItem size="small" onClick={handleMenuClick} data-test="mark-false-positive-btn">
						Mark as false positive
					</MenuItem>
				)}
			</Menu>
		</span>
	);
}

export default SensitivityString;
