import cn from 'classnames';
import { useStore } from 'effector-react';
import { CSSProperties, useEffect, useMemo, useCallback, useState, useRef } from 'react';
import Loader from 'components/Loader';
import { Typo } from 'components/typography/Typo';
import { getDiscoveredDataTypeGroups } from 'models/dashboard/api';
import { DiscoveredDataTypeGroups } from 'models/dashboard/dto';
import { dataTypesById } from 'models/dataTypes/store';
import { toAbbreviatedNumber } from 'services/numbers';
import { CircleGraph } from '../CircleGraph';
import styles from './index.module.css';

type Props = {
	data: DiscoveredDataTypeGroups;
};

function DataTypesOverview(props: Props) {
	const { data } = props;
	const dtById = useStore(dataTypesById);

	const nodeRef = useRef();

	const [height, setHeight] = useState(144);
	const [hovered, setHovered] = useState(-1);

	const onMouseEnter = useCallback(function (e) {
		setHovered(+e.currentTarget.dataset.i);
	}, []);
	const onMouseLeave = useCallback(function (e) {
		const { i } = e.currentTarget.dataset;
		setHovered((h) => (h === +i ? -1 : h));
	}, []);

	const observer = useMemo(
		() =>
			new ResizeObserver((entries) => {
				for (const entry of entries) {
					setHeight(
						entry.contentBoxSize ? entry.contentBoxSize[0].blockSize : entry.contentRect.height
					);
				}
			}),
		[]
	);

	const handleChart = useCallback((node) => {
		if (nodeRef.current) {
			observer.unobserve(nodeRef.current);
		}

		if (!node) return;

		observer.observe(node);
		nodeRef.current = node;
	}, []);

	return (
		<div
			className={styles.container}
			ref={handleChart}
			style={{ '--graph-max-width': `${height}px` } as CSSProperties}
		>
			<div className={styles.growingChart}>
				<div className={styles.growingChartInner}>
					<CircleGraph
						data={data.data_types.map((dt) => dt.data_fields_count)}
						hovered={hovered}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
					/>

					<div className={styles.totalContainer}>
						<Typo
							variant="D/Medium/H400-Page-Title"
							className={styles.totalNumber}
							dataTest="data-types-total"
						>
							{toAbbreviatedNumber(data.total_data_fields, 1)}
						</Typo>
						<Typo variant="D/Medium/Meta-S-CAP" color="secondary">
							Total
						</Typo>
					</div>
				</div>
			</div>

			<div className={styles.shrinkingTable}>
				<div className={styles.textColumn}>
					{data.data_types.map((dt, i) => (
						<span
							className={cn(styles.row, hovered === i && styles.hoveredRow)}
							key={i}
							data-i={i}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						>
							<span className={styles.square} />

							<span>{dtById[dt.data_type].name}</span>
						</span>
					))}
				</div>
				<div className={styles.numberColumn}>
					{data.data_types.map((dt, i) => (
						<span key={i} data-i={i} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
							{toAbbreviatedNumber(dt.data_fields_count, 1)}
						</span>
					))}
				</div>
				<div className={styles.numberColumn}>
					{data.data_types.map((dt, i) => (
						<span key={i} data-i={i} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
							<Typo variant="D/Regular/Body-S" color="secondary">
								{toAbbreviatedNumber(dt.data_fields_percent, 1)}%
							</Typo>
						</span>
					))}
				</div>
			</div>
		</div>
	);
}

function Wrapper() {
	const [data, setData] = useState<DiscoveredDataTypeGroups | null>(null);

	useEffect(() => {
		getDiscoveredDataTypeGroups().then((res) => {
			res.data_types.sort((a, b) => b.data_fields_count - a.data_fields_count);
			setData(res);
		});
	}, []);

	return data === null ? <Loader /> : <DataTypesOverview data={data} />;
}

export { Wrapper as DataTypesOverview };
