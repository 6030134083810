import Big from 'big.js';
import cn from 'classnames';
import { useState } from 'react';
import { CurtainOverlay } from 'components/Curtain';
import { Typo } from 'components/typography/Typo';
import { EventsTable, EventType } from 'models/eventsV2/dto';
import { eventTitleByType } from '../utils';
import styles from './index.module.css';

type Props = {
	data: EventsTable['breakdown_by_event'];
	wrap?: boolean;
	className?: string;
};

const Breakdowns = ({ data, wrap }: Props) => {
	if (data.length === 0) {
		return <div className={cn(styles.statistic, styles.noData)}>No data</div>;
	}

	const maxPercent = data[0].percent;
	const coefficient = Big(100)
		.div(maxPercent || 1)
		.toNumber();

	return (
		<div className={styles.statistic}>
			{data.map((item, index) => {
				const colorClass = index < 4 ? styles[`color${index}`] : styles.color3;

				return (
					<div className={styles.statisticItem} key={index}>
						<Typo variant="D/Regular/Body-S" className={styles.title} ellipsis={!wrap}>
							{eventTitleByType(item.title as EventType)}
						</Typo>
						<div className={styles.otherData}>
							<Typo variant="D/Regular/Body-S" className={styles.count}>
								{item.count}
							</Typo>
							<Typo variant="D/Regular/Body-S" color="secondary" className={styles.percent}>
								({item.percent < 0.1 ? '<0.1' : item.percent}%)
							</Typo>
							<div className={styles.percentLineWrapper}>
								<div
									className={cn(styles.percentLine, colorClass)}
									style={{ width: `${item.percent * coefficient}%` }}
								/>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export const BreakdownByIncident = ({ data = [], className }: Props) => {
	const [isCurtainOpen, setCurtainOpenState] = useState(false);

	return (
		<div className={cn(styles.container, className)}>
			<div className={styles.header}>
				<Typo variant="D/Medium/Meta-CAP" color="secondary" className={styles.headerTitle}>
					Breakdown by event
				</Typo>
				{data.length > 4 && (
					<Typo
						variant="D/Medium/Meta"
						color="secondary"
						className={styles.seeAll}
						onClick={() => setCurtainOpenState(true)}
					>
						See all
					</Typo>
				)}
			</div>

			<Breakdowns data={data.slice(0, 4)} />

			<CurtainOverlay
				open={isCurtainOpen}
				onClose={() => {
					setCurtainOpenState(false);
				}}
				classes={{
					rightPartOpen: styles.curtain,
				}}
				rightPart={
					<div className={styles.breakdownsCurtain}>
						<Typo variant="D/Medium/H100-Header" className={styles.breakdownsCurtainTitle}>
							Incidents
						</Typo>
						<Breakdowns data={data} wrap />
					</div>
				}
			/>
		</div>
	);
};
