import { default as UITypography, TypographyProps } from '@material-ui/core/Typography';
import cn from 'classnames';
import { ElementType, ReactNode } from 'react';
import { Tooltip } from 'components/Tooltip';
import styles from './index.module.pcss';

enum Variants {
	'D/Regular/Tiny' = 'tiny',
	'D/Medium/Tiny' = 'tinyMedium',
	'D/Regular/Meta-S' = 'metaS',
	'D/Medium/Meta-S' = 'metaSMedium',
	'D/Regular/Meta' = 'meta',
	'D/Medium/Meta' = 'metaMedium',
	'D/Regular/Body-S' = 'bodyS',
	'D/Medium/Body-S' = 'bodySMedium',
	'D/SemiBold/Body-S' = 'bodySSemiBold',
	'D/Regular/Body' = 'body',
	'D/Medium/Body' = 'bodyMedium',
	'D/Regular/Meta-CAP' = 'metaCap',
	'D/Medium/Meta-CAP' = 'metaCapMedium',
	'D/Regular/Meta-S-CAP' = 'metaSCap',
	'D/Medium/Meta-S-CAP' = 'metaSCapMedium',
	'D/Medium/H100-Header' = 'h100',
	'D/Medium/H200-Pane-Header' = 'h200',
	'D/Medium/H400-Page-Title' = 'h400',
	'D/Medium/H500-Greeting-Title' = 'h500',
	'D/Medium/H900-Cover-Title' = 'h900',
	'D/Regular/Body-S-code' = 'bodySCode',
}

type TypoProps = Omit<TypographyProps, 'variant'> & {
	component?: ElementType;
	children: ReactNode;
	color?: 'primary' | 'secondary';
	className?: string;
	variant?: keyof typeof Variants;
	dataTest?: string;
	ellipsis?: boolean;
	tooltip?: boolean | ReactNode;
};

function Typo({
	className,
	children,
	color = 'primary',
	variant = 'D/Regular/Body',
	dataTest,
	ellipsis = false,
	tooltip = ellipsis,
	...rest
}: TypoProps) {
	const typo = (
		<UITypography
			className={cn(
				styles.resetStyles,
				styles[color],
				styles[Variants[variant]],
				ellipsis && styles.ellipsis,
				className
			)}
			classes={{ root: styles.root }}
			data-test={dataTest}
			translate="no"
			{...rest}
		>
			{children}
		</UITypography>
	);

	if (!tooltip || !children) return typo;

	const title = tooltip !== true ? tooltip : children;

	return (
		<Tooltip
			className={className}
			ellipsisClass={ellipsis ? styles.ellipsis : undefined}
			title={title}
		>
			{typo}
		</Tooltip>
	);
}

export type { TypoProps };
export { Typo, Variants };
