import {
	ClusterGeoLocationItem,
	ClusterGeoLocationList,
	ClusterGeoLocationRegionItem,
	ClusterGeoLocationRegionList,
} from './dto';

export const clusterGeoLocationRegionItemsExample: ClusterGeoLocationRegionItem[] = [
	{
		keyword: 'us-west-1',
		description: 'US West (N. California)',
		is_used: true,
		source: 'aws_region',
		used_in_dar: true,
		used_in_dim: false,
	},
	{
		keyword: 'Germany',
		description: 'Germany',
		is_used: true,
		source: 'country',
		used_in_dar: false,
		used_in_dim: true,
	},
	{
		keyword: 'local-env-1',
		description: 'local-env-1',
		is_used: true,
		source: 'manual',
		used_in_dar: true,
		used_in_dim: true,
	},
];

export const clusterGeoLocationRegionListExample: ClusterGeoLocationRegionList = {
	regions: clusterGeoLocationRegionItemsExample,
};

export const clusterGeoLocationItemExample: ClusterGeoLocationItem = {
	token_id: 1,
	ip: '127.0.0.1',
	aws_local_zone: 'us-west-1-lz',
	aws_availability_zone: 'us-west-1-az',
	aws_region: 'us-west-1',
	country_iso_code: 'CY',
	country_iso_name: 'Cyprus',
	manual_region: 'my region',
	result_region: 'my region',
	result_region_source: 'manual',
	created_at: 1717738859336,
	updated_at: 1717738859336,
};

export const clusterGeoLocationListExample: ClusterGeoLocationList = {
	geo: [clusterGeoLocationItemExample],
};
