import { Popper, ClickAwayListener } from '@material-ui/core';
import { Draft } from 'immer';
import React, { createContext, MouseEvent, useEffect, useMemo, useState } from 'react';
import { DraftFunction } from 'use-immer';
import { ResetAll } from 'components/ResetAll';
import { Typo } from 'components/typography/Typo';
import { DARPolicyItem, DIMPolicyItem } from 'models/policiesV2/dto';
import { useScrollDetector } from 'services/watchParentContainer';
import { DARLocations } from 'views/PolicyV2/PolicyItem/PolicyItemForm/PolicyItemFormDAR';
import { DIMLocations } from 'views/PolicyV2/PolicyItem/PolicyItemForm/PolicyItemFormDIM';
import { HotKey, KeyAction } from '../HotKey';
import { MAIN_INPUT_INDEX } from '../RuleGroup';
import { RuleItemArray, RuleList } from '../RuleList';
import {
	AND_OPERATOR,
	darCategoriesOptions,
	dimCategoriesOptions,
	getEmptyGroup,
	isEmptyRule,
	isOperator,
	locationByTypeName,
	OR_OPERATOR,
	parseRules,
	validateTypeAfterEnter,
	validateIncludeValue,
	prepareDataForForm,
	isCustomType,
} from './helpers';
import styles from './index.module.css';
import OptionItem from './OptionItem';

type DIMGeneric = { policy: DIMPolicyItem; locations: DIMLocations; isDim: true };
type DARGeneric = { policy: DARPolicyItem; locations: DARLocations; isDim: false };

type Props<T extends DIMGeneric | DARGeneric> = {
	defaultRules: T['policy']['rules'];
	setFormData: (arg: DraftFunction<T['policy']>) => void;
	dim: T['isDim'];
	locations: T['locations'];
};

type Option = {
	id: number | string;
	name: string;
	values?: string[];
};

type RuleErrors = {
	[ruleIndex: number]: RuleError;
};

type RuleError = {
	type: boolean;
	operator: boolean;
	values: number[];
};

type ActiveRule = {
	currentValue: string;
	groupId: number;
	index: number;
	key?: string;
	operator?: typeof AND_OPERATOR | typeof OR_OPERATOR | null;
	ruleId: number;
	type: string;
	value?: string;
};

type BuilderProps = {
	option: Option | undefined;
	keyAction: KeyAction | undefined;
	emptyRulesError: boolean;
};

const initialState: BuilderProps = {
	option: undefined,
	keyAction: undefined,
	emptyRulesError: false,
};

const BuilderContext = createContext<{
	builderState: BuilderProps;
	setBuilderState: React.Dispatch<React.SetStateAction<BuilderProps>>;
}>({
	builderState: initialState,
	setBuilderState: () => {},
});

function PolicyBuilder<T extends DIMGeneric | DARGeneric>({
	defaultRules,
	dim,
	setFormData,
	locations,
}: Props<T>) {
	const [activeRule, setActiveRule] = React.useState<ActiveRule | null>(null);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [builderState, setBuilderState] = useState(initialState);
	const [modifiedRules, setModifiedRules] = useState<RuleItemArray['index'][]>([]);
	const [rules, setRules] = useState<RuleItemArray[]>([]);
	const [ruleErrors, setRuleErrors] = useState<RuleErrors>({});

	const [scrollRef, hasScroll] = useScrollDetector();

	useEffect(() => {
		setRules([...parseRules(defaultRules, locations), getEmptyGroup()]);
	}, []);

	useEffect(() => {
		if (builderState.option !== undefined && activeRule !== null) {
			setBuilderState((prevState) => ({ ...prevState, option: undefined }));
		}

		if (builderState.keyAction !== undefined && activeRule !== null) {
			setBuilderState((prevState) => ({ ...prevState, keyAction: undefined }));
		}
	}, [activeRule, builderState.option, builderState.keyAction]);

	useEffect(() => {
		// @ts-ignore
		const dataForForm = prepareDataForForm(rules, dim, locations);

		setFormData((draft: Draft<DIMPolicyItem> | Draft<DARPolicyItem>) => {
			draft.rules = dataForForm;
		});
	}, [rules]);

	useEffect(() => {
		checkForErrors();
	}, [modifiedRules, activeRule]);

	useEffect(() => {
		setBuilderState((prevState) => ({
			...prevState,
			emptyRulesError: !anchorEl && rules.length === 1,
		}));
	}, [anchorEl, rules]);

	function getUpdatedRuleErrors({
		part,
		index,
		currentRuleErrors,
		removeCondition,
		ruleId,
	}: {
		part: 'type' | 'key' | 'values';
		index: number;
		currentRuleErrors?: RuleErrors;
		removeCondition?: boolean;
		ruleId?: number;
	}) {
		const newRuleErrors = { ...ruleErrors, ...currentRuleErrors };

		if (removeCondition && newRuleErrors[index]) {
			const error = newRuleErrors[index];

			if (error) {
				if (part === 'type') {
					error[part] = false;
				} else if (part === 'values') {
					error.values = error.values.filter((errorIndex: number) => errorIndex !== ruleId);
				}

				if (!error.type && error.values.length === 0) {
					delete newRuleErrors[index];
				}
			}
		} else if (!removeCondition) {
			const error = newRuleErrors[index] ?? {
				type: false,
				key: false,
				operator: false,
				values: [],
			};

			if (part === 'type') {
				error[part] = true;
				newRuleErrors[index] = error;
			} else if (part === 'values' && ruleId !== undefined) {
				if (!error.values.includes(ruleId)) {
					error.values.push(ruleId);
				}

				newRuleErrors[index] = error;
			}
		}

		return newRuleErrors;
	}

	const prepareOptions = useMemo(() => {
		if (activeRule) {
			const changeType = activeRule.value === undefined;
			const changeKey =
				activeRule.type.length > 0 &&
				activeRule.key !== undefined &&
				activeRule.value === undefined;
			const activeGroup = rules[activeRule.groupId]; // TODO why do we need it
			const activeRuleType = activeRule.type.toLowerCase();

			if (changeKey) {
				// @ts-ignore TODO
				return locations[locationByTypeName[activeRuleType]].filter((o) =>
					o.name.toLowerCase().includes(activeRule.key?.toLowerCase())
				);
			} else if (changeType) {
				const dataList = (dim ? dimCategoriesOptions : darCategoriesOptions).filter((o) =>
					o.name.toLowerCase().includes(activeRuleType)
				);

				setRuleErrors(
					getUpdatedRuleErrors({
						part: 'type',
						index: activeRule.index,
						removeCondition: validateIncludeValue(activeRuleType, dataList),
					})
				);

				return dataList;
				// @ts-ignore TODO
			} else if (locations[locationByTypeName[activeRuleType]] && activeGroup) {
				const newServices = [...rules[activeRule.groupId].values];

				// When filtering, we need to use all the data except for the rule we are currently focused on.
				// Additionally, when moving within a group using clicks or keys,
				// the rule’s value should be visible in the Popover.
				if (activeRule.ruleId !== -1) {
					newServices.splice(activeRule.ruleId, 1);
				}

				let dataList: Option[];

				if (activeRule.key && activeRule.key.length > 0) {
					const valuesByKey =
						// @ts-ignore TODO
						locations[locationByTypeName[activeRuleType]].find(
							(v: Option) => v.id === activeRule.key
						)?.values || [];

					const mapByValue = valuesByKey.map((v: string) => ({ id: v, name: v }));

					dataList = [{ id: 'any', name: 'Any' }, { id: 'none', name: 'None' }, ...mapByValue]
						.filter((v: Option) => {
							return !newServices.includes(v.name);
						})
						.filter((v) => v.name.toLowerCase().includes(activeRule.value?.toLowerCase()));
				} else {
					dataList = [
						{ id: 'any', name: 'Any' },
						// @ts-ignore TODO
						...locations[locationByTypeName[activeRuleType]].filter(
							(v: Option) => !newServices.includes(v.name)
						),
					].filter((v) => v.name.toLowerCase().includes(activeRule.value?.toLowerCase()));
				}

				if (isCustomType(activeRule.type)) {
					setRuleErrors(
						getUpdatedRuleErrors({
							part: 'values',
							index: activeRule.index,
							removeCondition: validateIncludeValue(activeRule.value ?? '', dataList),
							ruleId: activeRule.ruleId,
						})
					);
				}

				return dataList;
			}

			return [];
		}

		return [];
	}, [activeRule]);

	function handleOpen(event: MouseEvent<HTMLElement>) {
		setAnchorEl(event.currentTarget);
	}

	function onClose() {
		setAnchorEl(null);
		setActiveRule(null);
		setBuilderState((prevState) => ({
			...initialState,
			emptyRulesError: prevState.emptyRulesError,
		}));
	}

	function checkForErrors() {
		if (activeRule && modifiedRules.length > 0) {
			let newRuleErrors = { ...ruleErrors };
			const cleanRules = rules.filter((rule) => !isEmptyRule(rule) && !isOperator(rule));

			cleanRules.forEach((rule: RuleItemArray) => {
				if (modifiedRules.includes(rule.index) && activeRule.index === rule.index) {
					const ruleType = rule.type.toLowerCase();

					// TYPE CHECK after we pressed Enter
					if (
						(activeRule.value !== undefined || activeRule.key !== undefined) &&
						!validateTypeAfterEnter(ruleType)
					) {
						newRuleErrors = getUpdatedRuleErrors({
							part: 'type',
							index: rule.index,
						});
					}

					const isTypeError = newRuleErrors[rule.index] && newRuleErrors[rule.index].type;

					if (!isTypeError) {
						// @ts-ignore TODO
						let valuesByType = locations[locationByTypeName[ruleType]];

						// VALUES CHECK after we pressed Enter
						if (activeRule.value !== undefined && activeRule.currentValue === '') {
							if (rule.key) {
								valuesByType = [
									{ id: 'any', name: 'Any', values: ['Any'] },
									{ id: 'none', name: 'None', values: ['None'] },
									...valuesByType,
								];
							} else {
								valuesByType = [{ id: 'any', name: 'Any' }, ...valuesByType];
							}

							if (isCustomType(rule.type)) {
								// We remove -1 element from errors array when currentValue is empty
								newRuleErrors = getUpdatedRuleErrors({
									part: 'values',
									index: rule.index,
									removeCondition: activeRule.currentValue.length === 0,
									ruleId: MAIN_INPUT_INDEX,
									currentRuleErrors: newRuleErrors,
								});
							}

							rule.values.forEach((v, vIndex) => {
								const valueInTheKeyType = valuesByType.find((o: Option) => {
									// If it's active then we use light verification with includes method
									if (activeRule.ruleId === vIndex && activeRule.index === rule.index) {
										const name = o.name.toLowerCase();
										const activeRuleKeyName = v.toLowerCase();

										return name.includes(activeRuleKeyName);
									}

									return o.name === v;
								});

								if (isCustomType(rule.type)) {
									newRuleErrors = getUpdatedRuleErrors({
										part: 'values',
										index: rule.index,
										removeCondition: valueInTheKeyType,
										ruleId: vIndex,
										currentRuleErrors: newRuleErrors,
									});
								}
							});
						}
					}
				}
			});

			// Clean errors if RuleGroup was deleted
			Object.keys(newRuleErrors).forEach((index) => {
				const isErrorExist = rules.find((rule) => rule.index === Number(index));

				if (!isErrorExist) {
					delete newRuleErrors[Number(index)];
				}
			});

			setRuleErrors(newRuleErrors);
			// setModifiedRules(modifiedRules.filter((e) => e === activeRule.index));
		}
	}

	function handleModifiedRules(index: number | undefined) {
		if (index !== undefined && !modifiedRules.includes(index)) {
			setModifiedRules([...modifiedRules, index]);
		}
	}

	const noMatchingResults = useMemo(() => {
		if (prepareOptions.length === 0 && activeRule?.type) {
			if (
				(activeRule.value !== undefined || activeRule.key !== undefined) &&
				!isCustomType(activeRule.type) &&
				// @ts-ignore TODO
				locationByTypeName[activeRule.type.toLowerCase()]
			) {
				return (
					<Typo variant="D/Regular/Body-S" color="secondary" className={styles.noOptionsBlock}>
						There are no matching results. Press ENTER for add a custom option.
					</Typo>
				);
			}

			return (
				<Typo variant="D/Regular/Body-S" color="secondary" className={styles.noOptionsBlock}>
					There are no matching results.
				</Typo>
			);
		}
	}, [prepareOptions]);

	return (
		<BuilderContext.Provider value={{ builderState, setBuilderState }}>
			<ClickAwayListener onClickAway={onClose}>
				<div className={styles.container}>
					<div className={styles.resetButton}>
						<ResetAll
							onConfirm={() => {
								setRules([getEmptyGroup()]);
								onClose();
							}}
						/>
					</div>

					<RuleList
						dim={dim}
						errors={ruleErrors}
						onActiveRuleChange={(r) => {
							setActiveRule(r);
						}}
						onClick={handleOpen}
						onClose={onClose}
						onRulesChange={(_rules, ruleIndex) => {
							setRules(_rules);
							handleModifiedRules(ruleIndex);
						}}
						open={!!anchorEl}
						rules={rules}
					/>

					<Popper
						className={styles.popper}
						anchorEl={anchorEl}
						open={!!anchorEl}
						placement="bottom"
						style={{ width: anchorEl?.clientWidth }}
					>
						{!activeRule?.operator ? (
							<div
								className={hasScroll ? styles.optionsWithScroll : styles.options}
								ref={scrollRef}
							>
								{prepareOptions.map((_option: Option) => (
									<OptionItem
										key={_option.id}
										option={_option}
										onClick={() =>
											setBuilderState((prevState) => ({ ...prevState, option: _option }))
										}
										searchString={activeRule?.value ?? activeRule?.key ?? activeRule?.type ?? ''}
										selected={false}
									/>
								))}

								{noMatchingResults}
							</div>
						) : null}

						<div className={styles.keysContainer}>
							{dim && <HotKey keys="ALT+1" description="AND" id="AND" />}
							<HotKey keys="ALT+2" description="OR" id="OR" className={styles.marginAuto} />

							{activeRule?.operator ? (
								<HotKey keys="Backspace" id="DELETE" description="To delete" />
							) : null}

							<HotKey keys="Enter" description="To update query" id="ENTER" />
							<HotKey keys="Close" id="ESC" description="Esc" />
						</div>
					</Popper>
				</div>
			</ClickAwayListener>
		</BuilderContext.Provider>
	);
}

export type { ActiveRule, Option, RuleErrors, RuleError, DIMGeneric, DARGeneric };
export { BuilderContext, PolicyBuilder };
