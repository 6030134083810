import { ConfirmModal, getConfirmation } from 'components/ConfirmModal';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import { enqueueSnackbar } from 'components/Snackbar';
import { Typo } from 'components/typography/Typo';
import { ClientItem } from 'models/clients/dto';
import { deleteClientFx, getInviteLinkFx, getPasswordLinkFx } from 'models/clients/effects';
import { PATHS } from 'services/router';
import styles from './index.module.pcss';
import { TStatus } from './index';

interface ITeamPopupMenu {
	isCurrent: boolean;
	client: ClientItem;
	fullName: string;
	setItemStatus: (status: TStatus) => void;
	anchorEl: null | HTMLElement;
	setAnchorEl: (anchorEl: null | HTMLElement) => void;
	isCanBlocked: boolean;
}

function TeamPopupMenu({
	isCurrent,
	client,
	fullName,
	setItemStatus,
	anchorEl,
	setAnchorEl,
	isCanBlocked,
}: ITeamPopupMenu) {
	const { role, email, status, client_id } = client;
	const isInvite = status === 'invite';
	const isAdmin = role === 'admin';

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onRevoke = async () => {
		setItemStatus('delete');

		try {
			if (await confirmRevoke()) {
				await deleteClientFx(client_id).then(() => {
					enqueueSnackbar(`Invite for ${email} revoked`);
				});
			}
		} catch (err) {
			enqueueSnackbar(`Failed to revoke invite for ${email}`);
		} finally {
			setItemStatus('stable');
			handleClose();
		}
	};

	const onBlock = async () => {
		setItemStatus('delete');

		try {
			if (await confirmBlock()) {
				await deleteClientFx(client_id).then(() => {
					enqueueSnackbar(`${fullName} blocked`);
				});
			}
		} catch (err) {
			enqueueSnackbar("Error of client's removing ");
		} finally {
			setItemStatus('stable');
			handleClose();
		}
	};

	const onGetInviteLink = async () => {
		setItemStatus('edit');

		try {
			await getInviteLinkFx(client_id).then(({ token }) => {
				if (token) {
					const value = `${window.location.origin}${PATHS.ACCEPT_INVITE}?token=${token}`;

					navigator.clipboard.writeText(value).then(() => {
						enqueueSnackbar('Invite link copied');
					});
				}
			});
		} catch (e) {
			enqueueSnackbar('Error generating invite link');
		} finally {
			setItemStatus('stable');
			handleClose();
		}
	};

	const onGetPasswordLink = async () => {
		setItemStatus('edit');

		try {
			await getPasswordLinkFx(client_id).then(({ token }) => {
				const value = `${window.location.origin}${PATHS.RESET_PASSWORD}?token=${token}`;

				navigator.clipboard.writeText(value).then(() => {
					enqueueSnackbar('Password recovery link generated and copied');
				});
			});
		} catch (e) {
			enqueueSnackbar('Error generating password recovery link');
		} finally {
			setItemStatus('stable');
			handleClose();
		}
	};

	async function confirmRevoke() {
		const titleMsg = `Revoke invite for ${email}?`;

		const confirmDeletionModal = (
			<ConfirmModal
				title={titleMsg}
				confirmProps={{
					autoFocus: true,
					children: 'Revoke invite',
				}}
				cancelProps={{
					className: styles.cancelButton,
				}}
			>
				<Typo variant="D/Regular/Body-S">
					{email} will no longer be able to use their
					<br />
					invite link.
				</Typo>
			</ConfirmModal>
		);

		return await getConfirmation(confirmDeletionModal);
	}

	async function confirmBlock() {
		const titleMsg = `Block ${fullName}?`;
		const confirmDeletionModal = (
			<ConfirmModal
				title={titleMsg}
				confirmProps={{
					children: 'Block',
				}}
			>
				<Typo variant="D/Regular/Body-S">
					{fullName} will no longer have access to Soveren and any team
					<br />
					resources within.
				</Typo>
			</ConfirmModal>
		);

		return await getConfirmation(confirmDeletionModal);
	}

	return (
		<>
			<Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
				{isInvite && (
					<MenuItem size="small" onClick={onGetInviteLink} data-test="team-copy-invite-link">
						Copy invite link
					</MenuItem>
				)}

				{isInvite && (
					<MenuItem size="small" theme="danger" onClick={onRevoke} data-test="team-revoke-invite">
						Revoke invite
					</MenuItem>
				)}

				{!isInvite && (
					<MenuItem size="small" onClick={onGetPasswordLink} data-test="team-generate-link">
						Generate password recovery link
					</MenuItem>
				)}

				{!isInvite && !isCurrent && (isCanBlocked || !isAdmin) && (
					<MenuItem size="small" theme="danger" onClick={onBlock} data-test="team-block-member">
						Block member
					</MenuItem>
				)}
			</Menu>
		</>
	);
}

export default TeamPopupMenu;
