import ButtonIcon from 'components/ButtonIcon';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import { enqueueSnackbar } from 'components/Snackbar';
import { Typo } from 'components/typography/Typo';
import { fileTypeLabels, S3ClusterFileWithSamples } from 'models/s3BucketCluster/dto';
import { lastSeen } from 'services/lastSeen';
import { toFileSize, toLocaleString } from 'services/numbers';
import { DASH_SIGN } from 'services/strings';
import styles from './index.module.css';

function copyToClipboard(data: string) {
	navigator.clipboard.writeText(data).then(() => {
		enqueueSnackbar('Sample path copied');
	});
}

type Props = {
	cluster: S3ClusterFileWithSamples;
};

function ClusterSummary(props: Props) {
	const {
		type,
		name,
		path,
		real_name_sample,
		objects_count,
		size,
		estimated_rows,
		last_scan_at,
		owner,
		sensitivity,
		data_types,
	} = props.cluster;

	const typeLabel = fileTypeLabels[type] || fileTypeLabels['unknown'];

	const lastScanAt = lastSeen({ timestamp: last_scan_at }).diff;

	return (
		<div>
			<Typo variant="D/Medium/H100-Header">Summary</Typo>
			<div className={styles.columns}>
				<div className={styles.leftColumn}>
					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Last scan
					</Typo>
					<Typo
						variant="D/Regular/Body-S"
						className={styles.lastScanAt}
						data-test="s3-cluster-summary-last-scan"
					>
						{last_scan_at !== 0 ? (
							lastScanAt
						) : (
							<>
								<Icon name="Clock/Filled" size={16} className={styles.grayIcon} /> Scanning in
								progress
							</>
						)}
					</Typo>

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Object type
					</Typo>
					<Typo variant="D/Regular/Body-S" data-test="s3-cluster-summary-object-type">
						{typeLabel}
					</Typo>

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Files
					</Typo>
					<Typo variant="D/Regular/Body-S" data-test="s3-cluster-summary-files-num">
						{toLocaleString(objects_count)}
					</Typo>

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Size
					</Typo>
					<Typo variant="D/Regular/Body-S" data-test="s3-cluster-summary-size">
						{toFileSize(size)}
					</Typo>

					{type === 'csv' && (
						<>
							<Typo variant="D/Regular/Meta-CAP" color="secondary">
								Est. rows
							</Typo>
							<Typo variant="D/Regular/Body-S" data-test="s3-cluster-summary-est-rows">
								{toLocaleString(estimated_rows)}
							</Typo>
						</>
					)}

					{type === 'parquet' && (
						<>
							<Typo variant="D/Regular/Meta-CAP" color="secondary">
								Rows
							</Typo>
							<Typo variant="D/Regular/Body-S" data-test="s3-cluster-summary-est-rows">
								{toLocaleString(estimated_rows)}
							</Typo>
						</>
					)}
				</div>

				<div className={styles.rightColumn}>
					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Owner
					</Typo>
					<Typo variant="D/Regular/Body-S" data-test="s3-cluster-summary-owner">
						{owner || DASH_SIGN}
					</Typo>

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Sensitivity
					</Typo>
					<SensitivityChip sensitivity={sensitivity} data-test="s3-cluster-summary-sensitivity" />

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Data types
					</Typo>
					<PiiTypeList data={data_types} noHighlight dataTest="s3-cluster-summary-data-types" />

					<Typo variant="D/Regular/Meta-CAP" color="secondary">
						Full path
					</Typo>

					<div className={styles.fullPathContainer}>
						<Typo
							variant="D/Regular/Body-S"
							className={styles.fullPath}
							data-test="s3-cluster-summary-full-path"
						>
							{path + name}
						</Typo>

						<ButtonIcon
							icon="copyLink"
							size="S"
							onClick={() => {
								copyToClipboard(real_name_sample);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export { ClusterSummary };
