import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Checkbox from 'components/Checkbox';
import Button from 'components/form/Button';
import BigWhiteSelect from 'components/form/Select/BigWhiteSelect';
import Preloader from 'components/Preloader';
import { enqueueSnackbar } from 'components/Snackbar';
import { RouterLink } from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import { integrationsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { EventCategory } from 'models/eventsV2/dto';
import {
	createSlackIntegration,
	getSlackChannels,
	getSlackSettings,
	updateSlackSettings,
} from 'models/integrations/slack/api';
import { SlackSettings } from 'models/integrations/slack/dto';
import { PATHS } from 'services/router';
import styles from './index.module.css';
import { STORAGE_KEY } from '.';

type CategoryOption = { id: EventCategory; name: string };

const categoryOptions: CategoryOption[] = [
	{ id: 'infrastructure_updates', name: 'Infrastructure updates' },
	{ id: 'data_at_risk', name: 'Data at risk' },
	{ id: 'misconfiguration', name: 'Misconfigurations' },
	{ id: 'others', name: 'Other' },
];

const initialSettings: SlackSettings = {
	has_token: true,
	is_enabled: false,
	channel: '',
	notify: [],
};

function Settings() {
	const history = useHistory();
	const location = useLocation();

	const [isLoading, setLoading] = useState(true);
	const [settings, setSettings] = useState<SlackSettings>(initialSettings);
	const [channels, setChannels] = useState<string[]>([]);
	const [selectedChannel, setSelectedChannel] = useState<string>('');
	const [selectedCategories, setSelectedCategories] = useState<EventCategory[]>([]);

	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const secret = query.get('code');
		const state = query.get('state');
		const localState = localStorage.getItem(STORAGE_KEY);

		let integrationPromise: Promise<unknown> = Promise.resolve();

		if (secret) {
			if (!state || state !== localState) {
				enqueueSnackbar('OAuth error: redirect state is corrupted.');
			} else {
				integrationPromise = createSlackIntegration(secret);
			}
		}

		integrationPromise
			.then(() => localStorage.removeItem(STORAGE_KEY))
			.then(() => Promise.all([getSlackSettings(), getSlackChannels()]))
			.then(([slackSettings, slackChannels]) => {
				setSettings(slackSettings);
				setChannels(slackChannels.channels.map(({ name }) => name));
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		setSelectedChannel(settings.channel);
		setSelectedCategories(
			categoryOptions.every((option) => settings.notify.includes(option.id)) ? [] : settings.notify
		);
	}, [settings]);

	function Label() {
		return selectedChannel ? (
			<Typo variant="D/Regular/Body-S">{selectedChannel}</Typo>
		) : (
			<Typo variant="D/Regular/Body-S" color="secondary">
				Select channel
			</Typo>
		);
	}

	function onCategoryClick(category?: EventCategory) {
		let newSelectedCategories: EventCategory[] = [];

		if (category) {
			newSelectedCategories = selectedCategories.includes(category)
				? selectedCategories.filter((c) => c !== category)
				: selectedCategories.concat(category);
		}

		if (newSelectedCategories.length === categoryOptions.length) {
			newSelectedCategories = [];
		}

		setSelectedCategories(newSelectedCategories);
	}

	function saveSettings() {
		updateSlackSettings({
			channel: selectedChannel,
			notify:
				selectedCategories.length > 0
					? selectedCategories
					: categoryOptions.map((option) => option.id),
			is_enabled: true,
		}).then((slackSettings) => {
			setSettings(slackSettings);
			enqueueSnackbar('Settings saved');
			history.push(PATHS.INTEGRATIONS);
		});
	}

	return (
		<>
			<Header>
				<Header.Breadcrumbs steps={integrationsSteps} finalStep="Slack integration configuration" />

				<Header.Title>Slack integration configuration</Header.Title>
			</Header>

			<Preloader isLoading={isLoading}>
				<div>
					<Typo variant="D/Medium/Body" className={styles.subtitle}>
						What event categories do you want to get Slack notifications about?
					</Typo>

					<div className={styles.checkboxContainer}>
						<Checkbox
							size="M"
							checked={selectedCategories.length === 0}
							onChange={() => onCategoryClick()}
							label="All events"
						/>

						{categoryOptions.map((option) => (
							<Checkbox
								size="M"
								checked={selectedCategories.includes(option.id)}
								onChange={() => onCategoryClick(option.id)}
								key={option.id}
								label={option.name}
							/>
						))}
					</div>

					<Typo variant="D/Medium/Body" className={styles.subtitle}>
						In what channel do you want to get Slack notifications?
					</Typo>

					<BigWhiteSelect
						hasSearch
						label=""
						options={channels}
						value={selectedChannel}
						onChange={setSelectedChannel}
						render={{
							label: Label,
						}}
					/>

					<div className={styles.buttonsContainer}>
						<RouterLink to={PATHS.INTEGRATIONS}>
							<Button color="secondary">Cancel</Button>
						</RouterLink>
						<Button onClick={saveSettings}>Save{!settings.is_enabled && ' and enable'}</Button>
					</div>
				</div>
			</Preloader>
		</>
	);
}

export default Settings;
