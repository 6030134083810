import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import Badge from 'components/Badge';
import Icon from 'components/Icon';
import Preloader from 'components/Preloader';
import { TabLink, TabPanel, Tabs } from 'components/Tabs';
import { Typo } from 'components/typography/Typo';
import { databasesSteps, dataStoragesSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getDatabaseDetails } from 'models/databases/api';
import {
	DatabaseDetails,
	DatabaseInstanceBase,
	DatabaseItem as DatabaseItemDto,
} from 'models/databases/dto';
import { databaseInstancesById, databaseInstancesStore } from 'models/databases/store';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { AutomaticDiscoveryTooltip } from 'views/common/AutomaticDiscovery';
import { AccessControlTable } from './AccessControlTable';
import styles from './index.module.css';
import { Summary } from './Summary';
import { TablesTable } from './TablesTable';

function DatabaseError() {
	const params: {
		id: string;
	} = useParams();

	return (
		<>
			<Header>
				<Header.Breadcrumbs steps={dataStoragesSteps} finalStep="Not found" />

				<Header.Title>Error has occurred while loading database info</Header.Title>
			</Header>

			<Typo variant="D/Regular/Body-S" color="secondary">
				Database with id={params.id} not found or was deleted.
			</Typo>
		</>
	);
}

function DatabaseItemWrapper() {
	const [db, setDB] = useState<DatabaseDetails | null>(null);
	const [isLoading, setLoading] = useState(true);

	const params: { id: string } = useParams();

	useEffect(() => {
		getDatabaseDetails(Number(params.id))
			.then(setDB)
			.finally(() => setLoading(false));
	}, [params.id]);

	return (
		<Preloader isLoading={isLoading}>{db ? <DatabaseItem db={db} /> : <DatabaseError />}</Preloader>
	);
}

const showAccessControl = (database: DatabaseItemDto, instances: DatabaseInstanceBase[]) => {
	const instance = instances.find(({ id }) => database.instance_id === id);

	return instance?.type === 'PostgreSQL';
};

function DatabaseItem({ db }: { db: DatabaseDetails }) {
	const history = useHistory();
	const params: {
		tab?: 'summary';
	} = useParams();
	const databaseInstances = useStore(databaseInstancesById);
	const instance = useMemo(
		() => databaseInstances[db.instance_id],
		[db.instance_id, databaseInstances]
	);

	const instances = useStore(databaseInstancesStore);

	const tabLinks = useMemo(() => {
		return {
			summary: generatePath(PATHS.DATABASES_ITEM, {
				id: db.id,
				tab: 'summary',
			}),
			tables: generatePath(PATHS.DATABASES_ITEM, {
				id: db.id,
				tab: 'tables',
			}),
			'access-control': generatePath(PATHS.DATABASES_ITEM, {
				id: db.id,
				tab: 'access-control',
			}),
		};
	}, [db.id]);

	const tabLabels = useMemo(() => {
		const accessControlTab = showAccessControl(db, instances)
			? { 'access-control': 'Access control' }
			: {};

		return {
			summary: 'Summary',
			tables: 'Tables',
			...accessControlTab,
		};
	}, [db, instances]);

	// Set default tab, if not specified.
	useEffect(function () {
		if (!params.tab) {
			const path = generatePath(PATHS.DATABASES_ITEM, {
				...params,
				tab: 'summary',
			});
			history.replace(path + history.location.search);
		}
	}, []);

	const isScanned = !!db.last_checked;

	const steps = [
		...databasesSteps,
		{
			path: generatePath(PATHS.DATABASES_ITEM, {
				id: db.id,
				tab: 'summary',
			}),
			name: db.name,
		},
	];

	return (
		<>
			<Header>
				<Header.Breadcrumbs steps={steps} finalStep={tabLabels[params.tab || 'summary']} />

				<Header.Title>
					<Typo variant="D/Medium/H400-Page-Title" className={styles.title}>
						{!instance.is_crawler_attached && <Icon name="Radar/Filled" size={24} />}
						{db.name}
					</Typo>
				</Header.Title>
				<Header.Subtitle>{instance.name}</Header.Subtitle>

				<Header.SensitiveSwitcher />
			</Header>

			<Tabs value={tabLinks[params.tab || 'summary']}>
				<TabLink value={tabLinks['summary']} label={tabLabels['summary']} />

				{instance.is_crawler_attached ? (
					<TabLink
						value={tabLinks['tables']}
						label={
							<>
								{tabLabels['tables']}
								<Badge
									badgeContent={isScanned ? toLocaleString(db.tables_count) : 'N/A'}
									data-test="database-tables-count"
								/>
							</>
						}
					/>
				) : (
					<AutomaticDiscoveryTooltip>
						<TabLink
							disabled
							value={tabLinks['tables']}
							label={
								<>
									{tabLabels['tables']}
									<Badge badgeContent="N/A" data-test="database-tables-count" />
								</>
							}
						/>
					</AutomaticDiscoveryTooltip>
				)}

				<TabLink value={tabLinks['access-control']} label={tabLabels['access-control']} />
			</Tabs>

			{/* TABS CONTENT */}
			<TabPanel value={params.tab} index="summary">
				<Summary db={db} instance={instance} />
			</TabPanel>

			<TabPanel value={params.tab} index="tables">
				<TablesTable databaseId={db.id} />
			</TabPanel>

			<TabPanel value={params.tab} index="access-control">
				<AccessControlTable databaseId={db.id} />
			</TabPanel>
		</>
	);
}

export { DatabaseItemWrapper as DatabaseItem };
