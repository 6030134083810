import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import MultiSelect from 'components/form/MultiSelect';
import DropdownButton from 'components/form/Select/DropdownButton';
import { AssetGroup } from 'models/assetsGroups/dto';
import { getAssetGroupsFx } from 'models/assetsGroups/effects';
import { assetGroupsList } from 'models/assetsGroups/store';
import { onceFx } from 'models/modelUtils/onceFx';
import { FilterPropsBase } from '../model';

type Option = { id: AssetGroup['id']; name: string };

type Props = FilterPropsBase & {
	value: AssetGroup['id'][];
	onChange: (newValue: AssetGroup['id'][]) => void;
};

function GroupFilter({ defaultOpen, fixed, onChange, onClose, resetFilter, value }: Props) {
	const assetGroups = useStore(assetGroupsList);

	useEffect(() => {
		onceFx(getAssetGroupsFx);
	}, []);

	const groupedValues = useMemo(() => {
		return assetGroups.map((asset) => {
			return { id: asset.id, name: asset.name };
		});
	}, [assetGroups]);

	const values = useMemo(() => {
		return groupedValues.filter((_value) => value.includes(_value.id));
	}, [value, groupedValues]);

	const handleOnChange = (options: Option[]) => {
		onChange(options.map((option) => option.id));
	};

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<MultiSelect
			defaultOpen={defaultOpen}
			hasSearch
			hasApplyButton
			options={groupedValues}
			value={values}
			onChange={handleOnChange}
			label={{ primary: 'Groups', secondary: `${values.length || ''}` }}
			onClose={onClose}
			render={{ dropdownButton }}
		/>
	);
}

export default GroupFilter;
