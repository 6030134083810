import { IHeadCell } from 'components/table/EnhancedTableHead';
import { AssetDataFlowsTableItem } from 'models/assetDataFlows/dto';

const tableConfig: IHeadCell<AssetDataFlowsTableItem>[] = [
	{
		id: 'flow_direction',
		numeric: false,
		label: 'Flow',
		sortable: false,
		filter: false,
	},
	{
		id: 'interacts_with',
		numeric: false,
		label: 'Interactions',
		sortable: true,
		filter: true,
	},
	{
		id: 'estimated_requests',
		numeric: true,
		label: 'Requests per day / avg RPS',
		sortable: false,
	},
	{
		id: 'namespace',
		numeric: false,
		label: 'Namespace',
		sortable: true,
		filter: false,
	},
	{
		id: 'groups',
		numeric: false,
		label: 'Group',
		sortable: false,
	},
	{
		id: 'sensitivity',
		numeric: false,
		label: 'Sensitivity',
		sortable: true,
	},
	{
		id: 'data_types',
		numeric: false,
		label: 'Data Types',
		sortable: false,
	},
	{
		id: 'last_seen',
		numeric: false,
		label: 'Last checked',
		sortable: true,
		reversedSort: true,
	},
];

export default tableConfig;
