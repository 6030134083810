import cn from 'classnames';
import { useMemo } from 'react';
import { Typo } from 'components/typography/Typo';
import { EventsTable } from 'models/eventsV2/dto';
import { toLocaleString } from 'services/numbers';
import styles from './index.module.css';

type Props = {
	data: EventsTable['counters_filtered'];
	className?: string;
};

const getPercentOfNumber = (partial: number, total: number) => Math.ceil((100 * partial) / total);

const PercentsLine = ({ data }: Props) => {
	const percents = useMemo(() => {
		return {
			critical: getPercentOfNumber(data.critical, data.total),
			high: getPercentOfNumber(data.high, data.total),
			medium: getPercentOfNumber(data.medium, data.total),
			low: getPercentOfNumber(data.low, data.total),
			info: getPercentOfNumber(data.info, data.total),
		};
	}, [data]);

	return (
		<div className={styles.percents}>
			{!!data.critical && (
				<div
					className={cn(styles.percentItem, styles.critical)}
					style={{ width: `${percents.critical}%` }}
				/>
			)}
			{!!data.high && (
				<div
					className={cn(styles.percentItem, styles.high)}
					style={{ width: `${percents.high}%` }}
				/>
			)}
			{!!data.medium && (
				<div
					className={cn(styles.percentItem, styles.medium)}
					style={{ width: `${percents.medium}%` }}
				/>
			)}
			{!!data.low && (
				<div className={cn(styles.percentItem, styles.low)} style={{ width: `${percents.low}%` }} />
			)}
			{!!data.info && (
				<div
					className={cn(styles.percentItem, styles.info)}
					style={{ width: `${percents.info}%` }}
				/>
			)}
		</div>
	);
};

export const EventsBySensitivity = ({ data, className }: Props) => {
	const noData = !data || !data.total;

	return (
		<div className={cn(styles.container, noData && styles.noDataContainer, className)}>
			<Typo variant="D/Medium/Meta-CAP" color="secondary">
				Events by sensitivity
			</Typo>

			{noData && <div className={styles.noData}>No data</div>}
			{!noData && (
				<>
					<div className={styles.counters}>
						<div className={cn(styles.counterItem, styles.counterItemTotal)}>
							<Typo variant="D/Medium/Meta-S-CAP" color="secondary">
								Total
							</Typo>
							<Typo data-test="events-total" variant="D/Medium/H400-Page-Title">
								{toLocaleString(data.total)}
							</Typo>
						</div>

						{!!data.critical && (
							<div className={styles.counterItem}>
								<Typo variant="D/Medium/Meta-S-CAP" color="secondary" className={styles.title}>
									<div className={cn(styles.titleDot, styles.critical)} />
									Critical
								</Typo>
								<Typo variant="D/Medium/H400-Page-Title">{toLocaleString(data.critical)}</Typo>
							</div>
						)}

						{!!data.high && (
							<div className={styles.counterItem}>
								<Typo variant="D/Medium/Meta-S-CAP" color="secondary" className={styles.title}>
									<div className={cn(styles.titleDot, styles.high)} />
									High
								</Typo>
								<Typo variant="D/Medium/H400-Page-Title">{toLocaleString(data.high)}</Typo>
							</div>
						)}

						{!!data.medium && (
							<div className={styles.counterItem}>
								<Typo variant="D/Medium/Meta-S-CAP" color="secondary" className={styles.title}>
									<div className={cn(styles.titleDot, styles.medium)} />
									Medium
								</Typo>
								<Typo variant="D/Medium/H400-Page-Title">{toLocaleString(data.medium)}</Typo>
							</div>
						)}

						{!!data.low && (
							<div className={styles.counterItem}>
								<Typo variant="D/Medium/Meta-S-CAP" color="secondary" className={styles.title}>
									<div className={cn(styles.titleDot, styles.low)} />
									Low
								</Typo>
								<Typo variant="D/Medium/H400-Page-Title">{toLocaleString(data.low)}</Typo>
							</div>
						)}

						{!!data.info && (
							<div className={styles.counterItem}>
								<Typo variant="D/Medium/Meta-S-CAP" color="secondary" className={styles.title}>
									<div className={cn(styles.titleDot, styles.info)} />
									Info
								</Typo>
								<Typo variant="D/Medium/H400-Page-Title">{toLocaleString(data.info)}</Typo>
							</div>
						)}
					</div>

					<PercentsLine data={data} />
				</>
			)}
		</div>
	);
};
