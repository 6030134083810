import cn from 'classnames';
import { ReactElement } from 'react';
import Chip, { CustomChipsProps } from 'components/Chip';
import { Tooltip } from 'components/Tooltip';
import { AssetJson } from 'models/assets/dto';
import styles from './index.module.pcss';

type Props = {
	label: AssetJson['name'] | ReactElement;
	isExternal: AssetJson['is_external'];
	size?: CustomChipsProps['size']; // Sometimes we need extra-small asset chip
	noTooltip?: boolean;
	className?: string;
	classes?: CustomChipsProps['classes'];
};

function AssetChip(props: Props) {
	const { label, size = 'small', noTooltip = false, classes, className } = props;

	const newClasses = {
		root: classes?.root,
		label: cn(styles.assetChip, size === 'small' && styles.small, classes?.label),
	};

	return noTooltip ? (
		<Chip
			label={label}
			size={size}
			theme="assetNeutral"
			className={className}
			classes={newClasses}
		/>
	) : (
		<Tooltip title={label}>
			<Chip
				label={label}
				size={size}
				theme="assetNeutral"
				className={className}
				classes={newClasses}
			/>
		</Tooltip>
	);
}

export default AssetChip;
