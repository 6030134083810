import { useCallback, useMemo } from 'react';
import Select from 'components/form/Select';
import DropdownButton from 'components/form/Select/DropdownButton';
import { EventSeverityParam } from 'models/eventsV2/model';
import { FilterPropsBase } from '../model';

type Props = FilterPropsBase & {
	value: EventSeverityParam;
	onChange: (newValue: EventSeverityParam) => void;
};

const options: { id: EventSeverityParam; name: string }[] = [
	// { id: 'critical', name: 'Critical' },
	{ id: 'high', name: 'High' },
	{ id: 'medium', name: 'Medium' },
	{ id: 'low', name: 'Low' },
	// { id: 'info', name: 'Info' },
];

export function SeverityFilter({
	defaultOpen,
	fixed,
	onChange,
	onClose,
	resetFilter,
	value,
}: Props) {
	const selected = useMemo(() => options.find((option) => !!value && option.id === value), [value]);

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<Select
			dataTest="select-severity"
			defaultOpen={defaultOpen}
			label={{ primary: 'Severity', secondary: selected?.name ?? '' }}
			options={options}
			onChange={(data) => onChange(data!.id)}
			onClose={onClose}
			render={{ dropdownButton }}
			value={selected}
		/>
	);
}
