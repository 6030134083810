import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { Typo } from 'components/typography/Typo';
import { getDataStoresOverview } from 'models/dashboard/api';
import { DataStoresOverview as TDataStoresOverview } from 'models/dashboard/dto';
import { toLocaleString, toFileSize, toAbbreviatedNumber } from 'services/numbers';
import { DASH_SIGN } from 'services/strings';
import styles from './index.module.css';

const labels: { [key in TDataStoresOverview['stores'][0]['type']]: string } = {
	sql_db_database: 'SQL databases',
	nosql_db_database: 'NoSQL databases',
	s3_bucket: 'S3 buckets',
	kafka_instance: 'Kafka',
};

type Props = {
	data: TDataStoresOverview;
};

function DataStoresOverview(props: Props) {
	const { data } = props;

	const [hovered, setHovered] = useState(-1);

	const onMouseEnter = useCallback(function (e) {
		setHovered(+e.currentTarget.dataset.i);
	}, []);
	const onMouseLeave = useCallback(function (e) {
		const { i } = e.currentTarget.dataset;
		setHovered((h) => (h === +i ? -1 : h));
	}, []);

	const maxDataFieldsCount = data.stores.reduce((acc, store) => Math.max(acc, store.data_count), 0);

	return (
		<>
			<div className={styles.totals}>
				<div className={styles.total}>
					<Typo variant="D/Medium/Meta-S-CAP" color="secondary">
						Storages
					</Typo>
					<Typo variant="D/Medium/H400-Page-Title" dataTest="data-stores-total">
						{toLocaleString(data.total_count)}
					</Typo>
				</div>

				<div className={styles.total}>
					<Typo variant="D/Medium/Meta-S-CAP" color="secondary">
						With sensitive data
					</Typo>
					<Typo variant="D/Medium/H400-Page-Title">
						{toLocaleString(data.with_sensitive_count)}
					</Typo>
				</div>
			</div>

			<div className={styles.overview} data-test="data-stores-overview">
				<div className={styles.overviewHeader}>
					<Typo variant="D/Medium/Meta-S-CAP" color="secondary">
						Overview
					</Typo>

					<span className={styles.leftSquare} />
					<Typo variant="D/Regular/Meta-S" color="secondary">
						Stored data
					</Typo>

					<span className={styles.rightSquare} />
					<Typo variant="D/Regular/Meta-S" color="secondary">
						Sensitive data
					</Typo>
				</div>

				<div className={styles.chartContainer}>
					{data.stores.map((store, i) => {
						const heightLeft = (60 * store.data_count) / maxDataFieldsCount;
						const heightRight = (60 * store.data_with_sensitive_count) / maxDataFieldsCount;

						return (
							<div
								className={cn(styles.chartForStore, hovered === i && styles.hoveredBars)}
								key={i}
								data-i={i}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
							>
								<div className={styles.bars}>
									<div className={styles.barContainer}>
										<Typo variant="D/Regular/Meta-S" className={styles.barNumber}>
											{toAbbreviatedNumber(store.data_count, 1)}
										</Typo>
										<div className={styles.leftBar} style={{ height: heightLeft }} />
									</div>
									<div className={styles.barContainer}>
										<Typo variant="D/Regular/Meta-S">
											{toAbbreviatedNumber(store.data_with_sensitive_count, 1)}
										</Typo>
										<div className={styles.rightBar} style={{ height: heightRight }} />
									</div>
								</div>
								<Typo variant="D/Regular/Meta-S" color="secondary">
									{labels[store.type]}
								</Typo>
							</div>
						);
					})}
					<div className={styles.hr} />
				</div>
			</div>

			<div className={styles.row}>
				<Typo variant="D/Medium/Meta-CAP" color="secondary">
					Storage
				</Typo>
				<Typo variant="D/Medium/Meta-CAP" color="secondary">
					Stored data
				</Typo>
				<Typo variant="D/Medium/Meta-CAP" color="secondary">
					Sensitive data
				</Typo>
				<Typo variant="D/Medium/Meta-CAP" color="secondary">
					Size
				</Typo>
			</div>

			{data.stores.map((store, i) => (
				<div className={styles.row} key={i}>
					<span>{labels[store.type]}</span>
					<span>{toAbbreviatedNumber(store.data_count, 1)}</span>
					<span>
						<span className={styles.sensitiveNumber}>
							{toAbbreviatedNumber(store.data_with_sensitive_count, 1)}
						</span>
						<Typo variant="D/Regular/Body-S" color="secondary" component="span">
							{toAbbreviatedNumber(store.data_with_sensitive_percent, 1)}%
						</Typo>
					</span>
					<span>{store.type !== 'kafka_instance' ? toFileSize(store.size, 0) : DASH_SIGN}</span>
				</div>
			))}
		</>
	);
}

function Wrapper() {
	const [data, setData] = useState<TDataStoresOverview | null>(null);

	useEffect(() => {
		getDataStoresOverview().then((res) => setData(res));
	}, []);

	return data === null ? <Loader /> : <DataStoresOverview data={data} />;
}

export { Wrapper as DataStoresOverview };
