import cn from 'classnames';
import React, {
	createContext,
	KeyboardEvent,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { ColoredWrapperState } from '../ColoredWrapper';
import { ActiveRule, BuilderContext, RuleError } from '../PolicyBuilder';
import {
	AND_OPERATOR,
	getAndRule,
	getEmptyGroup,
	getOrRule,
	OR_OPERATOR,
	isEmptyRule,
} from '../PolicyBuilder/helpers.ts';
import { RuleItemArray } from '../RuleList';
import styles from './index.module.pcss';
import { KeyLabelPart } from './KeyLabelPart';
import { TypePart } from './TypePart';
import { ValuesPart } from './ValuesPart';

interface Props {
	activeRule: ActiveRule | null;
	dim: boolean;
	error?: RuleError | undefined;
	first: boolean;
	groupId: number;
	last: boolean;
	setNextGroupActive: (groupId?: number) => void;
	onClose: () => void;
	open: boolean;
	onActiveRuleChange: (activeRule: ActiveRule) => void;
	onRuleChange: (ruleItem: RuleItemArray, activeRule: ActiveRule) => void;
	value: RuleItemArray;
}

export type EDIT_MODE = 'type' | 'values' | 'key' | null;
export const MAIN_INPUT_INDEX = -1;
export const KEY_INPUT_INDEX = -2;

interface StateProps {
	idActiveRule: number | null;
	color: ColoredWrapperState;
	editMode: EDIT_MODE;
	hovered: boolean;
	isLabelType: boolean;
}

const initialState: StateProps = {
	idActiveRule: null,
	color: 'unfocusedEmpty',
	hovered: false,
	editMode: null,
	isLabelType: false,
};

export const StateContext = createContext<{
	state: StateProps;
	setState: React.Dispatch<React.SetStateAction<StateProps>>;
}>({
	state: initialState,
	setState: () => {},
});

export function RuleGroup({
	activeRule,
	error,
	dim,
	first,
	groupId,
	last,
	setNextGroupActive,
	onClose,
	open,
	onActiveRuleChange,
	onRuleChange,
	value: ruleValue,
}: Props) {
	const { builderState } = useContext(BuilderContext);
	const [state, setState] = useState(initialState);
	const [currentValue, setCurrentValue] = useState('');

	const notEmpty = useMemo(() => {
		return ruleValue.type.length > 0 || currentValue.length > 0;
	}, [ruleValue.type, currentValue]);

	const showCompensator = useMemo(() => {
		return !first && isEmptyRule(ruleValue) && !!state.editMode;
	}, [ruleValue, state.editMode]);

	// If clickAway is occurred  we clean the state
	useEffect(() => {
		if (!open && activeRule == null) {
			onBlur();
		}
	}, [open, activeRule]);

	// Set state for ColoredWrapper
	useEffect(() => {
		switch (true) {
			case !!error:
				setState((prevState) => ({ ...prevState, color: 'error' }));
				break;
			case first && isEmptyRule(ruleValue):
				setState((prevState) => ({ ...prevState, color: 'unfocusedEmptyFirst' }));
				break;
			/* case state.hovered && !notEmpty:
				setState((prevState) => ({ ...prevState, color: 'unfocusedHoveredEmpty' }));
				break; */
			case state.hovered && notEmpty:
				setState((prevState) => ({ ...prevState, color: 'unfocusedHoveredWithContent' }));
				break;
			case state.editMode === 'type' && currentValue.length === 0:
				setState((prevState) => ({ ...prevState, color: 'focusedEmpty' }));
				break;
			case (notEmpty || ruleValue.values.length > 0) && !state.editMode:
				setState((prevState) => ({ ...prevState, color: 'unfocusedWithContent' }));
				break;
			case notEmpty || state.editMode:
				setState((prevState) => ({ ...prevState, color: 'focusedWithContent' }));
				break;
			default:
				setState((prevState) => ({ ...prevState, color: 'unfocusedEmpty' }));
		}
	}, [error, ruleValue, notEmpty, state.editMode, currentValue, state.hovered]);

	// Catch buttons' click from the HotKeys
	useEffect(() => {
		if (activeRule && activeRule.index === ruleValue.index) {
			switch (builderState.keyAction) {
				case 'ENTER':
					onEnter(
						activeRule.currentValue ?? activeRule.value ?? activeRule.key ?? activeRule.type,
						activeRule.ruleId
					);
					break;
				default:
					break;
			}
		}
	}, [builderState.keyAction, activeRule]);

	// Here we determine is label type or not
	useEffect(() => {
		setState((prevState) => ({
			...prevState,
			// TODO change for type id like namespace_label and label
			isLabelType: ruleValue.type === 'Namespace label' || ruleValue.type === 'Service label',
		}));
	}, [ruleValue.type]);

	//
	useEffect(() => {
		const isFocusedGroup = activeRule?.index === ruleValue.index;
		if (isFocusedGroup) {
			// setCurrentValue(activeRule?.currentValue);

			if (builderState.option) {
				// TODO Maybe move to separate useEffect
				onChange(builderState.option.name, state.idActiveRule ?? activeRule?.ruleId);
				onEnter(builderState.option.name, state.idActiveRule ?? activeRule?.ruleId);
			} else {
				if (isEmptyRule(ruleValue)) {
					setState((prevState) => ({ ...prevState, editMode: 'type' }));
				}

				if (state.idActiveRule === null) {
					setState((prevState) => ({ ...prevState, idActiveRule: activeRule?.ruleId }));
				}
			}
		} else {
			// When the rule is unfocused,
			// we deactivate editMode and update the status for ColoredWrapper accordingly
			// We cannot use here onBlur, because it closes the builder
			setState((prevState) => ({ ...prevState, editMode: null, idActiveRule: null }));
			setCurrentValue('');
			// TODO: when mainInput isn't empty and we click to another group we have to send new data to the Rule list
		}
	}, [builderState.option, activeRule, ruleValue]);

	// We need to send group data to RuleList depending on the cursor position.
	// state.idActiveRule - if we click to the rules inside the group, or use right or left keys
	// ruleValue - update activeRule with current value. If we remove it from dependencies activeRule stop updated when we type Enter from MainInput
	// state.editMode - allow to send the data just from active group
	useEffect(() => {
		if (state.editMode) {
			onActiveRuleChange(prepareActiveRule(ruleValue));
		}
		// TODO try to remove ruleValue, because it make additional update of activeRule
	}, [state.editMode, ruleValue, state.idActiveRule]);

	// Helper functions
	function saveLastServiceOnBlur() {
		if (
			state.editMode === 'values' &&
			state.idActiveRule === MAIN_INPUT_INDEX &&
			currentValue.length > 0
		) {
			sendGroupData({ ...ruleValue, values: [...ruleValue.values, currentValue] });
		}
	}

	function onBlur() {
		setState((prevState) => ({ ...prevState, editMode: null, idActiveRule: null }));
		setCurrentValue('');

		if (ruleValue.index === activeRule?.index) {
			saveLastServiceOnBlur();
		}
	}

	// This function is necessary if we're leaving component by `tab` pressing
	function setNewFocus(step: number, startIndex: number) {
		if (state.idActiveRule !== null) {
			if (startIndex === MAIN_INPUT_INDEX) {
				if (step === -1) {
					// Can move only to the left from the `mainInput`
					setState((prevState) => ({ ...prevState, idActiveRule: ruleValue.values.length - 1 }));
				}
			} else {
				const newIndex = state.idActiveRule + step;

				if (newIndex > ruleValue.values.length - 1) {
					setState((prevState) => ({ ...prevState, idActiveRule: MAIN_INPUT_INDEX }));
				} else if (newIndex >= 0 && newIndex !== startIndex) {
					setState((prevState) => ({
						...prevState,
						idActiveRule:
							state.idActiveRule !== null ? state.idActiveRule + step : MAIN_INPUT_INDEX,
					}));
				}
			}
		}
	}

	function prepareActiveRule(_rule: RuleItemArray, _currentValue?: string) {
		const newCurrentValue = _currentValue ?? currentValue;
		const ruleId = state.idActiveRule ?? MAIN_INPUT_INDEX;
		const _activeRule: ActiveRule = {
			currentValue: newCurrentValue,
			groupId,
			index: _rule.index,
			ruleId,
			type: _rule.type,
		};

		if (state.editMode === 'type') {
			_activeRule['type'] = newCurrentValue;
		} else if (state.editMode === 'key') {
			_activeRule['key'] = ruleValue.key ?? newCurrentValue;
		} else if (state.editMode === 'values') {
			if (ruleValue.key) {
				_activeRule['key'] = ruleValue.key;
			}

			_activeRule['value'] = _rule.values[ruleId] ?? newCurrentValue;
		}

		return _activeRule;
	}

	function sendGroupData(_rule: RuleItemArray, _currentValue?: string) {
		onRuleChange(_rule, prepareActiveRule(_rule, _currentValue));
	}

	function onKeyDown(event: KeyboardEvent<HTMLInputElement>, value: string, ruleId: number) {
		if (
			event.code === 'Enter' ||
			(event.code === 'Comma' && state.editMode === 'values') ||
			(event.code === 'Semicolon' && state.editMode === 'type')
		) {
			event.preventDefault();

			onEnter(value, ruleId);
		} else if (event.code === 'ArrowLeft') {
			const target = event.target as HTMLInputElement; // Typescript trick to recognize `selectionEnd`

			if (target.selectionEnd === 0) {
				setNewFocus(-1, ruleId);
			}
		} else if (event.code === 'ArrowRight') {
			const target = event.target as HTMLInputElement; // Typescript trick to recognize `selectionEnd`

			if (target.selectionEnd === value.length) {
				setNewFocus(1, ruleId);
			}
		} else if (event.code === 'Backspace') {
			if (value.length === 0) {
				event.preventDefault();

				onDelete(ruleId);
			}
		} else if (event.code === 'Escape') {
			event.preventDefault();

			onEnter(value, ruleId);
			onBlur();
			onClose();
		}
	}

	// onDelete handlers
	function onDelete(ruleId: number) {
		if (state.editMode === 'type') {
			onTypeDelete();
		} else if (state.editMode === 'key') {
			onKeyLabelDelete();
		} else if (state.editMode === 'values') {
			onValuesDelete(ruleId);
		}
	}

	function onTypeDelete() {
		if (!last) {
			// If we delete empty group(somewhere between in other groups) we just close the builder
			onBlur();
			onClose();
		}
	}

	function onKeyLabelDelete() {
		sendGroupData(getEmptyGroup());
	}

	function onValuesDelete(ruleId: number) {
		if (ruleId === MAIN_INPUT_INDEX) {
			if (ruleValue.values.length === 0) {
				if (state.isLabelType) {
					setState((prevState) => ({
						...prevState,
						editMode: 'key',
						idActiveRule: KEY_INPUT_INDEX,
					}));
				} else {
					sendGroupData(getEmptyGroup());
				}
			} else {
				setNewFocus(-1, MAIN_INPUT_INDEX);
			}
		} else {
			const newRuleValue = {
				...ruleValue,
				values: [...ruleValue.values.slice(0, ruleId), ...ruleValue.values.slice(ruleId + 1)],
			};

			if (newRuleValue.values.length === 0) {
				setNewFocus(1, ruleId);
			} else {
				setNewFocus(-1, ruleId);
			}

			sendGroupData(newRuleValue);
		}
	}

	// onEnter handlers
	function onEnter(_value: string, ruleId: number) {
		if (state.editMode === 'type') {
			onTypeEnter(_value);
		} else if (state.editMode === 'key') {
			onKeyLabelEnter(_value);
		} else if (state.editMode === 'values') {
			onValuesEnter(_value, ruleId);
		}

		setCurrentValue('');
	}

	function onTypeEnter(_value: string) {
		if (_value.toUpperCase() === OR_OPERATOR) {
			sendGroupData(getOrRule());

			setNextGroupActive();
		} else if (_value.toUpperCase() === AND_OPERATOR && dim) {
			sendGroupData(getAndRule());

			setNextGroupActive();
		} else if (_value === '') {
			onBlur();
			onClose();
		} else if (_value === 'Namespace label' || _value === 'Service label') {
			// TODO change for type id like namespace_label and label
			setState((prevState) => ({
				...prevState,
				editMode: 'key',
				idActiveRule: KEY_INPUT_INDEX,
			}));
		} else {
			setState((prevState) => ({
				...prevState,
				editMode: 'values',
				idActiveRule: MAIN_INPUT_INDEX,
			}));
		}
	}

	function onKeyLabelEnter(_value: string) {
		if (_value === '') {
			sendGroupData(getEmptyGroup());
		} else {
			sendGroupData({ ...ruleValue, key: _value });
			setState((prevState) => ({
				...prevState,
				editMode: 'values',
				idActiveRule: MAIN_INPUT_INDEX,
			}));
		}
	}

	function onValuesEnter(_value: string, ruleId: number) {
		if (_value.toUpperCase() === 'ANY') {
			sendGroupData({ ...ruleValue, values: [] });

			setNextGroupActive();
		} else if (_value.toUpperCase() === 'NONE') {
			sendGroupData({ ...ruleValue, values: ['None'] });

			setNextGroupActive();
		} else if (ruleId === MAIN_INPUT_INDEX) {
			if (_value === '') {
				// Because no values was added we do focus to the next group
				sendGroupData(ruleValue);
				setNextGroupActive();
			} else {
				sendGroupData({ ...ruleValue, values: [...ruleValue.values, _value] });
				setState((prevState) => ({ ...prevState, idActiveRule: MAIN_INPUT_INDEX }));
			}
		} else {
			setState((prevState) => ({ ...prevState, idActiveRule: MAIN_INPUT_INDEX }));
		}
	}

	// onChange handlers
	function onChange(_value: string, ruleId: number) {
		if (state.editMode === 'type') {
			onTypeChange(_value);
		} else if (state.editMode === 'key') {
			onKeyLabelChange(_value);
		} else if (state.editMode === 'values') {
			onValuesChange(_value, ruleId);
		}
	}

	function onTypeChange(_currentValue: string) {
		setCurrentValue(_currentValue);

		sendGroupData({ ...ruleValue, type: _currentValue }, _currentValue);
	}

	function onKeyLabelChange(_currentValue: string) {
		// If we change key, we cancel previous values
		sendGroupData({ ...ruleValue, key: _currentValue, values: [] }, _currentValue);
	}

	function onValuesChange(_currentValue: string, ruleId: number) {
		if (ruleId === MAIN_INPUT_INDEX) {
			setCurrentValue(_currentValue);

			sendGroupData(ruleValue, _currentValue);
		} else {
			const newServices = [...ruleValue.values];
			newServices[ruleId] = _currentValue;

			sendGroupData({ ...ruleValue, values: newServices }, _currentValue);
		}
	}

	return (
		<StateContext.Provider value={{ state, setState }}>
			<TypePart
				first={first}
				currentValue={currentValue}
				error={error?.type || builderState.emptyRulesError}
				onChange={onTypeChange}
				onKeyDown={onKeyDown}
				value={ruleValue.type}
			/>

			<KeyLabelPart onChange={onKeyLabelChange} onKeyDown={onKeyDown} value={ruleValue.key} />

			<ValuesPart
				currentValue={currentValue}
				error={error?.values}
				onChange={onValuesChange}
				onKeyDown={onKeyDown}
				typeValue={ruleValue.type}
				values={ruleValue.values}
			/>

			{showCompensator && (
				<div
					className={cn(styles.rightCompensator, {
						[styles.focused]: notEmpty,
					})}
				/>
			)}
		</StateContext.Provider>
	);
}
