import { useStore } from 'effector-react';
import { useMemo } from 'react';
import { CheckboxBase } from 'components/Checkbox';
import Button from 'components/form/Button';
import MultiSelect from 'components/form/MultiSelect';
import { OptionItemProps } from 'components/form/MultiSelect/OptionItem';
import { DropdownButtonProps } from 'components/form/Select/DropdownButton';
import { LabelProps } from 'components/form/Select/Label';
import Icon from 'components/Icon';
import MenuItem from 'components/MenuItem';
import { Typo } from 'components/typography/Typo';
import { AssetJson } from 'models/assets/dto';
import { getAssetNameByType } from 'models/assets/model';
import { gatewaysNamesById } from 'models/gateways/store';
import { dividerDot } from 'services/strings';
import styles from './index.module.css';
import ruleStyles from './ruleStyles.module.pcss';

function DropdownButton(props: DropdownButtonProps) {
	const { onClick, children } = props;

	return (
		<span className={ruleStyles.ruleType}>
			<Button
				className={ruleStyles.ruleTypeButton}
				color="ghost"
				onClick={onClick}
				data-test="third-party-select"
			>
				{children}
			</Button>
		</span>
	);
}

function Label(props: LabelProps) {
	const { label } = props;

	return typeof label === 'string' ? (
		<Typo variant="D/Medium/Body-S" color="secondary">
			{label}
		</Typo>
	) : (
		<>
			<Typo variant="D/Medium/Body-S" color="secondary">
				{label.primary}
			</Typo>
			<Typo variant="D/Medium/Body-S">{label.secondary}</Typo>
		</>
	);
}

function isAsset(option: AssetJson | unknown): option is AssetJson {
	return typeof option === 'object' && option !== null && 'is_external' in option;
}

function OptionItem<T extends AssetJson>(props: OptionItemProps<T>) {
	const { option, selected, searchString, onClick } = props;

	const gatewaysNamesStore = useStore(gatewaysNamesById);

	let optionText, renderedOption;

	if (isAsset(option)) {
		const assetName = getAssetNameByType(option.type, option.name);
		let clusterName = gatewaysNamesStore[option.cluster_id] || '';
		if (clusterName) clusterName = dividerDot + clusterName;

		optionText = assetName + clusterName;
		renderedOption = (
			<>
				{assetName}
				<Typo variant="D/Regular/Body-S" color="secondary" className={styles.preserveWhitespace}>
					{clusterName}
				</Typo>
			</>
		);
	} else {
		optionText = option.name;
		renderedOption = option.name;
	}

	const isSearched = searchString
		? optionText.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
		: true;

	return isSearched ? (
		<MenuItem size="small" onClick={onClick}>
			<CheckboxBase checked={selected} className={styles.checkbox} />
			{renderedOption}
		</MenuItem>
	) : null;
}

type RuleValueProps = {
	name: string;
	onDelete: () => void;
};

function RuleValue(props: RuleValueProps) {
	const { name, onDelete } = props;

	return (
		<span className={ruleStyles.ruleValue} onClick={onDelete} data-test="third-party-item">
			{name}
			<Icon name="DismissCircle/Filled" size={16} className={ruleStyles.deleteName} />
		</span>
	);
}

type Props = {
	options: AssetJson[];
	value: AssetJson[];
	onChange: (value: AssetJson[]) => void;
	label: string;
};

function ThirdPartyMultiSelect(props: Props) {
	const { value, options, onChange, label } = props;

	const ruleValues = useMemo(() => {
		const result = value.flatMap((v) => {
			return [
				<RuleValue
					key={v.id}
					name={v.name}
					onDelete={() => onChange(value.filter((val) => val !== v))}
				/>,
				<span key={-v.id} className={ruleStyles.rowItem}>
					{' , '}
				</span>,
			];
		});

		result.pop();

		return result;
	}, [value]);

	return (
		<div data-test="third-party-row">
			<Typo component="span" variant="D/Medium/Body-S" className={ruleStyles.prefix}>
				Except
			</Typo>

			<MultiSelect
				hasSearch
				hasApplyButton
				allOption="none"
				options={options}
				value={value}
				onChange={onChange}
				label={label}
				render={{
					label: Label,
					dropdownButton: DropdownButton,
					optionItem: OptionItem,
				}}
			/>

			{ruleValues}
		</div>
	);
}

export default ThirdPartyMultiSelect;
