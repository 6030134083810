import { InputAdornment } from '@material-ui/core';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Alert, { AlertSeverity } from 'components/Alert';
import ButtonIcon from 'components/ButtonIcon';
import Button from 'components/form/Button';
import PasswordField from 'components/form/PasswordField';
import TextField from 'components/form/TextField';
import Link from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import { signInFx } from 'models/session/effects';
import { APIError } from 'services/api/httpRequest';
import { PATHS } from 'services/router';
import signUpStyles from '../SignUp/index.module.pcss';

type FormValues = {
	email: string;
	password: string;
};

type Props = {
	email: string;
	clearEmail: () => void;
};

function SignInPassword(props: Props) {
	const [isLoading, setLoading] = useState(false);
	const [serverError, setServerError] = useState<string | null>(null);

	const { control, handleSubmit } = useForm<FormValues>({
		defaultValues: {
			email: props.email,
			password: '',
		},
		mode: 'onBlur', // "onChange"
	});

	async function onSubmit({ email, password }: FormValues) {
		setLoading(true);

		try {
			await signInFx({ login: email, password });
		} catch (error) {
			if (
				error instanceof APIError &&
				(error.response.status === 401 || error.response.status === 403)
			) {
				const { message } = await error.response.json();
				setServerError(message);
			} else {
				throw error;
			}

			setLoading(false);
		}
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className={signUpStyles.form}>
				<TextField
					value={props.email}
					label="Email"
					type="email"
					autoComplete="username"
					readOnly
					required
					fullWidth
					size="large"
					data-test="sign-in-email"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<ButtonIcon
									icon="Dismiss/Regular"
									color="icon/primary"
									onClick={props.clearEmail}
								/>
							</InputAdornment>
						),
					}}
				/>

				<PasswordField
					control={control}
					label="Password"
					name="password"
					autoFocus
					autoComplete="current-password"
					required
					fullWidth
					size="large"
					data-test="sign-in-password"
				/>

				<Button
					type="submit"
					size="large"
					loading={isLoading}
					className={signUpStyles.formButton}
					data-test="sign-in-submit-button"
				>
					Sign in
				</Button>

				{serverError && !isLoading && (
					<Alert className={signUpStyles.errorMessage} severity={AlertSeverity.error}>
						{serverError}
					</Alert>
				)}
			</form>

			<Typo variant="D/Medium/Body-S" className={signUpStyles.haveAccount}>
				Don’t have an account? <Link to={PATHS.SIGN_UP}>Sign up</Link>.
			</Typo>
		</>
	);
}

export default SignInPassword;
