import Chip from 'components/Chip';
import NoContent from 'components/NoContent';
import EnhancedTableHead from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import { Typo } from 'components/typography/Typo';
import { DatabaseRoleItem } from 'models/databases/dto';
import accessControlStyles from '../index.module.css';
import tableConfig from './config';
import styles from './index.module.css';

type Props = {
	role?: DatabaseRoleItem;
	curtainOpen: boolean;
};

export const PermissionsTable = ({ role, curtainOpen }: Props) => {
	if (!curtainOpen) return null;

	return (
		<div className={styles.container}>
			<Typo variant="D/Medium/H100-Header">{role?.name}</Typo>
			<Typo variant="D/Regular/Meta">{role?.type}</Typo>

			<div className={styles.table}>
				<EnhancedTableHead config={tableConfig} rowClassname={styles.rowContainer} />

				{role?.role_permissions?.length === 0 && (
					<NoContent type="databasePermissionsTable" className={styles.rowContainer}>
						<div className={accessControlStyles.emptyContainer}>
							<Typo
								variant="D/Medium/Body"
								color="primary"
								className={accessControlStyles.emptyContainerText}
							>
								No permissions found
							</Typo>
						</div>
					</NoContent>
				)}

				{role?.role_permissions?.length !== 0 && (
					<GridBody className={styles.gridBody}>
						{role?.role_permissions.map((perm) => (
							<GridRow
								key={perm.id}
								data-test="group-table-row"
								className={styles.rowContainer}
								hover
								border
							>
								<GridCell className={styles.gapCell} verticalAlign="middle">
									<Typo variant="D/Regular/Body-S">
										{perm.table_schema
											? `${perm.table_schema}.${perm.table_name}`
											: perm.table_name}
									</Typo>
								</GridCell>

								<GridCell verticalAlign="middle" className={styles.aggregatedPermissions}>
									{perm.permissions.map((permission) => (
										<Chip
											key={permission}
											className={styles.responseCodeChip}
											label={permission}
											size="small"
											color="primary"
											theme="assetNeutral"
										/>
									))}
								</GridCell>
							</GridRow>
						))}
					</GridBody>
				)}
			</div>
		</div>
	);
};
