import { ReactNode } from 'react';
import { Typo } from 'components/typography/Typo';

type Props = {
	title?: string;
	children: string | ReactNode;
	'data-test'?: string;
};

function Label(props: Props) {
	const { title, children, 'data-test': dataTest } = props;

	return (
		<>
			<Typo variant="D/Regular/Meta-CAP" color="secondary">
				{title}
			</Typo>
			{typeof children === 'string' ? (
				<Typo variant="D/Regular/Body-S" dataTest={dataTest}>
					{children}
				</Typo>
			) : (
				children
			)}
		</>
	);
}

export default Label;
