import { useStore } from 'effector-react';
import { useEffect, useMemo } from 'react';
import FilterLine from 'components/FilterLine';
import FilterResult from 'components/FilterResult';
import LoadMoreButton from 'components/LoadMoreButton';
import NewGatewayButton from 'components/NewGatewayBlock/NewGatewayButton';
import NoContent from 'components/NoContent';
import {
	clearPiiFilter,
	piiFilterToArray,
	piiFilterWithGroupsStore,
} from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import ResetFilters from 'components/ResetFilters';
import EnhancedTableHead, { TOrder } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import { Typo } from 'components/typography/Typo';
import { NoSQLDatabaseItem } from 'models/noSQLDatabases/dto';
import { noSQLDatabasesModel } from 'models/noSQLDatabases/model';
import { noSQLDatabaseInstancesStore } from 'models/noSQLDatabases/store';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import { UniversalSearch } from 'views/common/UniversalSearch';
import DataStorage from 'views/DataStorages';
import commonStyles from '../index.module.css';
import tableConfig from './config';
import styles from './index.module.css';
import { TableRow } from './TableRow';

const pageConfig = {
	'instance-ids': {
		type: 'numberArray',
		persistence: 'session',
	},
	'data-types': {
		type: 'numberArray',
		persistence: 'session',
	},
	nonempty: {
		type: 'boolean',
		persistence: 'session',
	},
	search: {
		type: 'string',
		persistence: 'session',
	},
	sort: {
		type: 'sort',
		persistence: 'session',
	},
} satisfies PageParamsConfig;

export function NoSQLDatabasesTable() {
	const state = useStore(noSQLDatabasesModel.store);
	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);
	const databaseInstances = useStore(noSQLDatabaseInstancesStore);

	// These addition tables the only one way to show DB instances where crawler wasn't attached
	// because we don't have filters by instances or separate page/view for them
	const nonExistedTables = useMemo(() => {
		return databaseInstances
			.filter((instance) => !instance.is_crawler_attached)
			.map(
				(instance): NoSQLDatabaseItem => ({
					collections_count: 0,
					data_types: [],
					id: instance.id,
					instance_id: instance.id,
					last_checked: 0,
					name: 'N/A',
					sensitivity: 'N/A',
					size: 0,
					status: '',
				})
			);
	}, [databaseInstances]);

	const [pageParams, setPageParams] = usePageParams(pageConfig, 'nosql-databases');

	useEffect(() => {
		setPageParams({
			...pageParams,
			'data-types': piiFilterWithGroups.dataTypes,
			nonempty: piiFilterWithGroups.nonEmpty,
		});
	}, [piiFilterWithGroups]);

	useEffect(() => {
		const { sort, 'instance-ids': instance_ids, search } = pageParams;

		const dataTypes = piiFilterToArray(piiFilterWithGroups);
		const paramsForFx = {
			sort: { orderBy: sort.value as keyof NoSQLDatabaseItem, order: sort.operator },
			instance_ids,
			search,
			'data-types': dataTypes,
		};

		noSQLDatabasesModel.fetchFx(paramsForFx);
	}, [pageParams]);

	// Clean up model on unmount
	useEffect(() => {
		return () => {
			noSQLDatabasesModel.resetFx();
		};
	}, []);

	function onSortUpdate(property: keyof NoSQLDatabaseItem) {
		const { sort } = pageParams;

		const operator: TOrder = sort.value === property && sort.operator === 'asc' ? 'desc' : 'asc';
		const newParams = { sort: { operator, value: property } };

		setPageParams({ ...pageParams, ...newParams });
	}

	function onUniversalSearchUpdate(search: string) {
		setPageParams({ ...pageParams, search });
	}

	function onResetFilters() {
		const newParams = {
			search: '',
			'data-types': [],
			'instance-ids': [],
		};

		setPageParams({ ...pageParams, ...newParams });
		clearPiiFilter();
	}

	const { status, data, total, total_filtered, hasMoreData } = state;

	const hasFilter =
		pageParams.search !== '' ||
		pageParams['data-types'].length > 0 ||
		pageParams['instance-ids'].length > 0;

	function contentWithCondition() {
		if (data.length || nonExistedTables.length) {
			return [...data, ...nonExistedTables].map((db) => (
				<TableRow db={db} search={pageParams.search} key={db.id} />
			));
		} else if (hasFilter) {
			return <ResetFilters onReset={onResetFilters} />;
		} else {
			return (
				<NoContent type="databases" className={styles.rowContainer}>
					<div className={commonStyles.emptyContainer}>
						<Typo variant="D/Regular/Body-S" color="secondary">
							No available analytics or no Sensors set up yet.
						</Typo>

						<NewGatewayButton isDAR />
					</div>
				</NoContent>
			);
		}
	}

	return (
		<DataStorage>
			<DataStorage.Header breadcrumbsFinalStep="NoSQL databases" />

			<UniversalSearch value={pageParams.search} onChange={onUniversalSearchUpdate} />

			<FilterLine
				config={['dataTypes']}
				values={{
					dataTypes: pageParams['data-types'],
				}}
				onChange={() => {}}
			/>

			<FilterResult
				entityLabel="database"
				loading={status === 'loading'}
				total={total}
				totalFiltered={total_filtered}
			/>

			<GridTable dataTest="database-table">
				<EnhancedTableHead
					config={tableConfig}
					order={pageParams.sort.operator}
					orderBy={pageParams.sort.value}
					onRequestSort={onSortUpdate}
					rowClassname={styles.rowContainer}
				/>

				<Preloader isLoading={status === 'initial' || status === 'loading'}>
					<GridBody data-test="database-list">
						{contentWithCondition()}

						<LoadMoreButton
							show={hasMoreData}
							loading={status === 'loadingMore'}
							request={noSQLDatabasesModel.fetchMoreFx}
						/>
					</GridBody>
				</Preloader>
			</GridTable>
		</DataStorage>
	);
}
