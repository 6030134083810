import cn from 'classnames';
import { useContext } from 'react';
import { Typo } from 'components/typography/Typo';
import { BuilderContext } from '../PolicyBuilder';
import styles from './index.module.css';

export type KeyAction = 'OR' | 'AND' | 'DELETE' | 'ENTER' | 'ESC';

interface Props {
	className?: string;
	description: string;
	id: KeyAction;
	keys: string;
}

export function HotKey({ className, description, id, keys }: Props) {
	const { setBuilderState } = useContext(BuilderContext);

	return (
		<div
			className={cn(styles.container, className)}
			onClick={() => setBuilderState((prevState) => ({ ...prevState, keyAction: id }))}
		>
			<Typo component="span" variant="D/Medium/Body-S" color="secondary">
				{description}
			</Typo>

			<Typo component="span" variant="D/Regular/Body-S-code" className={styles.key}>
				{keys}
			</Typo>
		</div>
	);
}
