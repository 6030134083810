import cn from 'classnames';
import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { piiFilterStore } from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import { Typo } from 'components/typography/Typo';
import { getDataFlowDetailsV2 } from 'models/assetDataFlowDetailsV2/api';
import { DataFlowDetailsParams, DataFlowDetailsTable } from 'models/assetDataFlowDetailsV2/dto';
import { AssetJson } from 'models/assets/dto';
import { TDataDirectionType } from 'models/common/dto';
import { dataTypesById as dataTypesByIdStore, dataTypesLoaded } from 'models/dataTypes/store';
import DataFlowStreamFlow from 'views/common/DataFlowStreamFlow';
import DataFlowDataTypeFilter from './DataFlowDataTypeFilter';
import DataFlowDetailsHeader from './DataFlowDetailsHeader';
import { DataFlowDetailsItem } from './DataFlowDetailsItem';
import styles from './index.module.css';

type Props = {
	asset1: AssetJson['id'];
	asset2: AssetJson['id'];
	direction: TDataDirectionType;
};

const OFFSET_STEP = 100;
const OVER_LIMIT = 101;

export const DataFlowDetails = ({ asset1, asset2, direction }: Props) => {
	const [isFirstLoading, setFirstLoading] = useState(true);
	const [isLoading, setLoading] = useState(true);
	const [detailsData, setDetailsData] = useState<DataFlowDetailsTable | null>(null);
	const dtIsLoaded = useStore(dataTypesLoaded);
	const [offset, setOffset] = useState(0);
	const [moreDataLoading, setMoreDataLoading] = useState(false);

	const [flowDirection, setFlowDirection] =
		useState<DataFlowDetailsParams['flowDirection']>('outgoing');
	const [streamDirection, setStreamDirection] =
		useState<DataFlowDetailsParams['streamDirection']>('');

	const dataTypesByIdValues = useStore(dataTypesByIdStore); // For map from string to number
	const { dataTypes: dataTypesStore } = useStore(piiFilterStore); // Get dataType by string aka 'PERSON'
	// Parse to the array of ids
	const dataTypesById = useMemo(
		() => dataTypesStore.map((dt) => dataTypesByIdValues[dt].id),
		[dataTypesStore]
	);
	const [activeDataTypes, setActiveDataTypes] =
		useState<DataFlowDetailsTable['data_types']>(dataTypesById);

	// Reset settings when we click to the other asset on the table
	useEffect(() => {
		setFirstLoading(true);
		setActiveDataTypes(dataTypesById);
		setFlowDirection('outgoing');
		setStreamDirection('');
		setOffset(0);
	}, [asset2]);

	// Update activeDataType when the global filter was updated
	useEffect(() => {
		setActiveDataTypes(dataTypesById);
	}, [dataTypesStore]);

	// Reset offset because changing of filter or asset2
	useEffect(() => {
		setOffset(0);
	}, [asset2, activeDataTypes, flowDirection, streamDirection]);

	useEffect(() => {
		if (!asset2 || !dtIsLoaded) return;

		setOffset(0);
		setLoading(true);

		loadData().then((resp) => {
			setDetailsData(resp);
			setLoading(false);
			setFirstLoading(false);
		});
	}, [asset1, asset2, dtIsLoaded, activeDataTypes, flowDirection, streamDirection]);

	function loadData(_offset = 0) {
		return getDataFlowDetailsV2({
			asset1Id: asset1,
			asset2Id: asset2,
			dataTypes: activeDataTypes,
			flowDirection,
			streamDirection,
			offset: _offset,
			limit: OVER_LIMIT,
		});
	}

	function loadMoreData() {
		const newOffset = offset + OFFSET_STEP;

		setOffset(newOffset);
		setMoreDataLoading(true);

		loadData(newOffset).then((resp) => {
			resp.streams = detailsData?.streams.concat(resp.streams) || [];

			setDetailsData(resp);
			setMoreDataLoading(false);
		});
	}

	let error = null;
	if (detailsData) {
		if (!detailsData['asset1_data']) {
			error = `Service with id=${asset1} not found.`;
		} else if (!detailsData['asset2_data']) {
			error = `Service with id=${asset2} not found.`;
		}
	}

	return (
		<Preloader isLoading={isFirstLoading}>
			<div className={styles.container}>
				{detailsData &&
					(error ? (
						<Typo variant="D/Regular/Body-S" color="secondary" className={styles.error}>
							{error}
						</Typo>
					) : (
						<>
							<div className={styles.filters}>
								<DataFlowDetailsHeader
									asset1={detailsData['asset1_data']}
									asset2={detailsData['asset2_data']}
									flowDirection={flowDirection}
									direction={direction}
									changeDirection={setFlowDirection}
									disabled={moreDataLoading || isLoading}
								/>

								<div className={cn({ [styles.disabled]: moreDataLoading || isLoading })}>
									<div className={styles.dataTypeFilter}>
										<DataFlowDataTypeFilter
											activeDataTypes={activeDataTypes}
											dataTypes={detailsData.data_types}
											onChange={setActiveDataTypes}
										/>
									</div>

									<DataFlowStreamFlow direction={streamDirection} onChange={setStreamDirection} />
								</div>
							</div>

							<Preloader isLoading={isLoading} className={styles.table}>
								<DataFlowDetailsItem
									activeDataTypes={activeDataTypes}
									loadMoreData={loadMoreData}
									moreData={detailsData['more_data']}
									moreDataLoading={moreDataLoading}
									streams={detailsData.streams}
								/>
							</Preloader>
						</>
					))}
			</div>
		</Preloader>
	);
};
