import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Preloader from 'components/Preloader';
import { enqueueSnackbar } from 'components/Snackbar';
import { Typo } from 'components/typography/Typo';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { createAccountAWS, getAccountAWS } from 'models/sensors/aws/api';
import { AccountAWSItem, AccountAWSTest } from 'models/sensors/aws/dto';
import { createDraftAccountAWSFx } from 'models/sensors/aws/effects';
import history from 'services/history';
import { PATHS } from 'services/router';
import { AccountCreateForm } from './AccountCreateForm';

export function AccountCreate() {
	const [account, setAccount] = useState<AccountAWSItem>();
	const [configurationChecked, setConfigurationChecked] = useState(false);
	const [loadingAccount, setLoadingAccount] = useState(true);

	const { id }: { id: string } = useParams();
	const isNew = id === 'new';

	useEffect(function onInit() {
		function getAccount() {
			if (isNew) {
				return createDraftAccountAWSFx();
			}

			return getAccountAWS(Number(id));
		}

		getAccount()
			.then(setAccount)
			.catch(async (e) => {
				if (e.response.status === 422 || e.response.status === 400) {
					const errorBody = await e.response.json();
					enqueueSnackbar(errorBody.message);
				} else {
					throw e;
				}
			})
			.finally(() => setLoadingAccount(false));
	}, []);

	async function setAWSExternalId(accountId: AccountAWSItem['id']) {
		setLoadingAccount(true);

		createAccountAWS({ id: accountId, regenerate_external_id: true })
			.then((data) => setAccount(data))
			.catch(async (e) => {
				if (e.response.status === 422 || e.response.status === 400) {
					const errorBody = await e.response.json();
					enqueueSnackbar(errorBody.message);
				} else {
					throw e;
				}
			})
			.finally(() => setLoadingAccount(false));
	}

	async function createAccount(data: {
		id: AccountAWSItem['id'];
		role_arn: AccountAWSItem['role_arn'];
	}) {
		createAccountAWS({ id: data.id, role_arn: data.role_arn })
			.then(function response() {
				enqueueSnackbar('New AWS connection successfully added');
				history.push(PATHS.ACCOUNT_AWS);
			})
			.catch(async (e) => {
				if (e.response.status === 422 || e.response.status === 400) {
					const errorBody = await e.response.json();
					enqueueSnackbar(errorBody.message);
				} else {
					throw e;
				}
			});
	}

	function configurationTestHandler(status: AccountAWSTest) {
		setConfigurationChecked(!status.connection_errors.length);
		if (account) setAccount({ ...account, last_checked_errors: status.connection_errors });
	}

	return (
		<>
			<Header>
				<Header.Breadcrumbs
					steps={[
						{ path: PATHS.SENSORS, name: 'Infrastructure access' },
						{ path: PATHS.ACCOUNT_AWS, name: 'AWS connections' },
					]}
					finalStep={`${isNew ? 'New' : 'Draft'} AWS connection`}
				/>

				<Header.Title>{isNew ? 'New' : 'Draft'} AWS connection</Header.Title>
			</Header>

			<Preloader isLoading={loadingAccount}>
				{account ? (
					<AccountCreateForm
						account={account}
						setAWSExternalId={setAWSExternalId}
						configurationChecked={configurationChecked}
						configurationTestHandler={configurationTestHandler}
						createAccount={createAccount}
						isNew={isNew}
					/>
				) : (
					<Typo variant="D/Regular/Body-S" color="secondary">
						The base modification has not been created.
					</Typo>
				)}
			</Preloader>
		</>
	);
}
