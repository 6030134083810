import { ReactNode, useEffect, useState } from 'react';
import Alert, { AlertSeverity } from 'components/Alert';
import { Typo } from 'components/typography/Typo';

const LS_POLICY_ALERT_HIDDEN = 'is-policy-alert-hidden';

function usePolicyAlert(): [boolean, () => void] {
	const [isShown, setShown] = useState(false);

	useEffect(() => {
		const isHiddenInLocalStorage = localStorage.getItem(LS_POLICY_ALERT_HIDDEN) === 'true';
		setShown(!isHiddenInLocalStorage);
	}, []);

	function hideAlert() {
		localStorage.setItem(LS_POLICY_ALERT_HIDDEN, 'true');
		setShown(false);
	}

	return [isShown, hideAlert];
}

type Props = {
	className?: string;
	header: string;
	children: ReactNode;
	onClose: () => void;
};

function PolicyAlert(props: Props) {
	const { className, header, children, onClose } = props;
	const [showButton, setShowButton] = useState(false);

	return (
		<Alert
			severity={AlertSeverity.info}
			onClick={showButton ? onClose : undefined}
			onMouseOver={() => setShowButton(true)}
			onMouseLeave={() => setShowButton(false)}
			className={className}
			header={header}
		>
			<Typo variant="D/Regular/Body-S">{children}</Typo>
		</Alert>
	);
}

export { PolicyAlert, usePolicyAlert };
