import { generatePath } from 'react-router';
import CopyToClipboard from 'components/CopyToClipboard';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import { Tooltip } from 'components/Tooltip';
import { RouterLink } from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import { dataTypesById } from 'models/dataTypes/store';
import { ExcessiveDbAccessEventJson } from 'models/eventsV2/dto';
import { PATHS } from 'services/router';
import { eventTitleAsText } from '../EventRow';
import JiraButton from '../JiraButton';
import { formatWithToday } from '../utils';
import styles from './index.module.pcss';

type Props = { event: ExcessiveDbAccessEventJson };

export const ExcessiveDataAccessFound = ({ event }: Props) => {
	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;

	const titleAsText = eventTitleAsText(event);
	const formattedLocal = formatWithToday(event.created_at);
	const jiraSummary = `Soveren issue: ${titleAsText}`;
	const jiraDescription = `*${titleAsText}*

*Event*: #${event.id}
*Time*: ${formattedLocal}
*Sensitivity*: ${event.sensitivity}
*Category*: Data at risk
*Data types*: ${event.data_types.map((dtId) => dataTypesById.getState()[dtId].name).join(', ')}
*Source*: ${event.db_access.auth_source}
*User*: ${event.db_access.user_name}
*Databases*: ${event.db_access.databases.map((db) => db.name).join(', ')}

See on Soveren dashboard: ${linkHere}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Event ID
				</Typo>
				<Typo variant="D/Regular/Body-S" className={styles.horizontalAlignment}>
					{event.id}{' '}
					<Tooltip title="Copy event link" placement="right">
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Data types
				</Typo>
				<PiiTypeList data={event.data_types} noHighlight dataTest="event-details-data-types" />

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Severity
				</Typo>
				<SensitivityChip sensitivity={event.sensitivity} data-test="event-details-sensitivity" />

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Category
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="event-details-category">
					Data at risk
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Source
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="event-details-auth-source">
					{event.db_access.auth_source}
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					User
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="event-details-category">
					{event.db_access.user_name}
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Databases
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="event-details-databases_list">
					<div className={styles.databasesList}>
						{event.db_access.databases.map((db, index) => (
							<RouterLink
								key={`db-item-${index}`}
								to={generatePath(PATHS.DATABASES_ITEM, { id: db.id })}
							>
								{db.name}
							</RouterLink>
						))}
					</div>
					<Typo
						variant="D/Regular/Meta"
						color="secondary"
						className={styles.databaseType}
						data-test="event-details-database-type"
					>
						postgres
					</Typo>
				</Typo>
			</div>

			<Typo variant="D/Regular/Body-S" className={styles.text}>
				User {event.db_access.user_name} has read to many databases containing{' '}
				<PiiTypeList data={event.data_types} noHighlight dataTest="event-details-data-types" />
			</Typo>

			<div className={styles.links}>
				<JiraButton summary={jiraSummary} description={jiraDescription} />
			</div>
		</>
	);
};
