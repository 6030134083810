import { MenuList } from '@material-ui/core';
import { Fragment, ReactElement, useState } from 'react';
import { Divider } from 'components/Divider';
import { Typo } from 'components/typography/Typo';
import styles from './index.module.css';
import { OptionItemProps } from './OptionItem';
import { SearchProps } from './Search';

type OptionListProps<T> = {
	options: T[];
	value: T;
	onChange: (value: T) => void;
	onClose: () => void; // It is OptionList's responsibility to close (or not to close!) after clicking some option.
	renderOption: (props: OptionItemProps<T>) => ReactElement | null;
	renderSearch: (props: SearchProps) => ReactElement | null;
};

// Common overrides: header; custom options (to be considered); handling of interdependent options (e.g. mutually exclusive).
function OptionList<T>(props: OptionListProps<T>) {
	const { options, value, onChange, onClose, renderOption, renderSearch } = props;

	const [searchString, setSearchString] = useState('');

	const searchElement = renderSearch({ value: searchString, onChange: setSearchString });
	const optionElements = options
		.map((option) =>
			renderOption({
				option,
				selected: option === value,
				searchString,
				onClick: () => {
					onChange(option);
					onClose();
				},
			})
		)
		.filter((optionElement) => !!optionElement);

	const noOptionsText = searchElement && searchString ? 'No matching results' : 'No items';

	return (
		<MenuList className={styles.menuList}>
			{searchElement}

			{!!searchElement && <Divider light />}

			<div className={styles.scrollContainer}>
				{optionElements.length > 0 ? (
					optionElements.map((optionElement, i) => <Fragment key={i}>{optionElement}</Fragment>)
				) : (
					<Typo variant="D/Regular/Body-S" color="secondary" className={styles.noOptions}>
						{noOptionsText}
					</Typo>
				)}
			</div>
		</MenuList>
	);
}

export default OptionList;
export type { OptionListProps };
