import { useCallback, useMemo } from 'react';
import Select from 'components/form/Select';
import DropdownButton from 'components/form/Select/DropdownButton';
import { EventTypeTypeParam } from 'models/eventsV2/model';
import { FilterPropsBase } from '../model';

type Props = FilterPropsBase & {
	value: EventTypeTypeParam;
	onChange: (newValue: EventTypeTypeParam) => void;
};

const options: { id: EventTypeTypeParam; name: string }[] = [
	{ id: 'infrastructure_updates', name: 'Infrastructure updates' },
	{ id: 'misconfiguration', name: 'Misconfiguration' },
	// { id: 'misplaced_data', name: 'Misplaced data' },
	{ id: 'data_at_risk', name: 'Data at risk' },
	{ id: 'others', name: 'Other' },
];

export function EventTypeV2Filter({
	defaultOpen,
	fixed,
	onChange,
	onClose,
	resetFilter,
	value,
}: Props) {
	const selected = useMemo(() => options.find((option) => !!value && option.id === value), [value]);

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<Select
			dataTest="select-severity"
			defaultOpen={defaultOpen}
			label={{ primary: 'Event type', secondary: selected?.name ?? '' }}
			options={options}
			onChange={(data) => onChange(data!.id)}
			onClose={onClose}
			render={{ dropdownButton }}
			value={selected}
		/>
	);
}
