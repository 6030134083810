import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import Icon from 'components/Icon';
import { Typo } from 'components/typography/Typo';
import { getAssetsFx } from 'models/assets/effects';
import { assetsList } from 'models/assets/store';
import { getAssetGroupsFx } from 'models/assetsGroups/effects';
import { assetGroupsList } from 'models/assetsGroups/store';
import { getGatewaysFx } from 'models/gateways/effects';
import { gatewaysList } from 'models/gateways/store';
import { PolicyRuleItem } from 'models/policies/dto';
import { AssetGroupOrClusterMultiSelect, RuleBuilderMultiSelect } from '../RuleBuilderSelect';
import styles from './index.module.pcss';

type RuleValueProps = {
	name: string;
	value?: string | number;
	onDelete: (name: string, value?: string | number) => void;
};

function RuleValue(props: RuleValueProps) {
	const { name, value, onDelete } = props;

	return (
		<span
			className={styles.ruleValue}
			onClick={() => onDelete(name, value)}
			data-test="policy-simple-rule-item"
		>
			{name}
			<Icon name="DismissCircle/Filled" size={16} className={styles.deleteName} />
		</span>
	);
}

const typeLabels = {
	asset: 'Service',
	asset_group: 'Group',
	namespace: 'Namespace',
	label: 'Label',
	namespace_label: 'Namespace label',
	cluster: 'Cluster',
};

type Props = {
	prefix?: string;
	value: PolicyRuleItem;
	onChange: (mutatorFn: (draft: PolicyRuleItem) => void) => void;
	onDelete: () => void;
};

function SimpleRule(props: Props) {
	const { prefix, value, onChange, onDelete } = props;

	const assetsListStore = useStore(assetsList);
	const assetGroups = useStore(assetGroupsList);
	const gateways = useStore(gatewaysList);

	useEffect(() => {
		getAssetsFx();
		getAssetGroupsFx();
		getGatewaysFx();
	}, []);

	const dropdownItems = useMemo(() => {
		if (value.type === 'asset') {
			return [
				...new Set(
					assetsListStore.filter((asset) => asset.type === 'internal').map((asset) => asset.name)
				),
			];
		} else {
			return [
				...new Set(
					assetsListStore.map((asset) => asset.namespace).filter((namespace) => !!namespace)
				),
			];
		}
	}, [assetsListStore]);

	const selectedItems = value.values;

	const onSelect = useCallback(
		(items: typeof dropdownItems) => {
			onChange((draft) => {
				draft.values = items;
			});
		},
		[onChange]
	);

	const onDeleteName = useCallback(
		function onDeleteName(name: string) {
			onChange((draft) => {
				draft.values = draft.values.filter((n) => n !== name);
			});
		},
		[onChange]
	);

	const selectedAssetGroupItems = useMemo(() => {
		return value.values.map((val) => {
			return assetGroups.find((item) => item.id.toString() === val)!;
		});
	}, [assetGroups, value]);

	const onAssetGroupSelect = useCallback(
		(items: typeof assetGroups) => {
			onChange((draft) => {
				draft.values = items.map((item) => item.id.toString());
			});
		},
		[onChange]
	);

	const onAssetGroupDelete = useCallback(
		function onAssetGroupDelete(name: string, val?: string | number) {
			onChange((draft) => {
				draft.values = draft.values.filter((n) => n !== String(val));
			});
		},
		[onChange]
	);

	const selectedClusterItems = useMemo(() => {
		return value.values.map((val) => {
			return gateways.find((item) => item.id.toString() === val)!;
		});
	}, [assetGroups, value]);

	const onClusterSelect = useCallback(
		(items: typeof gateways) => {
			onChange((draft) => {
				draft.values = items.map((item) => item.id.toString());
			});
		},
		[onChange]
	);

	const onClusterDelete = useCallback(
		function onClusterDelete(name: string, val?: string | number) {
			onChange((draft) => {
				draft.values = draft.values.filter((n) => n !== String(val));
			});
		},
		[onChange]
	);

	const typeCapitalized = typeLabels[value.type];
	const label =
		value.values.length === 0 ? `${typeCapitalized} name: any` : `${typeCapitalized} name:`;

	const multiselect =
		value.type === 'asset_group' ? (
			<AssetGroupOrClusterMultiSelect
				options={assetGroups}
				value={selectedAssetGroupItems}
				onChange={onAssetGroupSelect}
				label={label}
			/>
		) : value.type === 'cluster' ? (
			<AssetGroupOrClusterMultiSelect
				options={gateways}
				value={selectedClusterItems}
				onChange={onClusterSelect}
				label={label}
			/>
		) : (
			<RuleBuilderMultiSelect
				options={dropdownItems}
				value={selectedItems}
				onChange={onSelect}
				label={label}
			/>
		);

	const ruleValues = useMemo(() => {
		if (value.type === 'asset_group') {
			const result = value.values.flatMap((val, i) => {
				const fromDropdown = assetGroups.find((item) => item.id.toString() === val);

				return [
					<RuleValue
						key={i}
						name={fromDropdown?.name || val}
						value={fromDropdown?.id || val}
						onDelete={onAssetGroupDelete}
					/>,
					<span key={-1 - i} className={styles.rowItem}>
						{' , '}
					</span>,
				];
			});

			result.pop();

			return result;
		}

		if (value.type === 'cluster') {
			const result = value.values.flatMap((val, i) => {
				const fromDropdown = gateways.find((item) => item.id.toString() === val);

				return [
					<RuleValue
						key={i}
						name={fromDropdown?.name || val}
						value={fromDropdown?.id || val}
						onDelete={onClusterDelete}
					/>,
					<span key={-1 - i} className={styles.rowItem}>
						{' , '}
					</span>,
				];
			});

			result.pop();

			return result;
		}

		const result = value.values.flatMap((v, i) => {
			return [
				<RuleValue key={i} name={v} onDelete={onDeleteName} />,
				<span key={-1 - i} className={styles.rowItem}>
					{' , '}
				</span>,
			];
		});

		result.pop();

		return result;
	}, [value, onDeleteName]);

	return (
		<div>
			<div className={styles.container} data-test="policy-simple-rule-row">
				{prefix && (
					<Typo component="span" variant="D/Medium/Body-S" className={styles.prefix}>
						{prefix}
					</Typo>
				)}

				{multiselect}

				{ruleValues}

				<ButtonIcon
					dataTest="policy-simple-rule-delete-item"
					icon="Delete/Filled"
					onClick={onDelete}
					className={styles.deleteRule}
				/>
			</div>
		</div>
	);
}

export default SimpleRule;
