import { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Alert, { AlertSeverity } from 'components/Alert';
import Button from 'components/form/Button';
import PasswordField from 'components/form/PasswordField';
import TextField from 'components/form/TextField';
import Link from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import { AwsCustomer } from 'models/session/dto';
import { connectAwsFx } from 'models/session/effects';
import { parseURLSearchString } from 'services/api';
import { APIError } from 'services/api/httpRequest';
import { PATHS } from 'services/router';
import styles from 'views/SignUp/index.module.pcss';

type FormValues = {
	email: string;
	password: string;
};

export function ConnectAWS() {
	// Client can be given link to sign-in page with email in query params, for convenience.
	const initialEmail = useMemo(
		() => new URLSearchParams(window.location.search).get('email') || '',
		[]
	);
	const awsAccount = useMemo(() => {
		const { customerAWSAccountId, customerIdentifier, productCode } = parseURLSearchString(
			window.location.search
		);

		return {
			customer_aws_account_id: customerAWSAccountId,
			customer_identifier: customerIdentifier,
			product_code: productCode,
		};
	}, [window.location.search]) as AwsCustomer;

	const { control, handleSubmit } = useForm<FormValues>({
		defaultValues: {
			email: initialEmail,
			password: '',
		},
		mode: 'onBlur', // "onChange"
	});

	const [isLoading, setLoading] = useState(false);
	const [serverError, setServerError] = useState<string | null>(null);

	async function onSubmit({ email, password }: FormValues) {
		setLoading(true);

		try {
			await connectAwsFx({ login: email, password, ...awsAccount });
		} catch (error) {
			if (
				error instanceof APIError &&
				(error.response.status === 422 ||
					error.response.status === 403 ||
					error.response.status === 401)
			) {
				const { message } = await error.response.json();
				setServerError(message);
			} else {
				throw error;
			}
		}

		setLoading(false);
	}

	return (
		<div className={styles.container} data-test="container-sign-up">
			<div className={styles.wrapper}>
				<Typo variant="D/Medium/H500-Greeting-Title" className={styles.title}>
					Connect AWS account
				</Typo>

				<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
					<TextField
						control={control}
						label="Work email"
						name="email"
						type="email"
						data-test="email"
						required
						fullWidth
						size="large"
					/>

					<PasswordField
						control={control}
						label="Password"
						name="password"
						autoComplete="current-password"
						data-test="password"
						required
						fullWidth
						size="large"
					/>

					<TextField
						readOnly
						label="AWS Account Id"
						value={awsAccount.customer_aws_account_id}
						name="email"
						type="text"
						data-test="customerAWSAccountId"
						placeholder=""
						required
						fullWidth
						size="large"
					/>

					<Button
						type="submit"
						size="large"
						loading={isLoading}
						className={styles.formButton}
						data-test="create-acc-bt"
					>
						Connect account
					</Button>

					{serverError && !isLoading && (
						<Alert className={styles.errorMessage} severity={AlertSeverity.error}>
							{serverError}
						</Alert>
					)}
				</form>

				<Typo variant="D/Medium/Body-S" className={styles.haveAccount}>
					Don’t have an account? <Link to={PATHS.SIGN_UP + window.location.search}>Sign up</Link>.
				</Typo>
			</div>
		</div>
	);
}
