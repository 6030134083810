import { get } from 'api/Api';
import { ApiParams } from 'services/api';
import checkResponse from 'services/api/checkResponse';
import { EventJson, EventsJson, EventsStatisticJson } from './dto';
import { eventsJsonExample, eventsStatisticJsonExample } from './examples';

async function getEvents(apiParams?: ApiParams, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<EventsJson>('v2/log-events', apiParams, config)
		.then(checkResponse(eventsJsonExample))
		.then((response: EventsJson) => ({
			...response,
			total: response.total_filtered,
		}));
}

async function getEvent(id: number) {
	return get<EventJson>(`v2/log-events/${id}`).then(
		checkResponse<EventJson>(...eventsJsonExample.log_events)
	);
}

async function getEventsStatistic(apiParams?: ApiParams, signal?: AbortSignal) {
	const config = signal ? { signal } : {};

	return get<EventsStatisticJson>('v2/log-events/statistic', apiParams, config).then(
		checkResponse(eventsStatisticJsonExample)
	);
}

export { getEvent, getEvents, getEventsStatistic };
