import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Preloader from 'components/Preloader';
import { Typo } from 'components/typography/Typo';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { dataTypesById } from 'models/dataTypes/store';
import { getEvent } from 'models/eventsV2/api';
import { EventWithDIMSample, EventWithDARSample, EventJson } from 'models/eventsV2/dto';
import { PATHS } from 'services/router';
import { HeaderDAR } from './HeaderDAR';
import { HeaderDIM } from './HeaderDIM';
import styles from './index.module.css';
import { ViewerBlock } from './ViewerBlock';
import { ViewerTitle } from './ViewerTitle';

const activityLogSteps = [{ path: PATHS.EVENTS, name: 'Activity log' }];

type EventWithSample = EventWithDIMSample | EventWithDARSample;

function isSupportedEvent(event: EventJson | null): event is EventWithSample {
	if (event === null) return false;

	return (
		event.type === 'new_data_type' ||
		event.type === 'new_internal_receiver' ||
		event.type === 'new_internal_sender' ||
		event.type === 'updated_internal_receiver' ||
		event.type === 'updated_internal_sender' ||
		event.type === 'new_external_receiver' ||
		event.type === 'updated_enduser_receiver' ||
		event.type === 'updated_robot_receiver' ||
		event.type === 'updated_external_receiver' ||
		event.type === '3rd_party_policy_violation' ||
		event.type === 'policy_violation' ||
		event.type === 'policy_unencrypted_network' ||
		event.type === 'policy_public_s3_bucket' ||
		event.type === 'policy_unencrypted_s3_bucket' ||
		event.type === 'started_storing_data' ||
		event.type === 'started_storing_datatype' ||
		event.type === 'policy_unencrypted_rds' ||
		event.type === 'policy_violation_dar' ||
		event.type === 'excessive_db_access' ||
		event.type === 'anomaly_detected' ||
		event.type === 'new_potential_data_storage' ||
		event.type === 'new_data_storage'
	);
}
function isDIMEvent(event: EventWithSample): event is EventWithDIMSample {
	return event.sample?.sensor === 'dim';
}

function EventSample() {
	const [event, setEvent] = useState<EventWithSample | null>(null);
	const [isLoading, setLoading] = useState(true);
	const dataTypesByIdStore = useStore(dataTypesById);

	const {
		eventId,
	}: {
		eventId: string;
	} = useParams();

	useEffect(() => {
		setLoading(true);

		getEvent(+eventId).then((response) => {
			if (isSupportedEvent(response)) {
				setEvent(response);
			}

			setLoading(false);
		});
	}, []);

	const titleBlock = useMemo(() => {
		if (!isSupportedEvent(event)) return 'No eligible event';

		return isDIMEvent(event) ? <HeaderDIM event={event} /> : <HeaderDAR event={event} />;
	}, [event]);

	function getFinalStep() {
		if (!isSupportedEvent(event)) return 'No eligible event';
		let title = String(event.id);

		if (isDIMEvent(event)) {
			let internalOrExternal = '<unknown>';

			const asset = event.event_regarding === 'requestor' ? event.requestor : event.responder;

			if (asset) {
				internalOrExternal = asset.is_external ? 'External connection' : 'Service';
			}

			const dataTypeName = event.data_types
				.map((dt) => `[${dataTypesByIdStore[dt].name}]`)
				.join(', ');
			const plural = event.data_types.length > 1 ? 's' : '';

			switch (event.type) {
				case 'new_data_type':
					title = `New data type ${dataTypeName} in the infrastructure`;
					break;

				case 'new_internal_receiver':
				case 'new_external_receiver':
					title = `${event.id} ${internalOrExternal} [${asset.name}] started receiving data: ${dataTypeName}`;
					break;

				case 'new_internal_sender':
					// case 'new_external_sender':
					title = `${event.id} ${internalOrExternal} [${asset.name}] started sending data: ${dataTypeName}`;
					break;

				case 'updated_internal_receiver':
				case 'updated_external_receiver':
				case 'updated_enduser_receiver':
				case 'updated_robot_receiver':
					title = `${event.id} ${internalOrExternal} [${asset.name}] is receiving new data type${plural}: ${dataTypeName}`;
					break;

				case 'updated_internal_sender':
					// case 'updated_external_sender':
					title = `${event.id} ${internalOrExternal} [${asset.name}] is sending new data type${plural}: ${dataTypeName}`;
					break;

				case 'policy_violation':
					title = `${event.id} Data type ${dataTypeName} is outside the allowed set of services`;
					break;

				case '3rd_party_policy_violation':
					title = `${event.id} Service [${asset.name}] is sharing ${dataTypeName} with third parties`;
					break;

				case 'policy_unencrypted_network':
					title = 'Sensitive data transmitted over unencrypted network';
					break;

				default:
					title = 'Unexpected case';
			}
		}

		return title;
	}

	const viewerBlock = useMemo(() => {
		if (!event)
			return (
				<Typo variant="D/Regular/Body-S" color="secondary">
					Event with id={eventId} not found.
				</Typo>
			);

		if (!event.sample) {
			return (
				<Typo variant="D/Regular/Body-S" color="secondary">
					Event with id={eventId} has no relevant sample.
				</Typo>
			);
		} else if (isDIMEvent(event)) {
			return (
				<div className={styles.fullWidth}>
					<div className={styles.halfWidth}>
						<ViewerTitle
							label="Request"
							asset={event.requestor}
							showDanger={
								event.event_regarding === 'requestor' && event.type === 'policy_violation'
							}
						/>

						<ViewerBlock sample={event.sample.request} event={event} size="S" />
					</div>

					<div className={styles.halfWidth}>
						<ViewerTitle
							label="Response"
							asset={event.responder}
							showDanger={
								event.event_regarding === 'responder' && event.type === 'policy_violation'
							}
						/>

						<ViewerBlock sample={event.sample.response} event={event} size="S" />
					</div>
				</div>
			);
		} else {
			return <ViewerBlock sample={event.sample.object} event={event} />;
		}
	}, [event]);

	const showSensitiveSwitcher = useMemo(
		() => !!event && !isDIMEvent(event) && event.sample?.object.type === 'csv',
		[event]
	);

	return (
		<>
			<Header isLoading={isLoading}>
				<Header.Breadcrumbs steps={activityLogSteps} finalStep={getFinalStep()} />

				<Header.Title>{titleBlock}</Header.Title>

				{showSensitiveSwitcher && <Header.SensitiveSwitcher />}
			</Header>

			<div className={styles.wrapper}>
				<Preloader isLoading={isLoading}>
					<div
						className={
							showSensitiveSwitcher ? styles.viewerContainer : styles.viewerContainerBordered
						}
					>
						{viewerBlock}
					</div>
				</Preloader>
			</div>
		</>
	);
}

export type { EventWithSample };
export { isDIMEvent, EventSample };
