import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import { DraftFunction } from 'use-immer';
import Checkbox from 'components/Checkbox';
import { getAssetNameByType } from 'models/assets/model';
import { assetsList } from 'models/assets/store';
import { internalAssetsModel } from 'models/assetsExtended/model';
import { DIMPolicyItem } from 'models/policiesV2/dto';
import styles from '../index.module.css';
import ThirdPartyMultiSelect from '../ThirdPartyMultiSelect';

type Props = {
	allow_external_transfer: boolean;
	allowed_external_assets: number[];
	setFormData: (arg: DIMPolicyItem | DraftFunction<DIMPolicyItem>) => void;
};

export const ThirdPartyAccess = (props: Props) => {
	const { allow_external_transfer, allowed_external_assets, setFormData } = props;

	const assetsListStore = useStore(assetsList);

	const externalAssets = useMemo(
		() =>
			assetsListStore
				.filter((asset) => asset.type !== 'internal' && asset.is_deleted !== true)
				.map((asset) => ({ ...asset, name: getAssetNameByType(asset.type, asset.name) })),
		[assetsListStore]
	);

	const selectedExternalAssets = useMemo(() => {
		return externalAssets.filter((asset) => allowed_external_assets.includes(asset.id));
	}, [allowed_external_assets, externalAssets]);

	useEffect(() => {
		internalAssetsModel.fetchFx({});
	}, []);

	const setAllowExternalTransfer = useCallback((e) => {
		setFormData((draft) => {
			draft.allow_external_transfer = !e.target.checked;
		});
	}, []);

	const setExternalAssets = useCallback((assets) => {
		setFormData((draft) => {
			draft.allowed_external_assets = assets.map((asset: { id: number }) => asset.id);
		});
	}, []);

	return (
		<>
			<Checkbox
				className={styles.checkboxInput}
				size="M"
				label="Data types should not be shared with third parties for this set of services"
				checked={!allow_external_transfer}
				onChange={setAllowExternalTransfer}
				dataTest="policy-3rd-party-checkbox"
			/>

			<ThirdPartyMultiSelect
				value={selectedExternalAssets}
				options={externalAssets}
				onChange={setExternalAssets}
				label={selectedExternalAssets.length ? 'Third party: ' : 'No third party'}
			/>
		</>
	);
};
