import { useStore } from 'effector-react';
import { useEffect, useMemo } from 'react';
import FilterLine from 'components/FilterLine';
import FilterResult from 'components/FilterResult';
import NoContent from 'components/NoContent';
import { clearPiiFilter, piiFilterWithGroupsStore } from 'components/PiiGlobalFilterV2/model';
import ResetFilters from 'components/ResetFilters';
import EnhancedTableHead, { TOrder } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import { Typo } from 'components/typography/Typo';
import { getComparator } from 'models/modelUtils/comparator';
import { S3ClusterFileWithSamples, ClusterFileSample } from 'models/s3BucketCluster/dto';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import styles from './index.module.css';
import { SampleFileTableRow } from './SampleFileTableRow';
import { tableConfig } from './tableConfig';

const NOOP = () => {};

const pageConfig = {
	sort: {
		type: 'sort',
		persistence: 'session',
	},
	'data-types': {
		type: 'numberArray',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	nonempty: {
		type: 'boolean',
		persistence: 'session', // TODO implement 'global' in actuality
	},
} satisfies PageParamsConfig;

type Props = {
	type: S3ClusterFileWithSamples['type'];
	samples: S3ClusterFileWithSamples['samples'];
};

function ClusterSampleFiles(props: Props) {
	const { type, samples } = props;
	const [pageParams, setPageParams] = usePageParams(pageConfig, 'ClusterFiles');
	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);

	useEffect(() => {
		setPageParams({
			...pageParams,
			'data-types': piiFilterWithGroups.dataTypes,
			nonempty: piiFilterWithGroups.nonEmpty,
		});
	}, [piiFilterWithGroups]);

	const sortedAndFiltered = useMemo(() => {
		let result = [...samples];

		if (pageParams.nonempty) {
			result = result.filter((sample) => sample.data_types.length > 0);
		}

		if (pageParams['data-types'].length > 0) {
			result = result.filter((sample) =>
				sample.data_types.some((dt) => pageParams['data-types'].includes(dt))
			);
		}

		const orderBy = pageParams.sort.value as keyof ClusterFileSample;
		const order = pageParams.sort.operator;

		return result.sort(getComparator(orderBy, order));
	}, [pageParams, samples]);

	function onSortUpdate(property: keyof ClusterFileSample) {
		const { sort } = pageParams;

		const operator: TOrder = sort.value === property && sort.operator === 'asc' ? 'desc' : 'asc';
		const newParams = { sort: { operator, value: property } };

		setPageParams({ ...pageParams, ...newParams });
	}

	function onResetFilters() {
		clearPiiFilter();
	}

	const hasFilter = pageParams['data-types'].length > 0 || pageParams.nonempty;

	const withEstimated = type === 'csv';
	const config = withEstimated ? tableConfig : tableConfig.filter((c) => c.id !== 'estimated_rows');

	return (
		<div>
			<Typo variant="D/Medium/H100-Header">Sample files</Typo>

			<FilterLine
				config={['dataTypes']}
				values={{ dataTypes: pageParams['data-types'] }}
				onChange={NOOP}
			/>

			{samples.length > sortedAndFiltered.length && (
				<FilterResult
					entityLabel="File"
					loading={false}
					total={samples.length}
					totalFiltered={sortedAndFiltered.length}
				/>
			)}

			<GridTable dataTest="sample-file-table">
				<EnhancedTableHead
					config={config}
					order={pageParams.sort.operator}
					orderBy={pageParams.sort.value}
					onRequestSort={onSortUpdate}
					rowClassname={withEstimated ? styles.rowContainerWithEstimated : styles.rowContainer}
				/>

				<GridBody data-test="sample-file-list">
					<>
						{sortedAndFiltered.length ? (
							sortedAndFiltered.map((sample) => (
								<SampleFileTableRow sample={sample} withEstimated={withEstimated} key={sample.id} />
							))
						) : hasFilter ? (
							<ResetFilters onReset={onResetFilters} />
						) : (
							<NoContent
								type="s3BucketFilesTable"
								className={withEstimated ? styles.rowContainerWithEstimated : styles.rowContainer}
							/>
						)}
					</>
				</GridBody>
			</GridTable>
		</div>
	);
}

export { ClusterSampleFiles };
