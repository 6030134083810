import { useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import { Tooltip } from 'components/Tooltip';
import { TypoHighlighted } from 'components/typography/TypoHighlighted';
import { KafkaTopicTableItem } from 'models/kafkaTopics/dto';
import { lastSeen } from 'services/lastSeen';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import styles from './index.module.css';

type Props = {
	topic: KafkaTopicTableItem;
	search: string;
};

function TopicTableRow(props: Props) {
	const { topic, search } = props;
	const { id, name, partitions, messages_count, cluster, data_types, last_seen } = topic;

	const lastSeenValue = useMemo(() => lastSeen({ timestamp: last_seen, short: true }), [last_seen]);

	const history = useHistory();

	function clickHandler() {
		const path = generatePath(PATHS.KAFKA_TOPICS_ITEM, { id });
		history.push(path);
	}

	return (
		<GridRow
			className={styles.rowContainer}
			onClick={clickHandler}
			border
			hover
			data-test="kafka-topics-table-row"
		>
			<GridCell dataTest="kafka-topics-table-item-name">
				<TypoHighlighted variant="D/Medium/Body-S" search={search}>
					{name}
				</TypoHighlighted>
			</GridCell>

			<GridCell align="right" dataTest="kafka-topics-table-item-partitions">
				{toLocaleString(partitions)}
			</GridCell>

			<GridCell align="right" dataTest="kafka-topics-table-item-messages">
				{toLocaleString(messages_count)}
			</GridCell>

			<GridCell dataTest="kafka-topics-table-item-cluster">
				<TypoHighlighted variant="D/Medium/Body-S" search={search}>
					{cluster}
				</TypoHighlighted>
			</GridCell>

			<GridCell dataTest="kafka-topics-table-item-data_types">
				<PiiTypeList data={data_types} />
			</GridCell>

			<GridCell dataTest="kafka-topics-table-item-last-checked">
				{!last_seen ? (
					<Tooltip title="Scanning in progress">
						<Icon name="Clock/Filled" size={16} className={styles.grayIcon} />
					</Tooltip>
				) : (
					<Tooltip
						title={lastSeenValue.date}
						data-test="kafka-topics-table-item-last-checked-value"
					>
						<div>{lastSeenValue.diff}</div>
					</Tooltip>
				)}
			</GridCell>
		</GridRow>
	);
}

export { TopicTableRow };
