import { MenuList } from '@material-ui/core';
import cn from 'classnames';
import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckboxBase } from 'components/Checkbox';
import MultiSelect from 'components/form/MultiSelect';
import multiSelectStyles from 'components/form/MultiSelect/index.module.css';
import { OptionListProps } from 'components/form/MultiSelect/OptionList';
import DropdownButton from 'components/form/Select/DropdownButton';
import selectStyles from 'components/form/Select/index.module.css';
import Icon from 'components/Icon';
import MenuItem from 'components/MenuItem';
import { Typo } from 'components/typography/Typo';
import { DataTypeItem, DataTypeStructured } from 'models/dataTypes/dto';
import { getDataTypesFx } from 'models/dataTypes/effects';
import { getDataTypeCheckboxState, selectDataType } from 'models/dataTypes/helpers';
import { dataTypesStructuredStore } from 'models/dataTypes/store';
import { onceFx } from 'models/modelUtils/onceFx';
import { FilterPropsBase } from '../model';
import styles from './index.module.css';

type Props = FilterPropsBase & {
	value: DataTypeItem['id'][];
	onChange: (newValue: DataTypeItem['id'][]) => void;
	showUnused?: boolean;
};

const getPriority = (dataType: DataTypeStructured) => {
	if (dataType.alias === 'PII') return -4;
	if (dataType.alias === 'PCI') return -3;
	if (dataType.alias === 'DEVELOPER_SECRETS') return -2;
	if (dataType.alias === 'CUSTOM') return -1;

	return 0;
};
function sortDataTypes(a: DataTypeStructured, b: DataTypeStructured) {
	return getPriority(a) - getPriority(b);
}

function DataTypeFilter(props: Props) {
	const { defaultOpen, fixed, onClose, value, onChange, resetFilter, showUnused } = props;

	const dataTypesStructured = useStore(dataTypesStructuredStore);

	useEffect(() => {
		onceFx(getDataTypesFx);
	}, []);

	const options = useMemo(
		() =>
			dataTypesStructured
				.sort(sortDataTypes)
				.map((dtGroup) => [dtGroup, dtGroup.child_items])
				.flat(2)
				.filter((dt) => (showUnused ? true : dt.isUsed)),
		[dataTypesStructured]
	);

	const selected = options.filter((dt) => value.includes(dt.id));

	function handleChange(selectedDataTypes: DataTypeStructured[]) {
		onChange(selectedDataTypes.map((dt) => dt.id));
	}

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<MultiSelect
			defaultOpen={defaultOpen}
			hasApplyButton
			options={options}
			value={selected}
			onChange={handleChange}
			label={{ primary: 'Data types', secondary: `${selected.length || ''}` }}
			onClose={onClose}
			render={{
				dropdownButton,
				optionList: DataTypeOptionList,
			}}
		/>
	);
}

function DataTypeOptionList(props: OptionListProps<DataTypeStructured>) {
	const {
		options,
		value: externalValue,
		onChange: externalOnChange,
		onClose,
		renderApplyButton,
	} = props;

	const [internalValue, setInternalValue] = useState(externalValue);
	const [internalClickedOption, setInternalClickedOption] = useState<DataTypeStructured | null>(
		null
	);

	function onOptionClick(clickedOption: DataTypeStructured) {
		const newValue = selectDataType(clickedOption, options, internalValue);

		setInternalValue(newValue);
		setInternalClickedOption(clickedOption);
	}

	function onApply() {
		externalOnChange(internalValue, internalClickedOption);
		onClose();
	}

	const checkboxState = getDataTypeCheckboxState(options, internalValue);

	return (
		<MenuList className={cn(selectStyles.menuList, styles.menuList)}>
			<div className={cn(selectStyles.scrollContainer, multiSelectStyles.scrollContainer)}>
				{options.length > 0 ? (
					options.map((option) => {
						const { checked = false, halfPressed = false } = checkboxState[option.id] || {};

						return (
							<MenuItem size="small" onClick={() => onOptionClick(option)} key={option.id}>
								<CheckboxBase
									checked={checked}
									halfPressed={halfPressed}
									className={option.isGroup ? styles.checkbox : styles.checkboxOffset}
								/>
								{!option.isGroup && option.custom && (
									<Icon name="Tag/Filled" size={16} className={styles.icon} />
								)}
								{option.name}
							</MenuItem>
						);
					})
				) : (
					<Typo variant="D/Regular/Body-S" color="secondary" className={selectStyles.noOptions}>
						No items
					</Typo>
				)}
			</div>

			{renderApplyButton({ onClick: onApply })}
		</MenuList>
	);
}

export { DataTypeFilter, sortDataTypes };
