import { useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import { Tooltip } from 'components/Tooltip';
import { DarObjectStatusLabel } from 'models/databases/dto';
import { NoSQLDatabaseCollectionItem } from 'models/noSQLDatabases/dto';
import { lastSeen } from 'services/lastSeen';
import { toFileSize } from 'services/numbers';
import { PATHS } from 'services/router';
import commonStyles from '../../index.module.css';
import styles from './index.module.css';

type Props = {
	collection: NoSQLDatabaseCollectionItem;
};

function TableRow({ collection }: Readonly<Props>) {
	const { id, name, size, sensitivity, data_types, last_checked, status: errorStatus } = collection;
	const lastSeenValue = useMemo(
		() => lastSeen({ timestamp: last_checked, short: true }),
		[last_checked]
	);

	const history = useHistory();

	const clickHandler =
		!last_checked || !!errorStatus
			? undefined
			: () =>
					history.push(
						generatePath(PATHS.NOSQL_DATABASES_COLLECTION_ITEM, {
							id,
							tab: 'summary',
						})
					);

	const errorStatusCell = (
		<Tooltip title={DarObjectStatusLabel[errorStatus]}>
			<Icon name="ErrorCircle/Filled" size={16} className={commonStyles.redIcon} />
		</Tooltip>
	);
	const scanningInProgressCell = (
		<Tooltip title="Scanning in progress">
			<Icon name="Clock/Filled" size={16} className={commonStyles.grayIcon} />
		</Tooltip>
	);
	const lastCheckedCell = (
		<Tooltip title={lastSeenValue.date}>
			<div>{lastSeenValue.diff}</div>
		</Tooltip>
	);

	return (
		<GridRow
			className={styles.rowContainer}
			border
			hover={!!clickHandler}
			onClick={clickHandler}
			data-test="database-collection-row"
		>
			<GridCell dataTest="database-collection-item-offset">{name}</GridCell>

			<GridCell dataTest="database-collection-item-partition" align="right">
				{toFileSize(size)}
			</GridCell>
			<GridCell dataTest="database-collection-item-timestamp">
				<SensitivityChip sensitivity={sensitivity} />
			</GridCell>

			<GridCell dataTest="database-collection-item-data_types">
				<PiiTypeList data={data_types} />
			</GridCell>

			<GridCell dataTest="database-collection-item-last-checked">
				{errorStatus ? errorStatusCell : null}
				{!errorStatus && !last_checked ? scanningInProgressCell : null}
				{!errorStatus && last_checked ? lastCheckedCell : null}
			</GridCell>
		</GridRow>
	);
}

export { TableRow };
