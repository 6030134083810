import cn from 'classnames';
import React, { KeyboardEvent, useContext } from 'react';
import { Typo } from 'components/typography/Typo';
import { KEY_INPUT_INDEX, MAIN_INPUT_INDEX, StateContext } from '../.';
import { ColoredWrapper } from '../../ColoredWrapper';
import { RuleItem } from '../RuleItem';
import styles from './index.module.css';

interface Props {
	currentValue: string;
	first?: boolean;
	error: boolean | undefined;
	onChange: (value: string, ruleId: number) => void;
	onKeyDown: (event: KeyboardEvent<HTMLInputElement>, value: string, ruleId: number) => void;
	value: string;
}

export function TypePart({ currentValue, error, first, onChange, onKeyDown, value }: Props) {
	const { state, setState } = useContext(StateContext);

	return (
		<ColoredWrapper
			className={cn(styles.container, {
				[styles.hasContent]: value.length > 0 && state.editMode === 'type',
				[styles.noValue]: value.length === 0,
			})}
		>
			{state.editMode !== 'type' && value ? (
				<>
					<Typo
						className={cn({
							[styles.error]: error,
							[styles.activeText]: !!state.editMode && state.color !== 'error',
						})}
						component="span"
						onClick={() => {
							state.isLabelType
								? setState((prevState) => ({
										...prevState,
										idActiveRule: KEY_INPUT_INDEX,
										editMode: 'key',
								  }))
								: setState((prevState) => ({
										...prevState,
										idActiveRule: MAIN_INPUT_INDEX,
										editMode: 'values',
								  }));
						}}
						variant="D/Medium/Body-S"
						color="secondary"
					>
						{`${value}${state.isLabelType ? '' : ':'}`}
					</Typo>
				</>
			) : (
				<RuleItem
					first={first}
					className={cn({
						[styles.error]: error,
						[styles.emptyType]: (value.length === 0 && state.editMode === 'type') || first,
					})}
					focused={state.idActiveRule === MAIN_INPUT_INDEX}
					onChange={onChange}
					onFocus={() => {
						setState((prevState) => ({
							...prevState,
							editMode: 'type',
							idActiveRule: MAIN_INPUT_INDEX,
						}));
					}}
					onKeyDown={onKeyDown}
					ruleId={MAIN_INPUT_INDEX}
					value={currentValue}
				/>
			)}
		</ColoredWrapper>
	);
}
