import { generatePath } from 'react-router';
import CopyToClipboard from 'components/CopyToClipboard';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import { Tooltip } from 'components/Tooltip';
import { RouterLink } from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import { getDatabaseTableSample } from 'models/databases/api';
import { dataTypesById } from 'models/dataTypes/store';
import { StorageEventJson } from 'models/eventsV2/dto';
import { getKafkaMessageSchema } from 'models/kafkaMessageSchemas/api';
import { getS3ClusterFileWithSamples } from 'models/s3BucketCluster/api';
import { PATHS } from 'services/router';
import { getNoSQLCollectionDetails } from '../../../models/noSQLDatabases/api.ts';
import { eventTitleAsText } from '../EventRow';
import JiraButton from '../JiraButton';
import { formatWithToday } from '../utils';
import { SampleExistsIcon } from './fields/SampleExistsIcon';
import { ViewOnDataMapButton } from './fields/ViewOnDataMapButton';
import { ViewSampleButton } from './fields/ViewSampleButton';
import styles from './index.module.pcss';

type Props = { event: StorageEventJson };

const S3BucketStoring = ({ event }: Props) => {
	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;
	const pathToEvidence = generatePath(PATHS.EVENT_SAMPLE, { eventId: event.id });
	const pathToDataMap = `${PATHS.DATA_MAP}?entityId=${event.s3_bucket?.id}&type=s3_bucket`;

	const titleAsText = eventTitleAsText(event);
	const formattedLocal = formatWithToday(event.created_at);
	const jiraSummary = `Soveren issue: ${titleAsText}`;
	const jiraDescription = `*${titleAsText}*

*Event*: #${event.id}
*Time*: ${formattedLocal}
*Sensitivity*: ${event.sensitivity}
*Category*: Data storage change
*Data types*: ${event.data_types.map((dtId) => dataTypesById.getState()[dtId].name).join(', ')}
*Region*: ${event.s3_bucket?.region}
*File*: ${event.s3_bucket?.cluster_name}

See on Soveren dashboard: ${linkHere}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Event ID
				</Typo>
				<Typo variant="D/Regular/Body-S">
					{event.id}{' '}
					<Tooltip
						title="Copy event link"
						placement="right"
						className={styles.copyToClipboardWrapper}
					>
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Data types
				</Typo>
				<PiiTypeList data={event.data_types} noHighlight dataTest="event-details-data-types" />

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Severity
				</Typo>
				<SensitivityChip sensitivity={event.sensitivity} data-test="event-details-sensitivity" />

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Category
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="event-details-category">
					Data storage change
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Path
				</Typo>
				<RouterLink
					data-test="event-details-bucket-file"
					inherit
					to={generatePath(PATHS.S3_CLUSTER_ITEM, {
						id: event.s3_bucket?.id,
						clusterId: event.s3_bucket?.cluster_id,
					})}
					state={{
						name: event.s3_bucket?.cluster_name,
					}}
				>
					{event.s3_bucket?.cluster_name}
					<SampleExistsIcon
						getSampleFn={getS3ClusterFileWithSamples}
						sampleId={Number(event.s3_bucket?.cluster_id)}
					/>
				</RouterLink>
			</div>

			<div className={styles.links}>
				<JiraButton summary={jiraSummary} description={jiraDescription} />
				<ViewSampleButton path={pathToEvidence} />
				<ViewOnDataMapButton path={pathToDataMap} />
			</div>
		</>
	);
};

const DatabaseStoring = ({ event }: Props) => {
	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;
	const pathToEvidence = generatePath(PATHS.EVENT_SAMPLE, { eventId: event.id });
	const pathToDataMap = `${PATHS.DATA_MAP}?entityId=${event.database?.database_id}&type=sql_db_database`;

	const tableWithSchemaName = event.database?.table_schema_name
		? `${event.database?.table_schema_name}.${event.database?.table_name}`
		: event.database?.table_name;

	const titleAsText = eventTitleAsText(event);
	const formattedLocal = formatWithToday(event.created_at);
	const jiraSummary = `Soveren issue: ${titleAsText}`;
	const jiraDescription = `*${titleAsText}*

*Event*: #${event.id}
*Time*: ${formattedLocal}
*Sensitivity*: ${event.sensitivity}
*Category*: Data storage change
*Data types*: ${event.data_types.map((dtId) => dataTypesById.getState()[dtId].name).join(', ')}
*Instance*: ${event.database?.instance_name}, ${event.database?.type}
*Table*: ${tableWithSchemaName}

See on Soveren dashboard: ${linkHere}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Event ID
				</Typo>
				<Typo variant="D/Regular/Body-S" className={styles.horizontalAlignment}>
					{event.id}{' '}
					<Tooltip title="Copy event link" placement="right">
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Data types
				</Typo>
				<PiiTypeList data={event.data_types} noHighlight dataTest="event-details-data-types" />

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Severity
				</Typo>
				<SensitivityChip sensitivity={event.sensitivity} data-test="event-details-sensitivity" />

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Category
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="event-details-category">
					Data storage change
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Data types
				</Typo>
				<PiiTypeList data={event.data_types} noHighlight dataTest="event-details-data-types" />

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Instance
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="event-details-database_instance">
					{event.database?.instance_name}

					<Typo
						variant="D/Regular/Meta"
						color="secondary"
						className={styles.databaseType}
						data-test="event-details-database-type"
					>
						{event.database?.type}
					</Typo>
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Table
				</Typo>
				<RouterLink
					data-test="event-details-database-table"
					inherit
					to={generatePath(PATHS.DATABASES_TABLE_SAMPLE, {
						sampleId: event.database?.table_id,
					})}
					state={{
						instance_name: event.database?.instance_name,
						table_name: tableWithSchemaName,
						type: event.database?.type,
					}}
				>
					{tableWithSchemaName}
					<SampleExistsIcon
						getSampleFn={getDatabaseTableSample}
						sampleId={Number(event.database?.table_id)}
					/>
				</RouterLink>
			</div>

			<div className={styles.links}>
				<JiraButton summary={jiraSummary} description={jiraDescription} />
				<ViewSampleButton path={pathToEvidence} />
				<ViewOnDataMapButton path={pathToDataMap} />
			</div>
		</>
	);
};

const NoSQLDatabaseStoring = ({ event }: Props) => {
	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;
	const pathToEvidence = generatePath(PATHS.EVENT_SAMPLE, { eventId: event.id });
	const pathToDataMap = `${PATHS.DATA_MAP}?entityId=${event.nosql_database?.database_id}&type=nosql_db_database`;

	const titleAsText = eventTitleAsText(event);
	const formattedLocal = formatWithToday(event.created_at);
	const jiraSummary = `Soveren issue: ${titleAsText}`;
	const jiraDescription = `*${titleAsText}*

*Event*: #${event.id}
*Time*: ${formattedLocal}
*Sensitivity*: ${event.sensitivity}
*Category*: Data storage change
*Data types*: ${event.data_types.map((dtId) => dataTypesById.getState()[dtId].name).join(', ')}
*Instance*: ${event.nosql_database?.instance_name}, ${event.database?.type}
*Collection*: ${event.nosql_database?.collection_name}

See on Soveren dashboard: ${linkHere}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Event ID
				</Typo>
				<Typo variant="D/Regular/Body-S" className={styles.horizontalAlignment}>
					{event.id}{' '}
					<Tooltip title="Copy event link" placement="right">
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Sensitivity
				</Typo>
				<SensitivityChip sensitivity={event.sensitivity} data-test="event-details-sensitivity" />

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Category
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="event-details-category">
					Data storage change
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Data types
				</Typo>
				<PiiTypeList data={event.data_types} noHighlight dataTest="event-details-data-types" />

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Instance
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="event-details-database_instance">
					{event.nosql_database?.instance_name}

					<Typo
						variant="D/Regular/Meta"
						color="secondary"
						className={styles.databaseType}
						data-test="event-details-database-type"
					>
						{event.nosql_database?.type}
					</Typo>
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Collection
				</Typo>
				<RouterLink
					data-test="event-details-database-collection"
					inherit
					to={generatePath(PATHS.NOSQL_DATABASES_COLLECTION_ITEM, {
						id: event.nosql_database?.collection_id,
					})}
					state={{
						instance_name: event.nosql_database?.instance_name,
						database_name: event.nosql_database?.database_name,
						collection_name: event.nosql_database?.collection_name,
						type: event.nosql_database?.type,
					}}
				>
					{event.nosql_database?.collection_name}
					<SampleExistsIcon
						getSampleFn={getNoSQLCollectionDetails}
						sampleId={Number(event.nosql_database?.collection_id)}
					/>
				</RouterLink>
			</div>

			<div className={styles.links}>
				<JiraButton summary={jiraSummary} description={jiraDescription} />
				<ViewSampleButton path={pathToEvidence} />
				<ViewOnDataMapButton path={pathToDataMap} />
			</div>
		</>
	);
};

const KafkaStoring = ({ event }: Props) => {
	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;
	const pathToEvidence = generatePath(PATHS.EVENT_SAMPLE, { eventId: event.id });
	const pathToDataMap = `${PATHS.DATA_MAP}?entityId=${event.kafka?.instance_id}&type=kafka_instance`;

	const titleAsText = eventTitleAsText(event);
	const formattedLocal = formatWithToday(event.created_at);
	const jiraSummary = `Soveren issue: ${titleAsText}`;
	const jiraDescription = `*${titleAsText}*

*Event*: #${event.id}
*Time*: ${formattedLocal}
*Sensitivity*: ${event.sensitivity}
*Category*: Data storage change
*Data types*: ${event.data_types.map((dtId) => dataTypesById.getState()[dtId].name).join(', ')}
*Cluster*: ${event.kafka?.instance_name}
*Message scheme*: ${event.kafka?.scheme_name}

See on Soveren dashboard: ${linkHere}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Event ID
				</Typo>
				<Typo variant="D/Regular/Body-S" className={styles.horizontalAlignment}>
					{event.id}{' '}
					<Tooltip title="Copy event link" placement="right">
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Sensitivity
				</Typo>
				<SensitivityChip sensitivity={event.sensitivity} data-test="event-details-sensitivity" />

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Category
				</Typo>
				<Typo variant="D/Regular/Body-S" data-test="event-details-category">
					Data storage change
				</Typo>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Data types
				</Typo>
				<PiiTypeList data={event.data_types} noHighlight dataTest="event-details-data-types" />

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Cluster
				</Typo>
				<RouterLink
					data-test="event-details-kafka-instance"
					inherit
					to={generatePath(PATHS.KAFKA_INSTANCES_ITEM, {
						id: event.kafka?.instance_id,
					})}
				>
					{event.kafka?.instance_name}
				</RouterLink>

				<Typo color="secondary" variant="D/Regular/Meta-CAP">
					Message scheme
				</Typo>
				<RouterLink
					data-test="event-details-kafka-scheme"
					inherit
					to={generatePath(PATHS.KAFKA_MESSAGE_SCHEMA_ITEM, {
						topicId: event.kafka?.topic_id,
						schemaId: event.kafka?.scheme_id,
					})}
					state={{
						name: event.kafka?.scheme_name,
					}}
				>
					{event.kafka?.scheme_name}
					<SampleExistsIcon
						getSampleFn={getKafkaMessageSchema}
						sampleId={Number(event.kafka?.scheme_id)}
					/>
				</RouterLink>
			</div>

			<div className={styles.links}>
				<JiraButton summary={jiraSummary} description={jiraDescription} />
				<ViewSampleButton path={pathToEvidence} />
				<ViewOnDataMapButton path={pathToDataMap} />
			</div>
		</>
	);
};

export function StorageEvent({ event }: Props) {
	if (event.s3_bucket) {
		return <S3BucketStoring event={event} />;
	} else if (event.database) {
		return <DatabaseStoring event={event} />;
	} else if (event.nosql_database) {
		return <NoSQLDatabaseStoring event={event} />;
	} else if (event.kafka) {
		return <KafkaStoring event={event} />;
	}

	return <>Event is not supported</>;
}
