import { useStore } from 'effector-react';
import { useCallback, useEffect, useState } from 'react';
import { DraftFunction } from 'use-immer';
import Accordion from 'components/Accordion';
import AccordionDetails from 'components/AccordionDetails';
import AccordionSummary from 'components/AccordionSummary';
import { CheckboxBase } from 'components/Checkbox';
import Chip from 'components/Chip';
import { FilterPropsBase } from 'components/FilterLine/model';
import Icon from 'components/Icon';
import { DataTypeItem, DataTypeStructured } from 'models/dataTypes/dto';
import { getDataTypesFx } from 'models/dataTypes/effects';
import { dataTypesStructuredStore } from 'models/dataTypes/store';
import { onceFx } from 'models/modelUtils/onceFx';
import { PolicyItem } from 'models/policiesV2/dto';
import styles from './index.module.css';

type Props = FilterPropsBase & {
	value: DataTypeItem['id'][];
	setFormData: (arg: DraftFunction<PolicyItem>) => void;
};

const ICONS: { [key: string]: JSX.Element } = {
	PII: <Icon name="personBoard" size={16} />,
	PCI: <Icon name="payment" size={16} />,
	DEVELOPER_SECRETS: <Icon name="developerBoard" size={16} />,
	CUSTOM: <Icon name="customDataTypes" size={16} />,
};

function getPriority(dataType: DataTypeStructured) {
	if (dataType.alias === 'PII') return -4;
	if (dataType.alias === 'PCI') return -3;
	if (dataType.alias === 'DEVELOPER_SECRETS') return -2;
	if (dataType.alias === 'CUSTOM') return -1;

	return 4;
}

function customComparator(a: DataTypeStructured, b: DataTypeStructured) {
	return getPriority(a) - getPriority(b);
}

function PolicyDataTypesV2(props: Props) {
	const { value, setFormData } = props;

	const [accordionExpanded, setAccordionExpanded] = useState<{ [key: number]: boolean }>({});
	const dataTypesStructured = useStore(dataTypesStructuredStore);

	const onChange = useCallback((data_types) => {
		setFormData((draft) => {
			draft.data_types = data_types;
		});
	}, []);

	useEffect(() => {
		onceFx(getDataTypesFx);
	}, []);

	const selectDTGroup = (dtGroup: DataTypeStructured) => {
		const isSelected = !!value.find((id) => dtGroup.id === id);
		const isHalfSelected = dtGroup.child_ids.some((id) => value.includes(id));

		let nextValue: DataTypeItem['id'][] = [];

		switch (true) {
			case isSelected:
				nextValue = value.filter((id) => id !== dtGroup.id);
				break;
			case isHalfSelected:
				nextValue = value.filter((id) => !dtGroup.child_ids.includes(id)).concat(dtGroup.id);
				break;
			default:
				nextValue = value.concat(dtGroup.id);
				break;
		}

		onChange(nextValue);
	};

	const selectDataTypeHandler = (dtId: DataTypeItem['id'], dtParentGroup: DataTypeStructured) => {
		const isSelected = !!value.find((id) => dtId === id);
		const isGroupSelected = value.includes(dtParentGroup.id);
		const countUnselectedInGroup = dtParentGroup.child_ids.reduce((acc, childId) => {
			if (value.includes(childId)) {
				return acc - 1;
			}

			return acc;
		}, dtParentGroup.child_ids.length);

		let nextValue: DataTypeItem['id'][];

		switch (true) {
			case countUnselectedInGroup === 1 && !isSelected:
				nextValue = value
					.filter((id) => !dtParentGroup.child_ids.includes(id))
					.concat(dtParentGroup.id);
				break;
			case isGroupSelected:
				nextValue = value
					.concat(dtParentGroup.child_ids)
					.filter((id) => id !== dtId && id !== dtParentGroup.id);
				break;
			case isSelected:
				nextValue = value.filter((id) => id !== dtId);
				break;
			default:
				nextValue = value.concat(dtId);
		}

		onChange(nextValue);
	};

	return (
		<div>
			{dataTypesStructured.sort(customComparator).map((dtGroup) => {
				const isHalfSelected = dtGroup.child_ids.some((id) => value.includes(id));
				const isSelected = value.includes(dtGroup.id);

				const selectedDataTypes = dtGroup.child_items.filter(({ id }) => value.includes(id));

				return (
					<Accordion
						key={`dtGroup-${dtGroup.name}`}
						className={styles.accordion}
						expanded={accordionExpanded[dtGroup.id]}
					>
						<AccordionSummary
							onClick={() => {
								setAccordionExpanded({
									...accordionExpanded,
									[dtGroup.id]: !accordionExpanded[dtGroup.id],
								});
							}}
							classes={{
								root: styles.accordionSummaryRoot,
								content: styles.accordionSummaryContent,
							}}
						>
							<CheckboxBase
								checked={isSelected || isHalfSelected}
								halfPressed={isHalfSelected}
								onChange={() => selectDTGroup(dtGroup)}
								onClick={(e) => e.stopPropagation()}
							/>
							<div className={styles.accordionSummaryTitleWrapper}>
								<div className={styles.accordionSummaryTitle}>
									{ICONS[dtGroup.alias]}
									{dtGroup.name}
								</div>
								{!accordionExpanded[dtGroup.id] && (
									<div className={styles.selectedDataTypes}>
										{selectedDataTypes.slice(0, 4).map((child) => (
											<Chip
												key={`selectedDataType-${child}`}
												theme="dataTypeNeutral"
												label={child.name}
												size="small"
											/>
										))}
										{selectedDataTypes.length > 4 && (
											<Chip
												label={`+${selectedDataTypes.length - 4}`}
												theme="dataTypeNeutral"
												size="small"
											/>
										)}
									</div>
								)}
							</div>
						</AccordionSummary>

						<AccordionDetails classes={{ root: styles.accordionDetailsRoot }}>
							{dtGroup.child_items.map((dt) => {
								return (
									<div
										key={`dt-${dt.name}`}
										className={styles.dtItem}
										onClick={() => selectDataTypeHandler(dt.id, dtGroup)}
									>
										<CheckboxBase checked={value.includes(dt.id) || isSelected} />
										{dt.name}
									</div>
								);
							})}
						</AccordionDetails>
					</Accordion>
				);
			})}
		</div>
	);
}

export { PolicyDataTypesV2 };
