import Chip from 'components/Chip';
import Icon from 'components/Icon';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import { Typo } from 'components/typography/Typo';
import {
	AssetEventJson,
	ClusteringEventJson,
	DARNewDataTypeEventJson,
	DIMNewDataTypeEventJson,
	EventJson,
	ExternalAssetConflictJson,
	PolicyEventJson,
	PolicyS3EventJson,
} from 'models/eventsV2/dto';
import { DateFormat, format } from 'services/dayjs';
import { DASH_SIGN } from 'services/strings';
import tableStyles from '../EventsTable/index.module.css';
import { eventTitleByType } from '../utils';
import styles from './index.module.pcss';

type Props = {
	event: EventJson;
	selected: boolean;
	onSelect: (id: number) => void;
	dataTest?: string;
};

export const INFRASTRUCTURE_UPDATES = 'Infrastructure Updates';
export const DATA_AT_RISK = 'Data at risk';
export const MISCONFIGURATION = 'Misconfiguration';
export const OTHER = 'Other';

const ENTITY_NAME = {
	service: 'Serivce',
	s3Bucket: 'S3 bucket',
	kafkaTopic: 'Kafka topic',
	database: 'SQL Database',
	noSqlDatabase: 'NoSQL database',
};

// eslint-disable-next-line complexity
function eventTitle({ event }: { event: EventJson }) {
	const title = <></>;
	const titleText = '';

	let icon = <></>;
	let isPolicyViolation = false;
	let eventName = eventTitleByType(event.type);
	const createdAt = format(event.created_at, DateFormat.date);
	let eventType = '';
	let sensitivity = '';
	let resourceName = '';
	let resourceType = '';
	let region = '';
	const accountId = '';

	switch (event.type) {
		case 'new_data_type':
			eventType = INFRASTRUCTURE_UPDATES;
			region = event.region;
			sensitivity = event.sensitivity;

			switch (event.object_type) {
				case 'Service': {
					const { responder, requestor } = event as DIMNewDataTypeEventJson;
					resourceName = event.direction === 'request' ? responder.name : requestor.name;
					resourceType = ENTITY_NAME.service;
					break;
				}
				case 'S3': {
					const _event = event as DARNewDataTypeEventJson;
					resourceName = _event.s3_bucket?.name || '';
					resourceType = ENTITY_NAME.s3Bucket;
					break;
				}
				case 'Kafka': {
					const _event = event as DARNewDataTypeEventJson;
					resourceName = _event.kafka?.instance_name || '';
					resourceType = ENTITY_NAME.kafkaTopic;
					break;
				}
				case 'Database': {
					const _event = event as DARNewDataTypeEventJson;
					resourceName = _event.database?.database_name || '';
					resourceType = ENTITY_NAME.database;
					break;
				}
				case 'NoSQLDatabase': {
					const _event = event as DARNewDataTypeEventJson;
					resourceName = _event.nosql_database?.database_name || '';
					resourceType = ENTITY_NAME.noSqlDatabase;
					break;
				}
			}
			break;

		case 'new_internal_receiver':
		case 'new_internal_sender': {
			eventType = INFRASTRUCTURE_UPDATES;
			region = event.region;
			sensitivity = event.sensitivity;

			const { responder, requestor, direction } = event as AssetEventJson;
			resourceName = direction === 'request' ? responder.name : requestor.name;
			resourceType = ENTITY_NAME.service;
			break;
		}

		case 'updated_internal_receiver':
		case 'updated_internal_sender': {
			eventType = INFRASTRUCTURE_UPDATES;
			region = event.region;
			sensitivity = event.sensitivity;

			const { responder, requestor, direction } = event as AssetEventJson;
			resourceName = direction === 'request' ? responder.name : requestor.name;
			resourceType = ENTITY_NAME.service;

			break;
		}

		case 'new_external_receiver': {
			eventType = INFRASTRUCTURE_UPDATES;
			region = event.region;
			sensitivity = event.sensitivity;

			const { responder, requestor, direction } = event as AssetEventJson;
			resourceName = direction === 'request' ? responder.name : requestor.name;
			resourceType = ENTITY_NAME.service;

			break;
		}

		case 'updated_enduser_receiver':
		case 'updated_robot_receiver':
		case 'updated_external_receiver': {
			eventType = INFRASTRUCTURE_UPDATES;
			region = event.region;
			sensitivity = event.sensitivity;

			const { responder, requestor, direction } = event as AssetEventJson;
			resourceName = direction === 'request' ? responder.name : requestor.name;
			resourceType = ENTITY_NAME.service;

			break;
		}

		case 'started_storing_data':
		case 'started_storing_datatype': {
			eventType = INFRASTRUCTURE_UPDATES;
			region = event.region;
			sensitivity = event.sensitivity;

			if (event.s3_bucket) {
				resourceName = event.s3_bucket.name;
				resourceType = ENTITY_NAME.s3Bucket;
			}
			if (event.database) {
				resourceName = event.database.database_name;
				resourceType = ENTITY_NAME.database;
			}
			if (event.kafka) {
				resourceName = event.kafka.topic_name;
				resourceType = ENTITY_NAME.kafkaTopic;
			}
			if (event.nosql_database) {
				resourceName = event.nosql_database.database_name;
				resourceType = ENTITY_NAME.noSqlDatabase;
			}

			break;
		}

		case 'policy_violation': {
			icon = <Icon name="Shield" size={20} />;
			isPolicyViolation = true;
			eventType = DATA_AT_RISK;
			region = event.region;
			sensitivity = event.sensitivity;

			const { responder, requestor, direction } = event as PolicyEventJson;
			resourceName = direction === 'request' ? responder.name : requestor.name;
			resourceType = ENTITY_NAME.service;

			break;
		}

		case 'policy_unencrypted_s3_bucket': {
			icon = <Icon name="Shield" size={20} />;
			isPolicyViolation = true;
			eventType = MISCONFIGURATION;
			region = event.region;
			sensitivity = event.sensitivity;

			resourceType = ENTITY_NAME.s3Bucket;
			resourceName = (event as PolicyS3EventJson)?.s3_bucket?.name;

			break;
		}

		case 'policy_public_s3_bucket': {
			icon = <Icon name="Shield" size={20} />;
			isPolicyViolation = true;
			eventType = MISCONFIGURATION;
			region = event.region;
			sensitivity = event.sensitivity;

			resourceType = ENTITY_NAME.s3Bucket;
			resourceName = (event as PolicyS3EventJson)?.s3_bucket?.name;

			break;
		}

		case 'policy_unencrypted_network': {
			icon = <Icon name="Shield" size={20} />;
			isPolicyViolation = true;
			eventType = MISCONFIGURATION;
			region = event.region;
			sensitivity = event.sensitivity;

			const { responder, requestor, direction } = event as PolicyEventJson;
			resourceName = direction === 'request' ? responder.name : requestor.name;
			resourceType = ENTITY_NAME.service;

			break;
		}

		case '3rd_party_policy_violation': {
			icon = <Icon name="Shield" size={20} />;
			isPolicyViolation = true;
			eventType = DATA_AT_RISK;
			region = event.region;
			sensitivity = event.sensitivity;

			const { responder, requestor, direction } = event as PolicyEventJson;
			resourceName = direction === 'request' ? responder.name : requestor.name;
			resourceType = ENTITY_NAME.service;

			break;
		}

		case 'email_clustered': {
			eventType = INFRASTRUCTURE_UPDATES;
			region = event.region;
			sensitivity = event.sensitivity;

			const { responder, requestor, direction } = event as ClusteringEventJson;
			resourceName = direction === 'request' ? responder.name : requestor.name;
			resourceType = ENTITY_NAME.service;

			break;
		}

		case 'custom_asset_rule_conflict': {
			eventType = INFRASTRUCTURE_UPDATES;
			region = event.region;
			sensitivity = event.sensitivity;

			const { responder, requestor, direction } = event as ExternalAssetConflictJson;
			resourceName = direction === 'request' ? responder.name : requestor.name;
			resourceType = ENTITY_NAME.service;

			break;
		}

		case 'discovery_complete': {
			eventType = OTHER;
			sensitivity = 'Low';

			break;
		}

		case 'dim_release_update': {
			eventType = OTHER;
			sensitivity = 'Low';

			break;
		}

		case 'dim_release_update_critical': {
			icon = <Icon name="Danger/FilledRed" size={20} />;
			eventType = OTHER;
			sensitivity = 'Low';

			break;
		}

		case 'dar_release_update': {
			eventType = OTHER;
			sensitivity = 'Low';

			break;
		}

		case 'dar_release_update_critical': {
			icon = <Icon name="Danger/FilledRed" size={20} />;
			eventType = OTHER;
			sensitivity = 'Low';

			break;
		}

		case 'anomaly_detected': {
			eventType = DATA_AT_RISK;
			region = event.region;
			sensitivity = event.sensitivity;

			const { responder, requestor, direction } = event as AssetEventJson;
			resourceName = direction === 'request' ? responder.name : requestor.name;
			resourceType = ENTITY_NAME.service;

			break;
		}

		case 'excessive_db_access': {
			eventType = DATA_AT_RISK;
			region = event.region;
			sensitivity = event.sensitivity;
			resourceType = ENTITY_NAME.database;
			resourceName = event.db_access.databases.map((db) => db.name).join(', ');

			break;
		}

		case 'policy_unencrypted_rds': {
			eventType = MISCONFIGURATION;
			isPolicyViolation = true;
			region = event.region;
			sensitivity = event.sensitivity;

			if (event.s3_bucket) {
				resourceName = event.s3_bucket.name;
				resourceType = ENTITY_NAME.s3Bucket;
			}
			if (event.database) {
				resourceName = event.database.database_name;
				resourceType = ENTITY_NAME.database;
			}
			if (event.kafka) {
				resourceName = event.kafka.topic_name;
				resourceType = ENTITY_NAME.kafkaTopic;
			}
			if (event.nosql_database) {
				resourceName = event.nosql_database.database_name;
				resourceType = ENTITY_NAME.noSqlDatabase;
			}

			break;
		}

		case 'policy_violation_dar': {
			eventType = DATA_AT_RISK;
			isPolicyViolation = true;
			region = event.region;
			sensitivity = event.sensitivity;

			if (event.s3_bucket) {
				resourceName = event.s3_bucket.name;
				resourceType = ENTITY_NAME.s3Bucket;
			}
			if (event.database) {
				resourceName = event.database.database_name;
				resourceType = ENTITY_NAME.database;
			}
			if (event.kafka) {
				resourceName = event.kafka.topic_name;
				resourceType = ENTITY_NAME.kafkaTopic;
			}
			if (event.nosql_database) {
				resourceName = event.nosql_database.database_name;
				resourceType = ENTITY_NAME.noSqlDatabase;
			}

			break;
		}

		case 'new_potential_data_storage': {
			eventType = INFRASTRUCTURE_UPDATES;
			region = event.region;
			sensitivity = event.sensitivity;

			if (event.s3_bucket) {
				resourceName = event.s3_bucket.name;
				resourceType = ENTITY_NAME.s3Bucket;
			}
			if (event.database) {
				resourceName = event.database.database_name;
				resourceType = ENTITY_NAME.database;
			}
			if (event.kafka) {
				resourceName = event.kafka.topic_name;
				resourceType = ENTITY_NAME.kafkaTopic;
			}
			if (event.nosql_database) {
				resourceName = event.nosql_database.database_name;
				resourceType = ENTITY_NAME.noSqlDatabase;
			}

			eventName = eventTitleByType(event.type, resourceType);

			break;
		}

		case 'new_data_storage': {
			eventType = DATA_AT_RISK;
			isPolicyViolation = true;
			region = event.region;
			sensitivity = event.sensitivity;

			if (event.s3_bucket) {
				resourceName = event.s3_bucket.name;
				resourceType = ENTITY_NAME.s3Bucket;
			}
			if (event.database) {
				resourceName = event.database.database_name;
				resourceType = ENTITY_NAME.database;
			}
			if (event.kafka) {
				resourceName = event.kafka.topic_name;
				resourceType = ENTITY_NAME.kafkaTopic;
			}
			if (event.nosql_database) {
				resourceName = event.nosql_database.database_name;
				resourceType = ENTITY_NAME.noSqlDatabase;
			}

			eventName = eventTitleByType(event.type, resourceType);

			break;
		}

		default:
			eventType = '------';
	}

	return {
		title,
		titleText,
		eventName,
		createdAt,
		eventType,
		sensitivity,
		resourceName,
		resourceType,
		region,
		accountId,
		icon,
		isPolicyViolation,
	};
}

function eventTitleAsComponent({ event }: { event: EventJson }) {
	return eventTitle({ event });
}

function eventTitleAsText(event: EventJson) {
	return eventTitle({ event }).titleText;
}

function EventRow(props: Props) {
	const { event, selected, onSelect /* , filterIds */ } = props;

	function onClick() {
		onSelect(selected ? 0 : event.id);
	}

	const eventData = eventTitleAsComponent({ event /* size: 'small' , filterIds */ });

	return (
		<GridRow
			className={tableStyles.rowContainer}
			hoverClass={styles.hoverRow}
			hover
			border
			onClick={() => {
				onClick();
			}}
			data-test="event-row"
		>
			<GridCell className={styles.eventNameCell} verticalAlign="middle">
				<div className={styles.icon}>{eventData.icon}</div>
				<Typo variant="D/Regular/Body-S" className={styles.eventNameText} ellipsis>
					{eventData.eventName}
				</Typo>
				<Icon name="ChevronRight/Regular" size={20} />
			</GridCell>

			<GridCell className={styles.gapCell} verticalAlign="middle">
				<Typo variant="D/Regular/Body-S">{eventData.createdAt || DASH_SIGN}</Typo>
			</GridCell>

			<GridCell className={styles.eventTypeCell} verticalAlign="middle">
				{eventData.isPolicyViolation && (
					<Chip label="Policy violation" size="small" color="primary" theme="assetInternal" />
				)}
				<Chip
					label={eventData.eventType || DASH_SIGN}
					size="small"
					color="primary"
					theme="assetNeutral"
				/>
			</GridCell>

			<GridCell className={styles.gapCell} verticalAlign="middle">
				<SensitivityChip sensitivity={eventData.sensitivity || DASH_SIGN} />
			</GridCell>

			<GridCell className={styles.gapCell} verticalAlign="middle">
				<Typo variant="D/Regular/Body-S">{eventData.resourceName || DASH_SIGN}</Typo>
			</GridCell>

			<GridCell className={styles.gapCell} verticalAlign="middle">
				<Typo variant="D/Regular/Body-S">{eventData.resourceType || DASH_SIGN}</Typo>
			</GridCell>

			<GridCell className={styles.gapCell} verticalAlign="middle">
				<Typo variant="D/Regular/Body-S">{eventData.region || DASH_SIGN}</Typo>
			</GridCell>

			{/* <GridCell className={styles.gapCell} verticalAlign="middle"> */}
			{/* 	<Typo variant="D/Regular/Body-S">{eventData.accountId || DASH_SIGN}</Typo> */}
			{/* </GridCell> */}
		</GridRow>
	);
}

export { EventRow, eventTitle, eventTitleAsComponent, eventTitleAsText };
