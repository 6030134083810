import { useStore } from 'effector-react';
import { useEffect } from 'react';
import FilterLine from 'components/FilterLine';
import { ProtocolValue } from 'components/FilterLine/ProtocolFilter';
import FilterResult from 'components/FilterResult';
import LoadMoreButton from 'components/LoadMoreButton';
import NewGatewayBlock from 'components/NewGatewayBlock';
import NoContent from 'components/NoContent';
import {
	clearPiiFilter,
	piiFilterToArray,
	piiFilterWithGroupsStore,
} from 'components/PiiGlobalFilterV2/model';
import Preloader from 'components/Preloader';
import ResetFilters from 'components/ResetFilters';
import EnhancedTableHead, { TOrder } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { EndpointsTableItem } from 'models/endpointsV2/dto';
import { endpointsModel } from 'models/endpointsV2/model';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import { UniversalSearch, UniversalSearchInLayout } from 'views/common/UniversalSearch';
import tableConfig from './config';
import { EndpointItem } from './EndpointItem';
import styles from './index.module.css';

const pageConfig = {
	sort: {
		type: 'sort',
		persistence: 'session',
	},
	hosts: {
		type: 'string',
		persistence: 'session',
	},
	endpoints: {
		type: 'string',
		persistence: 'session',
	},
	'asset-names': {
		type: 'stringArray',
		persistence: 'session',
	},
	namespaces: {
		type: 'stringArray',
		persistence: 'session',
	},
	'cluster-ids': {
		type: 'numberArray',
		persistence: 'session',
	},
	assets: {
		type: 'string',
		persistence: 'session',
	},
	search: {
		type: 'string',
		persistence: 'session',
	},
	protocol: {
		type: 'string',
		persistence: 'session',
	},
	'data-types': {
		type: 'numberArray',
		persistence: 'session', // TODO implement 'global' in actuality
	},
	nonempty: {
		type: 'boolean',
		persistence: 'session', // TODO implement 'global' in actuality
	},
} satisfies PageParamsConfig;

type Props = { asset?: number; totalByAsset?: boolean };

function Endpoints(props: Props) {
	const state = useStore(endpointsModel.store);
	const piiFilterWithGroups = useStore(piiFilterWithGroupsStore);

	const [pageParams, setPageParams] = usePageParams(
		pageConfig,
		props.asset ? 'endpointsGroup' : 'endpoints'
	);

	useEffect(() => {
		setPageParams({
			...pageParams,
			'data-types': piiFilterWithGroups.dataTypes,
			nonempty: piiFilterWithGroups.nonEmpty,
		});
	}, [piiFilterWithGroups]);

	useEffect(() => {
		const {
			sort,
			hosts,
			endpoints,
			'asset-names': asset_names,
			namespaces,
			'cluster-ids': cluster_ids,
			assets,
			search,
			protocol,
		} = pageParams;

		const dataTypes = piiFilterToArray(piiFilterWithGroups);
		const paramsForFx = {
			sort: { orderBy: sort.value as keyof EndpointsTableItem, order: sort.operator },
			hosts,
			endpoints,
			asset: props.asset || 0,
			total_by_asset: !!props.totalByAsset,
			asset_names,
			namespaces,
			cluster_ids,
			assets,
			search,
			protocol,
			'data-types': dataTypes,
		};

		endpointsModel.fetchFx(paramsForFx);
	}, [pageParams]);

	// Clean up model on unmount
	useEffect(() => {
		return () => {
			endpointsModel.resetFx();
		};
	}, []);

	// That's Table filter, not filters in Filter line.
	function onFilterUpdate(property: keyof EndpointsTableItem, filterText: string) {
		let newParams;

		if (property === 'url') {
			newParams = { endpoints: filterText };
		} else if (property === 'host') {
			newParams = { hosts: filterText };
		} else if (property === 'asset_name') {
			newParams = { assets: filterText };
		} else {
			return;
		}

		setPageParams({ ...pageParams, ...newParams });
	}

	function onSortUpdate(property: keyof EndpointsTableItem) {
		const { sort } = state.params;

		const operator: TOrder = sort.orderBy === property && sort.order === 'asc' ? 'desc' : 'asc';
		const newParams = { sort: { operator, value: property } };

		setPageParams({ ...pageParams, ...newParams });
	}

	function onFilterLineUpdate(filterLineParams: Partial<typeof pageParams>) {
		setPageParams({ ...pageParams, ...filterLineParams });
	}

	function onUniversalSearchUpdate(search: string) {
		setPageParams({ ...pageParams, search });
	}

	function onResetFilters() {
		const newParams = {
			endpoints: '',
			assets: '',
			'asset-names': [],
			namespaces: [],
			search: '',
			'data-types': [],
			hosts: '',
			'cluster-ids': [],
			protocol: '',
		};

		setPageParams({ ...pageParams, ...newParams });
		clearPiiFilter();
	}

	const { status, data, total, total_filtered, hasMoreData, params } = state;
	const hasFilter =
		params.assets.length > 0 ||
		params.asset_names.length > 0 ||
		params.namespaces.length > 0 ||
		params.search.length > 0 ||
		(params['data-types'].length > 0 && params['data-types'][0] !== 'nonempty') ||
		params.endpoints.length > 0 ||
		params.hosts.length > 0 ||
		params.cluster_ids.length > 0 ||
		params.protocol !== '';

	return (
		<>
			{/**
					This Header should be visible only on the PATHS.API_ENDPOINTS route
					and not on the PATHS.SERVICE_ITEM route
			 */}
			{!props.asset && (
				<Header>
					<Header.Breadcrumbs finalStep="API endpoints" />

					<Header.Title>API endpoints</Header.Title>

					<Header.SensitiveSwitcher />
				</Header>
			)}

			<UniversalSearch value={params.search} onChange={onUniversalSearchUpdate} />

			{props.asset ? (
				<FilterLine
					config={['dataTypes', 'protocol']}
					values={{
						dataTypes: pageParams['data-types'],
						protocol: pageParams.protocol as ProtocolValue,
					}}
					onChange={(newValues) => {
						onFilterLineUpdate({
							protocol: newValues.protocol,
						});
					}}
				/>
			) : (
				<div className={styles.filterLine}>
					<FilterLine
						config={['dataTypes', 'namespaces', 'assets', 'clusters', 'protocol']}
						values={{
							dataTypes: pageParams['data-types'],
							namespaces: pageParams.namespaces,
							assets: pageParams['asset-names'],
							clusters: pageParams['cluster-ids'],
							protocol: pageParams.protocol as ProtocolValue,
						}}
						onChange={(newValues) => {
							onFilterLineUpdate({
								namespaces: newValues.namespaces,
								'asset-names': newValues.assets,
								'cluster-ids': newValues.clusters,
								protocol: newValues.protocol,
							});
						}}
					/>
					<UniversalSearchInLayout />
				</div>
			)}

			<FilterResult
				entityLabel="endpoint"
				loading={status === 'loading'}
				total={total}
				totalFiltered={total_filtered}
			/>

			<GridTable dataTest="endpoints-table">
				<EnhancedTableHead
					config={
						props.asset
							? tableConfig.filter(({ id }) => id !== 'asset_name' && id !== 'namespace')
							: tableConfig
					}
					order={params.sort.order}
					orderBy={params.sort.orderBy}
					filterBy={{ url: params.endpoints, host: params.hosts, asset_name: params.assets }}
					onRequestSort={onSortUpdate}
					onRequestFilter={onFilterUpdate}
					rowClassname={props.asset ? styles.oneAssetRowContainer : styles.rowContainer}
				/>

				<Preloader isLoading={status === 'initial' || status === 'loading'}>
					<GridBody data-test="endpoints-list">
						<>
							{data.length ? (
								data.map((endpoint) => (
									<EndpointItem
										endpoint={endpoint}
										key={endpoint.id}
										dataTest="endpoints-table-row"
										oneAssetMode={!!props.asset}
										search={params.search}
									/>
								))
							) : hasFilter ? (
								<ResetFilters onReset={onResetFilters} />
							) : (
								<NoContent
									type={props.asset ? 'endpointsV2OneAsset' : 'endpointsV2'}
									className={props.asset ? styles.oneAssetRowContainer : styles.rowContainer}
								>
									<NewGatewayBlock />
								</NoContent>
							)}

							<LoadMoreButton
								show={hasMoreData}
								loading={status === 'loadingMore'}
								request={endpointsModel.fetchMoreFx}
							/>
						</>
					</GridBody>
				</Preloader>
			</GridTable>
		</>
	);
}

export default Endpoints;
