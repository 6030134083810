import { Typo } from 'components/typography/Typo';
import { s3BucketsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import styles from '../SampleViewers/index.module.css';

interface WashedClusterProps {
	state: { name: string } | null;
	id: string;
}

function WashedCluster({ state, id }: WashedClusterProps) {
	return (
		<>
			<Header>
				<Header.Breadcrumbs steps={s3BucketsSteps} finalStep="Cluster" />

				<Header.Title>{state ? state.name : `Data sample with ID ${id}`}</Header.Title>
			</Header>

			<Typo variant="D/Regular/Body-S" color="secondary" className={styles.noSample}>
				No current cluster available, as there has been
				<br /> no traffic in the last 7 days.
			</Typo>
		</>
	);
}

export type { WashedClusterProps };
export { WashedCluster };
