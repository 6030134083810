import { useEffect, useState } from 'react';
import { ConfirmModal, getConfirmation } from 'components/ConfirmModal';
import Button from 'components/form/Button';
import Preloader from 'components/Preloader';
import { enqueueSnackbar } from 'components/Snackbar';
import { RouterLink } from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import { deleteWebhookIntegration, getWebhookIntegration } from 'models/integrations/webhook/api';
import { WebhookSettings } from 'models/integrations/webhook/dto';
import { PATHS } from 'services/router';
import integrationStyles from '../index.module.css';
import WebhookSVG from '../source/webhook.svg';
import ConnectionStatus from './ConectionStatus';

const initialSettings: WebhookSettings = {
	status: 'unknown',
	secret_token: '',
	url: '',
	notify: [],
	is_enabled: false,
};

function Webhook() {
	const [isLoading, setLoading] = useState(true);
	const [isDisconnectLoading, setDisconnectLoading] = useState(false);
	const [settings, setSettings] = useState<WebhookSettings>(initialSettings);

	const connected = settings.is_enabled;

	useEffect(() => {
		getWebhookIntegration()
			.then((webhookSettings) => {
				setSettings(webhookSettings);
				setLoading(false);
			})
			.catch(() => {
				enqueueSnackbar('Something went wrong');
			});
	}, []);

	async function confirmDisconnect() {
		const confirmResolveModal = (
			<ConfirmModal
				title="Disconnect Webhook"
				confirmProps={{
					children: 'Disconnect',
				}}
			>
				If you disconnect Webhook, you will stop receiving notifications of events in your Webhook.
			</ConfirmModal>
		);

		return await getConfirmation(confirmResolveModal);
	}

	async function disconnect() {
		const confirmed = await confirmDisconnect();

		if (!confirmed) return;

		setDisconnectLoading(true);

		deleteWebhookIntegration()
			.then(getWebhookIntegration)
			.then((setting) => {
				setSettings(setting);
				setDisconnectLoading(false);
			})
			.catch(() => {
				enqueueSnackbar('Something went wrong');
			});
	}

	const actionButtons = connected ? (
		<>
			<RouterLink to={PATHS.INTEGRATIONS_WEBHOOK}>
				<Button
					size="small"
					color="secondary"
					data-test="webhook-edit"
					disabled={isDisconnectLoading}
				>
					Edit
				</Button>
			</RouterLink>

			<Button
				size="small"
				color="ghost"
				onClick={disconnect}
				data-test="webhook-disconnect"
				loading={isDisconnectLoading}
			>
				Disconnect
			</Button>
		</>
	) : (
		<RouterLink to={PATHS.INTEGRATIONS_WEBHOOK}>
			<Button size="small" color="primary" data-test="webhook-connect">
				Connect
			</Button>
		</RouterLink>
	);

	return (
		<div className={integrationStyles.block} data-test="webhook-integration">
			<Preloader isLoading={isLoading}>
				<div className={integrationStyles.top}>
					<WebhookSVG />

					{settings.is_enabled && <ConnectionStatus status={settings.status} />}
				</div>

				<div className={integrationStyles.middle}>
					<div className={integrationStyles.name}>
						<Typo variant="D/Medium/H100-Header">Webhook</Typo>
					</div>

					<Typo variant="D/Regular/Body-S" className={integrationStyles.description}>
						Send notifications to a Webhook.
					</Typo>
				</div>

				<div className={integrationStyles.bottom}>{actionButtons}</div>
			</Preloader>
		</div>
	);
}

export default Webhook;
