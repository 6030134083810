import cn from 'classnames';
import { useStore } from 'effector-react';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { Typo } from 'components/typography/Typo';
import { gatewaysNamesById } from 'models/gateways/store';
import { PATHS } from 'services/router';
import { MapAsset } from '../../../index';
import CloseButton from '../CloseButton';
import commonStyles from '../index.module.css';
import Label from '../Label';

type Props = {
	id: MapAsset['cluster_id'];
};

function ClusterDetails(props: Props) {
	const { id } = props;

	const gateways = useStore(gatewaysNamesById);

	const name = gateways[id] || id;

	return (
		<div
			className={cn(commonStyles.container, commonStyles.sidePaddings)}
			data-test="data-map-details-cluster"
		>
			<div className={commonStyles.header}>
				<Typo variant="D/Medium/H100-Header">{name}</Typo>

				<CloseButton />
			</div>

			<div className={commonStyles.detailsGrid}>
				<Label title="Resource">K8s cluster</Label>
			</div>

			<Button
				color="tertiary"
				size="small"
				endIcon={<Icon name="Open/Regular" size={20} />}
				fullWidth
				href={PATHS.SENSORS_DIM}
				target="_blank"
			>
				View detailed info
			</Button>
		</div>
	);
}

export default ClusterDetails;
