import cn from 'classnames';
import React, { KeyboardEvent, useContext, useEffect, useRef, useState } from 'react';
import TextField from 'components/form/TextField';
import styles from './index.module.pcss';
import { StateContext } from './index';

interface InputRuleProps {
	className?: string;
	first?: boolean; // This props needed to determine the width of the first rule of empty rule list
	focused: boolean; // This prop look at the index of rule in the group
	focusRefresher?: string;

	onFocus: (ruleId: number) => void;
	onChange: (value: string, ruleId: number) => void;
	onKeyDown: (e: KeyboardEvent<HTMLInputElement>, value: string, ruleId: number) => void;

	ruleId: number;
	value: string;
}

const EMPTY_WIDTH = 4;
const EMPTY_WIDTH_FIRST = '100%';
const EMPTY_FOCUSED_WIDTH = 16;

export function RuleItem({
	className,
	first,
	focused,
	focusRefresher,
	onFocus,
	onChange,
	onKeyDown,
	ruleId,
	value,
}: InputRuleProps) {
	const { state } = useContext(StateContext);

	const inputRef = useRef<HTMLInputElement>(null);
	const valueRef = useRef<HTMLInputElement>(null);
	const [width, setWidth] = useState<number | string>(EMPTY_WIDTH);

	// Determine te width of empty rule
	useEffect(() => {
		if (valueRef?.current?.offsetWidth) {
			setWidth(valueRef?.current?.offsetWidth);
		} else {
			setWidth(
				first ? EMPTY_WIDTH_FIRST : state.editMode === 'values' ? EMPTY_WIDTH : EMPTY_FOCUSED_WIDTH
			);
		}
	}, [value, first]);

	// Set|unset focus in the input
	useEffect(() => {
		if (!focused) {
			inputRef.current?.blur();
		} else {
			inputRef.current?.focus();
		}
	}, [focused, focusRefresher]);

	return (
		<>
			<div ref={valueRef} className={styles.invisibleValue}>
				{value}
			</div>

			<TextField
				placeholder={first ? 'Enter a resource' : ''}
				onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => onKeyDown(event, value, ruleId)}
				className={styles.inputRoot}
				style={{ width }}
				InputProps={{
					inputProps: {
						tabIndex: state.editMode ? 0 : -1,
					},
					inputRef,
					onBlur: () => inputRef.current?.blur(),
					/* Why don’t we use onFocus, onMouseDown, or something similar?
					If we are focused on a rule and then click on the nearest EmptyRule, onMouseDown occurs
					first, then activeId changes, causing the current focus to be canceled and shifting the
					elements. As a result, the place where we initially clicked changes, and the cursor moves
					to the last rule.
					So we don't handle onMouseDown event, but at this moment we make caret of input
					transparent. (See unfocusedEmpty state of ColoredWrapper)
					 */
					onMouseUp: () => {
						onFocus(ruleId);
					},
					classes: {
						root: styles.inputRoot,
						input: cn(className, styles.input, {
							[styles.emptyInput]: value.length === 0,
							[styles.emptyServiceInput]: value.length === 0 && state.editMode === 'values',
						}),
						notchedOutline: cn(styles.notchedOutline),
					},
				}}
				value={value}
				onChange={({ target }) => onChange(target.value, ruleId)}
				helperText={null}
			/>
		</>
	);
}
