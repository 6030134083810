import { useCallback, useMemo } from 'react';
import Select from 'components/form/Select';
import DropdownButton from 'components/form/Select/DropdownButton';
import { Protocol, protocolLabel } from 'models/endpointsV2/dto';
import { FilterPropsBase } from '../model';

type ProtocolValue = '' | Protocol;

const options: { id: ProtocolValue; name: string }[] = [
	{ id: 'http', name: protocolLabel['http'] },
	{ id: 'grpc', name: protocolLabel['grpc'] },
];

type Props = FilterPropsBase & {
	value: ProtocolValue;
	onChange: (newValue: ProtocolValue) => void;
};

function ProtocolFilter({ defaultOpen, fixed, onChange, onClose, resetFilter, value }: Props) {
	const selected = useMemo(() => options.find((option) => !!value && option.id === value), [value]);

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<Select
			dataTest="select-protocol"
			defaultOpen={defaultOpen}
			label={{ primary: 'Protocol', secondary: selected?.name ?? '' }}
			options={options}
			onChange={(data) => onChange(data!.id)}
			onClose={onClose}
			render={{ dropdownButton }}
			value={selected}
		/>
	);
}

export default ProtocolFilter;
export type { ProtocolValue };
