import { get } from 'api/Api';
import checkResponse from 'services/api/checkResponse';
import { NamespaceLabels } from './dto';
import { namespaceLabelsExample } from './examples';

// This api has query parameters:
//   namespaces=ns1,ns2
//   cluster_ids=1,2,3
// We don't use them in frontend (yet?)

async function getNamespaces() {
	return get<NamespaceLabels>('v2/namespaces/labels').then(checkResponse(namespaceLabelsExample));
}

export { getNamespaces };
