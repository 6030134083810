import { ReactNode } from 'react';
import Accordion from 'components/Accordion';
import AccordionDetails from 'components/AccordionDetails';
import AccordionSummary from 'components/AccordionSummary';
import parentStyles from '../index.module.pcss';
import styles from './index.module.css';

type Props = {
	title: string;
	rows: { [key: string]: ReactNode };
};

export const EventDetailsAccordion = ({ rows, title }: Props) => {
	return (
		<Accordion defaultExpanded={true}>
			<AccordionSummary className={styles.accordionSummary}>{title}</AccordionSummary>
			<AccordionDetails className={styles.accordionDetails}>
				<div className={parentStyles.detailsGrid}>
					{Object.entries(rows).map(([key, value]) => (
						<>
							<span className={parentStyles.detailsLabel}>{key}</span>
							<span data-test={`event-${key.replaceAll(' ', '-').toLowerCase()}`}>{value}</span>
						</>
					))}
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
