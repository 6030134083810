import {
	DataDistributionByRegions,
	DataStoresOverview,
	DiscoveredDataTypeGroups,
	PolicyEventsByCategory,
	PolicyEventsByName,
} from './dto';

const coefReg = 147820 / 94;
function cReg(n: number) {
	return Math.round(n * coefReg);
}
const dataDistributionByRegionsExample: DataDistributionByRegions = {
	regions: [
		/*
		{
			region: 'Asia Pacific (Tokyo) (manual)',
			assets_count: 43148,
			assets_percent: 28.8,
		},
		{
			region: 'dar-sens (manual)',
			assets_count: 36256,
			assets_percent: 24.2,
		},
		{
			region: 'Ireland (manual)',
			assets_count: 22023,
			assets_percent: 14.7,
		},
		{
			region: 'Production (manual)',
			assets_count: 19027,
			assets_percent: 12.7,
		},
		{
			region: 'Europe (Stockholm)',
			assets_count: 16480,
			assets_percent: 11.0,
		},
		{
			region: 'Europe (Ireland)',
			assets_count: 6741,
			assets_percent: 4.5,
		},
		{
			region: 'Stage (manual)',
			assets_count: 3445,
			assets_percent: 2.3,
		},
		{
			region: 'US East (N. Virginia)',
			assets_count: 2546,
			assets_percent: 1.7,
		},
		*/
		{
			region: 'Датацентр ЮГ',
			assets_count: cReg(25),
			assets_percent: (25 * 100) / 94,
		},
		{
			region: 'Датацентр МСК',
			assets_count: cReg(20),
			assets_percent: (20 * 100) / 94,
		},
		{
			region: 'Датацентр Екат',
			assets_count: cReg(18),
			assets_percent: (18 * 100) / 94,
		},
		{
			region: 'Датацентр Екат-2',
			assets_count: cReg(13),
			assets_percent: (13 * 100) / 94,
		},
		{
			region: 'Датацентр Восток',
			assets_count: cReg(8),
			assets_percent: (8 * 100) / 94,
		},
		{
			region: 'Yandex Cloud',
			assets_count: cReg(5),
			assets_percent: (5 * 100) / 94,
		},
		{
			region: 'Датацентр Запад',
			assets_count: cReg(3),
			assets_percent: (3 * 100) / 94,
		},
		{
			region: 'Датацентр Север',
			assets_count: cReg(2),
			assets_percent: (2 * 100) / 94,
		},
	],
	total_assets: 147820,
};

const dataStoresOverviewExample: DataStoresOverview = {
	stores: [
		{
			type: 'sql_db_database',
			data_count: 4530,
			data_with_sensitive_count: 2976,
			data_with_sensitive_percent: 65.7,
			size: 161061261605,
		},
		{
			type: 's3_bucket',
			data_count: 2480,
			data_with_sensitive_count: 701,
			data_with_sensitive_percent: 28.26,
			size: 133217699020,
		},
		{
			type: 'kafka_instance',
			data_count: 2245,
			data_with_sensitive_count: 548,
			data_with_sensitive_percent: 24.4,
			size: 105226668759,
		},
		{
			type: 'nosql_db_database',
			data_count: 2014,
			data_with_sensitive_count: 184,
			data_with_sensitive_percent: 9.14,
			size: 87016037416,
		},
	],
	total_count: 99,
	with_sensitive_count: 36,
	total_scanned_size: 486521666800,
};

const coefDt = 10066 / 805;
const discoveredDataTypeGroupsExample: DiscoveredDataTypeGroups = {
	data_types: [
		// PII
		{
			data_type: 52,
			data_fields_percent: (483 * 100) / 805,
			data_fields_count: Math.round(483 * coefDt),
		},
		// PCI
		{
			data_type: 53,
			data_fields_percent: (23 * 100) / 805,
			data_fields_count: Math.round(23 * coefDt),
		},
		// Dev secrets
		{
			data_type: 233,
			data_fields_percent: (299 * 100) / 805,
			data_fields_count: Math.round(299 * coefDt),
		},
		// Custom
		/*
		{
			data_type: 214,
			data_fields_percent: 3.6,
			data_fields_count: 0,
		},
		*/
	],
	total_data_fields: 10066,
};

const policyEventsByCategoryExample: PolicyEventsByCategory = {
	categories: [
		{
			name: 'Data at risk',
			events_count: 11,
		},
		{
			name: 'Misplaced data',
			events_count: 9,
		},
		{
			name: 'Misconfigurations',
			events_count: 3,
		},
	],
	total_count: 23,
};

const policyEventsByNameExample: PolicyEventsByName = {
	policies: [
		{
			id: 0,
			name: 'PII User Policy Name',
			events_count: 5,
		},
		{
			id: 0,
			name: 'PCI User Policy',
			events_count: 3,
		},
		{
			id: 0,
			name: 'S3 Policy',
			events_count: 2,
		},
		{
			id: 0,
			name: 'Developer secrets',
			events_count: 1,
		},
	],
};

export {
	dataDistributionByRegionsExample,
	dataStoresOverviewExample,
	discoveredDataTypeGroupsExample,
	policyEventsByCategoryExample,
	policyEventsByNameExample,
};
