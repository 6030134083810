import { PolicyList, PolicyItemWithoutRules, DIMPolicyItem, DARPolicyItem } from './dto';

const dimPolicyItemExample: DIMPolicyItem = {
	id: 1,
	type: 'dim',
	name: 'Policy Card for bank card data',
	description: 'Policy\nDescription',
	tags: ['PCI DSS'],
	data_types: [7],
	is_active: false,
	allow_external_transfer: false,
	allowed_external_assets: [667],
	allow_encrypted_network_only: true,
	created_at: 1640009456607,
	updated_at: 1646899054009,
	rules: [
		[
			{
				type: 'namespace',
				operator: 'is',
				key: '',
				values: ['transact-controller', 'dev-fin-agent'],
			},

			{
				type: 'namespace_label',
				operator: 'is_set',
				key: 'PCI/DSS',
				values: [],
			},
		],
		[
			{
				type: 'asset',
				operator: 'is',
				key: '',
				values: ['fin-monitoring'],
			},
		],
	],
};

const darPolicyItemExample: DARPolicyItem = {
	id: 2,
	type: 'dar',
	name: 'Emails storage policy',
	description: '',
	tags: ['EU'],
	data_types: [4],
	is_active: false,
	allow_unencrypted_s3_buckets: false,
	allow_public_s3_buckets: false,
	allow_unencrypted_rds: true,
	created_at: 1640009456607,
	updated_at: 1646899054009,
	rules: [[{ type: 's3_bucket', operator: 'is', values: ['mailbox'], key: '' }]],
};

const networkPolicyItemExample: unknown = {}; // TODO implement me

const policiesWithoutRules: PolicyItemWithoutRules[] = [
	dimPolicyItemExample,
	darPolicyItemExample,
].map((policyItem) => {
	// We don't need 'rules' (and some other variables), just excluding them.
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { rules, ...policyItemWithoutRules } = policyItem;

	return policyItemWithoutRules;
});

const policyListExample: PolicyList = {
	policies: policiesWithoutRules,
	total: 2,
};

export { dimPolicyItemExample, darPolicyItemExample, networkPolicyItemExample, policyListExample };
