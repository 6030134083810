import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import MultiSelect from 'components/form/MultiSelect';
import DropdownButton from 'components/form/Select/DropdownButton';
import { ClusterGeoLocationRegionItem } from 'models/clusterGeoLocations/dto';
import { onceFx } from 'models/modelUtils/onceFx';
import { getPolicyListFx } from 'models/policiesV2/effects';
import { policiesTagList } from 'models/policiesV2/store';
import { FilterPropsBase } from '../model';

type Option = {
	name: ClusterGeoLocationRegionItem['description'];
	id: ClusterGeoLocationRegionItem['keyword'];
};

type Props = FilterPropsBase & {
	value: ClusterGeoLocationRegionItem['keyword'][];
	onChange: (newValue: ClusterGeoLocationRegionItem['keyword'][]) => void;
};

function TagFilter({ defaultOpen, fixed, onChange, onClose, resetFilter, value }: Props) {
	const tags = useStore(policiesTagList);

	useEffect(() => {
		onceFx(getPolicyListFx);
	}, []);

	const groupedValues = useMemo(() => {
		return tags.map((tag) => {
			return { id: tag, name: tag };
		});
	}, [tags]);

	const values = useMemo(() => {
		return groupedValues.filter((_value) => value.includes(_value.id));
	}, [value, groupedValues]);

	const handleChange = (options: Option[]) => {
		onChange(options.map((option) => option.id));
	};

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<MultiSelect
			defaultOpen={defaultOpen}
			label={{ primary: 'Policy tag', secondary: `${values.length || ''}` }}
			options={groupedValues}
			value={values}
			onChange={handleChange}
			onClose={onClose}
			render={{ dropdownButton }}
			hasSearch
			hasApplyButton
		/>
	);
}

export { TagFilter };
