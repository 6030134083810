import cn from 'classnames';
import React, { Fragment, KeyboardEvent, useContext } from 'react';
import { Typo } from 'components/typography/Typo';
import { MAIN_INPUT_INDEX, StateContext } from '../.';
import { ColoredWrapper } from '../../ColoredWrapper';
import { RuleItem } from '../RuleItem';
import styles from './index.module.css';

interface Props {
	currentValue: string;
	error?: number[];
	onChange: (value: string, ruleId: number) => void;
	onKeyDown: (event: KeyboardEvent<HTMLInputElement>, value: string, ruleId: number) => void;
	typeValue: string;
	values: string[];
}

const SERVICE_SEPARATOR = '  ,  ';

export function ValuesPart({ currentValue, error, onChange, onKeyDown, typeValue, values }: Props) {
	const { state, setState } = useContext(StateContext);

	if (
		state.editMode === 'values' ||
		state.editMode === 'key' ||
		(!state.editMode && typeValue.length > 0)
	) {
		return (
			<>
				{values.map((value, valueIndex) => (
					<ColoredWrapper
						key={valueIndex}
						className={cn(styles.container, {
							[styles.last]: !state.editMode && valueIndex === values.length - 1,
						})}
					>
						<RuleItem
							className={cn({
								[styles.error]: error?.includes(valueIndex),
							})}
							focused={state.idActiveRule === valueIndex}
							onChange={onChange}
							onFocus={() => {
								setState((prevState) => ({
									...prevState,
									editMode: 'values',
									idActiveRule: valueIndex,
								}));
							}}
							onKeyDown={onKeyDown}
							ruleId={valueIndex}
							value={value}
						/>
						{
							/* Display SERVICE_SEPARATOR after every rule, but only if the next rule is exist */
							valueIndex !== values.length - 1 ||
							/* When we're unfocused we shouldn't see last SERVICE_SEPARATOR */
							(values.length !== 0 && state.editMode === 'values')
								? SERVICE_SEPARATOR
								: null
						}
					</ColoredWrapper>
				))}

				{(!state.editMode && typeValue.length !== 0 && values.length === 0) ||
				(state.isLabelType && state.editMode === 'key' && values.length === 0) ? (
					<ColoredWrapper className={styles.anyLabel}>
						<Typo
							component="span"
							variant="D/Medium/Body-S"
							color="secondary"
							onClick={() =>
								setState((prevState) => ({
									...prevState,
									idActiveRule: MAIN_INPUT_INDEX,
									editMode: 'values',
								}))
							}
						>
							Any
						</Typo>
					</ColoredWrapper>
				) : null}

				{state.editMode === 'values' ? (
					<ColoredWrapper className={styles.last}>
						<RuleItem
							className={cn({
								[styles.error]: error?.includes(MAIN_INPUT_INDEX),
							})}
							focused={state.idActiveRule === MAIN_INPUT_INDEX}
							focusRefresher={typeValue + values.length + currentValue}
							onChange={onChange}
							onFocus={() =>
								setState((prevState) => ({
									...prevState,
									idActiveRule: MAIN_INPUT_INDEX,
									editMode: 'values',
								}))
							}
							onKeyDown={onKeyDown}
							ruleId={MAIN_INPUT_INDEX}
							value={currentValue}
						/>
					</ColoredWrapper>
				) : null}
			</>
		);
	} else return null;
}
