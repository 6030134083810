import cn from 'classnames';
import { ActiveRule } from '../PolicyBuilder';
import { AND_OPERATOR } from '../PolicyBuilder/helpers.ts';
import { RuleItemArray } from '../RuleList';
import styles from './index.module.pcss';

interface Props {
	activeRule: ActiveRule | null;
	error: boolean;
	groupId: number;
	onClick: (value: RuleItemArray, groupId: number) => void;
	value: RuleItemArray;
}

export function Operator({ activeRule, groupId, error, value, onClick }: Props) {
	return (
		<div
			onClick={() => onClick(value, groupId)}
			className={cn(styles.container, {
				[styles.active]: activeRule?.index === value.index,
				[styles.andOperator]: value.operator === AND_OPERATOR,
				[styles.error]: error,
			})}
			data-group={groupId}
		>
			{value.operator}
		</div>
	);
}
