import { useStore } from 'effector-react';
import { useState } from 'react';
import { Tab, TabPanel, Tabs } from 'components/Tabs';
import { Typo } from 'components/typography/Typo';
import { AssetGroupListItem } from 'models/assetsGroups/dto';
import { assetGroupsList } from 'models/assetsGroups/store';
import CloseButton from '../CloseButton';
import commonStyles from '../index.module.css';
import Details from './Details';
import Summary from './Summary';

type Props = {
	groupId: AssetGroupListItem['id'];
};

function GroupDetails(props: Props) {
	const [tab, setTab] = useState<'summary' | 'details'>('summary');

	const { groupId } = props;
	const groups = useStore(assetGroupsList);
	const group = groups.find((g) => g.id === groupId);
	if (!group) return null;

	return (
		<div className={commonStyles.container} data-test="data-map-details-group">
			<div className={commonStyles.sidePaddings}>
				<div className={commonStyles.header}>
					<Typo variant="D/Medium/H100-Header">{group.name}</Typo>

					<CloseButton />
				</div>

				<Tabs value={tab} onChange={setTab}>
					<Tab value="summary" label="Summary" />
					<Tab value="details" label="Details" />
				</Tabs>
			</div>

			<TabPanel value={tab} index="summary" className={commonStyles.tabPanel}>
				<Summary group={group} />
			</TabPanel>

			<TabPanel value={tab} index="details" className={commonStyles.tabPanel}>
				<Details groupId={groupId} />
			</TabPanel>
		</div>
	);
}

export default GroupDetails;
