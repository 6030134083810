import Big from 'big.js';
import { ChartArea, ChartItem, Scale } from 'chart.js';
import cn from 'classnames';
import { useStore } from 'effector-react';
import { useEffect, useState, MouseEvent } from 'react';
import { Chart } from 'react-chartjs-2';
import NewGatewayBlock from 'components/NewGatewayBlock';
import { Typo } from 'components/typography/Typo';
import { EventJson } from 'models/eventsV2/dto';
import { getRecentActivityFx } from 'models/recentActivity/effects';
import { recentActivityStore } from 'models/recentActivity/store';
import { dayjs } from 'services/dayjs';
import { toAbbreviatedNumber } from 'services/numbers';
import { externalTooltipHandler } from './ChartTooltip';
import { ChartTooltip } from './ChartTooltip/ChartTooltip';
import styles from './index.module.pcss';
import WarnSVG from './warnWithShadow.svg';

interface IGetData {
	labels: string[];
	apiCalls: number[];
}

const getGradient = (
	ctx: CanvasRenderingContext2D,
	chartArea: ChartArea,
	{ startColor, endColor }: { startColor: string; endColor: string }
) => {
	const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
	gradient.addColorStop(0, endColor);
	gradient.addColorStop(1, startColor);

	return gradient;
};

export const getData = ({ labels, apiCalls }: IGetData) => ({
	options: {
		legend: {
			display: false,
		},
	},
	labels: ['', ...labels, ''],
	datasets: [
		{
			type: 'line' as const,
			label: 'API CALLS',
			borderColor: '#608CE6', // --color-ocean-300
			borderWidth: 2,
			backgroundColor(context: Scale) {
				const { chart } = context;
				const { ctx, chartArea } = chart;

				if (!chartArea) {
					// This case happens on initial chart load
					return null;
				}

				return getGradient(ctx, chartArea, {
					startColor: 'rgba(238, 245, 254, 1)',
					endColor: 'rgba(238, 245, 254, 0)',
				});
			},
			fill: true,
			data: [apiCalls[0], ...apiCalls, apiCalls[apiCalls.length - 1]],
			apiCalls,
			labels,
			yAxisID: 'apiCallsY',
		},
	],
});

const CHART_HEIGHT = 183;
const X_LINES_COUNT = 4;
const X_LINES_COUNT_RATIO = 1.2; // so that the max value bar doesn't touch upper x-line
const DEFAULT_STEP_SIZE_VALUE = 0.3; // if max(value) === 0, 0.3 will draw 4 lines

const CHART_LENGTH_IN_MS = 9 * 24 * 60 * 60 * 1000; // how many milliseconds in inner chart (9 full days)

type Props = {
	events: null | EventJson[];
	hovered: number;
	onMouseEnter: (e: MouseEvent<HTMLElement>) => void;
	onMouseLeave: (e: MouseEvent<HTMLElement>) => void;
};

export const ApiCallsChart = (props: Props) => {
	const { events, hovered, onMouseEnter, onMouseLeave } = props;

	const { api_calls } = useStore(recentActivityStore);
	const [isData, handleDataCondition] = useState(false);
	const [step, setStep] = useState(DEFAULT_STEP_SIZE_VALUE);

	useEffect(() => {
		getRecentActivityFx();
	}, []);

	useEffect(() => {
		const labels = api_calls.daily.map(({ date }) =>
			dayjs(date).format('MMM D').toLocaleUpperCase()
		);
		const apiCalls = api_calls.daily.map(({ value }) => value);

		const maxApiCalls = Math.max(...apiCalls);
		handleDataCondition(!!maxApiCalls);

		const stepSize =
			new Big(maxApiCalls).div(X_LINES_COUNT).toNumber() * X_LINES_COUNT_RATIO ||
			DEFAULT_STEP_SIZE_VALUE;

		setStep(stepSize);

		const customScales = {
			apiCallsY: {
				// 'position' needed to display chart ¯\_(ツ)_/¯
				position: 'left',
				min: 0,
				max: maxApiCalls * X_LINES_COUNT_RATIO || X_LINES_COUNT_RATIO,
				ticks: {
					stepSize,
					display: false,
				},
				grid: {
					color: '#E7EBF2', // light rock 50
					lineWidth: 1,
				},
			},
		};

		const ctx = document.getElementById('apiCallsChart');
		const apiCallsChart = new Chart(ctx as ChartItem, {
			type: 'bar',
			// TODO SMAT-2878: The problem about backgroundColor as gradient, possible cause the Chart is Bar and not Line
			// @ts-ignore
			data: getData({
				labels,
				apiCalls,
			}),
			options: {
				// @ts-ignore
				categoryPercentage: 1,
				barPercentage: 0.9,

				responsive: true,
				maintainAspectRatio: false,
				aspectRatio: 6,

				layout: {
					padding: {
						left: -10,
					},
				},

				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						enabled: false,
						position: 'average',
						// @ts-ignore
						external: externalTooltipHandler,
					},
				},

				interaction: {
					mode: 'index',
					intersect: false,
				},

				// @ts-ignore
				scales: {
					...customScales,
					x: {
						grid: {
							display: false,
						},
						ticks: {
							color: '#6A768F', // light rock 400
							font: {
								size: 10,
								family: 'Inter',
							},
						},
					},
					y: {
						display: false,
						beginAtZero: true,
						ticks: {
							display: false,
							sampleSize: 1,
						},
					},
				},

				elements: {
					point: {
						radius: 0,
						hoverRadius: isData ? 8 : 0,
						hoverBorderWidth: 2,
						hoverBackgroundColor: '#fff', // --color-background-white
						hoverBorderColor: '#608CE6', // --color-ocean-300
					},
				},
			},
		});

		return () => {
			apiCallsChart.destroy();
		};
	}, [api_calls]);

	return (
		<div className={styles.overflowBustingWrapper}>
			<div className={styles.verticalScale}>
				<Typo variant="D/Regular/Meta-S-CAP" color="secondary">
					{toAbbreviatedNumber(step * 4, 1)}
				</Typo>
				<Typo variant="D/Regular/Meta-S-CAP" color="secondary">
					{toAbbreviatedNumber(step * 3, 1)}
				</Typo>
				<Typo variant="D/Regular/Meta-S-CAP" color="secondary">
					{toAbbreviatedNumber(step * 2, 1)}
				</Typo>
				<Typo variant="D/Regular/Meta-S-CAP" color="secondary">
					{toAbbreviatedNumber(step, 1)}
				</Typo>
				<Typo variant="D/Regular/Meta-S-CAP" color="secondary">
					0
				</Typo>
			</div>

			<div className={styles.container} data-test="api-calls-chart-container">
				<div className={styles.chartContainerContent}>
					<canvas height={CHART_HEIGHT} id="apiCallsChart" />

					{events?.map((event, i) => {
						const left =
							((event.created_at - (api_calls.daily[0].date - 24 * 60 * 60 * 1000)) * 100) /
							CHART_LENGTH_IN_MS;

						return (
							<span
								className={cn(styles.event, hovered === i && styles.hoveredEvent)}
								style={{ left: `${left}%` }}
								key={i}
								data-i={i}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
								data-test="api-call-icon"
							>
								<WarnSVG />
							</span>
						);
					})}
				</div>

				{!isData && <NewGatewayBlock className={styles.emptyData} />}
			</div>

			{isData && <ChartTooltip />}
		</div>
	);
};
