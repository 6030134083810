import { ChangeEventHandler, useState } from 'react';
import Button from 'components/form/Button';
import Label from 'components/Label';
import Switch from 'components/Switch';
import { goBackByDefault } from 'services/history';
import { PATHS } from 'services/router';
import styles from './index.module.css';

type Props = {
	isNew: boolean;
	isError: boolean;
	saveForm: () => void;
	isActive: boolean;
	setActive: ChangeEventHandler<HTMLInputElement>;
};

function PolicyFormFooter(props: Props) {
	const { isNew, isError, saveForm, isActive, setActive } = props;

	const [isLoading, setIsLoading] = useState(false);

	return (
		<div className={styles.container}>
			<Button
				color="secondary"
				size="medium"
				onClick={() => goBackByDefault(PATHS.POLICY_LIST)}
				data-test="policy-form-cancel"
				className={styles.button}
			>
				Cancel
			</Button>

			<Button
				data-test="policy-form-create-save"
				type="submit"
				color="primary"
				size="medium"
				onClick={async () => {
					setIsLoading(true);
					await saveForm();
					setIsLoading(false);
				}}
				disabled={isLoading || isError}
				className={styles.saveButton}
			>
				Save
			</Button>

			{isNew && (
				<Label className={styles.switch}>
					<Switch checked={isActive} onChange={setActive} />
					Enable after saving
				</Label>
			)}
		</div>
	);
}

export { PolicyFormFooter };
