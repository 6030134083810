import cn from 'classnames';
import ButtonIcon from 'components/ButtonIcon';
import { Typo } from 'components/typography/Typo';
import { nextId, TransformedRuleItem } from '..';
import RuleRowItem from '../RuleRowItem';
import styles from './index.module.css';

type Props = {
	type: 'ip' | 'userAgent';
	items: TransformedRuleItem[];
	onChange: (items: TransformedRuleItem[]) => void;
	prefix?: string;
};

function RuleRow(props: Props) {
	const { type, items, onChange, prefix } = props;

	function handleChange(item: TransformedRuleItem) {
		const result = items.map((oldItem) => (oldItem.id === item.id ? item : oldItem));

		onChange(result);
	}

	function handleCreateAfter(item: TransformedRuleItem) {
		const result = items.flatMap((oldItem) =>
			item !== oldItem ? oldItem : [item, { id: nextId(), value: '' }]
		);

		onChange(result);
	}

	function handleDelete(item: TransformedRuleItem) {
		const result = items.filter((oldItem) => item !== oldItem);

		onChange(result);
	}

	function handlePasteMultiple(item: TransformedRuleItem, values: string[]) {
		const result = items.flatMap((oldItem) =>
			item !== oldItem ? oldItem : values.map((value) => ({ id: nextId(), value }))
		);

		onChange(result);
	}

	function handleCreateLast() {
		const result = items.concat({ id: nextId(), value: '' });

		onChange(result);
	}

	function handleDeleteRow() {
		onChange([]);
	}

	const label = type === 'ip' ? 'IP' : 'user agent';

	return (
		<div className={styles.wrapper} data-test={`asset-rules-rule-dynamic-${type}`}>
			{!!prefix && (
				<Typo component="span" variant="D/Medium/Body-S">
					{prefix}
				</Typo>
			)}

			<div className={styles.multilineRow}>
				<div className={styles.roundedSpan}>
					<Typo variant="D/Medium/Body-S" color="secondary" className={cn(styles.label)}>
						{label}:
					</Typo>
				</div>

				{items.map((item, i) => {
					const isLast = i === items.length - 1;

					// Comma should stick to an item to its left and not break off at line break. So both are placed in the same container.
					return (
						<div key={item.id} className={styles.roundedSpan}>
							<RuleRowItem
								type={type}
								rule={item}
								onChange={handleChange}
								onCreateAfter={handleCreateAfter}
								onDelete={handleDelete}
								onPasteMultiple={handlePasteMultiple}
							/>

							{!isLast && (
								<Typo
									variant="D/Medium/Body-S"
									component="span"
									color="secondary"
									className={styles.comma}
								>
									,
								</Typo>
							)}
						</div>
					);
				})}

				<p className={styles.buttonsContainer}>
					<ButtonIcon
						className={styles.addDeleteButton}
						dataTest="asset-rules-add-rule-button"
						icon="Add/Filled"
						onClick={handleCreateLast}
						size="XS"
					/>

					<ButtonIcon
						className={styles.addDeleteButton}
						dataTest="asset-rules-delete-list-button"
						icon="Delete/Filled"
						onClick={handleDeleteRow}
						size="XS"
					/>
				</p>
			</div>
		</div>
	);
}

export default RuleRow;
