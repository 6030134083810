import { ReactNode } from 'react';
import { RouterLink } from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import styles from './index.module.css';

type Props = {
	title: string;
	counter?: number;
	seeAllLink?: string;
	children: ReactNode;
	flex?: number | null; // default 1; `null` for removing flex altogether
	noPadding?: boolean;
	dataTest?: string;
};

function WidgetContainer(props: Props) {
	const { title, counter, seeAllLink, children, flex = 1, noPadding = false, dataTest } = props;

	const style = typeof flex === 'number' ? { flex } : undefined;

	return (
		<div
			className={noPadding ? styles.widgetNoPadding : styles.widget}
			style={style}
			data-test={dataTest}
		>
			<div className={styles.header}>
				<Typo variant="D/Medium/Meta-CAP" color="secondary">
					{title}
				</Typo>

				{!!counter && (
					<Typo variant="D/Regular/Meta" color="secondary">
						{counter}
					</Typo>
				)}

				{seeAllLink && (
					<Typo variant="D/Medium/Meta" color="secondary" className={styles.seeAllLink}>
						<RouterLink inherit to={seeAllLink} setPageParams>
							See all
						</RouterLink>
					</Typo>
				)}
			</div>

			{children}
		</div>
	);
}

export { WidgetContainer };
