import { MouseEvent, MutableRefObject, ReactElement, useState, useCallback } from 'react';
import { AnyOption } from 'components/form/Select';
import DropdownButton, { DropdownButtonProps } from 'components/form/Select/DropdownButton';
import Label, { LabelProps } from 'components/form/Select/Label';
import Popover, { PopoverProps } from 'components/form/Select/Popover';
import Search, { SearchProps } from 'components/form/Select/Search';
import ApplyButton, { ApplyButtonProps } from './ApplyButton';
import OptionItem, { OptionItemProps } from './OptionItem';
import OptionList, { OptionListProps } from './OptionList';

type MultiSelectProps<T extends AnyOption> = {
	// Required options
	label: string | { primary: string; secondary: string };
	options: T[];
	onChange: (value: T[], clickedOption: T | null) => void;
	value: T[];

	// Options for extra out-of-the-box functionality
	allOption?: boolean | string;
	dataTest?: string;
	defaultOpen?: boolean;
	dividerAfter?: T[];
	closeRef?: MutableRefObject<Function>;
	hasApplyButton?: boolean;
	hasSearch?: boolean;
	onClose?: () => void;

	// Render overrides for individual components inside MultiSelect
	render?: {
		applyButton?: (props: ApplyButtonProps) => ReactElement | null;
		dropdownButton?: (props: DropdownButtonProps) => ReactElement | null;
		label?: (props: LabelProps) => ReactElement | null;
		optionItem?: (props: OptionItemProps<T>) => ReactElement | null;
		optionList?: (props: OptionListProps<T>) => ReactElement | null;
		popover?: (props: PopoverProps) => ReactElement | null;
		search?: (props: SearchProps) => ReactElement | null;
	};
};

function MultiSelect<T extends AnyOption>(props: MultiSelectProps<T>) {
	const {
		allOption = false,
		closeRef,
		defaultOpen,
		dividerAfter = [],
		hasApplyButton = false,
		hasSearch = false,
		label,
		options,
		onChange,
		onClose,
		render = {},
		value,
	} = props;

	const [open, setOpen] = useState(!!defaultOpen);

	const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
		setDropdownButtonNode(event.currentTarget);
		setOpen(true);
	}, []);
	const handleClose = useCallback(() => {
		setOpen(false);
		onClose?.();
	}, []);

	if (closeRef) {
		closeRef.current = handleClose;
	}

	// Custom ref handling to prevent popover disconnection during re-renders of parent component(s)
	const [dropdownButtonNode, setDropdownButtonNode] = useState<HTMLElement | null>(null);
	const dropdownButtonRef = useCallback((node) => {
		setDropdownButtonNode(node);
	}, []);

	// Composable parts
	const LabelComponent = render.label || Label;
	const DropdownButtonComponent = render.dropdownButton || DropdownButton;
	const OptionItemComponent = render.optionItem || OptionItem;
	const OptionListComponent = render.optionList || OptionList;
	const SearchComponent = render.search || (hasSearch ? Search : () => null);
	const ApplyButtonComponent = render.applyButton || (hasApplyButton ? ApplyButton : () => null);
	const PopoverComponent = render.popover || Popover;

	return (
		<>
			<DropdownButtonComponent onClick={handleOpen} open={open} buttonRef={dropdownButtonRef}>
				<LabelComponent label={label} />
			</DropdownButtonComponent>

			<PopoverComponent anchorEl={open ? dropdownButtonNode : null} onClose={handleClose}>
				<OptionListComponent
					options={options}
					allOption={allOption}
					value={value}
					onChange={onChange}
					onClose={handleClose}
					hasApplyButton={hasApplyButton}
					dividerAfter={dividerAfter}
					renderSearch={SearchComponent}
					renderOption={OptionItemComponent}
					renderApplyButton={ApplyButtonComponent}
				/>
			</PopoverComponent>
		</>
	);
}

export default MultiSelect;
export type { MultiSelectProps };
