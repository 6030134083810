import { MouseEvent, useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import ButtonIcon from 'components/ButtonIcon';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import { enqueueSnackbar } from 'components/Snackbar';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import { Tooltip } from 'components/Tooltip';
import { ExternalLinkButton } from 'components/typography/ExternalLink';
import { Typo } from 'components/typography/Typo';
import { ClusterFileSample } from 'models/s3BucketCluster/dto';
import { DateFormat, dayjs } from 'services/dayjs';
import { lastSeen } from 'services/lastSeen';
import { toFileSize, toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import styles from './index.module.css';

function copyToClipboard(data: string) {
	navigator.clipboard.writeText(data).then(() => {
		enqueueSnackbar('Sample path copied');
	});
}

type Props = {
	sample: ClusterFileSample;
	withEstimated: boolean;
};

function SampleFileTableRow(props: Props) {
	const { sample, withEstimated } = props;
	const {
		id,
		name,
		path,
		size,
		estimated_rows,
		data_types,
		cloud_console_url,
		created_at,
		last_scan_at,
		status,
	} = sample;

	const history = useHistory();

	const lastScanValue = useMemo(
		() => lastSeen({ timestamp: last_scan_at, short: true }),
		[last_scan_at]
	);
	const formattedLocal = dayjs(created_at).format(DateFormat.date);
	const formattedUTC = dayjs(created_at).utc().format(DateFormat.utc);

	function clickHandler() {
		history.push(generatePath(PATHS.S3_FILE_SAMPLE, { sampleId: id }));
	}

	const canClick = !!last_scan_at && !status;

	return (
		<GridRow
			className={withEstimated ? styles.rowContainerWithEstimated : styles.rowContainer}
			onClick={canClick ? clickHandler : undefined}
			border
			hover={canClick}
			data-test="sample-file-table-row"
		>
			<GridCell dataTest="sample-file-table-item-name">
				<Tooltip title={path + name}>
					<Typo variant="D/Medium/Body-S">.../{name}</Typo>
				</Tooltip>
			</GridCell>

			<GridCell align="right" dataTest="sample-file-table-size">
				<Typo variant="D/Regular/Body-S">{toFileSize(size)}</Typo>
			</GridCell>

			{withEstimated && (
				<GridCell align="right" dataTest="sample-file-table-estimated-rows">
					<Typo variant="D/Regular/Body-S">{toLocaleString(estimated_rows)}</Typo>
				</GridCell>
			)}

			<GridCell dataTest="sample-file-table-item-data-types">
				<PiiTypeList data={data_types} />
			</GridCell>

			<GridCell dataTest="sample-file-table-item-created">
				<Tooltip title={formattedUTC} data-test="sample-file-table-item-created-value">
					<Typo variant="D/Regular/Body-S">{formattedLocal}</Typo>
				</Tooltip>
			</GridCell>

			<GridCell dataTest="sample-file-table-item-last-scan">
				{!last_scan_at ? (
					<Tooltip title="Scanning in progress">
						<Icon name="Clock/Filled" size={16} className={styles.grayIcon} />
					</Tooltip>
				) : status ? (
					<Tooltip title={status}>
						<Icon name="Info/Filled" size={16} className={styles.redIcon} />
					</Tooltip>
				) : (
					<Tooltip title={lastScanValue.date} data-test="sample-file-table-item-last-scan-value">
						<Typo variant="D/Regular/Body-S">{lastScanValue.diff}</Typo>
					</Tooltip>
				)}
			</GridCell>

			<GridCell className={styles.actionsCell}>
				<ButtonIcon
					icon="copyLink"
					size="M"
					onClick={(e: MouseEvent<HTMLElement>) => {
						e.stopPropagation();
						copyToClipboard(path + name);
					}}
				/>
				{cloud_console_url && <ExternalLinkButton href={cloud_console_url} />}
			</GridCell>
		</GridRow>
	);
}

export { SampleFileTableRow };
