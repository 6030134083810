import { Typo } from 'components/typography/Typo';
import styles from './index.module.css';

type Props = {
	value: string;
	onEdit: () => void;
};

export function RuleBadge(props: Props) {
	const { value, onEdit } = props;

	return (
		<div className={styles.ruleBadgeContainer}>
			<Typo
				variant="D/Medium/Body-S"
				className={styles.ruleBadge}
				onClick={onEdit}
				data-test="asset-rules-rule-value"
			>
				{value}
			</Typo>

			<Typo variant="D/Medium/Body-S" color="secondary" className={styles.label}>
				/
			</Typo>
		</div>
	);
}
