import { useStore } from 'effector-react';
import { useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import { Tooltip } from 'components/Tooltip';
import { ExternalLinkButton } from 'components/typography/ExternalLink';
import { TypoHighlighted } from 'components/typography/TypoHighlighted';
import { S3BucketItem } from 'models/s3Buckets/dto';
import { s3RegionsByKeyword } from 'models/s3Regions/store';
import { DateFormat, dayjs } from 'services/dayjs';
import { lastSeen } from 'services/lastSeen';
import { PATHS } from 'services/router';
import stylesList from '../index.module.pcss';

type Props = {
	bucket: S3BucketItem;
	search: string;
	dataTest?: string;
};

enum S3BucketPublicStatus {
	'private' = 'Non-public',
	'public' = 'Public',
	'unknown' = 'Unknown',
}

function S3BucketTableRow(props: Props) {
	const { bucket, search, dataTest } = props;
	const {
		id,
		name,
		region,
		cloud_console_url,
		created_at,
		last_checked,
		public_status,
		sensitivity,
		data_types,
	} = bucket;

	const history = useHistory();
	const s3Regions = useStore(s3RegionsByKeyword);

	const lastSeenValue = useMemo(
		() => lastSeen({ timestamp: last_checked, short: true }),
		[last_checked]
	);
	const formattedLocal = dayjs(created_at).format(DateFormat.date);
	const formattedUTC = dayjs(created_at).utc().format(DateFormat.utc);
	const regionDescription = s3Regions[region]?.description || region;

	function clickHandler() {
		const path = generatePath(PATHS.S3_BUCKETS_ITEM, { id });
		history.push(path);
	}

	return (
		<span data-test={dataTest}>
			<GridRow className={stylesList.rowContainer} onClick={clickHandler} border hover>
				<GridCell dataTest="s3-bucket-table-item-name">
					<TypoHighlighted variant="D/Medium/Body-S" search={search}>
						{name}
					</TypoHighlighted>
				</GridCell>

				<GridCell dataTest="s3-bucket-table-item-region">
					<TypoHighlighted variant="D/Regular/Body-S" search={search}>
						{regionDescription}
					</TypoHighlighted>
				</GridCell>

				<GridCell dataTest="s3-bucket-table-item-public">
					{S3BucketPublicStatus[public_status]}
				</GridCell>

				<GridCell dataTest="s3-bucket-table-item-sensitivity">
					<SensitivityChip sensitivity={sensitivity} />
				</GridCell>

				<GridCell dataTest="s3-bucket-table-item-data_types">
					<PiiTypeList data={data_types} />
				</GridCell>

				<GridCell dataTest="s3-bucket-table-item-created">
					<Tooltip title={formattedUTC} data-test="s3-bucket-table-item-created-value">
						<>{formattedLocal}</>
					</Tooltip>
				</GridCell>

				<GridCell dataTest="s3-bucket-table-item-checked">
					<Tooltip title={lastSeenValue.date} data-test="s3-bucket-table-item-checked-value">
						<div>{lastSeenValue.diff}</div>
					</Tooltip>
				</GridCell>

				<GridCell dataTest="s3-bucket-table-item-link" className={stylesList.buttonCell}>
					{cloud_console_url && (
						<ExternalLinkButton href={cloud_console_url} dataTest="s3-bucket-table-item-aws-url" />
					)}
				</GridCell>
			</GridRow>
		</span>
	);
}

export { S3BucketTableRow, S3BucketPublicStatus };
