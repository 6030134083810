import { useCallback, useMemo } from 'react';
import Select from 'components/form/Select';
import DropdownButton from 'components/form/Select/DropdownButton';
import { EventResourceTypeParam } from 'models/eventsV2/model';
import { FilterPropsBase } from '../model';

type Props = FilterPropsBase & {
	value: EventResourceTypeParam;
	onChange: (newValue: EventResourceTypeParam) => void;
};

const options: { id: EventResourceTypeParam; name: string }[] = [
	{ id: 'service', name: 'Service' },
	{ id: 's3', name: 'S3 bucket' },
	{ id: 'database', name: 'SQL Database' },
	{ id: 'no_sql_database', name: 'NoSQL Database' },
	{ id: 'kafka', name: 'Kafka' },
];

export function ResourceTypeFilter({
	defaultOpen,
	fixed,
	onChange,
	onClose,
	resetFilter,
	value,
}: Props) {
	const selected = useMemo(() => options.find((option) => !!value && option.id === value), [value]);

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<Select
			dataTest="select-resource-type"
			defaultOpen={defaultOpen}
			label={{ primary: 'Resource type', secondary: selected?.name ?? '' }}
			options={options}
			onChange={(data) => onChange(data!.id)}
			onClose={onClose}
			render={{ dropdownButton }}
			value={selected}
		/>
	);
}
