import cn from 'classnames';
import { useMemo, memo, MouseEvent, Fragment } from 'react';
import styles from './index.module.css';

const SWEEP = 3.2; // minimum segment length in degrees
const GAP = 3.2; // minimum 'padding' between segments in degrees

function polarToCartesian(cx: number, cy: number, r: number, angleInDegrees: number) {
	const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

	return {
		x: cx + r * Math.cos(angleInRadians),
		y: cy + r * Math.sin(angleInRadians),
	};
}

function describeSegment(cx: number, cy: number, r: number, startAngle: number, endAngle: number) {
	const start = polarToCartesian(cx, cy, r, endAngle);
	const end = polarToCartesian(cx, cy, r, startAngle);

	const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

	// prettier-ignore
	const arc = [
        'M', start.x, start.y, 
        'A', r, r, 0, largeArcFlag, 0, end.x, end.y,
    ].join(' ');

	// prettier-ignore
	const sector = [
    	arc,
    	'L', cx, cy,
        'L', start.x, start.y
    ].join(' ');

	return { arc, sector };
}

type Props = {
	data: number[];
	hovered?: number;
	onMouseEnter?: (e: MouseEvent<SVGElement>) => void;
	onMouseLeave?: (e: MouseEvent<SVGElement>) => void;
};

function CircleGraph(props: Props) {
	const { data, hovered = -1, onMouseEnter = undefined, onMouseLeave = undefined } = props;

	const segmentData = useMemo(() => {
		const result: { arc: string; sector: string }[] = [];

		if (data.length < 2) return result;

		let runningTotal = data.reduce((acc, d) => acc + d); // sum of all data, reduced during calculations
		let availableDegrees = 360 - data.length * GAP;
		let currentAngle = 0 + GAP / 2; // Start drawing with half a gap

		const reversedData = [...data].reverse();

		for (const d of reversedData) {
			const startAngle = currentAngle;

			let deltaAngle = availableDegrees * (d / runningTotal);
			runningTotal -= d;

			if (deltaAngle < SWEEP) deltaAngle = SWEEP;
			availableDegrees -= deltaAngle;

			const endAngle = startAngle + deltaAngle;

			// 46 == 50 - stroke-width / 2
			result.push(describeSegment(50, 50, 46, startAngle, endAngle));

			currentAngle = endAngle + GAP;
		}

		return result.reverse();
	}, [data]);

	return (
		<svg viewBox="0 0 100 100" className={styles.svg}>
			{data.length === 1 ? (
				<circle
					cx="50"
					cy="50"
					r="46"
					className={cn(styles.fullCircle, hovered === 0 && styles.hovered)}
					data-i="0"
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
				/>
			) : (
				segmentData.map(({ arc, sector }, i) => (
					<Fragment key={i}>
						<path d={arc} className={cn(styles.arc, hovered === i && styles.hovered)} data-i={i} />
						<path
							d={sector}
							className={styles.sector}
							data-i={i}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						/>
					</Fragment>
				))
			)}
		</svg>
	);
}

const memoized = memo(CircleGraph);

export { memoized as CircleGraph };
