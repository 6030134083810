import cn from 'classnames';
import { useStore } from 'effector-react';
import { useMemo } from 'react';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { Typo } from 'components/typography/Typo';
import { namespaceList } from 'models/namespaces/store';
import { PATHS } from 'services/router';
import { DASH_SIGN } from 'services/strings';
import { MapAsset } from '../../../index';
import CloseButton from '../CloseButton';
import commonStyles from '../index.module.css';
import Label from '../Label';

type Props = {
	name: MapAsset['namespace'];
	clusterId: MapAsset['cluster_id'];
};

function NamespaceDetails(props: Props) {
	const { name, clusterId } = props;

	const namespaces = useStore(namespaceList);
	const namespace = namespaces.find((ns) => ns.name === name && ns.clusterId === clusterId);

	const labelsRendered = useMemo(
		function () {
			if (!namespace) return [];

			return namespace.labels.map((label) => {
				const asString = `${label.key}:${label.value}`;

				return (
					<Typo
						key={asString}
						variant="D/Medium/Meta"
						className={cn(commonStyles.ellipses, commonStyles.label)}
					>
						{asString}
					</Typo>
				);
			});
		},
		[namespace]
	);

	return (
		<div
			className={cn(commonStyles.container, commonStyles.sidePaddings)}
			data-test="data-map-details-namespace"
		>
			<div className={commonStyles.header}>
				<Typo variant="D/Medium/H100-Header">{name}</Typo>

				<CloseButton />
			</div>

			<div className={commonStyles.detailsGrid}>
				<Label title="Resource">Namespace</Label>

				<Label title="Labels">
					<div className={commonStyles.labelList}>
						{labelsRendered.length > 0 ? labelsRendered : DASH_SIGN}
					</div>
				</Label>
			</div>

			<Button
				color="tertiary"
				size="small"
				endIcon={<Icon name="Open/Regular" size={20} />}
				fullWidth
				href={`${PATHS.SERVICES}?namespaces=${name}&cluster-ids=${clusterId}`}
				target="_blank"
			>
				View detailed info
			</Button>
		</div>
	);
}

export default NamespaceDetails;
