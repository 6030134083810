import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import { Option } from 'components/form/Select';
import Icon from 'components/Icon';
import { Typo } from 'components/typography/Typo';
import { getAssetsFx } from 'models/assets/effects';
import { assetsList } from 'models/assets/store';
import { PolicyRuleItem } from 'models/policies/dto';
import { RuleBuilderSelect, RuleBuilderComplexSelect } from '../RuleBuilderSelect';
import styles from './index.module.pcss';

type RuleValueProps = {
	value: string;
	onDelete: (value: string) => void;
};

function RuleValue(props: RuleValueProps) {
	const { value, onDelete } = props;

	return (
		<span className={styles.ruleValue} onClick={() => onDelete(value)}>
			{value}
			<Icon name="DismissCircle/Filled" size={16} className={styles.deleteName} />
		</span>
	);
}

const anyOption: Option = { id: -1, name: 'any value' };
const noneOption: Option = { id: -2, name: 'none' };

type Props = {
	prefix?: string;
	value: PolicyRuleItem;
	onChange: (mutatorFn: (draft: PolicyRuleItem) => void) => void;
	onDelete: () => void;
};

// It is a bit like SimpleRule. Differences:
//	1) it is key:value rule
//	2) with operator
function LabelRule(props: Props) {
	const { prefix, value, onChange, onDelete } = props;

	const assets = useStore(assetsList);

	useEffect(() => {
		getAssetsFx();
	}, []);

	const existingLabels = useMemo(() => {
		const result = {};

		for (const asset of assets) {
			// TODO SMAT-2860
			// @ts-ignore
			for (const label of asset.labels) {
				// @ts-ignore
				result[label.key] = result[label.key] || new Set();
				// @ts-ignore
				result[label.key].add(label.value);
			}
		}

		for (const key in result) {
			// @ts-ignore
			result[key] = [...result[key]].sort().map((val) => ({ id: val, name: val }));
		}

		return result as { [key: string]: Option[] };
	}, [assets]);

	const labelKeyOptions = useMemo(() => {
		return Object.keys(existingLabels).sort();
	}, [existingLabels]);

	const labelValueOptions = useMemo(() => {
		const regularOptions: Option[] = existingLabels[value.key] || [];

		return [anyOption, noneOption].concat(regularOptions);
	}, [existingLabels, value]);

	// single select
	const onSelectLabelKey = useCallback(
		(labelKey: string) => {
			onChange((draft) => {
				draft.key = labelKey;
				draft.operator = 'is_set';
				draft.values = [];
			});
		},
		[onChange]
	);

	const onDeleteValue = useCallback(
		function onDeleteValue(val: string) {
			onChange((draft) => {
				draft.values = draft.values.filter((v) => v !== val);
				draft.operator = draft.values.length === 0 ? 'is_set' : 'is';
			});
		},
		[onChange]
	);

	const ruleValues = useMemo(() => {
		const result = value.values.flatMap((v, i) => {
			return [
				<RuleValue key={i} value={v} onDelete={onDeleteValue} />,
				<span key={-1 - i} className={styles.rowItem}>
					{' , '}
				</span>,
			];
		});

		result.pop();

		return result;
	}, [value, onDeleteValue]);

	return (
		<div className={styles.container}>
			{prefix && (
				<Typo component="span" variant="D/Medium/Body-S" className={styles.prefix}>
					{prefix}
				</Typo>
			)}

			<RuleBuilderSelect
				options={labelKeyOptions}
				value={value.key}
				onChange={onSelectLabelKey}
				label={{
					primary: value.key ? 'Label: ' : 'Label: Choose label...',
					secondary: value.key,
				}}
			/>

			{!!value.key && (
				<>
					<span className={styles.joiningWord}>with</span>

					<RuleBuilderComplexSelect
						value={value}
						options={labelValueOptions}
						onChange={onChange}
						label={
							value.operator === 'is'
								? 'value:'
								: value.operator === 'is_set'
								? 'any value'
								: 'none'
						}
					/>

					{ruleValues}
				</>
			)}

			<ButtonIcon icon="Delete/Filled" onClick={onDelete} className={styles.deleteRule} />
		</div>
	);
}

export default LabelRule;
