import { generatePath } from 'react-router';
import Chip from 'components/Chip';
import CopyToClipboard from 'components/CopyToClipboard';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import { Tooltip } from 'components/Tooltip';
import { RouterLink } from 'components/typography/Link';
import { PolicyS3EventJson } from 'models/eventsV2/dto';
import { PATHS } from 'services/router';
import styles from './index.module.pcss';

type Props = { event: PolicyS3EventJson };

// eslint-disable-next-line complexity
const PolicyS3Event = (props: Props) => {
	const { event } = props;

	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;

	const pathToEvidence = generatePath(PATHS.EVENT_SAMPLE, { eventId: event.id });
	const pathToPolicies = `${PATHS.POLICY_LIST}?data-types=${event.data_types.join(',')}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<span className={styles.detailsLabel}>Event ID</span>
				<span>
					{event.id}{' '}
					<Tooltip
						title="Copy event link"
						placement="right"
						className={styles.copyToClipboardWrapper}
					>
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</span>

				<span className={styles.detailsLabel}>Data types</span>
				<PiiTypeList data={event.data_types} noHighlight dataTest="event-details-data-types" />

				<span className={styles.detailsLabel}>Severity</span>
				<SensitivityChip sensitivity={event.sensitivity} data-test="event-details-sensitivity" />

				<span className={styles.detailsLabel}>Category</span>
				<span className={styles.goToPolicies}>
					Policy violation
					<Button
						color="tertiary"
						size="extraSmall"
						endIcon={<Icon name="Open/Regular" size={20} />}
						href={pathToPolicies}
						target="_blank"
						data-test="event-details-policy-link"
					>
						Go to policies
					</Button>
				</span>

				<span className={styles.detailsLabel}>Related policy</span>
				<RouterLink
					data-test="event-details-policy"
					inherit
					// REMOVE THE OPTIONAL MARK!!!
					to={generatePath(PATHS.POLICY_ITEM, { id: event.policy_id || 0 })}
				>
					{event.policy_name}
				</RouterLink>

				<span className={styles.detailsValueChipArray}>
					{event.policy_tags.map((tag) => (
						<Chip key={tag} label={tag} size="small" color="secondary" theme="assetNeutral" />
					))}
				</span>

				<span className={styles.detailsLabel}>S3 bucket</span>
				<span data-test="event-details-s3-bucket">{event.s3_bucket.name}</span>
			</div>

			<div className={styles.links}>
				<Button
					size="small"
					color="tertiary"
					endIcon={<Icon name="Open/Regular" size={20} />}
					href={pathToEvidence}
					target="_blank"
					data-test="event-details-sample-link"
				>
					View sample
				</Button>
			</div>
		</>
	);
};

export default PolicyS3Event;
