import { ChartTypeRegistry, TooltipModel } from 'chart.js';
import { hideChartTooltip, setChartTooltip } from './model';

export const externalTooltipHandler = (context: {
	chart: { canvas: HTMLCanvasElement; width: number };
	tooltip: TooltipModel<keyof ChartTypeRegistry>;
}) => {
	const { chart, tooltip } = context;

	if (tooltip.opacity === 0) {
		hideChartTooltip();
		return;
	}

	const {
		dataIndex,
		// we pass apiCalls, dataFields and labels in datasets, but ts doesn't know about it =\
		// @ts-ignore
		dataset: { apiCalls },
	} = tooltip.dataPoints[0];

	// because chart have +1 elements in the start and in the end
	const realIndex = dataIndex - 1;

	// Due to difference in widths of real chart and visible area (with overflow: hidden), we
	// need to calculate container offset.
	const offset = (chart.width / 1.32) * 0.16 - 34;

	setChartTooltip({
		visible: true,
		date: tooltip.title[0],
		apiCalls: apiCalls[realIndex],
		top: tooltip.caretY,
		left: tooltip.caretX - offset,
	});
};
