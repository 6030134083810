import { EventType } from 'models/eventsV2/dto';
import { DateFormat, dayjs } from 'services/dayjs';

function formatWithToday(timeRaw: number) {
	const today = dayjs().startOf('day');
	const time = dayjs(timeRaw);

	const dayFormatted = time.isSame(today, 'day')
		? 'Today'
		: time.format(DateFormat.dateWithWeekday);
	const timeFormatted = time.format(DateFormat.fullTime);

	return `${dayFormatted} - ${timeFormatted}`;
}

const eventTitleByType = (eventType: EventType, resourceType?: string) => {
	switch (eventType) {
		case 'new_data_type':
			return 'New data type in infrastructure';
		case 'new_internal_receiver':
		case 'new_internal_sender':
			return 'Sensitive data in new data flow';
		case 'updated_internal_receiver':
		case 'updated_internal_sender':
			return 'New sensitive data detected in the data flow';
		case 'new_external_receiver':
			return 'Authorized third-party environment started receiving data';
		case 'updated_enduser_receiver':
		case 'updated_robot_receiver':
		case 'updated_external_receiver':
			return 'Connection with a new third-party environment detected';
		case 'started_storing_data':
		case 'started_storing_datatype':
			return 'New data type detected in storage';
		case 'policy_violation':
			return 'Sensitive data found in unauthorized asset';
		case 'policy_unencrypted_s3_bucket':
			return 'Unencrypted storage with sensitive data';
		case 'policy_public_s3_bucket':
			return 'Publicly available storage with sensitive data';
		case 'policy_unencrypted_network':
			return 'Sensitive data transmitted over unencrypted network';
		case '3rd_party_policy_violation':
			return 'Sensitive data shared with unauthorized third-party environment';
		case 'email_clustered':
			return 'email Data type in API url path detected';
		case 'custom_asset_rule_conflict':
			return 'Conflict in rules for custom external connections';
		case 'discovery_complete':
			return 'Product setup and analysis of your infrastructure are complete. Data monitoring is now active';
		case 'dim_release_update':
			return 'New update released for data-in-motion sensor';
		case 'dim_release_update_critical':
			return 'Critical update released for data-in-motion sensor';
		case 'dar_release_update':
			return 'New update released for data-at-rest sensor';
		case 'dar_release_update_critical':
			return 'Critical update released for data-at-rest sensor';
		case 'anomaly_detected':
			return 'Anomalous behavior detected';
		case 'excessive_db_access':
			return 'Excessive data access found';
		case 'policy_unencrypted_rds':
			return 'Unencrypted storage with sensitive data';
		case 'policy_violation_dar':
			return 'Sensitive data found in unauthorized storage';
		case 'new_potential_data_storage':
			return `New ${resourceType || 'data-at-rest'} instance found`;
		case 'new_data_storage':
			return `New ${resourceType || 'data-at-rest resource'} found`;
		default:
			return 'Unknown event';
	}
};

export { formatWithToday, eventTitleByType };
