import { InputAdornment } from '@material-ui/core';
import { FormEvent, useState } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import Button from 'components/form/Button';
import TextField from 'components/form/TextField';
import Icon from 'components/Icon';
import { enqueueSnackbar } from 'components/Snackbar';
import { Typo } from 'components/typography/Typo';
import { getSSOLoginUrl } from 'models/session/api';
import { APIError } from 'services/api/httpRequest';
import signUpStyles from '../SignUp/index.module.pcss';
import styles from './index.module.css';

type Props = {
	email: string;
	clearEmail: () => void;
};

function SignInSSO(props: Props) {
	const [isLoading, setLoading] = useState(false);

	async function loginWithSSO(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);

		try {
			const { sso_login_url } = await getSSOLoginUrl({ email: props.email });
			window.location.replace(sso_login_url);
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();
				enqueueSnackbar(message);
				setLoading(false);
			} else {
				throw error;
			}
		}
	}

	return (
		<form onSubmit={loginWithSSO} className={signUpStyles.form}>
			<TextField
				value={props.email}
				label="Email"
				type="email"
				readOnly
				required
				fullWidth
				size="large"
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<ButtonIcon
								icon="Dismiss/Regular"
								color="icon/primary"
								dataTest="sign-in-sso-email-clear"
								onClick={props.clearEmail}
							/>
						</InputAdornment>
					),
				}}
				data-test="sign-in-sso-email"
			/>

			<Typo
				variant="D/Medium/Body-S"
				color="secondary"
				className={styles.ssoInfo}
				data-test="sign-in-sso-message"
			>
				<Icon name="Info/Regular" size={20} /> Your company uses Single Sign On.
			</Typo>

			<Button
				type="submit"
				size="large"
				loading={isLoading}
				className={signUpStyles.formButton}
				data-test="sign-in-sso-next"
			>
				Next
			</Button>
		</form>
	);
}

export default SignInSSO;
