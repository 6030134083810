import { SlackSettings, SlackChannels } from './dto';

const slackSettingsExample: SlackSettings = {
	has_token: false,
	is_enabled: false,
	channel: '',
	notify: ['infrastructure_updates'],
};

const slackChannelsExample: SlackChannels = {
	channels: [{ name: 'soveren-integration-events' }, { name: 'channel-1' }, { name: 'channel-2' }],
};

export { slackSettingsExample, slackChannelsExample };
