import { InputAdornment } from '@material-ui/core';
import { KeyboardEvent, useCallback, useRef } from 'react';
import { DraftFunction } from 'use-immer';
import Filter from 'components/Filter';
import Button from 'components/form/Button';
import MultiSelect from 'components/form/MultiSelect';
import { DropdownButtonProps } from 'components/form/Select/DropdownButton';
import selectStyles from 'components/form/Select/index.module.css';
import TextField from 'components/form/TextField';
import Icon from 'components/Icon';
import { Tooltip } from 'components/Tooltip';
import { PolicyItem } from 'models/policiesV2/dto';
import styles from './index.module.css';

function AddTag(props: DropdownButtonProps) {
	const { onClick, open, buttonRef } = props;

	return (
		<Button
			className={open ? styles.buttonActive : undefined}
			onClick={onClick}
			ref={buttonRef}
			size="extraSmall"
			color="tertiary"
			startIcon={<Icon name="Add/Regular" size={20} />}
		>
			Add tag
		</Button>
	);
}

type Props = {
	options: string[];
	value: string[];
	setFormData: (arg: DraftFunction<PolicyItem>) => void;
};

function Tags(props: Props) {
	const { options, value, setFormData } = props;

	const onChange = useCallback((tags) => {
		setFormData((draft) => {
			draft.tags = tags;
		});
	}, []);

	const closeRef = useRef(() => {});

	function closeSelect() {
		closeRef.current();
	}

	const Search = useCallback(
		function Search({ value: searchString, onChange: setSearchString }) {
			function addCustomOption() {
				if (!value.find((name) => name === searchString)) {
					onChange(value.concat(searchString));
					closeSelect();
				}
			}

			function onKeyDown(e: KeyboardEvent<HTMLElement>) {
				// This is to prevent MenuList from focusing on list item, when you
				// enter something in Search input field.
				e.stopPropagation();

				if (e.key === 'Enter') {
					e.preventDefault();
					addCustomOption();
				}
			}

			const endAdornment = searchString ? (
				<Tooltip title="Add custom option" placement="top">
					<InputAdornment onClick={addCustomOption} position="end" className={styles.addIcon}>
						<Icon name="AddCircle/Regular" size={20} />
					</InputAdornment>
				</Tooltip>
			) : (
				<InputAdornment position="end">
					<Icon name="search" size={20} />
				</InputAdornment>
			);

			return (
				<TextField
					data-test="policy-tags-search-input"
					autoFocus
					value={searchString}
					onChange={(e) => {
						setSearchString(e.target.value);
					}}
					placeholder="Search"
					size="small"
					helperText={null}
					onKeyDown={onKeyDown}
					className={selectStyles.searchInput}
					InputProps={{ endAdornment }}
				/>
			);
		},
		[value, onChange]
	);

	return (
		<div className={styles.tagsContainer}>
			{value.map((val, i) => {
				function onDelete() {
					onChange(value.filter((v) => v !== val));
				}

				return (
					<Filter key={i} kind="primary-removable" tooltip="" onClick={onDelete} onClose={onDelete}>
						{val}
					</Filter>
				);
			})}

			<MultiSelect
				label="fake (see render.label)"
				options={options}
				value={value}
				onChange={onChange}
				closeRef={closeRef}
				render={{
					dropdownButton: AddTag,
					search: Search,
				}}
				hasSearch
				hasApplyButton
			/>
		</div>
	);
}

// TODO icon adornment is not as per design
// TODO list width jumps when icon adornment changes from looking glass to add icon
// TODO onChange instead of form data

export { Tags };
