import cn from 'classnames';
import { Typo } from 'components/typography/Typo';
import { DataFlowDetailsParams } from 'models/assetDataFlowDetailsV2/dto';
import styles from './index.module.pcss';

interface Props {
	direction: DataFlowDetailsParams['streamDirection'];
	onChange: (direction: DataFlowDetailsParams['streamDirection']) => void;
}

type Direction = {
	name: string;
	value: DataFlowDetailsParams['streamDirection'];
};

function DataFlowStreamFlow({ direction, onChange }: Props) {
	const directionList: Direction[] = [
		{
			name: 'all',
			value: '',
		},
		{
			name: 'request',
			value: 'request',
		},
		{
			name: 'response',
			value: 'response',
		},
	];

	return (
		<div className={styles.container}>
			{directionList.map((dir) => (
				<Typo
					className={cn(styles.item, { [styles.itemActive]: direction === dir.value })}
					variant="D/Medium/Body-S"
					key={dir.name}
					onClick={() => onChange(dir.value)}
					data-test={`data-flow-stream-dynamic-${dir.name}`}
					color={direction === dir.value ? 'primary' : 'secondary'}
				>
					{dir.name}
				</Typo>
			))}
		</div>
	);
}

export default DataFlowStreamFlow;
