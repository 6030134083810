import { useStore } from 'effector-react';
import { Children, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Button, { IButtonProps } from 'components/form/Button';
import Icon, { IconProps } from 'components/Icon';
import NonEmptyPiiSwitch from 'components/PiiGlobalFilterV2/NonEmptyPiiSwitch';
import Preloader from 'components/Preloader';
import { RouterLink, RouterLinkProps } from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import { Breadcrumbs } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import styles from './index.module.css';
import { headerStore, setHeader } from './model';

//
// API for developer
//

type HeaderProps =
	| {
			isLoading: boolean;
	  }
	| {
			isLoading?: boolean;
			children: React.ReactNode;
	  };

function Header(props: HeaderProps) {
	useEffect(() => {
		setHeader(props);
	}, [props]);

	return null;
}

//
// Parts of header. Example basic usage:
//
//	<Header>
//		<Header.Breadcrumbs finalStep="Overview" />
//		<Header.Title>My Title</Header.Title>
//	</Header>
//

Header.Breadcrumbs = Breadcrumbs;

type TitleProps = {
	children: JSX.Element | string | string[];
};
function isStringArray(arr: unknown): arr is string[] {
	return Array.isArray(arr) && arr.every((s: unknown) => typeof s === 'string');
}
Header.Title = function Title(props: TitleProps) {
	const { children } = props;
	const location = useLocation();

	let pageTitle: string = useMemo(() => {
		const name = location.pathname.split('/')[1];
		return name.replaceAll('-', ' ').replace(/^\w/, (letter) => letter.toLocaleUpperCase());
	}, [location]);

	if (typeof children === 'string') {
		pageTitle = children;
	} else if (isStringArray(children)) {
		pageTitle = children.join('');
	} else {
		return children;
	}

	return (
		<Typo variant="D/Medium/H400-Page-Title" dataTest="header-title">
			{pageTitle}
		</Typo>
	);
};

Header.Subtitle = function Subtitle(props: TitleProps) {
	const { children } = props;

	let subtitle: string;

	if (typeof children === 'string') {
		subtitle = children;
	} else if (isStringArray(children)) {
		subtitle = children.join('');
	} else {
		return children;
	}

	return (
		<Typo variant="D/Regular/Body-S" color="secondary" dataTest="header-subtitle">
			{subtitle}
		</Typo>
	);
};

Header.SensitiveSwitcher = NonEmptyPiiSwitch;

Header.IconButton = function IconButton(
	props: Pick<IButtonProps, 'theme' | 'children' | 'onClick' | 'data-test'> &
		Pick<IconProps, 'name' | 'size'> &
		Partial<Pick<RouterLinkProps, 'to'>>
) {
	const { name, size = 16, to, ...buttonProps } = props;

	const button = (
		<Button
			startIcon={<Icon name={name} size={size} />}
			size="extraSmall"
			color={buttonProps.theme === 'danger' ? 'tertiary' : 'ghost'}
			className={styles.buttonOverride}
			{...buttonProps}
		/>
	);

	if (to) {
		return <RouterLink to={to}>{button}</RouterLink>;
	} else {
		return button;
	}
};

Header.MainButton = function MainButton(
	props: Pick<IButtonProps, 'children' | 'onClick' | 'data-test'> &
		Partial<Pick<RouterLinkProps, 'to'>>
) {
	const { to, ...buttonProps } = props;

	const button = (
		<Button
			startIcon={<Icon name="Add/Regular" size={20} />}
			size="extraSmall"
			color="secondary"
			{...buttonProps}
		/>
	);

	if (to) {
		return <RouterLink to={to}>{button}</RouterLink>;
	} else {
		return button;
	}
};

Header.CustomAction = function CustomAction(props: { children: JSX.Element }) {
	return props.children;
};

//
// Main markup for the header is here.
//

type HeaderParts = {
	breadcrumbs: React.ReactNode | null;
	title: React.ReactNode | null;
	subtitle: React.ReactNode | null;
	buttons: React.ReactNode[];
};

function HeaderLayout() {
	const hStore = useStore(headerStore);

	const { children = null, isLoading = false } = hStore;

	const headerParts: HeaderParts = useMemo(
		function extractParts() {
			const parts: HeaderParts = {
				breadcrumbs: null,
				title: null,
				subtitle: null,
				buttons: [],
			};

			let workaround = false;

			Children.forEach(children, function (child, idx) {
				if (!child || typeof child !== 'object' || !('type' in child)) return;

				const childType = child.type;

				switch (true) {
					case childType === Header.Breadcrumbs:
						parts.breadcrumbs = child;
						break;
					case childType === Header.Title:
						parts.title = child;
						break;
					case childType === Header.Subtitle:
						parts.subtitle = child;
						break;
					case childType === Header.MainButton:
					case childType === Header.IconButton:
						workaround = true;
						parts.buttons.push(child);
						break;
					case childType === Header.SensitiveSwitcher:
					case childType === Header.CustomAction:
						if (workaround) parts.buttons.push(<span className={styles.separator} key={-idx} />);
						workaround = true;
						parts.buttons.push(child);
						break;
					default:
						break;
				}
			});

			return parts;
		},
		[children]
	);

	return (
		<div className={styles.container}>
			<Preloader isLoading={isLoading}>
				<div className={styles.topContainer}>
					<div className={styles.leftBlock}>
						{headerParts.breadcrumbs}

						<div className={styles.titleBlock}>{headerParts.title}</div>
					</div>

					<div className={styles.rightBlock}>{headerParts.buttons}</div>
				</div>

				{headerParts.subtitle ? (
					<div className={styles.bottomContainer}>{headerParts.subtitle}</div>
				) : null}
			</Preloader>
		</div>
	);
}

export type { TitleProps };
export { HeaderLayout };
export default Header;
