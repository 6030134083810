import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import commonStyles from 'assets/styles/index.module.pcss';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import EnhancedTableHead from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import { RouterLink } from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getAssetGroupsFx } from 'models/assetsGroups/effects';
import { assetGroupListExample } from 'models/assetsGroups/examples';
import { assetGroupsList } from 'models/assetsGroups/store';
import { PATHS } from 'services/router';
import AssetGroupTableItem from './AssetGroupTableItem';
import tableConfig from './config';
import styles from './index.module.css';

const newGroupPath = generatePath(PATHS.ASSET_GROUP_ITEM, { id: 'new' });

function noop() {
	return undefined;
}

function AssetGroupsList() {
	let skeletonMode = false;
	let assetGroups = useStore(assetGroupsList);
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		getAssetGroupsFx().then(() => setLoading(false));
	}, []);

	if (!isLoading && assetGroups.length === 0) {
		skeletonMode = true;
		assetGroups = assetGroupListExample.groups;
	}

	return (
		<div>
			<Header>
				<Header.Breadcrumbs finalStep="Groups" />

				<Header.Title>Groups</Header.Title>

				{!skeletonMode && (
					<Header.MainButton to={newGroupPath} data-test="group-header-create-button">
						New group
					</Header.MainButton>
				)}
			</Header>

			<GridTable dataTest="group-table">
				<EnhancedTableHead
					config={tableConfig}
					onRequestSort={noop}
					onRequestFilter={noop}
					rowClassname={styles.rowContainer}
				/>

				<GridBody className={skeletonMode ? commonStyles.skeletonMode : undefined}>
					{assetGroups.map((group) => (
						<AssetGroupTableItem group={group} key={group.id} />
					))}
				</GridBody>
			</GridTable>

			{skeletonMode && (
				<div className={styles.noGroupContainer}>
					<Typo dataTest="group-no-rows" variant="D/Medium/Body">
						No Groups
					</Typo>
					<Typo color="secondary" variant="D/Regular/Body-S" className={styles.noGroupDescription}>
						You have not set up any Groups yet.
					</Typo>

					<RouterLink to={newGroupPath}>
						<Button
							data-test="group-create-button"
							className={styles.noGroupButton}
							color="primary"
							startIcon={<Icon name="Add/Regular" size={20} />}
						>
							New Group
						</Button>
					</RouterLink>
				</div>
			)}
		</div>
	);
}

export default AssetGroupsList;
