import cn from 'classnames';
import { useStore } from 'effector-react';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import { LabelProps } from 'components/form/Select/Label';
import { enqueueSnackbar } from 'components/Snackbar';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import { Typo } from 'components/typography/Typo';
import { ClientItem, ClientRoleEnum } from 'models/clients/dto';
import { updateClientFx } from 'models/clients/effects';
import { clientStore } from 'models/session/store';
import { APIError } from 'services/api/httpRequest';
import { lastSeen } from 'services/lastSeen';
import { DASH_SIGN } from 'services/strings';
import clientStyles from '../ClientList/index.module.pcss';
import ClientName from '../ClientName';
import styles from './index.module.pcss';
import RoleSelect from './RoleSelect';
import TeamPopupMenu from './TeamPopupMenu';

export type TStatus = 'stable' | 'edit' | 'delete';
type TActiveId = ClientItem['client_id'] | null;

interface IClientItem {
	activeId: TActiveId;
	setActiveId: (id: TActiveId) => void;
	isCanBlocked: boolean;
	client: ClientItem;
}

const options: { id: ClientRoleEnum; name: string }[] = [
	{ id: 'admin', name: 'Admin' },
	// TODO: revert it when contributor role will be available
	// { id: 'contributor', name: 'Contributor' },
];
const isOnlyOneRole = true; // TODO: remove it when other roles will be available

function ClientRow({ activeId, setActiveId, isCanBlocked, client }: IClientItem) {
	const currentClient = useStore(clientStore);
	const { full_name, email, role, status, last_seen, client_id } = client;
	const [itemStatus, setItemStatus] = useState<TStatus>('stable');
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedRole, setSelectedRole] = useState<ClientRoleEnum>(role || 'admin');

	const isCurrent = currentClient?.id === client.client_id;
	const isBlocked = useMemo(() => {
		return status === 'blocked';
	}, [status]);
	const lastSeenValue = useMemo(() => lastSeen({ timestamp: last_seen, short: true }), [last_seen]);

	const fullName = useMemo(() => full_name || getFullNameFromEmail(email), [full_name, email]);

	useEffect(() => {
		if (activeId !== client_id) {
			setItemStatus('stable');
		}
	}, [activeId]);

	useEffect(() => {
		if (itemStatus !== 'stable') {
			setActiveId(client_id);
		}
	}, [itemStatus]);

	function getFullNameFromEmail(emailValue: string) {
		return emailValue
			.split('@')[0]
			.split('.')
			.map((word) => word[0].toUpperCase() + word.slice(1))
			.join(' ');
	}

	const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const saveClientRole = async (option: { id: ClientRoleEnum; name: string }) => {
		if (selectedRole === option.id) {
			return; // Don't try to send new role if it makes no sense.
		}

		setIsLoading(true);

		try {
			await updateClientFx({ id: client_id, role: option.id });

			setSelectedRole(option.id);
		} catch (err) {
			if (err instanceof APIError) {
				const { message } = await err.response.json();

				enqueueSnackbar(message);
			} else {
				throw err;
			}
		}
		setIsLoading(false);
	};

	function Label({ label }: LabelProps) {
		return (
			<Typo variant="D/Regular/Body-S" className={styles.textCapital}>
				{label}
			</Typo>
		);
	}

	return (
		<>
			<GridRow
				className={cn(clientStyles.rowContainer, { [styles.blockedRowContainer]: isBlocked })}
				hover
				border
				data-test="client-item-row"
			>
				<GridCell className={styles.nameCell} dataTest="client-name">
					<ClientName
						isCurrent={isCurrent}
						isBlocked={isBlocked}
						client={client}
						itemStatus={itemStatus}
						setItemStatus={setItemStatus}
						fullName={fullName}
					/>
				</GridCell>

				{!isOnlyOneRole && !isBlocked ? (
					<GridCell verticalAlign="middle">
						<RoleSelect
							label={selectedRole}
							options={options}
							value={options.find((option) => option.id === selectedRole)!}
							onChange={saveClientRole}
							render={{
								label: Label,
							}}
						/>
					</GridCell>
				) : (
					<GridCell verticalAlign="middle" className={styles.textCapital} dataTest="client-role">
						{role}
					</GridCell>
				)}

				<GridCell verticalAlign="middle" className={styles.textCapital} dataTest="client-status">
					{status}
				</GridCell>

				<GridCell verticalAlign="middle">{lastSeenValue.diff || DASH_SIGN}</GridCell>

				<GridCell align="right" className={styles.menuCell}>
					{!isBlocked && (
						<ButtonIcon
							active={!!anchorEl}
							className={styles.submenuButton}
							dataTest="open-popup-menu"
							icon="More/Regular"
							loading={isLoading}
							onClick={handleOpen}
							size="M"
						/>
					)}
				</GridCell>
			</GridRow>

			<TeamPopupMenu
				client={client}
				fullName={fullName}
				setItemStatus={setItemStatus}
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				isCanBlocked={isCanBlocked}
				isCurrent={isCurrent}
			/>
		</>
	);
}

export default ClientRow;
