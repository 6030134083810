import { InputAdornment } from '@material-ui/core';
import cn from 'classnames';
import { useStore } from 'effector-react';
import { produce } from 'immer';
import debounce from 'lodash.debounce';
import {
	BaseSyntheticEvent,
	FocusEvent,
	KeyboardEvent,
	ReactNode,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import ButtonIcon from 'components/ButtonIcon';
import TextField from 'components/form/TextField';
import Icon from 'components/Icon';
import Label from 'components/Label';
import Switch from 'components/Switch';
import { Tooltip } from 'components/Tooltip';
import { Typo } from 'components/typography/Typo';
import { AssetJson } from 'models/assets/dto';
import { assetGroupsList } from 'models/assetsGroups/store';
import { MapAsset, RagsAndCubesTree } from '../../index';
import { getCubePosition } from '../../utils/getCubePosition';
import {
	changeMapControls,
	// GroupByRegionOrCluster,
	// GroupByNamespaceOrGroup,
	mapControlsStore,
} from '../model/store';
import { ZoomActions } from '../ZoomWrapper';
import styles from './index.module.css';
import { SearchDropdownItems } from './SearchDropdownItems';

function useFirstMount(): boolean {
	const isFirst = useRef(true);

	if (isFirst.current) {
		isFirst.current = false;

		return true;
	}

	return isFirst.current;
}

type Props = {
	mapAssets: MapAsset[];
	mapAssetsMap: Map<AssetJson['id'], MapAsset>;
	ragsAndCubes: RagsAndCubesTree;
	onSearchDropdownOpen?: (open: boolean) => void;
};

let previousSearchResult: ReactNode = null;

function TopPane(props: Props) {
	const { mapAssets, onSearchDropdownOpen, mapAssetsMap, ragsAndCubes } = props;

	const allGroups = useStore(assetGroupsList);

	const mapControls = useStore(mapControlsStore);
	const { search, groupByNamespaceOrGroup, showAssetSensitivity } = mapControls;

	const [searchDropdownOpen, setSearchDropdownOpen] = useState(false);
	const [searchString, setSearchString] = useState(search.searchString);
	const isFirstMount = useFirstMount();

	function onSensitive(value: boolean) {
		const newControls = produce(mapControls, (draft) => {
			draft.showAssetSensitivity = value;
		});

		changeMapControls(newControls);
	}

	function onSearch(value: string) {
		const newControls = produce(mapControls, (draft) => {
			draft.search.searchString = value;
		});

		changeMapControls(newControls);
	}
	const onSearchDebounced = debounce(onSearch, 300);

	useEffect(() => {
		if (isFirstMount) return;

		onSearchDebounced(searchString);
	}, [searchString, isFirstMount]);

	function determineType(type: MapAsset['type']) {
		switch (type) {
			case 'nosql_db_database':
			case 'sql_db_database':
			case 'kafka_instance':
			case 's3_bucket':
				return type;
			default:
				return 'asset';
		}
	}

	function onSearchExact(asset: MapAsset) {
		const newControls = produce(mapControls, (draft) => {
			const nextType = determineType(asset.type);

			draft.search.searchString = asset.name;
			draft.selected = { type: nextType, id: asset.elementId };
		});

		changeMapControls(newControls);
		setSearchString('');

		const position = getCubePosition({
			id: asset.elementId,
			types: [asset.type],
			mapAssetsMap,
			ragsAndCubes,
		});
		if (position) {
			ZoomActions.scrollToElement?.(position.top, position.left);
		}
	}

	function onBlur(e: FocusEvent<HTMLInputElement>) {
		if (
			(e.relatedTarget as unknown as { parentElement: HTMLElement })?.parentElement.querySelector(
				'input'
			) !== e.currentTarget
		) {
			setSearchDropdownOpen(false);
		}
	}

	function onKeyDown(e: KeyboardEvent) {
		if (e.key === 'Enter' || e.key === 'Escape') {
			(e.target as BaseSyntheticEvent<HTMLInputElement>['target']).blur();
			previousSearchResult = null;
			setSearchDropdownOpen(false);
		} else {
			setSearchDropdownOpen(true);
		}
	}

	useEffect(
		function () {
			setSearchDropdownOpen(false);
		},
		[mapControls.selected]
	);

	useEffect(() => {
		onSearchDropdownOpen?.(searchDropdownOpen);
	}, [searchDropdownOpen]);

	const searchDropdownRendered = useMemo(
		function () {
			if (!searchDropdownOpen || !search.searchString) return null;

			// skip intermediate searched results while user is typing
			if (searchString !== search.searchString) return previousSearchResult;

			const result = mapAssets
				.filter((asset) =>
					asset.name.toLocaleLowerCase().includes(search.searchString.toLocaleLowerCase().trim())
				)
				.map((asset) => {
					const idx = asset.name
						.toLocaleLowerCase()
						.indexOf(search.searchString.toLocaleLowerCase());
					const first = asset.name.slice(0, idx);
					const second = asset.name.slice(idx, idx + search.searchString.length);
					const third = asset.name.slice(idx + search.searchString.length);

					let nsOrGroup =
						groupByNamespaceOrGroup === 'namespace'
							? asset.namespace
							: allGroups.find((group) => group.id === asset.groups[0])?.name;

					if (nsOrGroup) nsOrGroup = `・${nsOrGroup}`;

					return (
						<div
							key={asset.entityId}
							className={styles.searchDropdownItem}
							onClick={() => onSearchExact(asset)}
							data-test="data-map-search-results-item"
						>
							<Tooltip
								title={first + second + third + nsOrGroup}
								enterDelay={800}
								enterNextDelay={800}
								className={styles.searchDropdownTooltip}
							>
								<Typo
									component="span"
									variant="D/Regular/Body-S"
									className={styles.searchDropdownItemText}
								>
									{first}
									<strong>{second}</strong>
									{third}
									<strong className={styles.nsOrGroup}>{nsOrGroup}</strong>
								</Typo>
							</Tooltip>
						</div>
					);
				});

			previousSearchResult = (
				<div tabIndex={-1} className={styles.searchDropdown} data-test="data-map-search-results">
					{result.length > 0 ? <SearchDropdownItems result={result} /> : 'No results'}
				</div>
			);

			return previousSearchResult;
		},
		[
			searchDropdownOpen,
			mapAssets,
			search.searchString,
			groupByNamespaceOrGroup,
			allGroups,
			searchString,
		]
	);

	return (
		<div className={styles.container} data-test="data-map-topPane">
			<div className={styles.box} data-test="data-map-topPane-sensitivity">
				<Label className={styles.content}>
					<Switch
						checked={showAssetSensitivity}
						onChange={({ target }) => onSensitive(target.checked)}
					/>
					Sensitivity
				</Label>
			</div>

			<div className={cn(styles.box, styles.staticGroupingDropdown)}>
				By regions
				<Typo variant="D/Regular/Body-S" color="secondary" component="span">
					|
				</Typo>
				namespaces
			</div>

			<div className={styles.box} data-test="data-map-topPane-search">
				<TextField
					value={searchString}
					onChange={(e) => setSearchString(e.target.value)}
					placeholder="Search"
					onBlur={onBlur}
					onKeyDown={onKeyDown}
					helperText={null}
					className={styles.searchInput}
					InputProps={{
						classes: {
							root: styles.searchInput,
						},
						endAdornment: search.searchString ? (
							<InputAdornment position="end">
								<ButtonIcon
									icon="Dismiss/Regular"
									onClick={() => {
										onSearch('');
										setSearchString('');
									}}
								/>
							</InputAdornment>
						) : (
							<InputAdornment position="end">
								<Icon name="search" size={20} />
							</InputAdornment>
						),
					}}
					data-test="data-map-search-input"
				/>

				{searchDropdownRendered}
			</div>
		</div>
	);
}

export default TopPane;
