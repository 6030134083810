import { ChipProps, default as UIChip } from '@material-ui/core/Chip';
import cn from 'classnames';
import commonStyles from 'assets/styles/index.module.pcss';
import styles from './index.module.pcss';

export interface CustomChipsProps extends Omit<ChipProps, 'size'> {
	theme?:
		| 'neutral'
		| 'warning'
		| 'danger'
		| 'success'
		| 'info'
		| 'highlight'
		| 'assetInternal'
		| 'assetExternal'
		| 'assetGroup'
		| 'assetNeutral'
		| 'dataTypeNeutral';
	size?: 'medium' | 'small' | 'extraSmall' | 'XXS';
	className?: string;
	color?: 'primary' | 'secondary';
	'data-test'?: string;
}

function Chip({
	theme = 'neutral',
	size = 'medium',
	color = 'primary',
	className,
	classes = {},
	...props
}: CustomChipsProps) {
	const { root, label, ...restClasses } = classes;

	return (
		<UIChip
			component="span"
			classes={{
				label: cn(styles.label, label),
				root: cn(
					styles[theme],
					styles[size],
					styles.chip,
					styles[color],
					commonStyles.skeletonBackground,
					root,
					className
				),
				disabled: styles.disabled,
				clickable: styles.clickable,
				...restClasses,
			}}
			{...props}
		/>
	);
}

export default Chip;
