import { useCallback, useMemo } from 'react';
import Select from 'components/form/Select';
import DropdownButton from 'components/form/Select/DropdownButton';
import { PolicyItem, policyTypeLabels } from 'models/policiesV2/dto';
import { FilterPropsBase } from '../model';

type Value = PolicyItem['type'] | '';

const options: { id: Value; name: string }[] = [
	{ id: 'dim', name: policyTypeLabels['dim'] },
	{ id: 'dar', name: policyTypeLabels['dar'] },
];

type Props = FilterPropsBase & {
	value: Value;
	onChange: (newValue: Value) => void;
};

function PolicyTypeFilter({ defaultOpen, fixed, onChange, onClose, resetFilter, value }: Props) {
	const selected = useMemo(() => options.find((option) => !!value && option.id === value), [value]);

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<Select
			dataTest="select-policy-type"
			defaultOpen={defaultOpen}
			label={{ primary: 'Policy type', secondary: selected?.name ?? '' }}
			options={options}
			onChange={(data) => onChange(data!.id)}
			onClose={onClose}
			render={{ dropdownButton }}
			value={selected}
		/>
	);
}

export default PolicyTypeFilter;
