import { useState, useCallback, useEffect, Ref } from 'react';

type Mode = 'vertical' | 'horizontal'; // default is 'vertical'

function useScrollDetector(mode = 'vertical' as Mode): [Ref<HTMLDivElement>, boolean] {
	const [hasScroll, setHasScroll] = useState(false);

	const [containerNode, setContainerNode] = useState<HTMLElement | null>(null);

	const ref = useCallback((node: HTMLElement | null) => {
		setContainerNode(node);
	}, []);

	useEffect(() => {
		if (containerNode === null) return;

		function detectAndSetScroll() {
			if (!containerNode) return;

			const scroll =
				mode === 'vertical'
					? containerNode.scrollHeight > containerNode.clientHeight
					: containerNode.scrollWidth > containerNode.clientWidth;

			setHasScroll(scroll);
		}

		const resizeObserver = new ResizeObserver(detectAndSetScroll);

		const mutationObserver = new MutationObserver(() => {
			// A child node has been added or removed

			resizeObserver.disconnect();

			resizeObserver.observe(containerNode);
			for (const childNode of containerNode.children) {
				resizeObserver.observe(childNode);
			}

			detectAndSetScroll();
		});

		resizeObserver.observe(containerNode);
		for (const childNode of containerNode.children) {
			resizeObserver.observe(childNode);
		}

		mutationObserver.observe(containerNode, { childList: true });

		detectAndSetScroll();

		return function cleanup() {
			resizeObserver.disconnect();
			mutationObserver.disconnect();
		};
	}, [containerNode, mode]);

	return [ref, hasScroll];
}

export { useScrollDetector };
