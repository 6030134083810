import { ReactNode, Fragment } from 'react';
import NoContent from 'components/NoContent';
import EnhancedTableHead from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import { Typo } from 'components/typography/Typo';
import { EventJson } from 'models/eventsV2/dto';
import { EventRow } from '../EventRow';
import tableConfig from './config';
import styles from './index.module.css';

type Props = {
	events: EventJson[];
	isLoading: boolean;
	onSelect: (id: number) => void;
	loadMoreButton: ReactNode;
};

export const EventsTable = (props: Props) => {
	const { events, isLoading, onSelect, loadMoreButton } = props;
	let skeletonMode = false;

	if (!isLoading && events.length === 0) {
		skeletonMode = true;
	}

	return (
		<div className={styles.tableWrapper}>
			<GridTable dataTest="database-roles-table" className={styles.table}>
				<EnhancedTableHead
					config={tableConfig}
					rowClassname={styles.rowContainer}
					headClassname={styles.tableHead}
				/>

				{skeletonMode && (
					<NoContent type="logEvents" className={styles.rowContainer}>
						<div className={styles.emptyContainer}>
							<Typo variant="D/Medium/Body" color="primary" className={styles.emptyContainerText}>
								No data found
							</Typo>
							<Typo
								variant="D/Regular/Body-S"
								color="secondary"
								className={styles.emptyContainerText}
							>
								Failed to retrieve access data, possibly due to a scanning error. <br /> Ensure the
								database user has the necessary permissions for role queries and rerun the scan.
							</Typo>
						</div>
					</NoContent>
				)}

				{!skeletonMode && (
					<GridBody className={styles.tableBody}>
						{events?.map((event, i) => (
							<Fragment key={`event-row-${event.id}`}>
								<EventRow event={event} selected={false} onSelect={onSelect} />

								{i === events.length - 1 && loadMoreButton}
							</Fragment>
						))}
					</GridBody>
				)}
			</GridTable>
		</div>
	);
};
