import cn from 'classnames';
import { useMemo } from 'react';
import GatewayStatusIcon from 'components/GatewayStatusIcon';
import Icon from 'components/Icon';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import { Tooltip } from 'components/Tooltip';
import { GatewayJson } from 'models/gateways/dto';
import { AccountAWSItem } from 'models/sensors/aws/dto';
import { lastSeen } from 'services/lastSeen';
import accountStyles from '../index.module.css';
import { AccountName } from './AccountName';
import { CompletedDeleteButton } from './CompletedDeleteButton';
import styles from './index.module.css';

type Props = {
	account: AccountAWSItem;
};

export function CompletedItem({ account }: Props) {
	const { role_arn, last_checked, id, last_checked_errors } = account;
	const isErrors = last_checked_errors.length > 0;
	const description = isErrors ? last_checked_errors.join(', ') : 'Connected and working';
	const lastChecked = useMemo(
		function getLastCheckedDetails() {
			return lastSeen({ timestamp: last_checked, short: true });
		},
		[last_checked]
	);

	const status: GatewayJson['status'] = isErrors ? 'error' : 'ok';

	return (
		<GridRow
			className={cn(accountStyles.rowContainer, styles.rowContainer)}
			hover
			border
			data-test="aws-account-item"
		>
			<GridCell className={accountStyles.statusContainer}>
				<GatewayStatusIcon status={status} hoverable={false} />

				<AccountName name={role_arn} description={description} />
			</GridCell>

			<GridCell verticalAlign="middle">
				{!last_checked ? (
					<Tooltip title="Discovery in progress" placement="top">
						<Icon name="Clock/Filled" size={16} className={styles.grayIcon} />
					</Tooltip>
				) : (
					<Tooltip title={lastChecked.date} placement="top">
						<div>{lastChecked.diff}</div>
					</Tooltip>
				)}
			</GridCell>

			<GridCell verticalAlign="middle" className={accountStyles.deleteButton}>
				<CompletedDeleteButton id={id} name={role_arn} />
			</GridCell>
		</GridRow>
	);
}
