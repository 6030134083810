import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Alert, { AlertSeverity } from 'components/Alert';
import { CurtainShift } from 'components/Curtain';
import Preloader from 'components/Preloader';
import { TabLink, TabPanel, Tabs, useTabLink } from 'components/Tabs';
import { Typo } from 'components/typography/Typo';
import { DataTypeItem } from 'models/dataTypes/dto';
import { falsePositivePiiType } from 'models/dataTypes/hooks';
import { deletePIIMark, getPIIMarksById, setPIIMark } from 'models/piiMarks/api';
import { PIIMarkJson } from 'models/piiMarks/dto';
import { getSample } from 'models/samplesV2/api';
import { SampleDataJson, SampleJson } from 'models/samplesV2/dto';
import { APIError } from 'services/api/httpRequest';
import { PATHS } from 'services/router';
import AddManualDataTypeModal from './AddManualDataTypeModal';
import FPVisibilityToggler from './FPVisibilityToggler';
import { fpVisibilityStore } from './FPVisibilityToggler/model';
import styles from './index.module.css';
import JsonErrorBoundary from './JsonErrorBoundary';
import JsonView, { LineDetection } from './JsonView';
import {
	confirmRemoveFalsePositive,
	confirmRemoveManualDataType,
	confirmSetFalsePositive,
} from './JsonView/modals';
import { ResponseWithCodes } from './ResponseWithCodes';
import SampleHeader, { TitleBlockProps } from './SampleHeader';
import TableOfTypes from './TableOfTypes';
import { WashedSample } from './WashedSample';

export type Direction = 'request' | 'response'; // TODO

interface Params {
	id: string;
	direction: Direction | string; // TODO
}

const emptyJson: SampleDataJson['json'] = '""';

const initialState: SampleJson = {
	host: '',
	url: '',
	method: 'GET',
	protocol: 'http',
	asset: 0,
	last_seen: 0,
	response_codes: [],
	request: { json: emptyJson, data_fields: [] },
	response: { json: emptyJson, data_fields: [] },
	is_encrypted: false,
	is_mesh_network: false,
};

interface IInitialPiiMarks {
	response: PIIMarkJson[];
	request: PIIMarkJson[];
}

function useQuery() {
	const query = new URLSearchParams(useLocation().search);

	return {
		id: query.get('id') || '',
		direction: query.get('direction') || '',
	};
}

const initialPiiMarks: IInitialPiiMarks = {
	response: [],
	request: [],
};

function Sample() {
	const history = useHistory();
	const { id, direction }: Params = useQuery();
	const isFalsePositiveVisible = useStore(fpVisibilityStore);
	const [piiMarksList, setPiiMarksList] = useState<IInitialPiiMarks>(initialPiiMarks);
	const [initialSample, setInitialSample] = useState<SampleJson>(initialState);
	const [activePiiType, setActivePiiType] = useState<LineDetection['jsonPath'] | null>(null);
	const [curtainOpen, setCurtainOpen] = useState(true);
	const [notFound, setNotFound] = useState(false);
	const { host, url, response_codes: responseCodes } = initialSample;

	const [addModalState, setAddModalState] = useState(false);
	const [dataTypeDetections, setDataTypeDetections] = useState<{
		dataTypes: DataTypeItem['id'][];
		jsonPath: string;
		direction: Direction;
	}>({ dataTypes: [], jsonPath: '', direction: 'request' });

	const isLoading = !host && !url;

	const tabs = useMemo(
		() => [
			`${PATHS.SAMPLE_V2}?id=${id}&direction=request`,
			`${PATHS.SAMPLE_V2}?id=${id}&direction=response`,
		],
		[id]
	);
	const tab = useTabLink(tabs) || tabs[0];

	useEffect(() => {
		const piiTypePath = location.hash.match(/^#(.*)$/)?.[1];

		if (!piiTypePath) return;

		setActivePiiType(piiTypePath);
	}, [isLoading, location.hash]);

	const handlePiiTypeClick = (piiTypePath: LineDetection['jsonPath']) => {
		history.push(`${PATHS.SAMPLE_V2}?id=${id}&direction=${direction}#${piiTypePath}`);
	};

	useEffect(() => {
		Promise.all([getSample(Number(id)), getPIIMarksById(Number(id))])
			.then(([sampleData, { pii_marks }]) => {
				setInitialSample(sampleData);
				setPiiMarksList(preparePiiMarks(pii_marks));
			})
			.catch((e) => {
				if (e instanceof APIError && e.response.status === 404) {
					setNotFound(true);
				} else {
					throw e;
				}
			});
	}, [id]);

	async function fetchPiiMarksList() {
		const { pii_marks } = await getPIIMarksById(Number(id));

		setPiiMarksList(preparePiiMarks(pii_marks));
	}

	function preparePiiMarks(piiMarks: PIIMarkJson[]) {
		return {
			response: piiMarks.filter((piiMark) => piiMark.direction === 'response'),
			request: piiMarks.filter((piiMark) => piiMark.direction === 'request'),
		};
	}

	function setFalsePositiveFor(dir: Direction) {
		return async function setFalsePositive(dataType: number, jsonPath: LineDetection['jsonPath']) {
			const confirmed = await confirmSetFalsePositive();

			if (confirmed) {
				await setPIIMark({
					endpoint_id: Number(id),
					detected_data_type: dataType,
					direction: dir,
					json_path: jsonPath,
					manual_data_type: falsePositivePiiType,
				});

				fetchPiiMarksList(); // TODO to store;
			}
		};
	}

	async function removeFalsePositive(fpId: number) {
		const confirmed = await confirmRemoveFalsePositive();

		if (confirmed) {
			await deletePIIMark(fpId);

			fetchPiiMarksList(); // TODO to store;
		}
	}

	async function removeManualDataType(manualId: number) {
		const confirmed = await confirmRemoveManualDataType();

		if (confirmed) {
			await deletePIIMark(manualId);

			fetchPiiMarksList(); // TODO to store;
		}
	}

	function handleDataTypeDetectionsFor(dir: Direction) {
		return async function handleDataTypeDetections(detections: {
			dataTypes: number[];
			jsonPath: string;
		}) {
			setDataTypeDetections({ ...detections, direction: dir });
			setAddModalState(true);
		};
	}

	async function addManualDataType(dataType: number) {
		/* TODO: #customDataType open when custom data type will be ready
		if (typeof piiType === 'object') {
			console.log('Create new piiType with param {type, sensitivity} if it is new one.');
		} */
		const { direction: dir, jsonPath } = dataTypeDetections;

		await setPIIMark({
			endpoint_id: Number(id),
			detected_data_type: falsePositivePiiType,
			direction: dir,
			json_path: jsonPath,
			manual_data_type: dataType,
		});

		fetchPiiMarksList(); // TODO to store;
	}

	if (notFound) {
		return <WashedSample state={history.location.state as TitleBlockProps} id={id} />;
	}

	const leftCurtainPart = (
		<div className={styles.tabPanels}>
			<TabPanel value={tab} index={tabs[0]}>
				<Preloader isLoading={isLoading}>
					{initialSample.request.json ? (
						<JsonView
							key="request"
							activePiiType={activePiiType}
							sampleData={initialSample.request as Required<SampleDataJson>}
							piiMarks={piiMarksList.request}
							hideFalsePositives={!isFalsePositiveVisible}
							setFalsePositive={setFalsePositiveFor('request')}
							removeFalsePositive={removeFalsePositive}
							removeManualDataType={removeManualDataType}
							setDataTypeDetections={handleDataTypeDetectionsFor('request')}
						/>
					) : (
						<EmptyJsonAlert />
					)}
				</Preloader>
			</TabPanel>

			<TabPanel value={tab} index={tabs[1]}>
				<Preloader isLoading={isLoading}>
					{initialSample.response.json ? (
						<JsonView
							key="response"
							activePiiType={activePiiType}
							sampleData={initialSample.response as Required<SampleDataJson>}
							piiMarks={piiMarksList.response}
							hideFalsePositives={!isFalsePositiveVisible}
							setFalsePositive={setFalsePositiveFor('response')}
							removeFalsePositive={removeFalsePositive}
							removeManualDataType={removeManualDataType}
							setDataTypeDetections={handleDataTypeDetectionsFor('response')}
						/>
					) : (
						<EmptyJsonAlert />
					)}
				</Preloader>
			</TabPanel>
		</div>
	);

	const rightCurtainPart = (
		<>
			<Typo variant="D/Medium/Body" className={styles.rightPartTitle}>
				Fields with sensitive data
			</Typo>

			<div className={styles.tabPanels}>
				<TabPanel value={tab} index={tabs[0]}>
					<Preloader isLoading={isLoading}>
						<TableOfTypes
							sampleData={initialSample.request}
							piiMarks={piiMarksList.request}
							hideFalsePositives={!isFalsePositiveVisible}
							onClickPiiType={handlePiiTypeClick}
						/>
					</Preloader>
				</TabPanel>

				<TabPanel value={tab} index={tabs[1]}>
					<Preloader isLoading={isLoading}>
						<TableOfTypes
							sampleData={initialSample.response}
							piiMarks={piiMarksList.response}
							hideFalsePositives={!isFalsePositiveVisible}
							onClickPiiType={handlePiiTypeClick}
						/>
					</Preloader>
				</TabPanel>
			</div>
		</>
	);

	return (
		<>
			<SampleHeader sample={initialSample} direction={direction} id={id} />

			<Tabs value={tab}>
				<TabLink label="Request" value={tabs[0]} replace />
				<TabLink
					label={<ResponseWithCodes responseCodes={responseCodes} />}
					value={tabs[1]}
					replace
				/>

				<FPVisibilityToggler />
			</Tabs>

			<div className={styles.wrapper}>
				<CurtainShift
					open={curtainOpen}
					onClose={() => {
						setCurtainOpen(false);
					}}
					onOpen={() => {
						setCurtainOpen(true);
					}}
					classes={{ rightPartOpen: styles.curtain }}
					leftPart={leftCurtainPart}
					rightPart={rightCurtainPart}
				/>

				<AddManualDataTypeModal
					dataTypeDetections={dataTypeDetections}
					open={addModalState}
					onClose={() => setAddModalState(false)}
					onSubmit={addManualDataType}
				/>
			</div>
		</>
	);
}

function EmptyJsonAlert() {
	return (
		<Alert severity={AlertSeverity.info} className={styles.emptyJsonAlert}>
			Empty JSON body
		</Alert>
	);
}

function SampleWrapped() {
	return (
		<JsonErrorBoundary>
			<Sample />
		</JsonErrorBoundary>
	);
}

export default SampleWrapped;
