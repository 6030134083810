import CopyToClipboard from 'components/CopyToClipboard';
import { Tooltip } from 'components/Tooltip';
import { getAssetNameByType as getAssetNameByTypeText } from 'models/assets/model';
import { dataTypesById } from 'models/dataTypes/store';
import { methodOrProtocol } from 'models/endpointsV2/dto';
import { AssetEventJson } from 'models/eventsV2/dto';
import { PATHS } from 'services/router';
import { eventTitleAsText } from '../EventRow';
import JiraButton from '../JiraButton';
import { formatWithToday } from '../utils';
import Asset from './fields/Asset';
import styles from './index.module.pcss';

type Props = { event: AssetEventJson };

function AnomalyDetection(props: Props) {
	const { event } = props;

	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;
	const sendingAsset = event.direction === 'request' ? event.requestor : event.responder;
	const sendingAssetNameText = getAssetNameByTypeText(sendingAsset.type, sendingAsset.name);
	const receivingAsset = event.direction === 'request' ? event.responder : event.requestor;
	const receivingAssetNameText = getAssetNameByTypeText(receivingAsset.type, receivingAsset.name);

	const formattedLocal = formatWithToday(event.created_at);
	const titleAsText = eventTitleAsText(event);
	const jiraSummary = `Soveren issue: ${titleAsText}`;
	const jiraDescription = `*${titleAsText}*

*Event*: #${event.id}
*Time*: ${formattedLocal}
*Sensitivity*: ${event.sensitivity}
*Category*: Data flow change
*Data types*: ${event.data_types.map((dtId) => dataTypesById.getState()[dtId].name).join(', ')}
*Sending*: ${sendingAssetNameText}${sendingAsset.namespace ? ` • ${sendingAsset.namespace}` : ''}
*Receiving*: ${receivingAssetNameText}${
		receivingAsset.namespace ? ` • ${receivingAsset.namespace}` : ''
	}
*Endpoint*: [${methodOrProtocol(event)}] ${event.hostname}${event.url}

See on Soveren dashboard: ${linkHere}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<span className={styles.detailsLabel}>Event ID</span>
				<span>
					{event.id}{' '}
					<Tooltip
						title="Copy event link"
						placement="right"
						className={styles.copyToClipboardWrapper}
					>
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</span>

				<span className={styles.detailsLabel}>Category</span>
				<span>Anomaly detection</span>

				<span className={styles.detailsLabel}>Sending</span>
				<Asset asset={sendingAsset} dataTest="event-details-sending" />

				<span className={styles.detailsLabel}>Receiving</span>
				<Asset asset={receivingAsset} dataTest="event-details-receiving" />

				<span className={styles.detailsLabel}>Data volume change</span>
				<span>2 rps {'—>'} 10 rps</span>
			</div>

			{/* <EventDetailsAccordion */}
			{/* 	title="Sending" */}
			{/* 	rows={{ */}
			{/* 		'Resource name': sendingAsset.name, */}
			{/* 		'Resource type': event.object_type, */}
			{/* 		'Account ID': 'asd', */}
			{/* 		Cluster: sendingAsset.cluster_name, */}
			{/* 		Region: event.region, */}
			{/* 		Tag: '---', */}
			{/* 	}} */}
			{/* /> */}

			{/* <EventDetailsAccordion */}
			{/* 	title="Receiving" */}
			{/* 	rows={{ */}
			{/* 		'Resource name': receivingAsset.name, */}
			{/* 		'Resource type': event.object_type, */}
			{/* 		'Account ID': 'asd', */}
			{/* 		Cluster: receivingAsset.cluster_name, */}
			{/* 		Region: event.region, */}
			{/* 		Tag: '---', */}
			{/* 	}} */}
			{/* /> */}

			<div className={styles.links}>
				<JiraButton summary={jiraSummary} description={jiraDescription} />
			</div>
		</>
	);
}

export default AnomalyDetection;
