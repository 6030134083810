import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/form/Button';
import BigWhiteMultiSelect from 'components/form/MultiSelect/BigWhiteMultiSelect';
import BigWhiteSelect from 'components/form/Select/BigWhiteSelect';
import { LabelProps } from 'components/form/Select/Label';
import TextField from 'components/form/TextField';
import Preloader from 'components/Preloader';
import { SecretToken } from 'components/SecretToken';
import { enqueueSnackbar } from 'components/Snackbar';
import { Typo } from 'components/typography/Typo';
import { integrationsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import {
	createJiraIntegration,
	getJiraProjects,
	getJiraTicketFields,
} from 'models/integrations/jira/api';
import { JiraProjects, JiraTicketFields } from 'models/integrations/jira/dto';
import { getJiraSettingsFx, updateJiraSettingsFx } from 'models/integrations/jira/effects';
import { jiraSettingsStore } from 'models/integrations/jira/store';
import { PATHS } from 'services/router';
import styles from './index.module.css';

const dummyToken = new Array(192).fill('*').join('');

function Label({ label }: LabelProps) {
	if (typeof label === 'string') {
		return <Typo variant="D/Regular/Body-S">{label}</Typo>;
	}

	return label.secondary ? (
		<>
			<Typo variant="D/Regular/Meta" color="secondary" className={styles.dropdownSmallLabel}>
				{label.primary}
			</Typo>

			<Typo variant="D/Regular/Body-S">{label.secondary}</Typo>
		</>
	) : (
		<Typo variant="D/Regular/Body-S" color="secondary">
			{label.primary}
		</Typo>
	);
}

function BaseSettings() {
	const history = useHistory();

	const settings = useStore(jiraSettingsStore);

	const [baseUrl, setBaseUrl] = useState(settings.jira_base_url);
	const [username, setUsername] = useState(settings.jira_username);
	const [apiToken, setApiToken] = useState(settings.jira_api_token_is_set ? dummyToken : '');
	const [showError, setShowError] = useState(false);

	const connected = settings.jira_api_token_is_set;

	function saveBaseSettings() {
		setShowError(true);

		if (!baseUrl || !username || !apiToken) return;

		createJiraIntegration({
			jira_base_url: baseUrl,
			jira_username: username,
			jira_api_token: apiToken,
		})
			.then(() => getJiraSettingsFx())
			.catch(async (e) => {
				if (e.response.status === 422 || e.response.status === 400) {
					// By convention, 422 is Jira http errors passed through
					const errorBody = await e.response.json();
					enqueueSnackbar(errorBody.message);
				} else {
					throw e;
				}
			});
	}

	function onCancel() {
		history.goBack();
	}

	return (
		<div className={styles.baseSettings}>
			<Typo variant="D/Medium/Body">Connection settings</Typo>
			<Typo variant="D/Regular/Body-S" className={styles.description}>
				You can find this information in your Atlassian account settings.
			</Typo>

			<TextField
				label="Jira username"
				required
				value={username}
				onChange={(e) => setUsername(e.target.value)}
				disabled={connected}
				error={showError && !username}
				autoComplete="new-password"
				dataTest="jira-username-input"
			/>

			<TextField
				label="Jira base URL"
				required
				value={baseUrl}
				onChange={(e) => setBaseUrl(e.target.value)}
				disabled={connected}
				helperText={
					<Typo variant="D/Medium/Meta" component="span">
						<a
							href="https://confluence.atlassian.com/adminjiraserver/configuring-the-base-url-938847830.html"
							target="_blank"
							rel="noreferrer"
						>
							How to get it
						</a>
					</Typo>
				}
				error={showError && !baseUrl}
				autoComplete="new-password"
				dataTest="jira-baseurl-input"
			/>

			{!connected ? (
				<TextField
					label="API token"
					required
					value={apiToken}
					onChange={(e) => setApiToken(e.target.value)}
					disabled={connected}
					helperText={
						<Typo variant="D/Medium/Meta" component="span">
							<a
								href="https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/"
								target="_blank"
								rel="noreferrer"
							>
								How to get it
							</a>
						</Typo>
					}
					error={showError && !apiToken}
					dataTest="jira-apitoken-input"
				/>
			) : (
				<SecretToken
					label="API token"
					value={apiToken}
					disabled={connected}
					helperText={
						<Typo variant="D/Medium/Meta" component="span">
							<a
								href="https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/"
								target="_blank"
								rel="noreferrer"
							>
								How to get it
							</a>
						</Typo>
					}
					dataTest="jira-apitoken-input"
				/>
			)}

			{!connected && (
				<div className={styles.baseSettingsButtons}>
					<Button color="secondary" onClick={onCancel}>
						Cancel
					</Button>
					<Button onClick={saveBaseSettings} data-test="jira-save-base-settings">
						Next
					</Button>
				</div>
			)}
		</div>
	);
}

function TicketSettings() {
	const history = useHistory();

	const settings = useStore(jiraSettingsStore);

	const [projects, setProjects] = useState<JiraProjects['projects']>([]);
	const [issueTypes, setIssueTypes] = useState<JiraTicketFields['issue_types']>([]);
	const [priorities, setPriorities] = useState<JiraTicketFields['priorities']>([]);
	const [components, setComponents] = useState<JiraTicketFields['components']>([]);
	const [showError, setShowError] = useState(false);
	const [editMode, setEditMode] = useState(!settings.is_enabled);

	const [selectedProject, setSelectedProject] = useState<JiraProjects['projects'][number]>(
		settings.project
	);
	const [selectedIssueType, setSelectedIssueType] = useState<
		JiraTicketFields['issue_types'][number]
	>(settings.issue_type);
	const [selectedPriority, setSelectedPriority] = useState<JiraTicketFields['priorities'][number]>(
		settings.priority
	);
	const [selectedComponents, setSelectedComponents] = useState<JiraTicketFields['components']>(
		settings.components
	);

	useEffect(() => {
		if (!editMode) return;

		getJiraProjects().then((payload) => {
			setProjects(payload.projects);
			setSelectedProject(
				payload.projects.find((data) => data.id === selectedProject.id) || selectedProject
			);
		});
	}, [editMode]);

	useEffect(() => {
		const projectId = selectedProject?.id;

		if (!projectId || !editMode) return;

		getJiraTicketFields(projectId).then((payload) => {
			setIssueTypes(payload.issue_types);
			setSelectedIssueType(
				payload.issue_types.find((data) => data.id === selectedIssueType.id) || {
					id: '',
					name: '',
					icon: '',
				}
			);

			setPriorities(payload.priorities);
			setSelectedPriority(
				payload.priorities.find((data) => data.id === selectedPriority.id) || {
					id: '',
					name: '',
					icon: '',
				}
			);

			setComponents(payload.components);
			setSelectedComponents(
				payload.components.filter((data) =>
					selectedComponents.some((selected) => data.id === selected.id)
				)
			);
		});
	}, [selectedProject, editMode]);

	function saveTicketSettings() {
		setShowError(true);

		if (!selectedProject.id || !selectedIssueType.id || !selectedPriority.id) return;

		updateJiraSettingsFx({
			...settings,
			project: selectedProject,
			issue_type: selectedIssueType,
			priority: selectedPriority,
			components: selectedComponents,
			is_enabled: true,
		}).then(() => {
			enqueueSnackbar('Settings saved');
			history.push(PATHS.INTEGRATIONS);
		});
	}

	function onCancel() {
		history.goBack();
	}

	return (
		<div className={styles.issueSettingsContainer}>
			<div className={styles.issueSettings}>
				<Typo variant="D/Medium/Body">Issue settings</Typo>
				{!editMode && (
					<Button color="tertiary" onClick={() => setEditMode(true)}>
						Edit
					</Button>
				)}
			</div>

			{editMode ? (
				<>
					<BigWhiteSelect
						hasSearch
						label={{ primary: 'Project', secondary: selectedProject.name }}
						options={projects}
						value={selectedProject}
						onChange={setSelectedProject}
						render={{
							label: Label,
						}}
						dataTest="jira-project-select"
					/>
					<Typo variant="D/Regular/Meta-S" className={styles.error}>
						{showError && !selectedProject.id && 'Required field'}
					</Typo>

					{selectedProject.id && (
						<>
							<BigWhiteSelect
								hasSearch
								label={{ primary: 'Issue type', secondary: selectedIssueType.name }}
								options={issueTypes}
								value={selectedIssueType}
								onChange={setSelectedIssueType}
								render={{
									label: Label,
								}}
								dataTest="jira-issue-type-select"
							/>
							<Typo variant="D/Regular/Meta-S" className={styles.error}>
								{showError && !selectedIssueType.id && 'Required field'}
							</Typo>

							<BigWhiteSelect
								hasSearch
								label={{ primary: 'Priority', secondary: selectedPriority.name }}
								options={priorities}
								value={selectedPriority}
								onChange={setSelectedPriority}
								render={{
									label: Label,
								}}
								dataTest="jira-priority-select"
							/>
							<Typo variant="D/Regular/Meta-S" className={styles.error}>
								{showError && !selectedPriority.id && 'Required field'}
							</Typo>

							<BigWhiteMultiSelect
								hasSearch
								label={{
									primary: 'Components',
									secondary: selectedComponents.map(({ name }) => name).join(', '),
								}}
								options={components}
								value={selectedComponents}
								onChange={setSelectedComponents}
								render={{
									label: Label,
								}}
								dataTest="jira-components-multiselect"
							/>
						</>
					)}

					<div className={styles.baseSettingsButtons}>
						<Button color="secondary" onClick={onCancel}>
							Cancel
						</Button>
						<Button onClick={saveTicketSettings} data-test="jira-save-ticket-settings">
							Save {!settings.is_enabled && 'and enable'}
						</Button>
					</div>
				</>
			) : (
				<>
					<TextField label="Project" value={settings.project.name} disabled required />
					<TextField label="Issue type" value={settings.issue_type.name} disabled required />
					<TextField label="Priority" value={settings.priority.name} disabled required />
					<TextField
						label="Components"
						value={settings.components.map((component) => component.name).join(', ') || 'none'}
						disabled
						required
					/>
				</>
			)}
		</div>
	);
}

function Settings() {
	const [isLoading, setLoading] = useState(true);
	const settings = useStore(jiraSettingsStore);

	const connected = settings.jira_api_token_is_set;

	useEffect(() => {
		getJiraSettingsFx().then(() => {
			setLoading(false);
		});
	}, []);

	return (
		<>
			<Header>
				<Header.Breadcrumbs steps={integrationsSteps} finalStep="Jira integration configuration" />
				<Header.Title>Jira integration configuration</Header.Title>
			</Header>

			<Preloader isLoading={isLoading}>
				<div>
					<BaseSettings />

					{connected && <TicketSettings />}
				</div>
			</Preloader>
		</>
	);
}

export default Settings;
