import cn from 'classnames';
import { ReactNode } from 'react';
import { useRouteMatch } from 'react-router';
import { Link, NavLink as RouterLink } from 'react-router-dom';
import Icon, { IconProps } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';
import { Typo } from 'components/typography/Typo';
import styles from './index.module.pcss';

interface IRouterWrapper {
	to: string;
	children: ReactNode;
	className?: string;
	'data-test'?: string;
	disabled?: boolean;
	external?: boolean;
	soon?: boolean;
	beta?: boolean;
}

interface Props extends Omit<IRouterWrapper, 'children'> {
	activeIcon: IconProps['name'];
	collapsed: boolean;
	icon: IconProps['name'];
	indicator?: ReactNode;
	label: string;
}

function RouterWrapper({
	external,
	to,
	className,
	disabled,
	soon,
	beta,
	children,
	'data-test': dataTest,
}: IRouterWrapper) {
	if (!external) {
		return (
			<RouterLink
				to={to}
				activeClassName={styles.activeMenuItem}
				className={cn(className, styles.menuItem, {
					[styles.disabled]: disabled,
					[styles.soon]: soon,
					[styles.beta]: beta,
				})}
				data-test={dataTest}
			>
				{children}
			</RouterLink>
		);
	}

	return (
		<Link
			to={{ pathname: to }}
			target="_blank"
			rel="noopener"
			className={cn(className, styles.menuItem, {
				[styles.disabled]: disabled,
				[styles.soon]: soon,
				[styles.beta]: beta,
			})}
			data-test={dataTest}
		>
			{children}
		</Link>
	);
}

function Indicator({ content }: { content: ReactNode }) {
	return (
		<>
			<span
				className={cn(styles.indicator, styles.collapsedIndicator)}
				data-test="side-menu-item-indicator"
			>
				{content}
			</span>

			<span
				className={cn(styles.indicator, styles.expandedIndicator)}
				data-test="side-menu-item-indicator"
			>
				{content}
			</span>
		</>
	);
}

function MatchRouterLink(props: Props) {
	const { to, icon, indicator, activeIcon, label, collapsed } = props;
	const match = useRouteMatch({
		path: to,
	});

	return (
		<Typo variant="D/Medium/Body-S" data-test="side-menu-item">
			<RouterWrapper {...props}>
				{collapsed ? (
					<Tooltip title={label} placement="left" data-test="side-menu-item-icon-tooltip">
						<Icon
							className={styles.icon}
							name={match ? activeIcon : icon}
							size={20}
							dataTest="side-menu-item-icon"
						/>
					</Tooltip>
				) : (
					<Icon
						className={styles.icon}
						name={match ? activeIcon : icon}
						size={20}
						dataTest="side-menu-item-icon"
					/>
				)}

				<span className={styles.label} data-test="side-menu-item-label">
					{label}
				</span>

				{indicator && <Indicator content={indicator} />}
			</RouterWrapper>
		</Typo>
	);
}

export default MatchRouterLink;
