import cn from 'classnames';
import { MouseEvent, useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import Badge from 'components/Badge';
import ButtonIcon from 'components/ButtonIcon';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import { Tooltip } from 'components/Tooltip';
import { Typo } from 'components/typography/Typo';
import { DataTypesExtendedStructured } from 'models/dataTypesExtended/dto';
import { lastSeen } from 'services/lastSeen';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import styles from './index.module.css';

type Props = {
	data: DataTypesExtendedStructured;
	collapsed?: boolean;
	onCollapse?: () => void;
	isChild?: boolean;
};

function DataTypeRow(props: Props) {
	const { data, collapsed, onCollapse, isChild } = props;
	const {
		id,
		name,
		custom,
		sensitivity,
		assets,
		buckets,
		endpoints,
		kafka_topics,
		sql_db_databases,
		policies,
		last_seen,
		isUsed,
		child_items,
	} = data;

	const history = useHistory();
	const lastSeenValue = useMemo(() => lastSeen({ timestamp: last_seen, short: true }), [last_seen]);

	function clickHandler() {
		history.push(generatePath(PATHS.DATA_TYPES_ITEM, { id }));
	}

	const policyContent = useMemo(() => {
		return policies === 0 ? (
			<Button
				size="extraSmall"
				color="tertiary"
				className={styles.createPolicy}
				onClick={(e) => {
					const path = `${generatePath(PATHS.POLICY_ITEM, { id: 'new' })}?data_type=${id}`;
					history.push(path);
					e.stopPropagation();
				}}
			>
				Create policy
			</Button>
		) : (
			<Typo variant={isChild ? 'D/Medium/Body-S' : 'D/SemiBold/Body-S'}>
				{toLocaleString(policies)}
			</Typo>
		);
	}, [isChild, policies, id]);

	const prepareCount = (count: number) => {
		const countTitle = toLocaleString(count);

		return !isChild ? <Typo variant="D/SemiBold/Body-S">{countTitle}</Typo> : countTitle;
	};

	const assetsContent = useMemo(() => prepareCount(assets), [assets]);
	const bucketsContent = useMemo(() => prepareCount(buckets), [buckets]);
	const endpointsContent = useMemo(() => prepareCount(endpoints), [endpoints]);
	const topicsContent = useMemo(() => prepareCount(kafka_topics), [kafka_topics]);
	const databasesContent = useMemo(() => prepareCount(sql_db_databases), [sql_db_databases]);

	return (
		<GridRow
			className={styles.rowContainer}
			level={isChild ? 3 : 1}
			onClick={isChild && custom ? clickHandler : undefined}
			hover={isChild && custom}
			border
			data-test={`data-type-${name}-${isUsed ? 'used' : 'unused'}`}
		>
			<GridCell verticalAlign="middle" className={styles.flex}>
				<span className={cn({ [styles.unusedPiiType]: !isUsed }, styles.flex)}>
					{!!onCollapse && (
						<ButtonIcon
							icon="ChevronDown/Regular"
							size="S"
							className={cn(!collapsed && styles.expanded, styles.toggleButton)}
							onClick={(e: MouseEvent<HTMLElement>) => {
								e.stopPropagation();
								onCollapse();
							}}
						/>
					)}
					{custom && isChild && <Icon name="Tag/Filled" size={16} className={styles.icon} />}
					{name}
					{!isChild && <Badge badgeContent={child_items.length} className={styles.badge} />}
				</span>

				{custom && isChild && (
					<span className={styles.editLink} data-test="policy-row-edit-button">
						Edit
					</span>
				)}
			</GridCell>

			<GridCell verticalAlign="middle" className={cn({ [styles.unusedPiiType]: !isUsed })}>
				<SensitivityChip sensitivity={sensitivity} />
			</GridCell>

			<GridCell
				verticalAlign="middle"
				className={cn({ [styles.unusedPiiType]: !isUsed })}
				align="right"
			>
				{assetsContent}
			</GridCell>

			<GridCell
				verticalAlign="middle"
				className={cn({ [styles.unusedPiiType]: !isUsed })}
				align="right"
			>
				{endpointsContent}
			</GridCell>

			<GridCell
				verticalAlign="middle"
				className={cn({ [styles.unusedPiiType]: !isUsed })}
				align="right"
			>
				{topicsContent}
			</GridCell>

			<GridCell
				verticalAlign="middle"
				className={cn({ [styles.unusedPiiType]: !isUsed })}
				align="right"
			>
				{databasesContent}
			</GridCell>

			<GridCell
				verticalAlign="middle"
				className={cn({ [styles.unusedPiiType]: !isUsed })}
				align="right"
			>
				{bucketsContent}
			</GridCell>

			<GridCell verticalAlign="middle" align="right" withoutBlur>
				{policyContent}
			</GridCell>

			<GridCell
				align="right"
				className={cn({ [styles.unusedPiiType]: !isUsed })}
				verticalAlign="middle"
			>
				<Tooltip title={lastSeenValue.date}>{lastSeenValue.diff}</Tooltip>
			</GridCell>
		</GridRow>
	);
}

export default DataTypeRow;
