import { MenuList } from '@material-ui/core';
import cn from 'classnames';
import { Fragment, ReactElement, useCallback, useState } from 'react';
import { Divider } from 'components/Divider';
import { Option } from 'components/form/Select';
import selectStyles from 'components/form/Select/index.module.css';
import { SearchProps } from 'components/form/Select/Search';
import { Typo } from 'components/typography/Typo';
import { ApplyButtonProps } from './ApplyButton';
import styles from './index.module.css';
import { OptionItemProps } from './OptionItem';

const defaultAllOption: Option = {
	id: 0,
	name: 'Any',
};

const dividerElement = <Divider light />;

type OptionListProps<T> = {
	options: T[];
	allOption: boolean | string;
	value: T[];
	onChange: (value: T[], clickedOption: T | null) => void;
	onClose: () => void; // It is OptionList's responsibility to close (or not to close!) after clicking some option.
	hasApplyButton: boolean;
	dividerAfter: T[];
	renderOption: (props: OptionItemProps<T>) => ReactElement | null;
	renderSearch: (props: SearchProps) => ReactElement | null;
	renderApplyButton: (props: ApplyButtonProps) => ReactElement | null;
};

// Common overrides: header; custom options (to be considered); handling of interdependent options (e.g. mutually exclusive).
function OptionList<T>(props: OptionListProps<T>) {
	const {
		options,
		allOption,
		value: externalValue,
		onChange: externalOnChange,
		onClose,
		hasApplyButton,
		dividerAfter,
		renderOption,
		renderSearch,
		renderApplyButton,
	} = props;

	const [searchString, setSearchString] = useState('');
	const [internalValue, setInternalValue] = useState(externalValue);
	const [internalClickedOption, setInternalClickedOption] = useState<T | null>(null);

	const value = hasApplyButton ? internalValue : externalValue;

	const onChange = useCallback(
		hasApplyButton
			? function (newValue, clickedOption) {
					setInternalValue(newValue);
					setInternalClickedOption(clickedOption);
			  }
			: externalOnChange,
		[externalOnChange, hasApplyButton]
	);

	const searchElement = renderSearch({ value: searchString, onChange: setSearchString });

	let optionElements = options.reduce(
		(acc, option) => {
			const optionElement = renderOption({
				option,
				selected: value.includes(option),
				searchString,
				onClick: () => {
					const newValue = value.includes(option)
						? value.filter((v) => v !== option)
						: value.concat(option);
					onChange(newValue, option);
				},
			});

			if (optionElement) {
				acc.push(optionElement);
				if (dividerAfter.includes(option)) {
					acc.push(dividerElement);
				}
			}

			return acc;
		},
		[] as (ReactElement | null)[]
	);

	if (allOption) {
		const allOptionItem =
			allOption === true ? defaultAllOption : { ...defaultAllOption, name: allOption };

		const allOptionElement = renderOption({
			option: allOptionItem,
			selected: value.length === 0,
			searchString,
			onClick: () => {
				onChange([], null);
			},
		});

		if (allOptionElement) {
			optionElements = [allOptionElement, dividerElement, ...optionElements];
		}
	}

	if (optionElements[optionElements.length - 1] === dividerElement) {
		optionElements.pop();
	}

	const noOptionsText = searchElement && searchString ? 'No matching results' : 'No items';

	const applyButtonElement = renderApplyButton({
		onClick: () => {
			externalOnChange(internalValue, internalClickedOption);
			onClose();
		},
	});

	return (
		<MenuList className={selectStyles.menuList}>
			{searchElement}

			{!!searchElement && dividerElement}

			<div className={cn(selectStyles.scrollContainer, styles.scrollContainer)}>
				{optionElements.length > 0 ? (
					optionElements.map((optionElement, i) => <Fragment key={i}>{optionElement}</Fragment>)
				) : (
					<Typo variant="D/Regular/Body-S" color="secondary" className={selectStyles.noOptions}>
						{noOptionsText}
					</Typo>
				)}
			</div>

			{applyButtonElement}
		</MenuList>
	);
}

export default OptionList;
export type { OptionListProps };
