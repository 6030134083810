import cn from 'classnames';
import { useEffect, useState, useCallback } from 'react';
import Preloader from 'components/Preloader';
import { Typo } from 'components/typography/Typo';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { getEvents } from 'models/eventsV2/api';
import { EventJson } from 'models/eventsV2/dto';
import { getRecentActivityFx } from 'models/recentActivity/effects';
import { getReleaseFx } from 'models/release/effects';
import { PATHS } from 'services/router';
import { ReleaseUpdateAlert } from 'views/common/ReleaseUpdateAlert';
import { APICalls } from './APICalls';
import { DataDistributionByRegions } from './DataDistributionByRegions';
import { DataStoresOverview } from './DataStoresOverview';
import { DataTypesOverview } from './DataTypesOverview';
import { HighPriorityEvents } from './HighPriorityEvents';
import styles from './index.module.css';
import { MajorPolicyViolations } from './MajorPolicyViolations';
import { PolicyEventsByCategory } from './PolicyEventsByCategory';
import { WidgetContainer } from './WidgetContainer';

function Dashboard() {
	const [isLoading, setLoading] = useState(true);

	// Events on chart and in list are connected with hovered state
	const [events, setEvents] = useState<EventJson[] | null>(null);
	const [hovered, setHovered] = useState(-1);

	const onMouseEnter = useCallback(function (e) {
		setHovered(+e.currentTarget.dataset.i);
	}, []);
	const onMouseLeave = useCallback(function (e) {
		const { i } = e.currentTarget.dataset;
		setHovered((h) => (h === +i ? -1 : h));
	}, []);

	useEffect(() => {
		Promise.all([
			getEvents({ severity: 'high', sort: 'created_at:desc', limit: 5 }),
			getRecentActivityFx(),
			getReleaseFx(),
		]).then(([eventsFromServer]) => {
			setLoading(false);
			setEvents(eventsFromServer.log_events);
		});
	}, []);

	return (
		<>
			<Header>
				<Header.Breadcrumbs hideHome />
				<Header.Title>Dashboard</Header.Title>
			</Header>

			<Preloader isLoading={isLoading}>
				<ReleaseUpdateAlert type="dim" />
				<ReleaseUpdateAlert type="dar" />

				<div className={cn(styles.twoWidgets, styles.marginTop, styles.minRightWidgetSize)}>
					<WidgetContainer title="API calls" dataTest="api-calls-widget">
						<APICalls
							events={events}
							hovered={hovered}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						/>
					</WidgetContainer>

					<WidgetContainer
						flex={null}
						title="High-priority events"
						counter={events?.length}
						seeAllLink={PATHS.EVENTS}
						dataTest="high-priority-widget"
					>
						<HighPriorityEvents
							events={events}
							hovered={hovered}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
						/>
					</WidgetContainer>
				</div>

				<Typo variant="D/Medium/H100-Header" className={styles.title}>
					Inventory
				</Typo>

				<div className={cn(styles.twoWidgets, styles.marginBottom)}>
					<WidgetContainer
						title="Data types"
						seeAllLink={PATHS.DATA_TYPES_LIST}
						dataTest="data-types-widget"
					>
						<DataTypesOverview />
					</WidgetContainer>

					<WidgetContainer title="Data distribution by regions" dataTest="data-distribution-widget">
						<DataDistributionByRegions />
					</WidgetContainer>
				</div>

				<WidgetContainer
					title="Data stores"
					seeAllLink={PATHS.DATA_STORAGES}
					noPadding
					dataTest="data-stores-widget"
				>
					<DataStoresOverview />
				</WidgetContainer>

				<Typo variant="D/Medium/H100-Header" className={styles.title}>
					Compliance
				</Typo>

				<div className={cn(styles.twoWidgets, styles.paddingBottom)}>
					<WidgetContainer
						title="Policy events"
						seeAllLink={PATHS.EVENTS}
						dataTest="policy-events-widget"
					>
						<PolicyEventsByCategory />
					</WidgetContainer>

					<WidgetContainer
						title="Major policy violations"
						seeAllLink={PATHS.POLICY_LIST}
						dataTest="policy-violations-widget"
					>
						<MajorPolicyViolations />
					</WidgetContainer>
				</div>
			</Preloader>
		</>
	);
}

export { Dashboard };
