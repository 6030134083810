import { generatePath } from 'react-router';
import Chip from 'components/Chip';
import CopyToClipboard from 'components/CopyToClipboard';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import { Tooltip } from 'components/Tooltip';
import { RouterLink } from 'components/typography/Link';
import { getAssetNameByType as getAssetNameByTypeText } from 'models/assets/model';
import { dataTypesById } from 'models/dataTypes/store';
import { methodOrProtocol } from 'models/endpointsV2/dto';
import { PolicyEventJson } from 'models/eventsV2/dto';
import { PATHS } from 'services/router';
import { eventTitleAsText } from '../EventRow';
import JiraButton from '../JiraButton';
import { formatWithToday } from '../utils';
import { EventDetailsAccordion } from './EventDetailsAccordion';
import { Asset2 } from './fields/Asset';
import { Endpoint } from './fields/Endpoint';
import styles from './index.module.pcss';

type Props = { event: PolicyEventJson };

// eslint-disable-next-line complexity
function PolicyEvent(props: Props) {
	const { event } = props;

	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;
	const sendingAsset = event.direction === 'request' ? event.requestor : event.responder;
	const sendingAssetNameText = getAssetNameByTypeText(sendingAsset.type, sendingAsset.name);
	const receivingAsset = event.direction === 'request' ? event.responder : event.requestor;
	const receivingAssetNameText = getAssetNameByTypeText(receivingAsset.type, receivingAsset.name);
	const eventRegardingAsset =
		event.event_regarding === 'requestor' ? event.requestor : event.responder;
	const interactsWithAsset =
		event.event_regarding === 'requestor' ? event.responder : event.requestor;

	const pathToEvidence = generatePath(PATHS.EVENT_SAMPLE, { eventId: event.id });
	const pathToPolicies = `${PATHS.POLICY_LIST}?data-types=${event.data_types.join(',')}`;
	const pathToDataMap = `${PATHS.DATA_MAP}?entityId=${eventRegardingAsset.id}&type=${eventRegardingAsset.type}&interacts-with=${interactsWithAsset.id}`;

	const formattedLocal = formatWithToday(event.created_at);
	const titleAsText = eventTitleAsText(event);
	const jiraSummary = `Soveren issue: ${titleAsText}`;
	const jiraDescription = `*${titleAsText}*

*Event*: #${event.id}
*Time*: ${formattedLocal}
${event.type === '3rd_party_policy_violation' ? `*Policy*: ${event.policy_name}` : ''}
*Sensitivity*: ${event.sensitivity}
*Category*: Policy violation
*Data types*: ${event.data_types.map((dtId) => dataTypesById.getState()[dtId].name).join(', ')}
*Sending*: ${sendingAssetNameText}${sendingAsset.namespace ? ` • ${sendingAsset.namespace}` : ''}
*Receiving*: ${receivingAssetNameText}${
		receivingAsset.namespace ? ` • ${receivingAsset.namespace}` : ''
	}
*Endpoint*: [${methodOrProtocol(event)}] ${event.hostname}${event.url}

See on Soveren dashboard: ${linkHere}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<span className={styles.detailsLabel}>Event ID</span>
				<span>
					{event.id}{' '}
					<Tooltip
						title="Copy event link"
						placement="right"
						className={styles.copyToClipboardWrapper}
					>
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</span>

				<span className={styles.detailsLabel}>Data types</span>
				<PiiTypeList data={event.data_types} noHighlight dataTest="event-details-data-types" />

				<span className={styles.detailsLabel}>Severity</span>
				<SensitivityChip sensitivity={event.sensitivity} data-test="event-details-sensitivity" />

				{event.type === '3rd_party_policy_violation' && (
					<>
						<span className={styles.detailsLabel}>Related policy</span>
						<RouterLink
							data-test="event-details-policy"
							inherit
							// REMOVE THE OPTIONAL MARK!!!
							to={generatePath(PATHS.POLICY_ITEM, { id: event.policy_id || 0 })}
						>
							{event.policy_name}
						</RouterLink>

						<span className={styles.detailsLabel}>Policy tag</span>
						<span className={styles.detailsValueChipArray}>
							{event.policy_tags.map((tag) => (
								<Chip key={tag} label={tag} size="small" color="secondary" theme="assetNeutral" />
							))}
						</span>
					</>
				)}

				<span className={styles.detailsLabel}>Category</span>
				<span>Policy violation</span>

				<Endpoint event={event} sendingAsset={sendingAsset} />
			</div>

			<EventDetailsAccordion
				title="Sending"
				rows={{
					'Resource name': Asset2({ asset: sendingAsset }),
					'Resource type': event.object_type,
					Namespace: sendingAsset.namespace,
					Cluster: sendingAsset.cluster_name,
					Region: event.region,
				}}
			/>

			<EventDetailsAccordion
				title="Receiving"
				rows={{
					'Resource name': Asset2({ asset: receivingAsset }),
					'Resource type': event.object_type,
					Namespace: receivingAsset.namespace,
					Cluster: receivingAsset.cluster_name,
					Region: event.region,
				}}
			/>

			<div className={styles.links}>
				<JiraButton summary={jiraSummary} description={jiraDescription} />

				<Button
					size="small"
					color="tertiary"
					endIcon={<Icon name="Open/Regular" size={20} />}
					href={pathToEvidence}
					target="_blank"
				>
					View sample
				</Button>

				{event.type === 'policy_violation' && (
					<RouterLink to={pathToPolicies}>
						<Button size="small" color="tertiary">
							Go to policies
						</Button>
					</RouterLink>
				)}

				<RouterLink to={pathToDataMap} data-test="data-map-link">
					<Button size="small" color="tertiary">
						View on data map
					</Button>
				</RouterLink>
			</div>
		</>
	);
}

export default PolicyEvent;
