import { OutlinedInputProps } from '@material-ui/core';
import {
	default as UiTextField,
	TextFieldProps as UiTextFieldProps,
} from '@material-ui/core/TextField';
import cn from 'classnames';
import { useMemo, forwardRef, ForwardedRef } from 'react';
import { Control, FieldValues, Path, RegisterOptions, useController } from 'react-hook-form';
import { validateEmail, validateUrl } from 'services/validation';
import styles from './index.module.pcss';

type TextFieldProps = Omit<UiTextFieldProps, 'size'> & {
	size?: 'large' | 'medium' | 'small' | 'extraSmall';
	readOnly?: boolean;
	pattern?: string;
	variant?: 'standard' | 'filled' | 'outlined';
	optional?: boolean;
	displayOptionalText?: boolean;
	validator?: (value: string) => string | void;
	dataTest?: string;
	InputProps?: OutlinedInputProps;
};

export function ControlledTextField({
	FormHelperTextProps,
	InputLabelProps,
	InputProps,
	multiline,
	size = 'medium',
	label,
	required,
	readOnly,
	optional = !required,
	displayOptionalText = true,
	helperText = ' ',
	dataTest,
	...props
}: TextFieldProps) {
	const resultLabel = label
		? `${label}${optional && displayOptionalText ? ' (optional)' : ''}`
		: '';

	return (
		// @ts-ignore
		<UiTextField
			classes={{ root: styles.controlRoot }}
			variant="outlined"
			label={resultLabel}
			required={required}
			multiline={multiline}
			data-test={dataTest}
			InputLabelProps={{
				required: false,
				...InputLabelProps,
				classes: {
					root: cn(styles.labelRoot, styles[`${size}LabelSize`], {
						[styles.labelReadOnly]: readOnly,
					}),
					focused: styles.labelFocused,
					error: styles.labelError,
					shrink: styles.labelShrink,
				},
			}}
			InputProps={{
				readOnly,
				...InputProps,
				classes: {
					...InputProps?.classes,
					disabled: styles.inputDisabled,
					root: cn(styles.inputRoot, InputProps?.classes?.root, {
						[styles.readOnly]: readOnly,
					}),
					error: styles.inputError,
					focused: styles.inputFocused,
					input: cn(
						multiline ? styles.textarea : styles.inputInput,
						InputProps?.classes?.input,
						styles[`${size}InputSize`]
					),
					notchedOutline: cn(InputProps?.classes?.notchedOutline, styles.inputNotchedOutline),
					multiline: styles.multiline,
					adornedEnd: styles.adornedEnd,
					adornedStart: styles.adornedStart,
				},
			}}
			FormHelperTextProps={{
				...FormHelperTextProps,
				// @ts-ignore : Object literal may only specify known properties, and 'data-test' does not exist in type Partial<FormHelperTextProps>
				'data-test': 'input-helper-text',
				classes: {
					root: cn(styles.helperTextRoot, styles[`${size}HelperText`]),
					error: styles.helperTextError,
				},
			}}
			helperText={helperText}
			{...props}
		/>
	);
}

const errorMessages: { [key: string]: string } = {
	required: 'Field is required',
	pattern: 'Wrong format',
	validateEmail: 'Wrong email format',
	validateUrl: 'Wrong url format',
};

type TextFieldInFormProps<T> = TextFieldProps & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: Control<any, object>;
	name: Path<T>;
};

export const TextFieldInForm = forwardRef(function TextFieldInForm<T extends FieldValues>(
	props: TextFieldInFormProps<T>,
	ref: ForwardedRef<HTMLDivElement>
) {
	const { name, control, error, helperText, InputProps, ...rest } = props;

	const rules = useMemo(() => {
		const result: RegisterOptions = {};
		if (props.required) result.required = true;
		if (props.pattern) result.pattern = new RegExp(props.pattern, 'g');
		if (props.type === 'email') {
			result.validate = { validateEmail };
		} else if (props.type === 'url') {
			result.validate = { validateUrl };
		}

		return result;
	}, [props.required, props.pattern, props.type]);

	const { field, fieldState } = useController({ name, control, rules });

	const errorType: string = String(fieldState.error?.type);
	const errorMessage = fieldState.error?.message || errorMessages[errorType];

	return (
		<ControlledTextField
			InputProps={{
				required: false, // disable browser validation
				...InputProps,
			}}
			error={fieldState.invalid || error}
			helperText={fieldState.invalid ? errorMessage : helperText}
			{...field}
			{...rest}
			ref={ref}
		/>
	);
});

function TextField<T extends FieldValues>(props: TextFieldProps | TextFieldInFormProps<T>) {
	return 'control' in props ? <TextFieldInForm {...props} /> : <ControlledTextField {...props} />;
}

export type { TextFieldProps, TextFieldInFormProps };

/*
	All right, that's a weird one. Apparently, memo() messes with TS types and
	makes TextFieldInForm complain about incompatibilities(!):

			Type 'Control<FormValues, any>' is not assignable to type 'Control<unknown, any>'

	So I'm commenting memo() out, we have no real use-case for it now, anyway.
*/
// export default memo(TextField);
export default TextField;
