import cn from 'classnames';
import { useStore } from 'effector-react';
import { Typo } from 'components/typography/Typo';
import { gatewaysList } from 'models/gateways/store';
import styles from './index.module.pcss';
import NewGatewayButton from './NewGatewayButton';

interface Props {
	className?: string;
}

function NewGatewayBlock({ className }: Props) {
	const gateways = useStore(gatewaysList);

	const noGatewaysExist = !gateways.length;

	return (
		<div className={cn(className, styles.container)}>
			<Typo color="secondary" variant="D/Regular/Body-S" className={styles.description}>
				No available analytics{noGatewaysExist && ' or no Agents set up yet'}.
			</Typo>

			{noGatewaysExist && <NewGatewayButton />}
		</div>
	);
}

export default NewGatewayBlock;
