import { InputAdornment } from '@material-ui/core';
import cn from 'classnames';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import Accordion from 'components/Accordion';
import AccordionDetails from 'components/AccordionDetails';
import AccordionSummary from 'components/AccordionSummary';
import Alert, { AlertSeverity } from 'components/Alert';
import ButtonIcon from 'components/ButtonIcon';
import TextField from 'components/form/TextField';
import Icon from 'components/Icon';
import { enqueueSnackbar } from 'components/Snackbar';
import { Tooltip } from 'components/Tooltip';
import { Typo } from 'components/typography/Typo';
import { AIChat, AIChatContent } from 'models/aiCopilot/dto';
import { createAIChatFx, getAIChatFx, updateAIChatFx } from 'models/aiCopilot/effects';
import { APIError } from 'services/api/httpRequest';
import { PATHS } from 'services/router';
import { debugSettings } from 'views/common/DebugOverlay';
import { ChatPreloader } from './ChatPreloader';
import styles from './index.module.pcss';

export function Chat() {
	const initialChat = { session_id: -1, messages: [], closed: false };
	const { sessionId }: { sessionId: string } = useParams();
	const [chat, setChat] = useState<AIChat>(initialChat);
	const [tempChat, setTempChat] = useState<AIChat | null>(null);
	const [userContent, setUserContent] = useState('');
	const [loading, setLoading] = useState(true);
	const [polling, setPolling] = useState(false);

	const endRef = useRef<null | HTMLDivElement>(null);
	const inputRef = useRef<null | HTMLInputElement>(null);

	const history = useHistory();

	// Initial block
	useEffect(() => {
		// Clear temporary data when we go through sessions
		setTempChat(null);

		if (sessionId === undefined) {
			setChat(initialChat);

			setLoading(false);
		} else {
			getChat();
		}
	}, [sessionId]);

	// Scrolling block
	const scrollToBottom = () => {
		endRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		scrollToBottom();
		inputRef.current?.focus();
	}, [chat, sessionId, loading]);

	function getChat() {
		getAIChatFx({ sessionId: +sessionId, abortSignal: true })
			.then((newChat) => {
				setChat(newChat);

				// !!! Polling condition !!!
				if (newChat.messages[newChat.messages.length - 1].role === 'user') {
					setPolling(true);
				} else {
					setPolling(false);
				}
			})
			.catch(async (error) => {
				if (error instanceof APIError && error.response.status === 404) {
					const { message } = await error.response.json();
					enqueueSnackbar(message);
				} else {
					throw error;
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}

	function onEnter(content: AIChatContent['content']) {
		// We need to see user message before we get the response
		setChat({ ...chat, messages: [...chat.messages, { role: 'user', content }] });

		setLoading(true);

		if (sessionId === undefined) {
			createAIChatFx({ content })
				.then((_chat) => {
					const path = generatePath(PATHS.AI_COPILOT, {
						sessionId: _chat.session_id,
					});

					setLoading(false);
					history.push(path);
				})
				.catch(async (error) => {
					if (error instanceof APIError && error.response.status === 404) {
						const { message } = await error.response.json();
						enqueueSnackbar(message);
					} else {
						throw error;
					}
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			updateAIChatFx({ sessionId: +sessionId, content: userContent })
				.then((newChat) => {
					// If, after sending a message to the chat, we switch to another session,
					// we must not modify its messages when the request is completed
					// and we save it in the temporary data
					setTempChat(newChat);

					setLoading(false);
				})
				.catch(async (error) => {
					if (error instanceof APIError && error.response.status === 404) {
						const { message } = await error.response.json();
						enqueueSnackbar(message);
					} else {
						throw error;
					}
				})
				.finally(() => {
					setLoading(false);
				});
		}

		setUserContent('');
	}

	// Here we set temporary data
	useEffect(() => {
		if (tempChat?.session_id === +sessionId && tempChat && !polling) {
			setChat(tempChat);
			setTempChat(null);
		}
	}, [sessionId, tempChat]);

	// Polling block
	let pollingTimer: NodeJS.Timer;

	useEffect(() => {
		if (polling) {
			const startPolling = () => {
				pollingTimer = setInterval(() => {
					getChat();
				}, 10000); // Poll every 10 seconds
			};

			startPolling();

			return () => {
				clearInterval(pollingTimer);
			};
		} else {
			clearInterval(pollingTimer);
		}
	}, [polling]);

	function copyToClipboard(data: string) {
		navigator.clipboard.writeText(data).then(() => {
			enqueueSnackbar('System message copied');
		});
	}

	return (
		<div className={styles.chatContainer}>
			<div className={styles.chat}>
				{sessionId === undefined && !loading && !polling ? (
					<Typo variant="D/Medium/H500-Greeting-Title" className={styles.emptyChatDescription}>
						<Icon name="Soveren/Filled" size={36} />
						Welcome to our AI Copilot!
						<br />
						How can I assist you today?
					</Typo>
				) : (
					chat.messages.map((message, index) => {
						if (message.role === 'user') {
							return (
								<Typo key={index} className={styles.user} variant="D/Medium/Body-S">
									{message.content}
								</Typo>
							);
						} else if (message.role === 'system') {
							return (
								debugSettings.FLAG_AI_ASSISTANCE_SYSTEM && (
									<div key={index} className={styles.messageContainer}>
										<Icon name="Soveren/Filled" className={styles.systemMessageIcon} />

										<Accordion defaultExpanded={false} key={index} isDivider={index !== 0}>
											<AccordionSummary
												classes={{ content: styles.message, root: styles.accordionSummery }}
											>
												<Typo className={styles.messageText} variant="D/Medium/Body-S">
													System message{' '}
												</Typo>

												<ButtonIcon
													icon="Copy/Regular"
													onClick={(e) => {
														e.stopPropagation();
														copyToClipboard(message.content);
													}}
													size="S"
													data-test="secret-token"
												/>
											</AccordionSummary>

											<AccordionDetails
												classes={{ root: styles.accordionDetailsRoot }}
												className={styles.accordionDetails}
											>
												<Typo
													className={cn(styles.message, styles.system)}
													variant="D/Medium/Body-S"
													component="div"
												>
													<ReactMarkdown className={styles.messageText}>
														{message.content}
													</ReactMarkdown>
												</Typo>
											</AccordionDetails>
										</Accordion>
									</div>
								)
							);
						}

						return (
							<div className={styles.messageContainer} key={index}>
								<Icon name="Soveren/Filled" />

								<Typo
									className={cn(styles.message, styles.assistant)}
									variant="D/Medium/Body-S"
									component="div"
								>
									<ReactMarkdown className={styles.messageText}>{message.content}</ReactMarkdown>
								</Typo>
							</div>
						);
					})
				)}

				{(loading || polling) && (
					<div className={styles.messageContainer}>
						<Icon name="Soveren/Filled" />
						<ChatPreloader />
					</div>
				)}

				<div ref={endRef} />
			</div>

			{chat.closed ? (
				<Alert severity={AlertSeverity.warning} header="Message Limit Reached">
					This beta chat and has reached the message limit. Please start a new chat to proceed.
				</Alert>
			) : (
				<TextField
					autoFocus
					InputProps={{
						inputProps: {
							ref: inputRef,
						},
						classes: {
							input: styles.input,
						},
						endAdornment: (
							<InputAdornment position="end" className={styles.enterIcon}>
								<Tooltip title="Press Enter to send the message." placement="top">
									<Icon name="ArrowEnterLeft/Regular" size={20} />
								</Tooltip>
							</InputAdornment>
						),
					}}
					helperText={null}
					placeholder="Type your question here..."
					multiline
					fullWidth
					minRows={3}
					maxRows={5}
					value={userContent}
					onChange={({ target }) => setUserContent(target.value)}
					onKeyDown={(e: KeyboardEvent<HTMLElement>) => {
						if (e.key === 'Enter' && !e.shiftKey) {
							e.preventDefault();
							onEnter(userContent);
						}
					}}
				/>
			)}
		</div>
	);
}
