import { InputAdornment } from '@material-ui/core';
import { useEffect } from 'react';
import TextField from 'components/form/TextField';
import Icon from 'components/Icon';
import { Tooltip } from 'components/Tooltip';
import styles from '../index.module.css';
import { SearchProps } from '../Search.tsx';
import currentStyles from './index.module.css';

const SearchWithManual = (
	props: SearchProps & { defaultValue: string; onEnter: (value: SearchProps['value']) => void }
) => {
	const { value = '', onChange, defaultValue, onEnter } = props;

	useEffect(() => {
		onChange(defaultValue);
	}, []);

	return (
		<TextField
			autoFocus
			value={value}
			onChange={(e) => {
				onChange(e.target.value);
			}}
			placeholder="Search"
			size="small"
			helperText={null}
			onKeyDown={(e) => {
				// This is to prevent MenuList from focusing on list item, when you
				// enter something in Search input field.
				e.stopPropagation();

				if (e.key === 'Enter') {
					onEnter(value);
					e.preventDefault();
				}
			}}
			className={styles.searchInput}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end" className={styles.inputAdornment}>
						{value.length === 0 ? (
							<Icon name="search" size={20} />
						) : (
							<Tooltip title="Add custom region" placement="top">
								<div className={currentStyles.addIcon} onClick={() => onEnter(value)}>
									<Icon
										name="AddCircle/Regular"
										size={20}
										className={styles.inputAdornmentAddCircle}
									/>
								</div>
							</Tooltip>
						)}
					</InputAdornment>
				),
			}}
		/>
	);
};

export default SearchWithManual;
