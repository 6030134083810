import { InputAdornment } from '@material-ui/core';
import { useStore } from 'effector-react';
import { useEffect } from 'react';
import Badge from 'components/Badge';
import TextField from 'components/form/TextField';
import Icon from 'components/Icon';
import LoadMoreButton from 'components/LoadMoreButton';
import Preloader from 'components/Preloader';
import { Typo } from 'components/typography/Typo';
import { TypoHighlighted } from 'components/typography/TypoHighlighted';
import { assetIPsModel, assetUserAgentsModel } from 'models/assetDetails/model';
import { AssetJson } from 'models/assets/dto';
import { toLocaleString } from 'services/numbers';
import styles from './index.module.css';

type Props = {
	asset: AssetJson;
};

function AssetDetails(props: Props) {
	const { asset } = props;

	const ipStore = useStore(assetIPsModel.store);
	const userAgentStore = useStore(assetUserAgentsModel.store);

	useEffect(() => {
		assetIPsModel.fetchFx({ asset: asset.id, search: '' });
	}, [asset.id]);

	useEffect(() => {
		assetUserAgentsModel.fetchFx({
			asset: asset.id,
			search: '',
		});
	}, [asset.id]);

	function onIPSearchChange(search: string) {
		assetIPsModel.fetchFx({ search });
	}

	function onUserAgentSearchChange(search: string) {
		assetUserAgentsModel.fetchFx({ search });
	}

	return (
		<div className={styles.container}>
			<div className={styles.listContainer}>
				<div className={styles.header}>
					<Typo variant="D/Medium/H100-Header">IPs</Typo>
					<div className={styles.badgeContainer}>
						<Badge badgeContent={ipStore.total} data-test="ip-badge" />
					</div>

					<TextField
						value={ipStore.params.search}
						onChange={(e) => {
							onIPSearchChange(e.target.value);
						}}
						placeholder="Search IPs"
						size="small"
						helperText={null}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon name="search" size={20} />
								</InputAdornment>
							),
						}}
						data-test="ip-search-input"
					/>
				</div>

				<Preloader isLoading={ipStore.status === 'loading'}>
					<>
						<div className={styles.items} data-test="ip-list">
							{ipStore.status === 'loaded' && ipStore.params.search && (
								<Typo variant="D/Medium/Body-S">
									{toLocaleString(ipStore.total_filtered || 0)} IPs found
								</Typo>
							)}

							{ipStore.status === 'loaded' && !ipStore.params.search && ipStore.total === 0 && (
								<Typo variant="D/Regular/Body-S" color="secondary">
									No IPs yet.
								</Typo>
							)}

							{ipStore.data.map(({ value }) => {
								const { search } = ipStore.params;
								if (!search)
									return (
										<Typo key={value} data-test="ip-item" variant="D/Regular/Body-S">
											{value}
										</Typo>
									);

								return (
									<TypoHighlighted
										key={value}
										search={search}
										variant="D/Regular/Body-S"
										dataTest="ip-item"
									>
										{value}
									</TypoHighlighted>
								);
							})}

							<LoadMoreButton
								show={ipStore.hasMoreData}
								loading={ipStore.status === 'loadingMore'}
								request={assetIPsModel.fetchMoreFx}
							/>
						</div>
					</>
				</Preloader>
			</div>

			<div className={styles.listContainer}>
				<div className={styles.header}>
					<Typo variant="D/Medium/H100-Header">User agents</Typo>
					<div className={styles.badgeContainer}>
						<Badge badgeContent={userAgentStore.total} data-test="user-agent-badge" />
					</div>

					<TextField
						value={userAgentStore.params.search}
						onChange={(e) => {
							onUserAgentSearchChange(e.target.value);
						}}
						placeholder="Search user agents"
						size="small"
						helperText={null}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon name="search" size={20} />
								</InputAdornment>
							),
						}}
						data-test="user-agent-search-input"
					/>
				</div>

				<Preloader isLoading={userAgentStore.status === 'loading'}>
					<>
						<div className={styles.items} data-test="user-agent-list">
							{userAgentStore.status === 'loaded' && userAgentStore.params.search && (
								<Typo variant="D/Medium/Body-S">
									{toLocaleString(userAgentStore.total_filtered || 0)} user agents found
								</Typo>
							)}

							{userAgentStore.status === 'loaded' &&
								!userAgentStore.params.search &&
								userAgentStore.total === 0 && (
									<Typo variant="D/Regular/Body-S" color="secondary">
										No user agents yet.
									</Typo>
								)}

							{userAgentStore.data.map(({ value }) => {
								const { search } = userAgentStore.params;
								if (!search)
									return (
										<span key={value} data-test="user-agent-item">
											{value}
										</span>
									);

								return (
									<TypoHighlighted
										key={value}
										search={search}
										variant="D/Regular/Body-S"
										dataTest="user-agent-item"
									>
										{value}
									</TypoHighlighted>
								);
							})}

							<LoadMoreButton
								show={userAgentStore.hasMoreData}
								loading={userAgentStore.status === 'loadingMore'}
								request={assetUserAgentsModel.fetchMoreFx}
							/>
						</div>
					</>
				</Preloader>
			</div>
		</div>
	);
}

export default AssetDetails;
