import cn from 'classnames';
import newGatewayStyles from 'components/NewGatewayBlock/index.module.pcss';
import { Typo } from 'components/typography/Typo';
import { CreateButton } from '../CreateButton';
import styles from './index.module.css';

export function NoAccountsBlock() {
	return (
		<div className={cn(newGatewayStyles.container, styles.container)}>
			<Typo variant="D/Medium/Body">No AWS connections</Typo>

			<Typo variant="D/Regular/Body-S" className={styles.description} color="secondary">
				You have not added any AWS connections yet.
			</Typo>

			<CreateButton />
		</div>
	);
}
