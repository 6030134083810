import commonStyles from 'assets/styles/index.module.pcss';
import EnhancedTableHead from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import { PolicyItemWithoutRules } from 'models/policiesV2/dto';
import tableConfig from './config';
import styles from './index.module.css';
import { PolicyRow } from './PolicyRow';

type Props = {
	policies: PolicyItemWithoutRules[];
	skeletonMode: boolean;
};

function PolicyTable(props: Props) {
	const { policies, skeletonMode } = props;

	return (
		<GridTable className={styles.table} dataTest="policy-table">
			<EnhancedTableHead config={tableConfig} rowClassname={styles.rowContainer} />

			<GridBody className={skeletonMode ? commonStyles.skeletonMode : undefined}>
				{policies.map((policy) => (
					<PolicyRow policy={policy} key={policy.id} />
				))}
			</GridBody>
		</GridTable>
	);
}

export { PolicyTable };
