import { useStore } from 'effector-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BaseModalTheme } from 'components/BaseModal';
import { ConfirmModal, getConfirmation } from 'components/ConfirmModal';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { Typo } from 'components/typography/Typo';
import { JiraSettings } from 'models/integrations/jira/dto';
import { getJiraSettingsFx } from 'models/integrations/jira/effects';
import { jiraSettingsStore } from 'models/integrations/jira/store';
import { PATHS } from 'services/router';

type Props = {
	summary: string;
	description: string;
};

function JiraButton(props: Props) {
	const settings = useStore(jiraSettingsStore);

	useEffect(() => {
		getJiraSettingsFx({ cacheTime: 5 * 60 * 1000 });
	}, []);

	if (settings.is_enabled) {
		return <JiraCreateButton settings={settings} {...props} />;
	} else {
		return <JiraIntegrateButton />;
	}
}

type CreateProps = {
	summary: string;
	description: string;
	settings: JiraSettings;
};

function JiraCreateButton(props: CreateProps) {
	const { settings, summary, description } = props;

	const pid = settings.project.id;
	const issuetype = settings.issue_type.id;
	const priority = settings.priority.id;
	const componentsFull = settings.components
		.map((component) => `&components=${component.id}`)
		.join('');
	const summaryEncoded = encodeURIComponent(summary);
	const descriptionEncoded = encodeURIComponent(description);

	const href = `${settings.jira_base_url}/secure/CreateIssueDetails!init.jspa?pid=${pid}&issuetype=${issuetype}&priority=${priority}&summary=${summaryEncoded}&description=${descriptionEncoded}${componentsFull}`;

	return (
		<Button
			size="small"
			href={href}
			target="_blank"
			startIcon={<Icon name="jiraInverted" size={20} />}
		>
			Open a Jira ticket
		</Button>
	);
}

function JiraIntegrateButton() {
	const history = useHistory();

	async function onClick() {
		const confirmModal = (
			<ConfirmModal
				theme={BaseModalTheme.hint}
				title="Integration with Jira is not set up"
				confirmProps={{
					children: 'Go to integration settings',
					'data-test': 'goto-integration-button',
				}}
			>
				<Typo variant="D/Regular/Body-S">
					Set up an integration with Jira to create Jira tickets.
				</Typo>
			</ConfirmModal>
		);

		if (!(await getConfirmation(confirmModal))) {
			return;
		}

		history.push(PATHS.INTEGRATIONS);
	}

	return (
		<Button
			size="small"
			onClick={onClick}
			data-test="open-jira-ticket-button"
			startIcon={<Icon name="jiraInverted" size={20} />}
		>
			Open a Jira ticket
		</Button>
	);
}

export default JiraButton;
