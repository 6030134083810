import React from 'react';
import { isStringOption, isOption } from 'components/form/Select';
import Icon from 'components/Icon';
import MenuItem from 'components/MenuItem';
import { TypoHighlighted } from 'components/typography/TypoHighlighted';
import styles from './index.module.css';
import { Option } from './index.tsx';

type OptionItemProps = {
	option: Option;
	selected: boolean;
	searchString: string;
	onClick: () => void;
};

// Common overrides: search highlight; custom display e.g. 'Item (found in 90 assets)'
function OptionItem({ option, onClick, selected, searchString }: OptionItemProps) {
	let optionText: string;

	if (isStringOption(option)) {
		optionText = option;
	} else if (isOption(option)) {
		optionText = option.name;
	} else {
		optionText = JSON.stringify(option);
	}

	const isSearched = searchString
		? optionText.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
		: true;

	return isSearched ? (
		<MenuItem size="small" onClick={onClick} selected={selected} className={styles.menuItem}>
			<Icon name="search" size={20} className={styles.searchIcon} />
			<TypoHighlighted search={searchString} variant="D/Regular/Body-S">
				{optionText}
			</TypoHighlighted>
		</MenuItem>
	) : null;
}

export default OptionItem;
export type { OptionItemProps };
