import ButtonIcon from 'components/ButtonIcon';
import { Typo } from 'components/typography/Typo';
import styles from './index.module.css';

type Props = {
	value: string;
	onEdit: () => void;
	onDelete: () => void;
};

function RuleBadge(props: Props) {
	const { value, onEdit, onDelete } = props;

	return (
		<div className={styles.ruleBadgeContainer}>
			<div className={styles.ruleBadgeDelete}>
				<ButtonIcon
					className={styles.buttonClose}
					dataTest="asset-rules-rule-value-delete"
					icon="Delete/Filled"
					size="XS"
					onClick={onDelete}
				/>
			</div>

			<Typo
				variant="D/Medium/Body-S"
				className={styles.ruleBadge}
				onClick={onEdit}
				data-test="asset-rules-rule-value"
			>
				{value}
			</Typo>
		</div>
	);
}

export default RuleBadge;
