import cn from 'classnames';
import { useStore } from 'effector-react';
import { produce } from 'immer';
import { useMemo } from 'react';
import { generatePath } from 'react-router';
import Alert, { AlertSeverity } from 'components/Alert';
import Button from 'components/form/Button';
import NonEmptyPiiSwitch from 'components/PiiGlobalFilterV2/NonEmptyPiiSwitch';
import { RouterLink } from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import DiscoveryStatus from 'layouts/AuthorizedWithLeftMenu/DiscoveryStatus';
import { AssetGroupListItem } from 'models/assetsGroups/dto';
import { assetGroupsList } from 'models/assetsGroups/store';
import {
	clusterGeoLocationRegionsUsedList,
	clusterGeoLocationsByTokenId,
} from 'models/clusterGeoLocations/store';
import { gatewaysList } from 'models/gateways/store';
import { S3RegionItem } from 'models/s3Regions/dto';
// import { s3RegionsUsedList } from 'models/s3Regions/store';
import { sensorsDARList } from 'models/sensors/dar/store';
import { PATHS } from 'services/router';
import { OTHER_REGION } from 'views/Sensors/DataInMotion/GatewayRegion';
import { MapAsset } from '../../index';
import { changeMapControls, mapControlsStore } from '../model/store';
import DataTypesFilter from './DataTypesFilter';
import FilterSection from './FilterSection';
import styles from './index.module.css';

type Props = {
	mapAssets: MapAsset[];
	assetsMatch: number;
};

function FiltersPane(props: Props) {
	const { mapAssets: allMapAssets, assetsMatch } = props;
	const allGroups = useStore(assetGroupsList);
	const usedRegions = useStore(clusterGeoLocationRegionsUsedList);
	const clusterGeoLocations = useStore(clusterGeoLocationsByTokenId);
	const DIMSensors = useStore(gatewaysList);
	const DARSensors = useStore(sensorsDARList);

	const mapControls = useStore(mapControlsStore);
	const { filter } = mapControls;

	const mapAssets = useMemo(
		function () {
			if (filter.clusters.length === 0) return allMapAssets;

			return allMapAssets.filter((asset) => filter.clusters.includes(asset.cluster_id));
		},
		[allMapAssets, filter.clusters]
	);

	const namespaces = useMemo(
		function () {
			return [...new Set(mapAssets.map((asset) => asset.namespace))]
				.filter((namespace) => !!namespace)
				.sort();
		},
		[mapAssets]
	);

	function onNamespaceChange(payload: string[]) {
		const newControls = produce(mapControls, (draft) => {
			draft.filter.namespaces = payload;
		});

		changeMapControls(newControls);
	}

	const labelKeys = useMemo(
		function () {
			return [...new Set(mapAssets.flatMap((asset) => asset.labels.map((label) => label.key)))]
				.filter((labelKey) => !!labelKey)
				.sort();
		},
		[mapAssets]
	);

	function onLabelKeyChange(payload: string[]) {
		const newControls = produce(mapControls, (draft) => {
			draft.filter.labelKeys = payload;
		});

		changeMapControls(newControls);
	}

	const labelValues = useMemo(
		function () {
			function isLabelAllowed(label: { key: string; value: string }) {
				if (filter.labelKeys.length === 0) return true;

				return filter.labelKeys.includes(label.key);
			}

			const allowedAndSorted = [
				...new Set(
					mapAssets.flatMap((asset) =>
						asset.labels.filter(isLabelAllowed).map((label) => label.value)
					)
				),
			]
				.filter((labelValue) => !!labelValue)
				.sort();

			// Product requirement - sort values starting with number below values starting with non-number
			const numberFirst = allowedAndSorted.filter((item) => /^[0-9]/.test(item));
			const nonNumberFirst = allowedAndSorted.filter((item) => !/^[0-9]/.test(item));

			return nonNumberFirst.concat(numberFirst);
		},
		[mapAssets, filter.labelKeys]
	);

	function onLabelValueChange(payload: string[]) {
		const newControls = produce(mapControls, (draft) => {
			draft.filter.labelValues = payload;
		});

		changeMapControls(newControls);
	}

	const groups = useMemo(
		function () {
			return [
				...new Set(
					mapAssets.flatMap((asset) =>
						asset.groups
							.map((groupId) => allGroups.find((group) => group.id === groupId))
							.filter((group) => !!group)
					)
				),
			];
		},
		[mapAssets, allGroups]
	);

	const selectedGroups = useMemo(
		function () {
			return filter.groups.map((groupId) => allGroups.find((group) => group.id === groupId));
		},
		[filter.groups, allGroups]
	);

	function onGroupChange(payload: { id: number }[]) {
		const newControls = produce(mapControls, (draft) => {
			draft.filter.groups = payload.map((group) => group.id);
		});

		changeMapControls(newControls);
	}

	const clusters = useMemo(
		function () {
			return [
				...new Set(
					allMapAssets.map((asset) => DIMSensors.find((gateway) => gateway.id === asset.cluster_id))
				),
			]
				.filter((gateway) => !!gateway)
				.map((gateway) => ({ id: gateway!.id, name: gateway!.cluster_name }))
				.sort((a, b) => a.id - b.id);
		},
		[allMapAssets, DIMSensors]
	);

	const selectedClusters = useMemo(
		function () {
			return filter.clusters.map(
				(clusterId) => clusters.find((cluster) => cluster.id === clusterId)!
			);
		},
		[filter.clusters, clusters]
	);

	function onClusterChange(payload: { id: number }[]) {
		const newControls = produce(mapControls, (draft) => {
			draft.filter.clusters = payload.map((cluster) => cluster.id);
		});

		changeMapControls(newControls);
	}

	const isOtherRegion = useMemo(
		function findOtherRegion() {
			const sensorIds = [...DIMSensors, ...DARSensors].map(({ id }) => id);

			for (let i = 0; i < sensorIds.length; i++) {
				const currentGeo = clusterGeoLocations[sensorIds[i]];

				if (!currentGeo || currentGeo?.result_region === '') {
					return true;
				}
			}

			return false;
		},
		[DARSensors, DIMSensors, clusterGeoLocations]
	);

	const regions = useMemo(() => {
		const _regions = [
			...usedRegions.map(({ keyword, description, source }) => {
				const resultDescription = source === 'manual' ? `${description} (manual)` : description;
				return {
					id: keyword,
					name: resultDescription,
				};
			}),
		];

		if (isOtherRegion) _regions.unshift(OTHER_REGION);

		return _regions;
	}, [usedRegions, isOtherRegion]);

	const selectedRegions = useMemo(
		function () {
			return filter.regions.map((regionKey) => regions.find(({ id }) => id === regionKey)!);
		},
		[filter.regions, regions]
	);

	function onRegionChange(payload: { id: S3RegionItem['keyword'] }[]) {
		const newControls = produce(mapControls, (draft) => {
			draft.filter.regions = payload.map(({ id }) => id);
		});

		changeMapControls(newControls);
	}

	const resourceTypes = useMemo(() => {
		return [
			{
				id: 'service',
				name: 'Service',
			},
			{
				id: 's3_bucket',
				name: 'S3 bucket',
			},
			{
				id: 'kafka_instance',
				name: 'Kafka instance',
			},
			{
				id: 'sql_db_database',
				name: 'Database',
			},
			{
				id: 'nosql_db_database',
				name: 'NoSQL database',
			},
		];
	}, []);

	function onResourceTypesChange(payload: { id: string; name: string }[]) {
		const newControls = produce(mapControls, (draft) => {
			draft.filter.resourceTypes = payload;
		});

		changeMapControls(newControls);
	}

	function onReset() {
		const newControls = produce(mapControls, (draft) => {
			draft.filter = {
				dataTypes: [],
				namespaces: [],
				labelKeys: [],
				labelValues: [],
				groups: [],
				clusters: [],
				regions: [],
				resourceTypes: [],
			};
		});

		changeMapControls(newControls);
	}

	return (
		<div className={styles.container}>
			{allGroups.length === 0 && (
				<Alert
					severity={AlertSeverity.warning}
					header={
						<>
							You have no groups.{' '}
							<RouterLink to={generatePath(PATHS.ASSET_GROUP_ITEM, { id: 'new' })}>
								Create new group
							</RouterLink>
						</>
					}
				/>
			)}

			<DiscoveryStatus />

			<NonEmptyPiiSwitch className={cn(styles.sidePaddings, styles.nonEmptyPIISwitch)} />

			<Typo
				variant="D/Medium/Body-S"
				className={cn(styles.sidePaddings, styles.matchText)}
				dataTest="data-map-filtersPane-counter"
			>
				{assetsMatch} of {allMapAssets.length} services match
			</Typo>

			<div className={cn(styles.sidePaddings, styles.filterContainer)}>
				<DataTypesFilter mapAssets={mapAssets} />

				<FilterSection
					label="Region"
					options={regions}
					value={selectedRegions}
					onChange={onRegionChange}
					data-test="data-map-filtersPane-region"
				/>

				<FilterSection
					label="Cluster"
					options={clusters}
					value={selectedClusters}
					onChange={onClusterChange}
					data-test="data-map-filtersPane-cluster"
				/>

				<FilterSection
					label="Resource type"
					options={resourceTypes}
					value={filter.resourceTypes}
					onChange={onResourceTypesChange}
					data-test="data-map-filtersPane-resourceTypes"
				/>

				<FilterSection
					label="Namespace"
					options={namespaces}
					value={filter.namespaces}
					onChange={onNamespaceChange}
					data-test="data-map-filtersPane-namespace"
				/>

				<FilterSection
					label="Label key"
					options={labelKeys}
					value={filter.labelKeys}
					onChange={onLabelKeyChange}
					data-test="data-map-filtersPane-labelKey"
				/>

				<FilterSection
					label="Label value"
					options={labelValues}
					value={filter.labelValues}
					onChange={onLabelValueChange}
					data-test="data-map-filtersPane-labelValue"
				/>

				<FilterSection
					label="Custom group"
					options={groups as AssetGroupListItem[]}
					value={selectedGroups as AssetGroupListItem[]}
					onChange={onGroupChange}
					data-test="data-map-filtersPane-customGroup"
				/>
			</div>

			<div className={styles.resetFiltersContainer}>
				<Button
					color="tertiary"
					size="extraSmall"
					onClick={onReset}
					fullWidth
					data-test="data-map-filtersPane-resetButton"
				>
					Reset
				</Button>
			</div>
		</div>
	);
}

export default FiltersPane;
