import { IHeadCell } from 'components/table/EnhancedTableHead';
import { EventsTableItem } from 'models/eventsV2/dto';

const tableConfig: IHeadCell<EventsTableItem>[] = [
	{
		id: 'event_name',
		numeric: false,
		label: 'Event',
		sortable: false,
		filter: false,
	},
	{
		id: 'created_at',
		numeric: false,
		label: 'Created At',
		sortable: false,
		filter: false,
	},
	{
		id: 'type',
		numeric: false,
		label: 'Event type',
		sortable: false,
		filter: false,
	},
	{
		id: 'sensitivity',
		numeric: false,
		label: 'Sensitivity',
		sortable: false,
		filter: false,
	},
	{
		id: 'resource_name',
		numeric: false,
		label: 'Resource name',
		sortable: false,
		filter: false,
	},
	{
		id: 'resource_type',
		numeric: false,
		label: 'Resource type',
		sortable: false,
		filter: false,
	},
	{
		id: 'region',
		numeric: false,
		label: 'Region',
		sortable: false,
		filter: false,
	},
	// {
	// 	id: 'account_id',
	// 	numeric: false,
	// 	label: 'Account id',
	// 	sortable: false,
	// 	filter: false,
	// },
];

export default tableConfig;
