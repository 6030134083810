import cn from 'classnames';
import { InputHTMLAttributes, ReactNode } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import Icon from 'components/Icon';
import { Typo } from 'components/typography/Typo';
import styles from './index.module.pcss';

enum AlertSeverity {
	success = 'success',
	error = 'error',
	warning = 'warning',
	info = 'info',
}

enum Icons {
	success = 'success',
	error = 'Danger/FilledRed',
	warning = 'warning',
	info = 'Info/Filled',
}

enum Colors {
	success = 'icon/success-secondary',
	error = 'icon/danger-secondary',
	warning = 'icon/warning-secondary',
	info = 'icon/accent-secondary',
}

interface AlertProps extends InputHTMLAttributes<HTMLDivElement> {
	severity?: AlertSeverity;
	header?: string | ReactNode;
	children?: ReactNode;
	className?: string;
	onClick?: () => void;
	teaser?: boolean;
	isIcon?: boolean;
	startIcon?: ReactNode;
	dataTest?: string;
}

function Alert({
	severity = AlertSeverity.success,
	className,
	header,
	children,
	onClick,
	teaser,
	isIcon = true,
	startIcon,
	dataTest = 'alert-row',
	...props
}: AlertProps) {
	return (
		<div
			className={cn(styles.container, className, styles[severity], {
				[styles.containerClose]: onClick,
				[styles.teaser]: teaser,
			})}
			{...props}
			onClick={teaser ? onClick : () => {}}
		>
			{isIcon && !startIcon && <Icon name={Icons[severity]} size={20} className={styles.icon} />}

			{startIcon && (
				<div className={cn(styles.icon, { [styles.teaserIcon]: teaser })}>{startIcon}</div>
			)}

			<div className={styles.content} data-test={dataTest}>
				{header && <Typo variant="D/Medium/Body-S">{header}</Typo>}

				{children && (
					<Typo variant={teaser ? 'D/Regular/Body' : 'D/Regular/Body-S'} component="div">
						{children}
					</Typo>
				)}

				{onClick && !teaser && (
					<ButtonIcon
						icon="Dismiss/Regular"
						onClick={onClick}
						className={cn(styles.closeButton, styles[`${severity}Button`])}
						color={Colors[severity]}
					/>
				)}
			</div>
		</div>
	);
}

export { AlertSeverity };
export default Alert;
