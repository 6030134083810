import cn from 'classnames';
import { useStore } from 'effector-react';
import { useCallback, useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { getClusterGeoLocationRegionsFx } from 'models/clusterGeoLocations/effects';
import { clusterGeoLocationRegionsByKeyword } from 'models/clusterGeoLocations/store';
import { getDataDistributionByRegions } from 'models/dashboard/api';
import { DataDistributionByRegions as TDataDistributionByRegions } from 'models/dashboard/dto';
import { toAbbreviatedNumber } from 'services/numbers';
import styles from './index.module.css';

type Props = {
	data: TDataDistributionByRegions;
};

function DataDistributionByRegions(props: Props) {
	const {
		data: { regions, total_assets },
	} = props;

	const geoLocationRegions = useStore(clusterGeoLocationRegionsByKeyword);

	const [hovered, setHovered] = useState(-1);

	const onMouseEnter = useCallback(function (e) {
		setHovered(+e.currentTarget.dataset.i);
	}, []);
	const onMouseLeave = useCallback(function (e) {
		const { i } = e.currentTarget.dataset;
		setHovered((h) => (h === +i ? -1 : h));
	}, []);

	const split = Math.ceil(regions.length / 2);
	const columnsData =
		regions.length <= 1 ? [regions] : [regions.slice(0, split), regions.slice(split)];

	return (
		<>
			<div className={styles.total}>
				<span>All data types</span>
				<span>{toAbbreviatedNumber(total_assets)}</span>
			</div>

			<div className={styles.barContainer}>
				{regions.map((r, i) => (
					<div
						className={cn(styles.bar, hovered === i && styles.hoveredBar)}
						style={{ flex: r.assets_count }}
						key={i}
						data-i={i}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
					>
						<div className={styles.innerBar} />
					</div>
				))}
			</div>

			<div className={styles.columns}>
				{columnsData.map((columnData, ii) => (
					<div key={ii} className={styles.column}>
						{columnData.map((r, i) => {
							const regionName = geoLocationRegions[r.region]?.description || 'Other regions';

							return (
								<div
									className={cn(styles.row, hovered === ii * split + i && styles.hoveredRow)}
									key={i}
									data-i={ii * split + i}
									onMouseEnter={onMouseEnter}
									onMouseLeave={onMouseLeave}
								>
									<span className={styles.square} />
									<span className={styles.regionWithEllipsis}>{regionName}</span>
									<span className={styles.percents}>
										{toAbbreviatedNumber(r.assets_percent, 1)}%
									</span>
								</div>
							);
						})}
					</div>
				))}
			</div>
		</>
	);
}

function Wrapper() {
	const [data, setData] = useState<TDataDistributionByRegions | null>(null);

	useEffect(() => {
		getDataDistributionByRegions().then((res) => {
			res.regions.sort((a, b) => b.assets_count - a.assets_count);
			setData(res);
		});
		getClusterGeoLocationRegionsFx();
	}, []);

	return data === null ? <Loader /> : <DataDistributionByRegions data={data} />;
}

export { Wrapper as DataDistributionByRegions };
