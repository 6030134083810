import { useCallback, useMemo } from 'react';
import MultiSelect from 'components/form/MultiSelect';
import DropdownButton from 'components/form/Select/DropdownButton';
import { EventResourceTypeParam } from 'models/eventsV2/model';
import { FilterPropsBase } from '../model';

type Option = {
	id: EventResourceTypeParam;
	name: string;
};

type Props = FilterPropsBase & {
	value: EventResourceTypeParam[];
	onChange: (newValue: EventResourceTypeParam[]) => void;
};

const options: { id: EventResourceTypeParam; name: string }[] = [
	{ id: 'service', name: 'Service' },
	{ id: 's3', name: 'S3 bucket' },
	{ id: 'database', name: 'SQL Database' },
	{ id: 'no_sql_database', name: 'NoSQL Database' },
	{ id: 'kafka', name: 'Kafka' },
];

export function ResourceTypesFilter({
	defaultOpen,
	fixed,
	onChange,
	onClose,
	resetFilter,
	value,
}: Props) {
	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	const values = useMemo(() => {
		return options.filter((_value) => value.includes(_value.id));
	}, [value, options]);

	const handleChange = (opts: Option[]) => {
		onChange(opts.map((option) => option.id));
	};

	return (
		<MultiSelect
			defaultOpen={defaultOpen}
			label={{ primary: 'Resource type', secondary: `${values.length || ''}` }}
			options={options}
			value={values}
			onChange={handleChange}
			onClose={onClose}
			render={{ dropdownButton }}
			hasSearch
			hasApplyButton
		/>
	);
}
