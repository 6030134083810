import { useStore } from 'effector-react';
import { useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { AssetGroupChipList } from 'components/AssetGroupChip';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import { Tooltip } from 'components/Tooltip';
import { Typo } from 'components/typography/Typo';
import { TypoHighlighted } from 'components/typography/TypoHighlighted';
import { TAssetsItem } from 'models/assetsExtended/dto';
import { gatewaysNamesById } from 'models/gateways/store';
import { lastSeen } from 'services/lastSeen';
import { PATHS } from 'services/router';
import { DASH_SIGN, getStringDividedByDot } from 'services/strings';
import styles from '../index.module.css';

type Props = {
	internalAsset: TAssetsItem;
	search: string;
	oneGroupMode: boolean;
};

function InternalAssetsTableRow(props: Props) {
	const {
		cluster_id,
		id,
		name,
		namespace,
		endpoints,
		interacts_with,
		k8s_types,
		sensitivity,
		groups,
		data_types,
		last_seen,
	} = props.internalAsset;
	const { oneGroupMode, search } = props;

	const history = useHistory();
	const gatewaysNamesStore = useStore(gatewaysNamesById);
	const lastSeenValue = useMemo(() => lastSeen({ timestamp: last_seen, short: true }), [last_seen]);

	function onRowClick() {
		const path = generatePath(PATHS.SERVICE_ITEM, { id });
		history.push(path);
	}

	return (
		<GridRow
			className={oneGroupMode ? styles.rowWOGroupsContainer : styles.rowContainer}
			onClick={onRowClick}
			hover
			border
			data-test="internal-assets-table-row"
		>
			<GridCell dataTest="internal-assets-item-name">
				<TypoHighlighted variant="D/Medium/Body-S" search={search}>
					{name}
				</TypoHighlighted>

				<Typo variant="D/Medium/Meta" color="secondary" className={styles.k8Type}>
					{getStringDividedByDot(gatewaysNamesStore[cluster_id], k8s_types)}
				</Typo>
			</GridCell>

			<GridCell>
				<TypoHighlighted variant="D/Regular/Body-S" search={search}>
					{namespace || DASH_SIGN}
				</TypoHighlighted>
			</GridCell>

			<GridCell align="right" dataTest="internal-assets-item-endpoints">
				{endpoints}
			</GridCell>
			<GridCell align="right" dataTest="internal-assets-item-interacts_with">
				{interacts_with}
			</GridCell>

			<GridCell dataTest="internal-assets-item-sensitivity">
				<SensitivityChip sensitivity={sensitivity} />
			</GridCell>

			{!oneGroupMode && (
				<GridCell dataTest="internal-assets-item-groups">
					{groups.length > 0 ? <AssetGroupChipList groups={groups} /> : DASH_SIGN}
				</GridCell>
			)}

			<GridCell dataTest="internal-assets-item-data_types">
				<PiiTypeList data={data_types} />
			</GridCell>

			<GridCell dataTest="internal-assets-item-last_seen">
				<Tooltip data-test="internal-assets-item-last_seen-value" title={lastSeenValue.date}>
					{lastSeenValue.diff}
				</Tooltip>
			</GridCell>
		</GridRow>
	);
}

export { InternalAssetsTableRow };
