import { Typo } from 'components/typography/Typo';
import styles from './index.module.css';

type Props = {
	children: string;
	highlightRegex: RegExp;
	highlightLength: number;
};

function HighlightedText(props: Props) {
	const { children: text, highlightRegex, highlightLength } = props;

	const foundIdx = text.search(highlightRegex);
	if (foundIdx === -1) {
		return <>{text}</>;
	}

	const first = text.slice(0, foundIdx);
	const found = text.slice(first.length, first.length + highlightLength);
	const rest = text.slice(first.length + highlightLength);

	return (
		<>
			{first}
			<Typo component="span" variant="D/SemiBold/Body-S" className={styles.highlighted}>
				{found}
			</Typo>
			{rest}
		</>
	);
}

export default HighlightedText;
