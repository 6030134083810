import { InputAdornment } from '@material-ui/core';
import { MouseEvent, useMemo, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import Accordion from 'components/Accordion';
import AccordionDetails from 'components/AccordionDetails';
import AccordionSummary from 'components/AccordionSummary';
import ButtonIcon from 'components/ButtonIcon';
import Checkbox from 'components/Checkbox';
import { AnyOption, isOption } from 'components/form/Select';
import TextField from 'components/form/TextField';
import Icon from 'components/Icon';
import { Tooltip } from 'components/Tooltip';
import { Typo } from 'components/typography/Typo';
import styles from './index.module.css';

function getName(option: AnyOption) {
	return isOption(option) ? option.name : String(option);
}

function highlightName(name: string, highlight: string) {
	if (!highlight) return name;

	const idx = name.toLocaleLowerCase().indexOf(highlight.toLocaleLowerCase());
	const first = name.slice(0, idx);
	const second = name.slice(idx, idx + highlight.length);
	const third = name.slice(idx + highlight.length);

	return (
		<>
			<Typo component="span" variant="D/Regular/Body-S">
				{first}
			</Typo>
			<Typo component="span" variant="D/SemiBold/Body-S">
				{second}
			</Typo>
			<Typo component="span" variant="D/Regular/Body-S">
				{third}
			</Typo>
		</>
	);
}

const ITEM_HEIGHT = 32; // 28 - item height +4 - gap between elements;
const OPTIONS_HEIGHT = 212; // views/DataMap/IsometricMap/FiltersPane/FilterSection/index.module.css —> .accordionDetailsRoot —> height

type Props<T extends AnyOption> = {
	options: T[];
	value: T[];
	onChange: (value: T[]) => void;
	label: string;
	'data-test'?: string;
};

function FilterSection<T>(props: Props<T>) {
	const { options, value, onChange, label } = props;
	const [search, setSearch] = useState('');

	const height = useMemo(() => {
		return Math.min(OPTIONS_HEIGHT, ITEM_HEIGHT * Math.max(options.length, 2));
	}, []);

	const filteredOptions = useMemo(
		function () {
			if (!search) return options;

			return options.filter((option) =>
				getName(option).toLocaleLowerCase().includes(search.toLocaleLowerCase())
			);
		},
		[options, search]
	);

	function onOptionChange(clickedOption: T) {
		if (value.includes(clickedOption)) {
			onChange(value.filter((val) => val !== clickedOption));
		} else {
			onChange(value.concat(clickedOption));
		}
	}

	function onFilterReset(e: MouseEvent) {
		e.preventDefault();
		e.stopPropagation();
		onChange([]);
	}

	return (
		<Accordion className={styles.container} data-test={props['data-test']}>
			<AccordionSummary
				classes={{ root: styles.accordionSummaryRoot, content: styles.accordionSummaryContent }}
			>
				<Typo variant="D/Medium/Body-S">{label}</Typo>
				{value.length > 0 && (
					<Typo variant="D/Medium/Meta" color="secondary" className={styles.selectedCount}>
						selected: {value.length}
					</Typo>
				)}
				{value.length > 0 && (
					<Typo variant="D/Medium/Meta" className={styles.resetLink} onClick={onFilterReset}>
						Reset
					</Typo>
				)}
			</AccordionSummary>

			<AccordionDetails classes={{ root: styles.accordionDetailsRoot }}>
				<TextField
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					size="small"
					helperText={null}
					placeholder="Search"
					fullWidth
					InputProps={{
						classes: {
							root: styles.searchInput,
						},
						endAdornment: search ? (
							<InputAdornment position="end">
								<ButtonIcon icon="Dismiss/Regular" onClick={() => setSearch('')} />
							</InputAdornment>
						) : (
							<InputAdornment position="end">
								<Icon name="search" size={20} />
							</InputAdornment>
						),
					}}
				/>

				<div className={styles.options}>
					<List
						itemCount={filteredOptions.length}
						itemSize={ITEM_HEIGHT}
						width="100%"
						height={height}
						className={styles.optionsList}
					>
						{({ index, style }) => {
							const name = highlightName(getName(filteredOptions[index]), search);

							return (
								<div key={index} className={styles.checkboxLabel} style={style}>
									<Checkbox
										size="M"
										className={styles.checkbox}
										checked={value.includes(filteredOptions[index])}
										onChange={() => onOptionChange(filteredOptions[index])}
										label={
											<Tooltip
												title={name}
												enterDelay={800}
												enterNextDelay={800}
												className={styles.optionText}
											>
												{name}
											</Tooltip>
										}
									/>
								</div>
							);
						}}
					</List>
				</div>

				<div className={styles.blur} />
			</AccordionDetails>
		</Accordion>
	);
}

export default FilterSection;
