import * as Sentry from '@sentry/react';
import { useStore } from 'effector-react';
import { produce } from 'immer';
import { Component, ErrorInfo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { CurtainOverlay } from 'components/Curtain';
import { piiFilterStore, setPiiFilter } from 'components/PiiGlobalFilterV2/model';
import ResetFilters from 'components/ResetFilters';
import { SENTRY } from 'consts';
import { AssetJson } from 'models/assets/dto';
import { getS3RegionsFx } from 'models/s3Regions/effects';
import { MapAsset, RagsAndCubesTree } from '../index';
import { getCubePosition } from '../utils/getCubePosition';
import ArrowsLayer from './ArrowsLayer';
import CubesLayer from './CubesLayer';
import AssetDetails from './Details/Asset';
import ClusterDetails from './Details/Cluster';
import { DatabaseDetails } from './Details/Database';
import GroupDetails from './Details/Group';
import KafkaDetails from './Details/Kafka';
import NamespaceDetails from './Details/Namespace';
import { NoSQLDatabaseDetails } from './Details/NoSQLDatabase';
import { S3BucketDetails } from './Details/S3Bucket';
import FiltersPane from './FiltersPane';
import styles from './index.module.css';
import {
	_purgeStoreIfNonDefault,
	changeMapControls,
	DataMapTypes,
	mapControlsStore,
} from './model/store';
import RagsLayer from './RagsLayer';
import TopPane from './TopPane';
import ZoomWrapper, { ZoomActions } from './ZoomWrapper';

export const CUSTOM_SCALE = 3;
const TILE_SIZE = 16 * CUSTOM_SCALE; // in px

/*
	We need to have 3 debounce values for different situations:
	- fast — used for simple debounce cases;
	- medium and slow — used for setting order of data changing and drawing.
			The deeper the element in DOM hierarchy, the longer the delay should be.
			Because we shouldn't use browser resource for elements which might not be rendered.
			Right now we only have 2 layers (namespace and assets), that's why we have 2 delay values.
 */
export const DEBOUNCE_VALUES = {
	fast: 200,
	medium: 400,
	slow: 500,
};

function getIsoPoint(x: number, y: number) {
	return [x * 0.707107 + y * -0.707107, x * 0.40558 + y * 0.40558];
}

type Props = {
	mapAssets: MapAsset[];
	mapAssetsMap: Map<AssetJson['id'], MapAsset>;
	ragsAndCubes: RagsAndCubesTree;
	groupBy: string;
};

function IsometricMap(props: Props) {
	const { mapAssets, mapAssetsMap, ragsAndCubes } = props;

	const mapControls = useStore(mapControlsStore);
	const { filter, selected, curtainClosed } = mapControls;
	const [isNonePointerEvents, setIsNonPointerEvents] = useState(false);
	const { nonEmpty } = useStore(piiFilterStore);

	// Update data for Regions in the Filter pane
	useEffect(() => {
		getS3RegionsFx();
	}, []);

	const fitToScreenRef = useRef(() => {});

	const assetPosition = useMemo(() => {
		const query = new URLSearchParams(window.location.search);
		const queryElementId = query.get('entityId');
		const queryElementType = query.get('type') as MapAsset['type'];

		if (!queryElementId || !queryElementType) return;

		let elementId = 0;
		mapAssetsMap.forEach((el) => {
			if (queryElementType === el.type && Number(queryElementId) === el.entityId) {
				elementId = el.elementId;
			}
		});

		return getCubePosition({
			id: elementId,
			types: [queryElementType],
			mapAssetsMap,
			ragsAndCubes,
		});
	}, [getCubePosition, mapAssetsMap, ragsAndCubes]);

	/*
		FIT TO SCREEN
	 */
	useEffect(() => {
		if (!assetPosition) {
			fitToScreenRef.current();
		}
	}, [assetPosition, nonEmpty, mapControls.groupByNamespaceOrGroup]);

	/*
		SCROLL TO THE ELEMENT
	 */
	useEffect(() => {
		if (assetPosition) {
			ZoomActions.scrollToElement?.(assetPosition.top, assetPosition.left);
		}
	}, [assetPosition]);

	// Capture query params
	useEffect(() => {
		const query = new URLSearchParams(window.location.search);
		const queryEntityId = query.get('entityId');
		const queryType = query.get('type') || '';
		const isAsset = ![
			's3_bucket',
			'kafka_instance',
			'sql_db_database',
			'nosql_db_database',
		].includes(queryType);
		const queryInteractsWith = query.get('interacts-with');

		if (!queryEntityId) return;

		const newControls = produce(mapControls, (draft) => {
			const foundEntity = mapAssets.find(
				(asset) => asset.entityId === Number(queryEntityId) && asset.type === queryType
			);

			if (foundEntity) {
				draft.selected = {
					type: isAsset ? 'asset' : (foundEntity.type as DataMapTypes),
					id: foundEntity.elementId,
				};

				if (isAsset && queryInteractsWith) {
					const foundInteractsWith = mapAssets.find(
						(asset) => asset.entityId === Number(queryInteractsWith)
					);

					draft.interactsWith.selected = { id: foundInteractsWith!.entityId, type: 'asset' };
				}
			}
		});

		changeMapControls(newControls);
	}, []);

	const filteredAssets = useMemo(
		function () {
			let result = mapAssets;

			if (filter.dataTypes.length) {
				result = result.filter((asset) =>
					filter.dataTypes.some((dataType) => asset.dataTypes.includes(dataType))
				);
			}
			if (filter.namespaces.length) {
				result = result.filter((asset) => filter.namespaces.includes(asset.namespace));
			}
			if (filter.labelKeys.length) {
				result = result.filter((asset) =>
					filter.labelKeys.some((labelKey) => asset.labels.some((label) => label.key === labelKey))
				);
			}
			if (filter.labelValues.length) {
				result = result.filter((asset) =>
					filter.labelValues.some((labelValue) =>
						asset.labels.some((label) => label.value === labelValue)
					)
				);
			}
			if (filter.groups.length) {
				result = result.filter((asset) =>
					filter.groups.some((groupId) => asset.groups.includes(groupId))
				);
			}
			if (filter.clusters.length) {
				result = result.filter((asset) => filter.clusters.includes(asset.cluster_id));
			}

			return new Set(result.map((asset) => asset.elementId));
		},
		[mapAssets, filter]
	);

	const resetFilters = useCallback(() => {
		const newControls = produce(mapControls, (draft) => {
			draft.filter = {
				dataTypes: [],
				namespaces: [],
				labelKeys: [],
				labelValues: [],
				groups: [],
				clusters: [],
				regions: [],
				resourceTypes: [],
			};
		});

		changeMapControls(newControls);
		setPiiFilter({ nonEmpty: false });
	}, [changeMapControls]);

	const resetSelected = () => {
		const newControls = produce(mapControls, (draft) => {
			draft.selected = null;
		});

		changeMapControls(newControls);
	};

	function setCurtainClosed(value: boolean) {
		const newControls = produce(mapControls, (draft) => {
			draft.curtainClosed = value;
		});

		changeMapControls(newControls);
	}

	let curtainContent = null;
	if (selected === null) {
		curtainContent = <FiltersPane mapAssets={mapAssets} assetsMatch={filteredAssets.size} />;
	} else {
		switch (selected.type) {
			case 'asset': {
				const asset = mapAssetsMap.get(selected.id);
				if (!asset) throw new Error('Unknown asset in mapControls.selected');

				curtainContent = <AssetDetails asset={asset} />;
				break;
			}

			case 'namespace':
				curtainContent = <NamespaceDetails name={selected.name} clusterId={selected.clusterId} />;
				break;

			case 'cluster': {
				curtainContent = <ClusterDetails id={selected.id} />;
				break;
			}

			case 'group': {
				const group = mapAssetsMap.get(selected.id)!;
				curtainContent = <GroupDetails groupId={group.entityId} />;
				break;
			}

			case 's3_bucket': {
				const bucket = mapAssetsMap.get(selected.id)!;
				if (!bucket) throw new Error('Unknown s3 bucket in mapControls.selected');

				curtainContent = <S3BucketDetails asset={bucket} bucketId={bucket.entityId} />;
				break;
			}

			case 'kafka_instance': {
				const kafka = mapAssetsMap.get(selected.id)!;
				curtainContent = <KafkaDetails kafkaInstanceId={kafka.entityId} />;
				break;
			}
			case 'sql_db_database': {
				const database = mapAssetsMap.get(selected.id)!;
				curtainContent = <DatabaseDetails databaseId={database.entityId} />;
				break;
			}
			case 'nosql_db_database': {
				const database = mapAssetsMap.get(selected.id)!;
				curtainContent = <NoSQLDatabaseDetails databaseId={database.entityId} />;
				break;
			}
		}
	}

	const zoomWrapperRect = useMemo(() => {
		const nonIsoWidth = ragsAndCubes.dimensions.width * TILE_SIZE;
		const nonIsoHeight = ragsAndCubes.dimensions.height * TILE_SIZE;

		const top = 0;
		const left = getIsoPoint(0, nonIsoHeight)[0];
		const bottom = getIsoPoint(nonIsoWidth, nonIsoHeight)[1];
		const right = getIsoPoint(nonIsoWidth, 0)[0];

		return {
			y: top,
			x: left,
			width: right - left,
			height: bottom - top,
		};
	}, [ragsAndCubes.dimensions]);

	return (
		<div className={styles.wrapper} data-test="data-map-wrapper">
			<Helmet>
				<title>Data map | Soveren Dashboard</title>
			</Helmet>

			{mapAssets.length === 0 ? (
				<div className={styles.resetFiltersContainer}>
					<ResetFilters onReset={resetFilters} className={styles.resetFiltersButton} />
				</div>
			) : (
				<>
					<ZoomWrapper
						contentRect={zoomWrapperRect}
						curtainOpen={!curtainClosed}
						onClick={resetSelected}
						preventClickWhenDrag={true}
						className={isNonePointerEvents ? styles.nonPointerEvents : ''}
						fitToScreenRef={fitToScreenRef}
					>
						<RagsLayer
							mapAssets={mapAssets}
							mapAssetsMap={mapAssetsMap}
							ragsAndCubes={ragsAndCubes}
							groupBy={props.groupBy}
						/>

						<CubesLayer
							mapAssets={mapAssets}
							mapAssetsMap={mapAssetsMap}
							ragsAndCubes={ragsAndCubes}
						/>

						<ArrowsLayer mapAssets={mapAssets} ragsAndCubes={ragsAndCubes} />
					</ZoomWrapper>

					<TopPane
						mapAssets={mapAssets}
						onSearchDropdownOpen={(open) => setIsNonPointerEvents(open)}
						mapAssetsMap={mapAssetsMap}
						ragsAndCubes={ragsAndCubes}
					/>

					<CurtainOverlay
						dataTest="data-map"
						open={!curtainClosed}
						onOpen={() => setCurtainClosed(false)}
						onClose={() => setCurtainClosed(true)}
						classes={{
							rightPartOpen: styles.curtainOpen,
							rightPart: styles.curtainRightPart,
						}}
						rightPart={curtainContent}
					/>
				</>
			)}
		</div>
	);
}

// The purpose of this class: try to recover from unexpected errors from bad localStorage state, by
// purging such state and reloading.
class WrappedIsometricMap extends Component<Props, { hasError: boolean }> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error: Error, info: ErrorInfo) {
		// Do not attempt to recover if in e2e test environment.
		if (window.Cypress) {
			console.log(error, info);
			throw error;
		}

		if (SENTRY.ENABLED) {
			Sentry.captureException(error, {
				extra: { info },
			});
		}

		_purgeStoreIfNonDefault();
	}

	render() {
		if (this.state.hasError) {
			// Do not render anything; browser tab will reload soon.
			return null;
		}

		return <IsometricMap {...this.props} />;
	}
}

export default WrappedIsometricMap;
export { TILE_SIZE };
