import cn from 'classnames';
import React, { MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import Icon from 'components/Icon';
import { Typo } from 'components/typography/Typo';
import { Operator } from '../Operator';
import { ActiveRule, BuilderContext, RuleErrors } from '../PolicyBuilder';
import {
	AND_OPERATOR,
	OR_OPERATOR,
	getEmptyGroup,
	isEmptyRule,
	isOperator,
	getOrRule,
	getAndRule,
} from '../PolicyBuilder/helpers.ts';
import { MAIN_INPUT_INDEX, RuleGroup } from '../RuleGroup';
import styles from './index.module.css';

interface Props {
	dim: boolean;
	errors: RuleErrors;
	onActiveRuleChange: (activeRule: ActiveRule | null) => void;
	onClick: (event: MouseEvent<HTMLElement>) => void;
	onClose: () => void;
	onRulesChange: (rules: RuleItemArray[], ruleIndex?: number) => void;
	open: boolean;
	rules: RuleItemArray[];
}

export interface RuleItemArray {
	key: string;
	operator: typeof AND_OPERATOR | typeof OR_OPERATOR | null;
	type: string;
	values: string[];
	index: number;
	modified?: boolean;
}

export function RuleList({
	dim,
	errors,
	onActiveRuleChange,
	onClick,
	onClose,
	onRulesChange,
	open,
	rules,
}: Props) {
	const { builderState } = useContext(BuilderContext);
	const ruleListContainerRef = useRef<HTMLDivElement>(null);
	const [activeRule, setActiveRule] = useState<ActiveRule | null>(null);

	// Catch buttons' click from the HotKeys
	useEffect(() => {
		switch (builderState.keyAction) {
			case 'OR':
				addOperatorByKey(getOrRule());
				break;
			case 'AND':
				addOperatorByKey(getAndRule());
				break;
			case 'DELETE':
				if (activeRule) {
					onChange(getEmptyGroup(), activeRule);
				}
				break;
			case 'ESC':
				onClose();
				break;
			default:
				break;
		}
	}, [builderState.keyAction]);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (activeRule) {
				if (event.altKey) {
					if (event.code === 'Digit1') {
						event.preventDefault();

						addOperatorByKey(getAndRule());
					} else if (event.code === 'Digit2') {
						event.preventDefault();

						addOperatorByKey(getOrRule());
					}
				} else if (activeRule.operator && event.code === 'Backspace' && activeRule) {
					onChange(getEmptyGroup(), activeRule);
				}
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [activeRule, ruleListContainerRef]);

	// When clickAway is occurred we clean the state
	useEffect(() => {
		if (!open) {
			setActiveRule(null);
		}
	}, [open]);

	// Every time when active rule is changed we sent actual information to the Popover
	useEffect(() => {
		onActiveRuleChange(activeRule);
	}, [activeRule]);

	function addOperatorByKey(operator: RuleItemArray) {
		if (activeRule) {
			// If we clicked to some operator and want to replace it by another operator
			if (activeRule.operator) {
				const newRules = [...rules];
				newRules[activeRule.groupId] = operator;

				onRulesChange(newRules);
				setNextGroupActive();
			} else {
				const newRules = [...rules];

				if (isEmptyRule(rules[activeRule.groupId])) {
					newRules.splice(activeRule.groupId, 1, getEmptyGroup(), operator, getEmptyGroup());
				} else {
					newRules.splice(activeRule.groupId + 1, 1, getEmptyGroup(), operator, getEmptyGroup());
				}

				onRulesChange(newRules);

				setActiveRule({
					...activeRule,
					index: operator.index + 2,
					ruleId: MAIN_INPUT_INDEX,
					currentValue: '',
				});
			}
		}
	}

	function onOperatorClick(_rule: RuleItemArray, groupId: number) {
		setActiveRule({
			currentValue: '',
			groupId,
			index: _rule.index,
			operator: _rule.operator,
			ruleId: MAIN_INPUT_INDEX,
			type: '',
		});
	}

	function onChange(rule: RuleItemArray, _activeRule: ActiveRule) {
		const _groupId = _activeRule.groupId;

		if (_groupId === rules.length - 1) {
			// If we're on the last rule, and we delete all values, we update all rules for additional rendering
			if (isEmptyRule(rule)) {
				onRulesChange([...rules]);
				setActiveRule(_activeRule);
			} else {
				// If this rule is the only one
				if (rules.length === 1) {
					const newRules = [...rules];
					newRules.splice(_groupId, 1, rule, getEmptyGroup());

					onRulesChange(newRules, rule.index);
					setActiveRule({ ..._activeRule, groupId: _activeRule.groupId + 1 });
				} else {
					const newRules = [...rules];
					newRules.splice(_groupId, 1, getEmptyGroup(), rule, getEmptyGroup());

					onRulesChange(newRules, rule.index);
					setActiveRule({ ..._activeRule, groupId: _activeRule.groupId + 1 });
				}
			}
		} else {
			if (isEmptyRule(rules[_groupId])) {
				if (!isEmptyRule(rule)) {
					const newRules = [...rules];
					newRules.splice(_groupId, 1, getEmptyGroup(), rule, getEmptyGroup());

					onRulesChange(newRules, rule.index);
					setActiveRule({ ..._activeRule, groupId: _activeRule.groupId + 1 });
				} else {
					setActiveRule(_activeRule);
				}
			} else {
				// We send emptyRule if we want to delete rule
				if (isEmptyRule(rule) || isOperator(rules[_groupId])) {
					let newRules = [...rules];

					// When the rule list is empty, and we start to type, another group is created.
					// And when the first group is deleted with Backspace key, we have to use created group to focus it.
					// If we create new list with [getEmptyGroup()], setNextGroupActive cannot find new group
					if (rules.length === 2) {
						newRules = [newRules[1]];
					} else {
						// _groupId - 1 < 0 - if rule has _groupId === 0, then splice will remove from the end, that's wrong behavior
						// count 2 - because we delete previous emptyRule
						// newRules.splice(_groupId - 1 < 0 ? 0 : _groupId - 1, 2);
						newRules.splice(_groupId - 1 < 0 ? 0 : _groupId - 1, 2);
					}

					onRulesChange(newRules);
					setNextGroupActive();
				} else {
					const newRules = [...rules];
					newRules[_groupId] = rule;

					onRulesChange(newRules, rule.index);
					setActiveRule(_activeRule);
				}
			}
		}
	}

	function onContainerClick(e: MouseEvent<HTMLDivElement>) {
		e.stopPropagation();
		e.preventDefault();

		// if we click exactly the container, not the rule we activate last group
		if (e.target instanceof HTMLElement && !e.target.closest('[data-group]')) {
			setNextGroupActive(rules.length - 1);
		}

		onClick(e);
	}

	function setNextGroupActive(_groupId?: ActiveRule['groupId']) {
		const activeGroupId =
			_groupId !== undefined && rules[_groupId]
				? _groupId
				: activeRule && rules[activeRule?.groupId + 1]
				? activeRule?.groupId + 1
				: rules.length - 1;
		const activeGroup = rules[activeGroupId];

		const newActiveRule: ActiveRule = {
			...activeGroup,
			currentValue: '',
			groupId: activeGroupId,
			ruleId: MAIN_INPUT_INDEX,
		};

		setActiveRule(newActiveRule);
	}

	return (
		<div
			id="rule-list"
			className={cn(styles.container, {
				[styles.active]: open,
				[styles.emptyRulesError]: builderState.emptyRulesError,
				// [styles.emptyRulesError]: !open && rules.length === 0,
			})}
			onClick={onContainerClick}
			ref={ruleListContainerRef}
		>
			<Icon name="search" size={20} className={styles.searchIcon} />

			<div className={styles.rulesContainer}>
				{rules.map((rule, groupId) => {
					if (rule.operator) {
						return (
							<Operator
								activeRule={activeRule}
								error={!!errors[rule.index]}
								groupId={groupId}
								key={`operator${rule.index}`}
								onClick={onOperatorClick}
								value={rule}
							/>
						);
					}

					return (
						<RuleGroup
							activeRule={activeRule}
							error={errors[rule.index]}
							dim={dim}
							first={rules.length === 1}
							groupId={groupId}
							key={`rule${rule.index}`}
							last={rules.length - 1 === groupId}
							open={open}
							onActiveRuleChange={setActiveRule}
							onClose={onClose}
							onRuleChange={onChange}
							setNextGroupActive={setNextGroupActive}
							value={rule}
						/>
					);
				})}
			</div>

			{builderState.emptyRulesError && (
				<Typo variant="D/Regular/Meta-S" className={styles.emptyRulesErrorText}>
					Resource cannot be empty
				</Typo>
			)}
		</div>
	);
}
