import { useStore } from 'effector-react';
import { useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import SensitivityChip from 'components/SensitivityChip';
import GridCell from 'components/table/GridCell';
import GridRow from 'components/table/GridRow';
import { Tooltip } from 'components/Tooltip';
import { Typo } from 'components/typography/Typo';
import { TypoHighlighted } from 'components/typography/TypoHighlighted';
import { getAssetNameByType } from 'models/assets/model';
import { TAssetsItem } from 'models/assetsExtended/dto';
import { gatewaysNamesById } from 'models/gateways/store';
import { lastSeen } from 'services/lastSeen';
import { PATHS } from 'services/router';
import styles from './index.module.css';

function noop() {
	return undefined;
}

function ExternalAssetTableRow({
	externalAsset,
	search,
}: {
	externalAsset: TAssetsItem;
	search: string;
}) {
	const {
		id,
		type,
		interacts_with,
		sensitivity,
		data_types,
		last_seen,
		name,
		is_deleted,
		cluster_id,
	} = externalAsset;
	const history = useHistory();
	const gatewaysNamesStore = useStore(gatewaysNamesById);
	const lastSeenValue = useMemo(() => lastSeen({ timestamp: last_seen, short: true }), [last_seen]);

	function onRowClick() {
		const path = generatePath(PATHS.SERVICE_ITEM, { id });
		history.push(path);
	}

	const displayName = getAssetNameByType(type, name);

	return (
		<GridRow
			className={styles.rowContainer}
			onClick={is_deleted ? noop : onRowClick}
			hover={!is_deleted}
			border
			data-test="external-assets-table-row"
		>
			<GridCell dataTest="external-assets-item-name">
				{type === 'custom' && <Icon name="Tag/Filled" size={16} className={styles.customIcon} />}

				<TypoHighlighted
					variant="D/Medium/Body-S"
					color={is_deleted ? 'secondary' : 'primary'}
					search={search}
				>
					{displayName}
				</TypoHighlighted>

				<Typo variant="D/Medium/Meta" color="secondary" className={styles.k8Type}>
					{gatewaysNamesStore[cluster_id]}
				</Typo>
			</GridCell>

			<GridCell dataTest="external-assets-item-interacts_with" align="right">
				{interacts_with}
			</GridCell>

			<GridCell dataTest="external-assets-item-sensitivity">
				<SensitivityChip sensitivity={sensitivity} />
			</GridCell>

			<GridCell dataTest="external-assets-item-data_types">
				<PiiTypeList data={data_types} />
			</GridCell>

			<GridCell dataTest="external-assets-item-last_seen">
				<Tooltip data-test="external-assets-item-last_seen-value" title={lastSeenValue.date}>
					{lastSeenValue.diff}
				</Tooltip>
			</GridCell>
		</GridRow>
	);
}

export { ExternalAssetTableRow };
