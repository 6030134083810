import cn from 'classnames';
import { ReactNode } from 'react';
import { MarkedPiiType } from 'components/PiiType';
import GridBody from 'components/table/GridBody';
import GridCell from 'components/table/GridCell';
import GridHead from 'components/table/GridHead';
import GridRow from 'components/table/GridRow';
import GridTable from 'components/table/GridTable';
import { Typo } from 'components/typography/Typo';
import { DataTypeItem } from 'models/dataTypes/dto';
import { ViewerSize } from '../';
import styles from './index.module.pcss';

interface TableOfTypesHeaderProps {
	size?: ViewerSize;
}

function TableOfTypesHeader({ size }: TableOfTypesHeaderProps) {
	return (
		<GridHead>
			<GridRow head className={styles.tableHeader} size={size}>
				<GridCell head align="right" className={styles.cellHeader}>
					Row
				</GridCell>

				<GridCell head className={styles.cellHeader}>
					Parameter
				</GridCell>

				<GridCell head className={styles.cellHeader}>
					Data type
				</GridCell>
			</GridRow>
		</GridHead>
	);
}

type TableOfTypesGroupRowProps = {
	label: string;
	size?: ViewerSize;
};

function TableOfTypesGroupRow({ label, size = 'M' }: TableOfTypesGroupRowProps) {
	return (
		<GridRow className={styles.tableGroupRow} data-test="table-of-types-group">
			<Typo variant={size === 'S' ? 'D/Medium/Meta' : 'D/Medium/Body-S'}>{label}</Typo>
		</GridRow>
	);
}

type TableOfTypesRowProps = {
	row: number;
	parameter: number | string;
	parentPath?: string;
	dataType: DataTypeItem['id'];
	onClick?: (row: number) => void;
	size?: ViewerSize;
};
function TableOfTypesRow(props: TableOfTypesRowProps) {
	const { row, parameter, parentPath, dataType, size = 'M', onClick } = props;
	const parameterContent = parentPath ? (
		<>
			<Typo variant="D/Regular/Meta" color="secondary">
				{parentPath}.
			</Typo>
			<Typo variant="D/Medium/Body-S">{parameter}</Typo>
		</>
	) : (
		parameter
	);

	return (
		<GridRow
			hover={!!onClick}
			className={styles.tableRow}
			onClick={() => {
				onClick?.(row);
			}}
			data-test="table-of-types-row"
		>
			<GridCell align="right" className={styles.cell}>
				{row}
			</GridCell>

			<GridCell className={styles.cell}>{parameterContent}</GridCell>

			<GridCell className={styles.cell}>
				<MarkedPiiType
					type={dataType}
					className={styles.type}
					size={size === 'S' ? 'extraSmall' : 'medium'}
				/>
			</GridCell>
		</GridRow>
	);
}

type TableOfTypesProps = {
	children: ReactNode;
	size?: ViewerSize;
};

function TableOfTypes({ children, size = 'M' }: TableOfTypesProps) {
	return (
		<GridTable className={cn(styles.table, styles[size])} dataTest="table-of-types">
			<TableOfTypesHeader size={size} />

			<GridBody className={styles.tableBody}>{children}</GridBody>
		</GridTable>
	);
}

export { TableOfTypes, TableOfTypesRow, TableOfTypesGroupRow };
