import { useEffect, useMemo, useState } from 'react';
import NoContent from 'components/NoContent';
import Preloader from 'components/Preloader';
import EnhancedTableHead, { TOrder } from 'components/table/EnhancedTableHead';
import GridBody from 'components/table/GridBody';
import GridTable from 'components/table/GridTable';
import { Typo } from 'components/typography/Typo';
import { getKafkaConsumerGroupsMemberList } from 'models/kafkaTopics/api';
import { KafkaConsumerGroupsMemberItem, KafkaTopicTableItem } from 'models/kafkaTopics/dto';
import { getComparator } from 'models/modelUtils/comparator';
import { PageParamsConfig, usePageParams } from 'services/pageParams';
import tableConfig from './config';
import { ConsumerGroupsMemberTableRow } from './ConsumerGroupsMemberTableRow';
import styles from './index.module.css';

const pageConfig = {
	sort: {
		type: 'sort',
		persistence: 'session',
	},
} satisfies PageParamsConfig;

type Props = {
	topic: KafkaTopicTableItem;
};

function ConsumerGroupsMembersTable(props: Props) {
	const { topic } = props;

	const [pageParams, setPageParams] = usePageParams(pageConfig, 'kafkaConsumerGroupsMembers');

	const [isLoading, setLoading] = useState(true);
	const [consumerGroupsMembers, setConsumerGroupsMembers] = useState<
		KafkaConsumerGroupsMemberItem[]
	>([]);

	useEffect(() => {
		getKafkaConsumerGroupsMemberList(topic.id).then((resp) => {
			setConsumerGroupsMembers(resp.consumer_groups_members);
			setLoading(false);
		});
	}, [topic.id]);

	const sortedAndFiltered = useMemo(() => {
		const result = [...consumerGroupsMembers];

		const orderBy = pageParams.sort.value as keyof KafkaConsumerGroupsMemberItem;
		const order = pageParams.sort.operator;

		return result.sort(getComparator(orderBy, order));
	}, [pageParams, consumerGroupsMembers]);

	function onSortUpdate(property: keyof KafkaConsumerGroupsMemberItem) {
		const { sort } = pageParams;

		const operator: TOrder = sort.value === property && sort.operator === 'asc' ? 'desc' : 'asc';
		const newParams = { sort: { operator, value: property } };

		setPageParams({ ...pageParams, ...newParams });
	}

	return (
		<GridTable dataTest="kafka-members-table" className={styles.table}>
			<EnhancedTableHead
				config={tableConfig}
				order={pageParams.sort.operator}
				orderBy={pageParams.sort.value}
				onRequestSort={onSortUpdate}
				rowClassname={styles.rowContainer}
			/>

			<Preloader isLoading={isLoading}>
				<GridBody data-test="kafka-members-list">
					<>
						{sortedAndFiltered.length ? (
							sortedAndFiltered.map((consumerGroupsMember, i) => (
								<ConsumerGroupsMemberTableRow
									consumerGroupsMember={consumerGroupsMember}
									search=""
									key={i}
								/>
							))
						) : (
							<NoContent type="kafkaConsumerGroupsMembers" className={styles.rowContainer}>
								<div className={styles.emptyContainer}>
									<Typo variant="D/Regular/Body-S" color="secondary">
										No available analytics.
									</Typo>
								</div>
							</NoContent>
						)}
					</>
				</GridBody>
			</Preloader>
		</GridTable>
	);
}

export { ConsumerGroupsMembersTable };
