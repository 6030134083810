import { useStore } from 'effector-react';
import { MouseEvent, useMemo, useState } from 'react';
import Alert, { AlertSeverity } from 'components/Alert';
import BaseModal, { BaseModalTheme } from 'components/BaseModal';
import Button from 'components/form/Button';
import Radio from 'components/Radio';
import { Typo } from 'components/typography/Typo';
import { dataTypesStore } from 'models/dataTypes/store';
import styles from './index.module.pcss';

function AddManualDataTypeModal({
	dataTypeDetections,
	open,
	onClose,
	onSubmit,
}: {
	dataTypeDetections: {
		dataTypes: number[];
		jsonPath: string;
	};
	open: boolean;
	onClose: () => void;
	onSubmit: (dataType: number) => void;
}) {
	const [dataTypeId, setDataTypeId] = useState<number | null>(null);
	const dtStore = useStore(dataTypesStore);

	const filteredPiiTypes = useMemo(() => {
		const filtered = dtStore.filter((piiType) => {
			const idDT = piiType.id;

			return !dataTypeDetections.dataTypes.includes(idDT);
		});

		return filtered.map(({ name, id }) => {
			return { name, id };
		});
	}, [dtStore, dataTypeDetections]);

	const [isSaving, setSaving] = useState(false);

	if (!open) return null;

	async function sendPiiType(event: MouseEvent<HTMLElement>) {
		if (dataTypeId !== null) {
			event.preventDefault();
			setSaving(true);

			await onSubmit(dataTypeId);
			onClose();
			setSaving(false);
		}
	}

	function onCancel() {
		setDataTypeId(null);
		onClose();
	}

	return (
		<BaseModal title="Assign data type" open={open} theme={BaseModalTheme.normal} onClose={onClose}>
			<Typo variant="D/Regular/Body-S">
				Manually assign the parameter a data type. Use a type from the list or create a new one. The
				parameter will show with the type you set here.
			</Typo>

			<Alert severity={AlertSeverity.warning} className={styles.alert}>
				Data types assigned manually will not be tracked automatically.
			</Alert>

			<form className={styles.form}>
				{filteredPiiTypes.length === 0 ? (
					<Typo variant="D/Regular/Body-S" className={styles.noDTText}>
						No available data types.
					</Typo>
				) : (
					<>
						<div className={styles.groupsContainer}>
							{filteredPiiTypes.map(({ name, id }) => (
								<Radio
									key={name}
									value={String(id)}
									label={name}
									name="radio-buttons-piiTypes"
									onChange={({ target }) => {
										setDataTypeId(Number(target.value));
									}}
								/>
							))}
						</div>
					</>
				)}

				<div className={styles.buttonsContainer}>
					<Button onClick={onCancel} className={styles.leftmostButton} color="ghost" size="small">
						Cancel
					</Button>

					<Button
						type="submit"
						onClick={sendPiiType}
						disabled={!dataTypeId}
						size="small"
						autoFocus
						loading={isSaving}
					>
						Apply
					</Button>
				</div>
			</form>
		</BaseModal>
	);
}

export default AddManualDataTypeModal;
