import cn from 'classnames';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import newGatewayStyles from 'components/NewGatewayBlock/index.module.pcss';
import { RouterLink } from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import { PATHS } from 'services/router';
import styles from './index.module.css';

function NoGatewayBlock() {
	return (
		<div className={cn(newGatewayStyles.container, styles.noContentContainer)}>
			<Typo variant="D/Medium/Body" className={styles.noGatewayTitle}>
				No Sensors
			</Typo>

			<p className={newGatewayStyles.description}>You have not set up any Sensors yet.</p>

			<RouterLink to={PATHS.SENSORS_DIM_CREATE} className={styles.noGatewayButton}>
				<Button data-test="create-gateway-bt">
					<Icon name="Add/Filled" size={24} className={styles.noGatewayIcon} /> New Sensor
				</Button>
			</RouterLink>
		</div>
	);
}

export default NoGatewayBlock;
