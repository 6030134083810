import { MouseEvent, useState } from 'react';
import Button from 'components/form/Button';
import Popover from 'components/Popover';
import styles from './index.module.css';

type Props = {
	onConfirm: () => void;
};

function ResetAll(props: Props) {
	const { onConfirm } = props;

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	function handleOpen(event: MouseEvent<HTMLElement>) {
		setAnchorEl(event.currentTarget);
	}

	function handleClose() {
		setAnchorEl(null);
	}

	function handleConfirm() {
		onConfirm();
		handleClose();
	}

	return (
		<>
			<Button color="ghost" size="extraSmall" onClick={handleOpen} className={styles.button}>
				Reset all
			</Button>

			<Popover
				anchorEl={anchorEl}
				open={!!anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				className={styles.paper}
			>
				Are you sure?
				<div className={styles.separator} />
				<Button
					color="tertiary"
					theme="danger"
					size="extraSmall"
					onClick={handleConfirm}
					className={styles.button}
				>
					Reset
				</Button>
				<Button color="ghost" size="extraSmall" onClick={handleClose} className={styles.button}>
					Cancel
				</Button>
			</Popover>
		</>
	);
}

export { ResetAll };
