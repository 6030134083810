import { CSSProperties, useContext } from 'react';
import { Typo } from 'components/typography/Typo';
import { toAbbreviatedNumber, toRPS } from 'services/numbers';
import { ZoomContext } from '../../ZoomWrapper';
import styles from './index.module.css';

type Props = {
	x: number;
	y: number;
	estimatedRequests: number;
};

function MapPopover({ x, y, estimatedRequests }: Props) {
	const { scaleStep } = useContext(ZoomContext);

	const style = {
		left: x,
		top: y,
		'--popover-scale': 300 / scaleStep,
	} as CSSProperties;

	return (
		<div className={styles.popover} style={style}>
			<div className={styles.square} />
			<Typo variant="D/Medium/Body-S">Requests</Typo>

			<Typo variant="D/Medium/Body-S" className={styles.number}>
				{toAbbreviatedNumber(estimatedRequests)}
			</Typo>
			<Typo variant="D/Medium/Body-S" color="secondary">
				per&nbsp;day
			</Typo>

			<Typo variant="D/Medium/Body-S" className={styles.number}>
				{toRPS(estimatedRequests)}
			</Typo>
			<Typo variant="D/Medium/Body-S" color="secondary">
				avg&nbsp;rps
			</Typo>

			<div className={styles.pointer} />
		</div>
	);
}

export { MapPopover };
