import cn from 'classnames';
import { useStore } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { PiiTypeList } from 'components/PiiType';
import Preloader from 'components/Preloader';
import SensitivityChip from 'components/SensitivityChip';
import { Tab, TabPanel, Tabs } from 'components/Tabs';
import { Tooltip } from 'components/Tooltip';
import { Typo } from 'components/typography/Typo';
import { AssetGroupListItem } from 'models/assetsGroups/dto';
import { S3BucketItem } from 'models/s3Buckets/dto';
import { getS3BucketByIdFx } from 'models/s3Buckets/effects';
import { s3RegionsByKeyword } from 'models/s3Regions/store';
import { DateFormat, dayjs } from 'services/dayjs';
import { lastSeen } from 'services/lastSeen';
import { toLocaleString } from 'services/numbers';
import { PATHS } from 'services/router';
import { S3BucketPublicStatus } from 'views/SuperAssets/S3Buckets/S3BucketTableRow';
import { S3BucketTagList } from 'views/SuperAssets/S3Buckets/S3BucketTag';
import { MapAsset } from '../../../index';
import CloseButton from '../CloseButton';
import commonStyles from '../index.module.css';
import Label from '../Label';
import Details from './Details';
import styles from './index.module.css';

type Props = {
	bucketId: AssetGroupListItem['id'];
	asset: MapAsset;
};

function S3BucketDetails({ bucketId, asset }: Props) {
	const [loading, setLoading] = useState(true);
	const [bucket, setBucket] = useState<S3BucketItem>();

	useEffect(() => {
		getS3BucketByIdFx(bucketId)
			.then((data) => setBucket(data))
			.finally(() => setLoading(false));
	}, [bucketId]);

	return (
		<div className={commonStyles.container} data-test="data-map-details-bucket">
			<Preloader isLoading={loading}>
				{bucket && <S3BucketDetailsTable asset={asset} bucket={bucket} />}
			</Preloader>
		</div>
	);
}

function S3BucketDetailsTable({ bucket, asset }: { bucket: S3BucketItem; asset: MapAsset }) {
	const formattedLocal = dayjs(bucket.created_at).format(DateFormat.date);
	const formattedUTC = dayjs(bucket.created_at).utc().format(DateFormat.utc);
	const lastSeenValue = useMemo(
		() => lastSeen({ timestamp: bucket.last_checked, short: true }),
		[bucket.last_checked]
	);

	const s3Regions = useStore(s3RegionsByKeyword);
	const regionDescription = s3Regions[bucket.region]?.description || '';

	const [tab, setTab] = useState<'summary' | 'details'>('summary');

	return (
		<div className={commonStyles.sidePaddings}>
			<div className={commonStyles.header}>
				<Typo variant="D/Medium/H100-Header">{bucket.name}</Typo>

				<CloseButton />
			</div>

			<Typo variant="D/Regular/Body-S" color="secondary">
				{regionDescription}
			</Typo>

			<Tabs value={tab} onChange={setTab}>
				<Tab value="summary" label="Summary" />
				<Tab value="details" label="Details" />
			</Tabs>

			<TabPanel value={tab} index="summary" className={commonStyles.tabPanel}>
				<div className={commonStyles.detailsGrid}>
					<Label data-test="s3-bucket-details-type" title="Resource">
						S3 bucket
					</Label>

					<Label title="Resource type">
						<Typo variant="D/Regular/Body-S" className={styles.type}>
							{bucket?.type}
						</Typo>
					</Label>

					<Label title="Description">{bucket.description || '—'}</Label>
					<Label title="Owner">{bucket.owner || '—'}</Label>
					<Label title="Account ID">{bucket.account_id}</Label>
					<Label data-test="s3-bucket-details-region" title="Region">
						{regionDescription}
					</Label>
					<Label title="Created">
						<Tooltip title={formattedUTC}>
							<>{formattedLocal}</>
						</Tooltip>
					</Label>
					<Label title="Last checked">
						<Tooltip title={lastSeenValue.date}>
							<>{lastSeenValue.diff}</>
						</Tooltip>
					</Label>
					<Label data-test="s3-bucket-details-files" title="files">
						{toLocaleString(bucket.objects_count)}
					</Label>
					<Label title="access rights">{S3BucketPublicStatus[bucket.public_status]}</Label>
					<Label title="Encryption">{bucket.is_encrypted ? 'Yes' : 'No'}</Label>
					<Label title="sensitivity">
						<SensitivityChip
							data-test="s3-bucket-details-sensitivity"
							sensitivity={bucket.sensitivity}
						/>
					</Label>
					<Label title="data types">
						<PiiTypeList
							dataTest="s3-bucket-details-data-types"
							noHighlight
							data={bucket.data_types}
						/>
					</Label>
					<Label title="tags">
						<S3BucketTagList tags={bucket.tags} />
					</Label>
				</div>

				<Button
					color="tertiary"
					size="small"
					endIcon={<Icon name="Open/Regular" size={20} />}
					fullWidth
					href={generatePath(PATHS.S3_BUCKETS_ITEM, { id: bucket.id })}
					target="_blank"
					className={commonStyles.detailedInfo}
				>
					View S3 bucket page
				</Button>
			</TabPanel>

			<TabPanel value={tab} index="details" className={cn(styles.tabPanel, commonStyles.tabPanel)}>
				<Details bucket={bucket} asset={asset} />
			</TabPanel>
		</div>
	);
}

export { S3BucketDetails };
