import React, { KeyboardEvent, useContext } from 'react';
import { Typo } from 'components/typography/Typo';
import { KEY_INPUT_INDEX, MAIN_INPUT_INDEX, StateContext } from '../.';
import { ColoredWrapper } from '../../ColoredWrapper';
import { RuleItem } from '../RuleItem';
import styles from './index.module.css';

interface Props {
	onChange: (value: string, ruleId: number) => void;
	onKeyDown: (event: KeyboardEvent<HTMLInputElement>, value: string, ruleId: number) => void;
	value: string;
}

export function KeyLabelPart({ onChange, onKeyDown, value }: Props) {
	const { state, setState } = useContext(StateContext);

	if (!state.isLabelType) return null;

	return (
		<ColoredWrapper className={styles.container}>
			<Typo
				className={styles.keyLabel}
				component="span"
				variant="D/Medium/Body-S"
				color="secondary"
				onClick={() =>
					setState((prevState) => ({
						...prevState,
						editMode: 'key',
						idActiveRule: KEY_INPUT_INDEX,
					}))
				}
			>
				key:{' '}
			</Typo>

			<RuleItem
				focused={state.idActiveRule === KEY_INPUT_INDEX}
				onChange={onChange}
				onFocus={() => {
					setState((prevState) => ({
						...prevState,
						editMode: 'key',
						idActiveRule: KEY_INPUT_INDEX,
					}));
				}}
				onKeyDown={onKeyDown}
				ruleId={KEY_INPUT_INDEX}
				value={value}
			/>

			<Typo
				className={styles.valueLabel}
				component="span"
				onClick={() => {
					setState((prevState) => ({
						...prevState,
						editMode: 'values',
						idActiveRule: MAIN_INPUT_INDEX,
					}));
				}}
				variant="D/Medium/Body-S"
				color="secondary"
			>
				value:{' '}
			</Typo>
		</ColoredWrapper>
	);
}
