import CopyToClipboard from 'components/CopyToClipboard';
import { Tooltip } from 'components/Tooltip';
import { DiscoveryCompleteJson } from 'models/eventsV2/dto';
import { PATHS } from 'services/router';
import { eventTitleAsText } from '../EventRow';
import JiraButton from '../JiraButton';
import { formatWithToday } from '../utils';
import styles from './index.module.pcss';

type Props = { event: DiscoveryCompleteJson };

function DiscoveryCompleteEvent({ event }: Props) {
	const linkHere = `${location.origin}${PATHS.EVENTS}?id=${event.id}`;

	const formattedLocal = formatWithToday(event.created_at);
	const titleAsText = eventTitleAsText(event);
	const jiraSummary = `Soveren issue: ${titleAsText}`;
	const jiraDescription = `*${titleAsText}*

*Event*: #${event.id}
*Time*: ${formattedLocal}
*Category*: Other

See on Soveren dashboard: ${linkHere}`;

	return (
		<>
			<div className={styles.detailsGrid}>
				<span className={styles.detailsLabel}>Event ID</span>
				<span>
					{event.id}{' '}
					<Tooltip
						title="Copy event link"
						placement="right"
						className={styles.copyToClipboardWrapper}
					>
						<CopyToClipboard
							value={linkHere}
							successText="Event link copied to clipboard"
							className={styles.copyToClipboard}
						/>
					</Tooltip>
				</span>

				<span className={styles.detailsLabel}>Category</span>
				<span>Other</span>
			</div>

			<div className={styles.links}>
				<JiraButton summary={jiraSummary} description={jiraDescription} />
			</div>
		</>
	);
}

export default DiscoveryCompleteEvent;
