import cn from 'classnames';
import { useState } from 'react';
import Button from 'components/form/Button';
import TextField from 'components/form/TextField';
import { SecretToken } from 'components/SecretToken';
import { enqueueSnackbar } from 'components/Snackbar';
import { Tooltip } from 'components/Tooltip';
import ExternalLink from 'components/typography/ExternalLink';
import { Typo } from 'components/typography/Typo';
import { AccountAWSItem, AccountAWSTest } from 'models/sensors/aws/dto';
import { goBackByDefault } from 'services/history';
import { PATHS } from 'services/router';
import { NeedHelpBlock } from 'views/common/NeedHelpBlock';
import styles from './index.module.css';
import { TestConfiguration } from './TestConfiguration';

const SOVEREN_AWS_ACCOUNT_ID = '579178354807';

interface Props {
	account: AccountAWSItem;
	setAWSExternalId: (id: AccountAWSItem['id']) => void;
	createAccount: ({
		id,
		role_arn,
	}: {
		id: AccountAWSItem['id'];
		role_arn: AccountAWSItem['role_arn'];
	}) => Promise<void>;
	configurationTestHandler: (testStatus: AccountAWSTest) => void;
	configurationChecked: boolean;
	isNew: boolean;
}

export function AccountCreateForm({
	account,
	isNew,
	setAWSExternalId,
	configurationChecked,
	configurationTestHandler,
	createAccount,
}: Props) {
	const [role, setRole] = useState('');
	const [loading, setLoading] = useState(false);

	const isDraft = isNew && !role;

	async function createAccountHandler() {
		setLoading(true);

		createAccount({ id: account.id, role_arn: role }).finally(function finalizeRequest() {
			setLoading(false);
		});
	}

	function AddButton() {
		if (!configurationChecked) {
			return (
				<Tooltip
					title={'Fill in the fields above and run\nthe configuration test first'}
					className={styles.saveButton}
				>
					<Button
						color="primary"
						data-test="aws-connection-save-button"
						disabled={!configurationChecked}
						loading={loading}
						onClick={createAccountHandler}
						size="medium"
						type="submit"
					>
						Add
					</Button>
				</Tooltip>
			);
		}

		return (
			<Button
				color="primary"
				data-test="aws-connection-save-button"
				disabled={!configurationChecked}
				loading={loading}
				onClick={createAccountHandler}
				size="medium"
				type="submit"
			>
				Add
			</Button>
		);
	}

	function SaveDraftButton() {
		return (
			<Button
				color="primary"
				data-test="aws-connection-save-draft-button"
				loading={loading}
				onClick={() => {
					goBackByDefault(PATHS.ACCOUNT_AWS);
					enqueueSnackbar('Draft successfully saved');
				}}
				size="medium"
				type="submit"
			>
				Save draft
			</Button>
		);
	}

	return (
		<div className={styles.container}>
			<div>
				<Typo variant="D/Medium/H100-Header">Configuring the AWS IAM Role</Typo>

				<Typo
					variant="D/Regular/Body-S"
					className={cn(styles.commonWidth, styles.description)}
					color="secondary"
				>
					To enable Soveren to automatically discover objects in your infrastructure, create a role
					in AWS IAM using the AWS External ID and Soveren AWS Account ID from the fields below and
					following the policies in our {/* TODO SMAT-3805: use a right link */}
					<ExternalLink href="https://docs.soveren.io/en/stable/administration/aws-connections/">
						guide
					</ExternalLink>
					.
				</Typo>

				<div>
					<div className={styles.externalIdContainer}>
						<SecretToken
							canBeHidden={false}
							canBeCopied
							focused
							className={cn(styles.commonWidth, styles.input)}
							data-test="aws-connection-external-id"
							label="AWS External ID"
							optional={false}
							value={account.external_id}
							helperText={null}
						/>

						<Button color="tertiary" onClick={() => setAWSExternalId(account.id)}>
							Regenerate
						</Button>
					</div>
				</div>

				<SecretToken
					canBeHidden={false}
					canBeCopied
					focused
					className={cn(styles.commonWidth, styles.input)}
					data-test="aws-connection-external-id"
					label="Soveren AWS Account ID"
					optional={false}
					value={SOVEREN_AWS_ACCOUNT_ID}
					helperText={null}
				/>
			</div>

			<div>
				<Typo variant="D/Medium/H100-Header">Role delegation info</Typo>

				<Typo
					variant="D/Regular/Body-S"
					className={cn(styles.commonWidth, styles.description)}
					color="secondary"
				>
					Once you have configured the IAM role, paste Role ARN to the field below.
				</Typo>

				<TextField
					className={cn(styles.commonWidth, styles.input)}
					data-test="aws-connection-role"
					label="Role ARN"
					onChange={function onRoleChange({ target }) {
						setRole(target.value);
					}}
					optional={false}
					value={role}
					autoFocus
				/>
			</div>

			<div className={cn({ [styles.disabled]: role.length === 0 })}>
				<Typo variant="D/Medium/H100-Header">Configuration test</Typo>

				<Typo
					variant="D/Regular/Body-S"
					className={cn(styles.commonWidth, styles.description)}
					color="secondary"
				>
					Ensure everything is working correctly.
				</Typo>

				<TestConfiguration
					onConfigurationTest={configurationTestHandler}
					id={account.id}
					role_arn={role}
				/>
			</div>

			<NeedHelpBlock />

			<div className={styles.buttonBlock}>
				<Button
					color="secondary"
					data-test="aws-connection-cancel-button"
					onClick={() => goBackByDefault(PATHS.ACCOUNT_AWS)}
					size="medium"
				>
					Cancel
				</Button>

				{isDraft ? <SaveDraftButton /> : <AddButton />}
			</div>
		</div>
	);
}
