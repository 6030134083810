import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Alert, { AlertSeverity } from 'components/Alert';
import Button from 'components/form/Button';
import PasswordField from 'components/form/PasswordField';
import TextField from 'components/form/TextField';
import Icon from 'components/Icon';
import Preloader from 'components/Preloader';
import { enqueueSnackbar } from 'components/Snackbar';
import Link from 'components/typography/Link';
import { Typo } from 'components/typography/Typo';
import { getInviteInfoFx } from 'models/clients/effects';
import { getSSOLoginUrl } from 'models/session/api';
import { signUpWithTokenFx } from 'models/session/effects';
import { APIError } from 'services/api/httpRequest';
import styles from './index.module.pcss';

type FormValues = {
	email: string;
	name: string;
	password: string;
};

function SignUpWithToken() {
	const token = new URLSearchParams(useLocation().search).get('token') || '';
	const [createdBy, setCreatedBy] = useState('');
	const [isSSOEnabled, setSSOEnabled] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const [serverError, setServerError] = useState<string | null>(null);
	const [isSubmitting, setSubmitting] = useState(false);

	const { control, handleSubmit, reset } = useForm<FormValues>({
		defaultValues: {
			email: '',
			name: '',
			password: '',
		},

		mode: 'onBlur', // "onChange"
	});

	useEffect(() => {
		getInviteInfoFx({ token })
			.then(({ created_by, email, full_name, is_sso_enabled }) => {
				setCreatedBy(created_by);
				setSSOEnabled(is_sso_enabled);

				reset({ email, name: full_name });
			})
			.catch((error) => {
				if (!(error instanceof APIError)) throw error;

				if (error.response.status === 400) {
					setServerError('Invalid invite link');
				} else if (error.response.status === 403) {
					return error.response.json().then(({ code, message }) => {
						if (code.startsWith('104')) {
							setServerError(message);
						} else {
							throw error;
						}
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	function onSubmit(values: FormValues) {
		return isSSOEnabled ? onSubmitSSO(values) : onSubmitPassword(values);
	}

	async function onSubmitPassword({ email, password, name }: FormValues) {
		setSubmitting(true);

		try {
			await signUpWithTokenFx({
				token,
				email,
				password,
				full_name: name,
			});
		} catch (error) {
			if (error instanceof APIError && error.response.status === 422) {
				const { message } = await error.response.json();
				setServerError(message);
			} else if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();
				enqueueSnackbar(message);
			} else {
				throw error;
			}
		} finally {
			setSubmitting(false);
		}
	}

	async function onSubmitSSO({ email }: FormValues) {
		setSubmitting(true);

		try {
			const { sso_login_url } = await getSSOLoginUrl({ email });
			window.location.replace(sso_login_url);
		} catch (error) {
			if (error instanceof APIError && error.response.status === 400) {
				const { message } = await error.response.json();
				setServerError(message);
				setSubmitting(false);
			} else {
				throw error;
			}
		}
	}

	return (
		<div className={styles.container}>
			<div className={styles.wrapper} data-test="sign-up-with-token-form">
				<Preloader isLoading={isLoading}>
					{serverError ? (
						<>
							<Typo
								variant="D/Medium/H500-Greeting-Title"
								className={styles.title}
								dataTest="sign-up-invalid-link"
							>
								Invalid link!
							</Typo>

							{serverError === 'Invalid invite link' ? (
								<Typo className={styles.subTitle}>
									The link is incorrect, has expired, or has already
									<br />
									been used. Check the link or contact your
									<br />
									administrator.
								</Typo>
							) : (
								<Typo className={styles.subTitle}>{serverError}</Typo>
							)}
						</>
					) : (
						<>
							<Typo variant="D/Medium/H500-Greeting-Title" className={styles.title}>
								Join your team on Soveren
							</Typo>

							<Typo className={styles.subTitle} color="secondary">
								<Typo variant="D/Medium/Body" component="span">
									{createdBy}
								</Typo>{' '}
								has invited
								<br />
								you to join their team.
							</Typo>

							<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
								<TextField
									control={control}
									label="Email"
									name="email"
									type="email"
									data-test="email"
									required
									fullWidth
									size="large"
									autoComplete="off"
									readOnly
								/>

								{!isSSOEnabled && (
									<TextField
										required
										control={control}
										label="Name"
										name="name"
										data-test="name"
										fullWidth
										size="large"
										autoComplete="off"
									/>
								)}

								{!isSSOEnabled && (
									<PasswordField
										control={control}
										label="Password"
										name="password"
										autoComplete="off"
										data-test="password"
										required
										fullWidth
										size="large"
									/>
								)}

								{isSSOEnabled && (
									<Typo
										variant="D/Medium/Body-S"
										color="secondary"
										className={styles.ssoInfo}
										data-test="sign-up-sso-message"
									>
										<Icon name="Info/Regular" size={20} /> Your company uses Single Sign On.
									</Typo>
								)}

								<Button
									type="submit"
									size="large"
									loading={isSubmitting}
									className={styles.formButton}
									data-test="create-acc-bt"
									fullWidth
								>
									Join
								</Button>

								{serverError && !isLoading && (
									<Alert className={styles.errorMessage} severity={AlertSeverity.error}>
										{serverError}
									</Alert>
								)}
							</form>

							<Typo color="secondary" variant="D/Regular/Meta" className={styles.terms}>
								By creating an account, I agree to the{' '}
								<Link
									inherit
									target="_blank"
									className={styles.termsLink}
									to="/documents/terms.pdf"
								>
									Terms of service.
								</Link>
							</Typo>
						</>
					)}
				</Preloader>
			</div>
		</div>
	);
}

export default SignUpWithToken;
