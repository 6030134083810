import { CheckedPiiType } from 'components/PiiType';
import { Typo } from 'components/typography/Typo';
import { DataFlowDetailsTable } from 'models/assetDataFlowDetailsV2/dto';
import { DataTypeItem } from 'models/dataTypes/dto';
import styles from './index.module.css';

interface Props {
	dataTypes: DataFlowDetailsTable['data_types'];
	activeDataTypes: DataFlowDetailsTable['data_types'];
	onChange: (activeDataTypes: DataFlowDetailsTable['data_types']) => void;
}

function DataFlowDataTypeFilter({ dataTypes, activeDataTypes, onChange }: Props) {
	function handleDataTypeChange(dataType: DataTypeItem['id']) {
		let newArray = activeDataTypes.slice();

		if (activeDataTypes.includes(dataType)) {
			newArray = newArray.filter((dt) => dt !== dataType);
		} else {
			newArray.push(dataType);
		}

		onChange(newArray);
	}

	return (
		<div className={styles.container} data-test="data-flow-filter-row">
			{dataTypes !== null ? (
				<>
					{dataTypes.map((dataType) => (
						<CheckedPiiType
							checked={activeDataTypes.includes(dataType)}
							type={dataType}
							key={dataType}
							onChange={handleDataTypeChange}
							className={styles.checkbox}
						/>
					))}

					{activeDataTypes.length > 0 && (
						<Typo
							variant="D/Medium/Body-S"
							className={styles.reset}
							data-test="data-flow-filter-reset"
							onClick={() => onChange([])}
						>
							<a onClick={() => onChange([])}>Reset</a>
						</Typo>
					)}
				</>
			) : (
				<Typo variant="D/Regular/Body-S" color="secondary" className={styles.noDataText}>
					No sensitive data.
				</Typo>
			)}
		</div>
	);
}

export default DataFlowDataTypeFilter;
