import cn from 'classnames';
import { ReactNode, useContext } from 'react';
import { StateContext } from '../RuleGroup';
import styles from './index.module.pcss';

interface ColoredWrapperProps {
	children: ReactNode;
	className?: string;
	onClick?: () => void;
}

export type ColoredWrapperState =
	| 'error'
	| 'unfocusedEmptyFirst'
	| 'unfocusedEmpty'
	| 'unfocusedWithContent'
	| 'unfocusedHoveredEmpty'
	| 'unfocusedHoveredWithContent'
	| 'focusedWithContent'
	| 'focusedEmpty';

export function ColoredWrapper({ children, className, onClick }: ColoredWrapperProps) {
	const { state, setState } = useContext(StateContext);

	return (
		<div
			className={cn(className, styles.container, {
				[styles[state.color]]: state,
			})}
			data-group=""
			onClick={onClick}
			onMouseEnter={() => {
				setState((prevState) => ({ ...prevState, hovered: true }));
			}}
			onMouseLeave={() => {
				setState((prevState) => ({ ...prevState, hovered: false }));
			}}
		>
			{children}
		</div>
	);
}
