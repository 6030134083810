import AssetChip from 'components/AssetChip';
import Icon from 'components/Icon';
import { Typo } from 'components/typography/Typo';
import { EventAsset } from 'models/eventsV2/dto';
import { getStringDividedByDot } from 'services/strings';
import { getAssetNameByType } from 'views/common/AssetNameWithIcon';
import styles from './index.module.css';

interface Props {
	label: 'Request' | 'Response';
	asset: EventAsset;
	showDanger: boolean;
}

export function ViewerTitle({ label = 'Request', asset, showDanger }: Props) {
	return (
		<div className={styles.viewerTitle}>
			<Typo variant="D/Medium/Body-S">{label} by </Typo>

			<AssetChip
				label={getAssetNameByType(asset.type, asset.name, 12)}
				isExternal={asset.is_external}
				size="extraSmall"
				noTooltip
			/>

			<Typo variant="D/Medium/Body-S" color="secondary" className={styles.flex}>
				{getStringDividedByDot(asset.cluster_name, asset.namespace)}
			</Typo>

			{showDanger && <Icon name="Danger/FilledRed" size={16} />}
		</div>
	);
}
