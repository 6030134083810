import { InputAdornment } from '@material-ui/core';
import { ReactNode } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import TextField from 'components/form/TextField';
import { Typo } from 'components/typography/Typo';
import styles from './index.module.css';

type ExampleProps = {
	children: ReactNode;
};

function Example(props: ExampleProps) {
	return (
		<Typo variant="D/Medium/Body-S" component="span" className={styles.example}>
			{props.children}
		</Typo>
	);
}
function ExampleSmall(props: ExampleProps) {
	return (
		<Typo variant="D/Medium/Body-S" component="span" className={styles.exampleSmall}>
			{props.children}
		</Typo>
	);
}
function Prefix(props: ExampleProps) {
	return (
		<Typo variant="D/Medium/Body-S" color="secondary" component="span">
			{props.children}
		</Typo>
	);
}
function Value(props: ExampleProps) {
	return (
		<Typo variant="D/Medium/Body-S" component="span">
			{props.children}
		</Typo>
	);
}
function Comma() {
	return (
		<Typo variant="D/Medium/Body-S" color="secondary" component="span">
			,
		</Typo>
	);
}
function Input(props: ExampleProps) {
	return (
		<TextField
			size="extraSmall"
			helperText={null}
			value={props.children}
			focused={true}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<ButtonIcon icon="DismissCircle/Filled" size="XS" />
					</InputAdornment>
				),
				classes: {
					root: styles.validInput,
					notchedOutline: styles.bolt,
				},
			}}
		/>
	);
}
function InvalidInput(props: ExampleProps) {
	return (
		<TextField
			size="extraSmall"
			helperText={null}
			error
			value={props.children}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<ButtonIcon icon="DismissCircle/Filled" size="XS" />
					</InputAdornment>
				),
				classes: {
					root: styles.input,
				},
			}}
		/>
	);
}

type Props = {
	otherInLink: string;
};

function UsefulTipsCurtain(props: Props) {
	const { otherInLink } = props;

	return (
		<div className={styles.container}>
			<Typo variant="D/Medium/H100-Header">💡 Useful tips</Typo>

			<Typo variant="D/Medium/Body-S" className={styles.subtitle}>
				How to enter IP
			</Typo>

			<ul className={styles.list}>
				<li>
					<Typo variant="D/Medium/Body-S" component="span">
						Single IP.
					</Typo>{' '}
					<Typo variant="D/Regular/Body-S" component="span">
						Example:
					</Typo>{' '}
					<Example>
						<Prefix>IP:</Prefix>
						<Value>123.123.123.0</Value>
					</Example>
				</li>

				<li>
					<Typo variant="D/Medium/Body-S" component="span">
						IP range.
					</Typo>{' '}
					<Typo variant="D/Regular/Body-S" component="span">
						Use a dash (&quot;-&quot;) as a separator. For a range, the right boundary must be
						greater than the left one, considering all octets.
					</Typo>
					<div className={styles.flex}>
						<Typo variant="D/Regular/Body-S">Example:</Typo>
						<div className={styles.flexColumn}>
							<span>
								<Example>
									<Prefix>IP:</Prefix>
									<Input>129.1.1.1-129.255.255.255</Input>
								</Example>{' '}
								is valid
							</span>
							<span>
								<Example>
									<Prefix>IP:</Prefix>
									<InvalidInput>129.1.1.1-129.1.0.1</InvalidInput>
								</Example>{' '}
								is not valid.
							</span>
						</div>
					</div>
				</li>

				<li>
					<Typo variant="D/Medium/Body-S" component="span">
						Combination of single IPs and IP ranges.
					</Typo>{' '}
					<Typo variant="D/Regular/Body-S" component="span">
						You can input a list of IP addresses and IP ranges using commas (&quot;,&quot;) as
						separators.
					</Typo>
					<div className={styles.flex}>
						<Typo variant="D/Regular/Body-S">Example:</Typo>
						<div>
							<Example>
								<Prefix>IP:</Prefix>
								<Value>123.123.123.0</Value>
								<Comma />
								<Value>129.1.1.1-129.255.255.255</Value>
							</Example>
						</div>
					</div>
				</li>

				<li>
					<Typo variant="D/Medium/Body-S" component="span">
						Paste a list of IPs from anywhere.
					</Typo>{' '}
					<Typo variant="D/Regular/Body-S" component="span">
						Use commas (&quot;,&quot;) as separators.
					</Typo>
				</li>

				<li>
					<Typo variant="D/Medium/Body-S" component="span">
						Do not use private IP ranges in the rules.
					</Typo>{' '}
					<Typo variant="D/Regular/Body-S" component="span">
						The service derives from IPs in{' '}
						<a href={otherInLink} target="_blank" rel="noopener">
							Other incoming
						</a>
						, where no private IP addresses are anticipated.
					</Typo>
				</li>
			</ul>

			<Typo variant="D/Medium/Body-S" className={styles.subtitle}>
				How to enter User Agent
			</Typo>

			<ul className={styles.list}>
				<li>
					<Typo variant="D/Regular/Body-S" component="span">
						Employ the wildcard <ExampleSmall>[ver]</ExampleSmall> to match version strings like{' '}
						<ExampleSmall>v1.1</ExampleSmall> or <ExampleSmall>1.1</ExampleSmall> (but not{' '}
						<ExampleSmall>v1</ExampleSmall> or <ExampleSmall>1</ExampleSmall>).
					</Typo>

					<div className={styles.flex}>
						<Typo variant="D/Regular/Body-S">Example:</Typo>
						<div className={styles.flexColumn}>
							<Example>
								<Prefix>user agent:</Prefix>
								<Value>Mozilla-[ver]-alpha</Value>
							</Example>
							<span>
								matches{' '}
								<Example>
									<Prefix>user agent:</Prefix>
									<Value>Mozilla-v1.1-alpha</Value>
								</Example>
							</span>
							<span>
								but not{' '}
								<Example>
									<Prefix>user agent:</Prefix>
									<Value>Mozilla-v1-alpha</Value>
								</Example>
							</span>
						</div>
					</div>
				</li>
			</ul>
		</div>
	);
}

export default UsefulTipsCurtain;
