import { useCallback } from 'react';
import { DraftFunction } from 'use-immer';
import Checkbox from 'components/Checkbox';
import { DARPolicyItem } from 'models/policiesV2/dto';
import styles from '../index.module.css';

type Props = {
	allow_unencrypted_s3_buckets: boolean;
	allow_public_s3_buckets: boolean;
	allow_unencrypted_rds: boolean;
	setFormData: (arg: DARPolicyItem | DraftFunction<DARPolicyItem>) => void;
};

function ResourceConfigurations(props: Props) {
	const {
		allow_unencrypted_s3_buckets,
		allow_public_s3_buckets,
		allow_unencrypted_rds,
		setFormData,
	} = props;

	const setAllowUnencryptedBuckets = useCallback((e) => {
		setFormData((draft) => {
			draft.allow_unencrypted_s3_buckets = !e.target.checked;
		});
	}, []);

	const setAllowPublicBuckets = useCallback((e) => {
		setFormData((draft) => {
			draft.allow_public_s3_buckets = !e.target.checked;
		});
	}, []);

	const setAllowUnencryptedRDS = useCallback((e) => {
		setFormData((draft) => {
			draft.allow_unencrypted_rds = !e.target.checked;
		});
	}, []);

	return (
		<div className={styles.checkboxes}>
			<Checkbox
				className={styles.checkboxInput}
				size="M"
				label="Data types should not be stored in unencrypted S3 buckets"
				checked={!allow_unencrypted_s3_buckets}
				onChange={setAllowUnencryptedBuckets}
				dataTest="policy-s3-unencrypted-checkbox"
			/>

			<Checkbox
				className={styles.checkboxInput}
				size="M"
				label="Data types should not be stored in public S3 buckets"
				checked={!allow_public_s3_buckets}
				onChange={setAllowPublicBuckets}
				dataTest="policy-s3-unencrypted-checkbox"
			/>

			<Checkbox
				className={styles.checkboxInput}
				size="M"
				label="Data types should not be stored in unencrypted RDS"
				checked={!allow_unencrypted_rds}
				onChange={setAllowUnencryptedRDS}
				dataTest="policy-s3-unencrypted-checkbox"
			/>
		</div>
	);
}

export { ResourceConfigurations };
