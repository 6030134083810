import CongratulationSVG from 'assets/icons/congratulation.svg';
import { BaseModalTheme } from 'components/BaseModal';
import { ConfirmModal, getConfirmation } from 'components/ConfirmModal';
import { Typo } from 'components/typography/Typo';
import styles from './index.module.css';

const CongratulationModal = () => {
	const confirmCongratulationModal = (
		<ConfirmModal
			theme={BaseModalTheme.normal}
			title={
				<div className={styles.title}>
					<CongratulationSVG /> Congratulations!
				</div>
			}
			confirmProps={{
				children: 'OK, got it',
				'data-test': 'policy-close-modal-button',
			}}
			hideCancel={true}
			data-test="policy-congratulation-modal"
		>
			<Typo className={styles.description} variant="D/Regular/Body-S">
				We have created Policies based on your data. Activate violation tracking to
				<br />
				start receiving events when policies are violated. You can change these
				<br />
				Policies manually.
			</Typo>
		</ConfirmModal>
	);

	return getConfirmation(confirmCongratulationModal);
};

export default CongratulationModal;
