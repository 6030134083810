import { useCallback } from 'react';
import { DraftFunction } from 'use-immer';
import Checkbox from 'components/Checkbox';
import { DIMPolicyItem } from 'models/policiesV2/dto';
import styles from '../index.module.css';

type Props = {
	allow_encrypted_network_only: boolean;
	setFormData: (arg: DIMPolicyItem | DraftFunction<DIMPolicyItem>) => void;
};

function DataFlowMonitoring(props: Props) {
	const { allow_encrypted_network_only, setFormData } = props;

	// allow_encrypted_network_only
	const setAllowEncryptedNetworkOnly = useCallback((e) => {
		setFormData((draft) => {
			draft.allow_encrypted_network_only = e.target.checked;
		});
	}, []);

	return (
		<Checkbox
			className={styles.checkboxInput}
			size="M"
			label="Notify if the selected data types are shared in unencrypted traffic"
			checked={allow_encrypted_network_only}
			onChange={setAllowEncryptedNetworkOnly}
			dataTest="policy-encrypted_network_only-checkbox"
		/>
	);
}

export { DataFlowMonitoring };
