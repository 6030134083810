import { useStore } from 'effector-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useImmer } from 'use-immer';
import Alert, { AlertSeverity } from 'components/Alert';
import TextField from 'components/form/TextField';
import Radio from 'components/Radio';
import { SecretToken } from 'components/SecretToken';
import SensitivityChip from 'components/SensitivityChip';
import { enqueueSnackbar } from 'components/Snackbar';
import { Typo } from 'components/typography/Typo';
import { setCustomDataType, updateCustomDataTypeRules } from 'models/dataTypes/api';
import { dataTypesStore } from 'models/dataTypesExtended/store';
import { APIError } from 'services/api/httpRequest';
import { PATHS } from 'services/router';
import { Section } from 'views/common/Section';
import { DataTypeData } from '../index';
import { DataTypeFormFooter } from './DataTypeFormFooter';
import { DataTypeFormHeader } from './DataTypeFormHeader';
import {
	connectStoreAndFormData,
	ERROR_TEXTS,
	findEmptyFieldsInTheRules,
	nameValidation,
	prepareAliasByName,
	REGEXP_LIST,
	scrollToTop,
} from './helpers';
import styles from './index.module.css';
import { RuleBuilder } from './RuleBuilder';

type Props = {
	data: DataTypeData;
};

export function DataTypesForm({ data }: Props) {
	const history = useHistory();
	const [formData, setFormData] = useImmer(data);
	const dataTypesFromStore = useStore(dataTypesStore);

	const topRef = useRef<HTMLDivElement>(null);
	const detailsSectionRef = useRef<HTMLDivElement>(null);
	const sensitivitySectionRef = useRef<HTMLDivElement>(null);
	const rulesSectionRef = useRef<HTMLDivElement>(null);

	const [nameError, setNameError] = useState('');
	const [duplicateError, setDuplicateError] = useState('');
	const [aliasError, setAliasError] = useState('');
	const [sensitivityError, setSensitivityError] = useState(false);

	const isNew = useMemo(
		function doesIdExist() {
			return data.id === 0;
		},
		[data.id]
	);

	const onChangeName = useCallback(function saveNameAndAlias({ target }) {
		const name = target.value;

		setFormData(function saveNameHandler(draft) {
			draft.name = name;
		});

		if (nameValidation(name)) {
			setNameError('');
			setDuplicateError('');
			setDuplicateError('');
			setAliasError('');

			setFormData(function saveAliasHandler(draft) {
				draft.alias = prepareAliasByName(name);
			});
		} else {
			setNameError(ERROR_TEXTS['nameValue']);
		}
	}, []);

	const onChangeSensitivity = useCallback(function saveSensitivity({ target }) {
		setSensitivityError(false);

		setFormData(function saveSensitivityHandler(draft) {
			draft.sensitivity = target.value;
		});
	}, []);

	async function saveDataType() {
		const findValueInUsageByParamName = connectStoreAndFormData(dataTypesFromStore, formData);

		if (formData.name.length < 3) {
			scrollToTop(detailsSectionRef);
			setNameError(ERROR_TEXTS['nameMinLength']);
			return;
		}

		if (formData.name.length > 255) {
			scrollToTop(detailsSectionRef);
			setNameError(ERROR_TEXTS['nameMaxLength']);
			return;
		}

		if (isNew && findValueInUsageByParamName('name')) {
			scrollToTop(detailsSectionRef);
			setNameError(ERROR_TEXTS['nameInUsage']);
			return;
		}

		if (isNew && findValueInUsageByParamName('alias')) {
			scrollToTop(detailsSectionRef);
			setAliasError(ERROR_TEXTS['aliasInUsage']);
			return;
		}

		if (findEmptyFieldsInTheRules(formData.rules)) {
			scrollToTop(rulesSectionRef);
			enqueueSnackbar(ERROR_TEXTS['emptyRule']);
			return;
		}

		try {
			if (isNew) {
				const newDataType = await setCustomDataType({
					name: formData.name.trim(),
					alias: formData.alias,
					sensitivity: formData.sensitivity,
				});

				await updateCustomDataTypeRules(newDataType.id, { rules: formData.rules });
			} else {
				await updateCustomDataTypeRules(formData.id, { rules: formData.rules });
			}
		} catch (error) {
			if (error instanceof APIError && [400, 409].includes(error.response.status)) {
				const { message } = await error.response.json();

				switch (true) {
					case REGEXP_LIST['nameAndAliasAreRequired'].test(message):
						scrollToTop(detailsSectionRef);
						setNameError(message);
						break;

					case REGEXP_LIST['sensitivityIsRequired'].test(message):
						scrollToTop(sensitivitySectionRef);
						setSensitivityError(true);
						enqueueSnackbar(message);
						break;

					case REGEXP_LIST['duplicateNameOrAlias'].test(message):
						scrollToTop(detailsSectionRef);
						setDuplicateError(message);
						break;

					default:
						scrollToTop(topRef);
						enqueueSnackbar(message);
				}

				return;
			}

			throw error;
		}

		history.push(generatePath(PATHS.DATA_TYPES_LIST));
		enqueueSnackbar(isNew ? `Custom data type ${formData.name} has been saved` : 'Changes saved');
	}

	function onRulesChange(newRules: DataTypeData['rules']) {
		setFormData(function setRulesToFormData(draft) {
			draft.rules = newRules;
		});
	}

	const nameHelperText = useMemo(
		function nameHelperTextMemoization() {
			return (
				nameError ||
				duplicateError ||
				'Only latin letters, numbers, spaces and symbols -_ are allowed. You can only start with letters.'
			);
		},
		[nameError, duplicateError]
	);

	const aliasHelperText = useMemo(
		function aliasHelperTextMemoization() {
			return (
				aliasError ||
				duplicateError ||
				'Keyword is a unique identifier that is assigned automatically to each custom datatype.'
			);
		},
		[duplicateError, aliasError]
	);

	return (
		<div className={styles.container} ref={topRef}>
			<DataTypeFormHeader isNew={isNew} name={data.name} />

			<Section ref={detailsSectionRef} className={styles.detailSection}>
				<Typo variant="D/Medium/H100-Header">Custom data type details</Typo>

				<TextField
					dataTest="custom-data-type-name"
					placeholder="Name"
					label="Name"
					value={formData.name}
					onChange={onChangeName}
					helperText={nameHelperText}
					error={!!nameError || !!duplicateError}
					optional={false}
					classes={{ root: styles.inputRoot }}
					readOnly={!isNew}
				/>

				{isNew && (
					<Alert severity={AlertSeverity['info']} className={styles.alert}>
						You will not be able to change the name afterwards.
					</Alert>
				)}

				<SecretToken
					canBeCopied={true}
					canBeHidden={false}
					optional={false}
					label="Keyword"
					dataTest="custom-data-type-alias"
					helperText={aliasHelperText}
					error={!!duplicateError || !!aliasError}
					placeholder="Keyword"
					value={formData.alias}
					classes={{ root: styles.inputRoot }}
				/>
			</Section>

			<Section error={sensitivityError} ref={sensitivitySectionRef}>
				{!isNew ? (
					<Typo variant="D/Medium/H100-Header" className={styles.sensitivityHeader}>
						Sensitivity <SensitivityChip sensitivity={formData.sensitivity} />
					</Typo>
				) : (
					<>
						<Typo variant="D/Medium/H100-Header">Sensitivity</Typo>

						<div className={styles.radioGroup}>
							<Radio
								name="sensitivity"
								onChange={onChangeSensitivity}
								value="Low"
								label="Low"
								className={styles.assetRadio}
								dataTest="custom-data-type-sensitivity-low"
								checked={formData.sensitivity === 'Low'}
							/>

							<Radio
								name="sensitivity"
								onChange={onChangeSensitivity}
								value="Medium"
								label="Medium"
								className={styles.assetRadio}
								dataTest="custom-data-type-sensitivity-medium"
								checked={formData.sensitivity === 'Medium'}
							/>

							<Radio
								name="sensitivity"
								onChange={onChangeSensitivity}
								value="High"
								label="High"
								className={styles.assetRadio}
								dataTest="custom-data-type-sensitivity-high"
								checked={formData.sensitivity === 'High'}
							/>
						</div>

						<Alert severity={AlertSeverity['info']} className={styles.contentWidth}>
							You will not be able to change the sensitivity afterwards.
						</Alert>
					</>
				)}
			</Section>

			<Section>
				<RuleBuilder initialRules={formData.rules} onChange={onRulesChange} />
			</Section>

			<Section className={styles.plainSection}>
				<DataTypeFormFooter
					isNew={isNew}
					isError={!!nameError || sensitivityError}
					saveForm={saveDataType}
				/>
			</Section>
		</div>
	);
}
