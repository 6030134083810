// ========== List API ==========

import { DataTypeItem } from '../dataTypes/dto.ts';

type PolicyItemWithoutRules = {
	id: number;
	type: 'dim' | 'dar' | 'network';
	name: string;
	description: string;
	tags: string[]; // TODO use this field for Tags component
	data_types: DataTypeItem['id'][];
	is_active: boolean;
	created_at: number;
	updated_at: number;
};

// This API response does not contain policy type-specific fields; they are only present in single policy API.
type PolicyList = {
	policies: PolicyItemWithoutRules[];
	total: number;
};

// ========== Item API ==========

type DIMPolicyRuleItem = {
	type: 'namespace' | 'asset' | 'label' | 'namespace_label' | 'asset_group' | 'cluster' | 'region';
	key: string; // for label only; empty string otherwise
	operator: 'is' | 'is_set' | 'is_not_set';
	values: string[];
};

type DIMPolicyItem = PolicyItemWithoutRules & {
	type: 'dim';
	rules: DIMPolicyRuleItem[][];
	allow_external_transfer: boolean; // TODO to be deleted when Network policies are implemented
	allowed_external_assets: number[]; // TODO to be deleted when Network policies are implemented
	allow_encrypted_network_only: boolean; // TODO to be deleted when Network policies are implemented
};

type DARPolicyRuleItem = {
	type:
		| 's3_bucket'
		| 's3_hostname'
		| 'sql_instance'
		| 'sql_database'
		| 'nosql_instance'
		| 'nosql_database'
		| 'kafka_instance'
		| 'kafka_topic';
	operator: 'is' | 'is_set' | 'is_not_set';
	key: string;
	values: string[];
};

type DARPolicyItem = PolicyItemWithoutRules & {
	type: 'dar';
	rules: DARPolicyRuleItem[][];
	allow_unencrypted_s3_buckets: boolean;
	allow_public_s3_buckets: boolean;
	allow_unencrypted_rds: boolean;
};

type NetworkPolicyRuleItem = {
	// TODO Implement me. That's a tough nut to crack.
};

type NetworkPolicyItem = PolicyItemWithoutRules & {
	type: 'network';
	rules: NetworkPolicyRuleItem[][];
	allow_external_transfer: boolean;
	allow_encrypted_network_only: boolean;
	notifications: {
		severity: 'critical' | 'high' | 'medium' | 'low' | 'info'; // TODO check levels after designs are approved
		webhook: boolean;
		slack: boolean;
	};
};

type PolicyItem = DIMPolicyItem | DARPolicyItem; // TODO | NetworkPolicyItem;

// ========== Labels & helpers ==========

const policyTypeLabels: Record<PolicyItemWithoutRules['type'], string> = {
	dim: 'Data in motion',
	dar: 'Data at rest',
	network: 'Network',
};

function isDIMPolicy(policy: PolicyItem | null): policy is DIMPolicyItem {
	return policy !== null && policy.type === 'dim';
}

function isDARPolicy(policy: PolicyItem | null): policy is DARPolicyItem {
	return policy !== null && policy.type === 'dar';
}

/*
function isNetworkPolicy(
	policy: PolicyItem | null
): policy is NetworkPolicyItem {
	return policy !== null && policy.type === 'network';
}
*/

export { policyTypeLabels, isDIMPolicy, isDARPolicy };
export type {
	PolicyList,
	PolicyItem,
	DIMPolicyItem,
	DARPolicyItem,
	NetworkPolicyItem,
	PolicyItemWithoutRules,
	DIMPolicyRuleItem,
	DARPolicyRuleItem,
};
