import Chip from 'components/Chip';
import { Typo } from 'components/typography/Typo';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { Chat } from './Chat';
import { History } from './History';
import styles from './index.module.pcss';

export function AIAssistant() {
	return (
		<div className={styles.container}>
			<Header>
				<Header.Breadcrumbs finalStep="AI Copilot" />
				<Header.Title>
					<Typo variant="D/Medium/H400-Page-Title" className={styles.header}>
						AI Copilot
						<Chip size="extraSmall" label="beta" theme="assetNeutral" color="secondary" />
					</Typo>
				</Header.Title>
			</Header>

			<History />
			<Chat />
		</div>
	);
}
