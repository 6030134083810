import cn from 'classnames';
import { ChangeEvent, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import BaseModal, { BaseModalTheme } from 'components/BaseModal';
import Button from 'components/form/Button';
import TextField from 'components/form/TextField';
import Radio from 'components/Radio';
import { enqueueSnackbar } from 'components/Snackbar';
import { Typo } from 'components/typography/Typo';
import { ClientItem, InviteClientItem } from 'models/clients/dto';
import { getInviteLinkFx, inviteClientFx } from 'models/clients/effects';
import { APIError } from 'services/api/httpRequest';
import { PATHS } from 'services/router';
import styles from './index.module.pcss';

type FormValues = {
	email: string;
};

function InviteMemberModal({
	clients,
	open,
	onClose,
}: {
	clients: ClientItem[];
	open: boolean;
	onClose: () => void;
}) {
	const [radioValue, setRadioValue] = useState<InviteClientItem['role']>('admin');
	const [isSaving, setSaving] = useState(false);
	const [inviteLink, setInviteLink] = useState<string>('');
	const [inviteError, setInviteError] = useState<string>('');

	const isInviteLink = useMemo(() => inviteLink !== '', [inviteLink]);

	const { control, handleSubmit, reset } = useForm<FormValues>({
		defaultValues: {
			email: '',
		},

		mode: 'onBlur', // "onChange"
	});

	async function generateInviteLink({ email }: FormValues) {
		setSaving(true);

		if (isEmailAlreadyExists(email)) {
			setInviteError('User with this email already on this team');
		} else {
			try {
				const { client_id } = await inviteClientFx({ full_name: '', role: radioValue, email });
				const { token } = await getInviteLinkFx(client_id);
				const value = `${window.location.origin}${PATHS.ACCEPT_INVITE}?token=${token}`;

				setInviteLink(value);
			} catch (error) {
				if (error instanceof APIError && error.response.status === 400) {
					const { message } = await error.response.json();
					setInviteError(message);
				} else {
					throw error;
				}
			}
		}

		setSaving(false);
	}

	const copyTextHandler = () => {
		navigator.clipboard.writeText(String(inviteLink)).then(() => {
			enqueueSnackbar('Invite link copied');
		});
	};

	function isEmailAlreadyExists(emailValue: string) {
		return clients.some((client) => client.email === emailValue);
	}

	function onChangeRadio({ target }: ChangeEvent<HTMLInputElement>) {
		setInviteError('');
		const _value = target.value as InviteClientItem['role'];
		setRadioValue(_value);
	}

	function refreshModal() {
		setInviteLink('');
		setInviteError('');
		setRadioValue('admin');
		reset({ email: '' });
		onClose();
	}

	return (
		<BaseModal
			title="Invite a new team member"
			open={open}
			theme={BaseModalTheme.normal}
			onClose={refreshModal}
			className={styles.titleModal}
		>
			<Typo variant="D/Regular/Body-S" className={styles.description}>
				{inviteLink
					? 'Copy link below and send it to a person you want to invite.'
					: 'Enter email of a person you want to invite.'}
			</Typo>

			<form onSubmit={handleSubmit(generateInviteLink)}>
				<TextField
					control={control}
					autoFocus
					size="medium"
					label="Email"
					type="email"
					name="email"
					autoComplete="username"
					fullWidth
					required
					error={Boolean(inviteError)}
					helperText={inviteError}
					disabled={Boolean(inviteLink)}
					dataTest="invite-member-input"
				/>

				<div className={styles.groupsContainer}>
					{/*
					 TODO: revert it when contributor role will be available
					<Radio
						name="radio-buttons-roles"
						onChange={onChangeRadio}
						value="contributor"
						label="Contributor"
					/>
					*/}

					<Radio
						name="radio-buttons-roles"
						onChange={onChangeRadio}
						disabled={Boolean(inviteLink)}
						value="admin"
						label="Admin"
						dataTest="invite-member-role-item"
					/>
				</div>

				{!isInviteLink ? (
					<div className={styles.buttonsContainer}>
						<Button
							onClick={onClose}
							color="ghost"
							size="small"
							className={styles.button}
							data-test="invite-member-cancel-button"
						>
							Cancel
						</Button>

						<Button
							type="submit"
							size="small"
							loading={isSaving}
							data-test="invite-member-invite-button"
						>
							Generate invite link
						</Button>
					</div>
				) : (
					<div className={cn(styles.buttonsContainer, styles.secondButtonsContainer)}>
						<Typo
							variant="D/Medium/Body-S"
							className={styles.inviteLink}
							dataTest="invite-member-invite-link"
						>
							{inviteLink}
						</Typo>

						<Button
							onClick={copyTextHandler}
							size="small"
							className={styles.button}
							data-test="invite-member-copy-link-button"
						>
							Copy link
						</Button>
					</div>
				)}
			</form>
		</BaseModal>
	);
}

export default InviteMemberModal;
