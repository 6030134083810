import { ChangeEvent, FocusEvent, KeyboardEvent, useRef, useState } from 'react';
import TextField from 'components/form/TextField';
import { Typo } from 'components/typography/Typo';
import styles from './index.module.css';

type Props = {
	type: 'key' | 'value';
	value: string;
	onChange: (value: string) => void;
	onConfirm: () => void;
	onChangeAndConfirm: (value: string) => void;
	error: string | null;
};

function RuleInput(props: Props) {
	const { type, value, onChange, onConfirm, onChangeAndConfirm, error } = props;

	const inputRef = useRef<HTMLInputElement>(null);

	const [isFocused, setFocused] = useState(false);
	const [savedValue, setSavedValue] = useState(value); // For Escape key.
	const [touched, setTouched] = useState(false);

	function onKeyDown(e: KeyboardEvent<HTMLElement>) {
		// Special keys handling could be made inside parent component. But we need to keep it here for 'defocusing' behaviour, which uses ref to DOM element.

		if (e.key === 'Enter') {
			// After pressing Enter, defocus.
			e.preventDefault();
			inputRef.current?.blur();
		} else if (e.key === 'Escape') {
			// After pressing comma, restore value that was at the moment of focusing, and defocus.
			e.preventDefault();
			inputRef.current?.blur();
			onChangeAndConfirm(savedValue);
		}
	}

	function handleChange(e: ChangeEvent<HTMLInputElement>) {
		onChange(e.target.value);
	}

	function onFocus() {
		setFocused(true);
		setTouched(true);
		setSavedValue(value);
	}

	function onBlur(e: FocusEvent<HTMLInputElement>) {
		if (e.target.parentNode?.contains(e.relatedTarget as Node)) {
			e.preventDefault();
			e.stopPropagation();
			return;
		}

		setFocused(false);
		onConfirm();
	}

	const dataTest = type === 'key' ? 'data-type-rules-key-input' : 'data-type-rules-value-input';
	// Activate autofocus:
	// 1. only for first input, when we add new key-value pair
	// 2. when input is exist
	const autoFocus = (type !== 'value' && !touched) || !!value;
	return (
		<>
			<TextField
				dataTest={dataTest}
				size="extraSmall"
				helperText={null}
				error={touched && !isFocused && !!error}
				autoFocus={autoFocus}
				value={value}
				onKeyDown={onKeyDown}
				onChange={handleChange}
				InputProps={{
					inputRef,
					onFocus,
					onBlur,
					classes: {
						root: styles.input,
					},
				}}
			/>

			<Typo variant="D/Medium/Body-S" color="secondary" className={styles.label}>
				/
			</Typo>

			{touched && !isFocused && error && (
				<Typo variant="D/Regular/Meta-S" className={styles.errorText}>
					{error}
				</Typo>
			)}
		</>
	);
}

export default RuleInput;
