import { TestWebhook, WebhookSettings } from './dto';

const webhookSettingsExample: WebhookSettings = {
	status: 'unknown',
	secret_token: 'bh3GHG43GDGHV67vcscnjkU81bjjBHK213NMHvbdwjjhjndjj32JHk3458BGJKMN346FGB9',
	url: 'https://en.wikipedia.org/wiki/Banksia_brownii',
	notify: ['misconfiguration'],
	is_enabled: false,
};

const testWebhookExample: TestWebhook = {
	last_seen: 1638267989332,
	error_message: 'string',
};

export { webhookSettingsExample, testWebhookExample };
