import SensitivityCircle from 'components/SensitivityCircle';
import { Tooltip } from 'components/Tooltip';
import { DataTypeItem } from 'models/dataTypes/dto';
import { useSensitivityData } from 'models/dataTypes/hooks';

interface Props {
	className?: string;
	id: DataTypeItem['id'];
	isFP: boolean;
	isManual: boolean;
	isCorrected: boolean;
	size?: 'M' | 'S' | 'XS';
}

function Mark({ id, isFP, isManual, isCorrected, className, size = 'M' }: Props) {
	const { sensitivityLevel } = useSensitivityData(id, isFP);

	const tooltipText: string | null = isFP
		? 'False positive'
		: isManual
		? 'Manually assigned'
		: isCorrected
		? 'Custom, can’t be marked as false positive'
		: null;

	if (tooltipText) {
		return (
			<Tooltip title={tooltipText} placement="right">
				<SensitivityCircle
					sensitivityLevel={sensitivityLevel}
					changed={isFP || isManual}
					className={className}
					size={size === 'S' ? 'extraSmall' : 'medium'}
				/>
			</Tooltip>
		);
	}

	return (
		<SensitivityCircle
			sensitivityLevel={sensitivityLevel}
			changed={isFP || isManual}
			className={className}
			size={size === 'S' ? 'extraSmall' : 'medium'}
		/>
	);
}

export default Mark;
