import cn from 'classnames';
import Button from 'components/form/Button';
import { Typo } from 'components/typography/Typo';
import styles from './index.module.css';

type Props = {
	onReset: () => void;
	className?: string;
	description?: string;
};

function ResetFilters(props: Props) {
	const description = props.description || (
		<span>
			No results matching these filters or no sensitive data.
			<br />
			If resetting the filters didn&apos;t help, try disabling the switcher
			<br />
			With sensitive data only.
		</span>
	);

	return (
		<div className={cn(styles.container, props.className)}>
			<Typo variant="D/Regular/Body-S" className={styles.description} color="secondary">
				{description}
			</Typo>

			<Button
				color="tertiary"
				className={styles.button}
				onClick={props.onReset}
				data-test="reset-filters"
			>
				Reset filters
			</Button>
		</div>
	);
}

export default ResetFilters;
