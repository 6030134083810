import { useStore } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';
import MultiSelect from 'components/form/MultiSelect';
import DropdownButton from 'components/form/Select/DropdownButton';
import { ClusterGeoLocationRegionItem } from 'models/clusterGeoLocations/dto';
import { getClusterGeoLocationRegionsFx } from 'models/clusterGeoLocations/effects';
import { clusterGeoLocationRegionsList } from 'models/clusterGeoLocations/store';
import { onceFx } from 'models/modelUtils/onceFx';
import { FilterPropsBase } from '../model';

type Option = {
	name: ClusterGeoLocationRegionItem['description'];
	id: ClusterGeoLocationRegionItem['keyword'];
};

type Props = FilterPropsBase & {
	value: ClusterGeoLocationRegionItem['keyword'][];
	onChange: (newValue: ClusterGeoLocationRegionItem['keyword'][]) => void;
};

export function RegionFilter({ defaultOpen, fixed, onChange, onClose, resetFilter, value }: Props) {
	const regions = useStore(clusterGeoLocationRegionsList);

	useEffect(() => {
		onceFx(getClusterGeoLocationRegionsFx);
	}, []);

	const groupedValues = useMemo(() => {
		return regions.map((region) => {
			return { id: region.keyword, name: region.description };
		});
	}, [regions]);

	const values = useMemo(() => {
		return groupedValues.filter((_value) => value.includes(_value.id));
	}, [value, groupedValues]);

	const handleChange = (options: Option[]) => {
		onChange(options.map((option) => option.id));
	};

	const dropdownButton = useCallback(
		(dropdownProps) => <DropdownButton {...dropdownProps} fixed={fixed} onClose={resetFilter} />,
		[fixed, resetFilter]
	);

	return (
		<MultiSelect
			defaultOpen={defaultOpen}
			label={{ primary: 'Regions', secondary: `${values.length || ''}` }}
			options={groupedValues}
			value={values}
			onChange={handleChange}
			onClose={onClose}
			render={{ dropdownButton }}
			hasSearch
			hasApplyButton
		/>
	);
}
