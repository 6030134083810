import { Fragment, useMemo, useState } from 'react';
import ButtonIcon from 'components/ButtonIcon';
import Button from 'components/form/Button';
import Icon from 'components/Icon';
import { Typo } from 'components/typography/Typo';
import { CustomDataTypeRulesResponse } from 'models/dataTypes/dto';
import { DataTypeData } from '../../index';
import styles from './index.module.css';
import { RuleRow } from './RuleRow';

/*
	HELPERS
*/

type TransformedRule = {
	id: number; // Unique id is necessary, see above comment.
	key: string;
	value: string;
};

function nextId() {
	return nextId.val++;
}
nextId.val = 1;

function mapRulesToTransformed(rules: CustomDataTypeRulesResponse['rules']): TransformedRule[] {
	return rules.map(function addIdToRule(rule) {
		return { id: nextId(), key: rule.key, value: rule.value };
	});
}

function mapTransformedToRules(
	transformedRules: TransformedRule[]
): CustomDataTypeRulesResponse['rules'] {
	// Called on each change on form

	return transformedRules.map(function removeIdFromRule({ key, value }) {
		return { key, value };
	});
}

/*
	MAIN COMPONENT

	Uncontrolled by design.
*/
type Props = {
	initialRules: DataTypeData['rules'];
	onChange: (rules: DataTypeData['rules']) => void;
};

export function RuleBuilder({ initialRules, onChange }: Props) {
	const initialRulesTransformed = useMemo(function initialRulesTransformedMemoization() {
		return mapRulesToTransformed(initialRules);
	}, []); // no dependencies, must only be called once on mount.

	const [rulesTransformed, _setRulesTransformed] =
		useState<TransformedRule[]>(initialRulesTransformed);

	function setRulesTransformed(newRules: TransformedRule[]) {
		_setRulesTransformed(newRules);
		onChange(mapTransformedToRules(newRules));
	}

	function onChangeKey(oldRule: TransformedRule) {
		return function (newValue: TransformedRule['key']) {
			onChangeValues({ ...oldRule, key: newValue });
		};
	}

	function onChangeValue(oldRule: TransformedRule) {
		return function (newValue: TransformedRule['value']) {
			onChangeValues({ ...oldRule, value: newValue });
		};
	}

	function onChangeValues(newRule: TransformedRule) {
		const newRules = rulesTransformed.map(function findAndChangeOldRuleById(tr) {
			return tr.id !== newRule.id ? tr : newRule;
		});

		setRulesTransformed(newRules);
	}

	function addUserAgentRule() {
		const result = rulesTransformed.concat({
			id: nextId(),
			key: '',
			value: '',
		});

		setRulesTransformed(result);
	}

	function deleteRule(oldRule: TransformedRule) {
		const newRules = rulesTransformed.filter(function filterRuleById(tr) {
			return tr.id !== oldRule.id;
		});

		setRulesTransformed(newRules);
	}

	return (
		<>
			<Typo variant="D/Medium/H100-Header">Match pattern</Typo>

			<Typo variant="D/Regular/Body-S" className={styles.rulesDescription}>
				Enter regular expressions that define patterns for the key and value to match. <br />
				It can be edited any time after creating the data type.
			</Typo>

			<div className={styles.rulesGrid}>
				{rulesTransformed.map(function renderRules(transformedRule, i) {
					return (
						<Fragment key={transformedRule.id}>
							<Typo variant="D/Medium/Body-S">{i === 0 ? 'for' : 'or'}</Typo>

							<div className={styles.ruleContainer} data-test="asset-rules-list">
								<RuleRow
									type="key"
									value={transformedRule.key}
									onChange={onChangeKey(transformedRule)}
								/>

								<RuleRow
									type="value"
									value={transformedRule.value}
									onChange={onChangeValue(transformedRule)}
									prefix="and"
								/>
							</div>

							<ButtonIcon
								icon="Delete/Filled"
								size="XS"
								className={styles.deleteButton}
								dataTest="asset-rules-delete-button"
								onClick={function deleteRuleHandler() {
									deleteRule(transformedRule);
								}}
							/>
						</Fragment>
					);
				})}
			</div>

			<div className={styles.ruleButtonList}>
				<Button
					color="tertiary"
					size="small"
					className={styles.ruleButton}
					data-test="asset-info-add-user-agent-button"
					onClick={addUserAgentRule}
				>
					<Icon name="Add/Regular" size={18} className={styles.buttonIcon} />
					Add key-value pair
				</Button>
			</div>
		</>
	);
}

export type { TransformedRule };
export { nextId };
